import React, { useState, useRef, forwardRef, useEffect } from 'react';
import styled from 'styled-components';
import { formatToPhone } from '../../hooks/useTextHelper';

const PhoneInput = forwardRef(
  ({ value, onChange, onFocus, onBlur, disabled = false }, ref) => {
    const isInitialMount = useRef(true);
    const [phone, setPhone] = useState(value ? formatToPhone(value) : '');

    const onValueChange = e => {
      const digits =
        e.target.value.length > 9
          ? formatToPhone(e.target.value)
          : e.target.value;
      setPhone(digits);
      onChange(digits);
    };

    useEffect(() => {
      if (isInitialMount.current) {
        isInitialMount.current = false;
        onChange(phone);
      }
    }, [onChange, phone]);

    return (
      <TextInput
        ref={ref}
        onChange={onValueChange}
        onFocus={onFocus ? e => onFocus(e.target.value) : null}
        onBlur={onBlur ? e => onBlur(e.target.value) : null}
        placeholder="(123) 456-7890"
        type="text"
        name="phone"
        value={phone}
        maxLength={14}
        disabled={disabled}
      />
    );
  },
);

const TextInput = styled.input``;

export default PhoneInput;
