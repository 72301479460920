import React from 'react';
import styled, { keyframes } from 'styled-components';
import { FaHeart } from 'react-icons/fa';
import { LineChart, Line } from 'recharts';
import BatteryMeter from './BatteryMeter';

const PulseOx = ({
  battery,
  pi,
  pleth,
  pulse,
  spo2,
  active,
  widget,
  saved,
}) => (
  <Container widget={widget}>
    <ReadingWrapper>
      <ReadingBox active={active}>
        <FaHeart />
        <Reading>
          <Value>{pulse}</Value>
          <Label>BPM</Label>
        </Reading>
      </ReadingBox>
      <ReadingBox>
        <Reading>
          <Value>{spo2}</Value>
          <Label>
            %SpO<sub>2</sub>
          </Label>
        </Reading>
      </ReadingBox>
    </ReadingWrapper>
    <LineChart width={widget ? 120 : 180} height={70} data={pleth}>
      <Line
        type="monotone"
        dataKey="value"
        dot={false}
        stroke="#91D4F2"
        strokeWidth={3}
        animationDuration={1500}
      />
    </LineChart>
    {battery ? <BatteryMeter value={battery} /> : null}
  </Container>
);

const heartbeat = keyframes`
  0% {
    transform: scale(1);
  }
  60% {
    transform: scale(1);
  }
  70% {
    transform: scale(1.4);
  }
  80% {
    transform: scale(1.2);
  }
  90% {
    transform: scale(1.4);
  }
  100% {
    transform: scale(1);
  }
`;

const ReadingWrapper = styled.div`
  margin-right: 20px;
`;

const ReadingBox = styled.div`
  position: relative;

  svg {
    position: absolute;
    top: 10px;
    left: -24px;
    font-size: 16px;
    color: ${props => props.theme.colors.primary};
    transform: scale(1);

    animation-name: ${props => (props.active ? heartbeat : '')};
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards;
  }
`;

const Reading = styled.div`
  text-align: right;
`;

const Value = styled.div`
  font-size: 36px;
  color: ${props => props.theme.textColors.primary};
  font-weight: ${props => props.theme.fontWeights.bold};
`;

const Label = styled.label`
  padding-right: 3px;
  text-transform: none;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-left: 24px;

  ${({ widget }) =>
    widget &&
    `
    ${ReadingWrapper} {
      margin-right: 15px;
    }
  `}
`;

export default PulseOx;
