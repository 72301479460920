// @flow
import React, { createContext, useReducer } from 'react';

const INITIAL_STATE = {
  audioTrack: null,
  videoTrack: null,
};

export const MediaContext = createContext(INITIAL_STATE);

const Context = ({ children }) => {
  const reducer = (state, { type, data }) => {
    switch (type) {
      case 'SET_MEDIA': {
        return {
          ...state,
          ...data,
        };
      }
      case 'CLEAR': {
        return INITIAL_STATE;
      }
      default: {
        return state;
      }
    }
  };

  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <MediaContext.Provider value={{ state, dispatch }}>
      {children}
    </MediaContext.Provider>
  );
};

export default Context;
