import { UPDATE_PRIMARY_CONDITIONS } from '../App/api/patients';

export const DIAGNOSES_FORM = {
  fields: [
    {
      id: 'conditions',
      type: 'search',
      label: 'Patient Diagnoses',
      config: {
        fill: true,
        mode: 'icd10',
        multiple: true,
        placeholder: 'Lookup diagnoses by name or ICD10 code',
      },
    },
  ],
  mutation: UPDATE_PRIMARY_CONDITIONS,
  maxWidth: '640px',
};
