const ABDOMEN_FORM = {
  fields: [
    {
      id: 'giInputOutput',
      sctid: {
        anatomy: ['122865005'],
      },
      type: 'checklist',
      label: 'GI Input/Output',
      config: {
        options: {
          normal: {
            sctid: '300438007',
            label: 'Normal',
          },
          anorexia: {
            sctid: '79890006',
            label: 'Anorexia',
          },
          weightLoss: {
            sctid: '89362005',
            label: 'Weight Loss',
          },
          weightGain: {
            sctid: '8943002',
            label: 'Weight Gain',
          },
          vomitting: {
            sctid: '249497008',
            label: 'Vomitting',
          },
          diarrhea: {
            sctid: '62315008',
            label: 'Diarrhea',
          },
          stoolBlood: {
            sctid: '405729008',
            label: 'Blood in Stool',
          },
        },
      },
    },
    {
      id: 'guOutput',
      sctid: {
        anatomy: ['21514008'],
      },
      type: 'checklist',
      label: 'GU Output',
      config: {
        options: {
          normal: {
            sctid: '300561007',
            label: 'Normal',
          },
          urination: {
            sctid: '49650001',
            label: 'Urination Painful',
          },
          urgency: {
            sctid: '75088002',
            label: 'Urgency',
          },
          frequency: {
            sctid: '162116003',
            label: 'Increased Frequency',
          },
          vaginalDischarge: {
            sctid: '271939006',
            label: 'Vaginal Discharge',
          },
          penileDischarge: {
            sctid: '2910007',
            label: 'Penile Discharge',
          },
        },
      },
    },
    {
      id: 'inspectionAbdomen',
      sctid: {
        action: '129433002',
        anatomy: ['818983003'],
      },
      type: 'checklist',
      label: 'Inspection of Abdomen',
      config: {
        options: {
          distended: {
            sctid: '60728008',
            label: 'Distended',
          },
          painLower: {
            sctid: '425860006',
            label: 'Pain in Lower',
          },
          painUpper: {
            sctid: '427075000',
            label: 'Pain in Upper',
          },
          feedingTube: {
            sctid: '61420007',
            label: 'Feeding Tube',
          },
          drainageTube: {
            sctid: '449895007',
            label: 'Drainage Tube',
          },
          skinAppearance: {
            sctid: '225544001',
            label: 'Skin Apperance Normal',
          },
          skinSymptom: {
            sctid: '308926009',
            label: 'Skin Symptom',
          },
        },
      },
    },
    {
      id: 'inspectionGU',
      sctid: {
        action: '129433002',
        anatomy: ['21514008'],
      },
      type: 'checklist',
      label: 'Inspection of GU',
      config: {
        options: {
          guNormal: {
            sctid: '300561007',
            label: 'GU Normal',
          },
          guAbnormal: {
            sctid: '300562000',
            label: 'GU Abnormal',
          },
          skinSymptom: {
            sctid: '308926009',
            label: 'Skin symptom',
          },
        },
      },
    },
    {
      id: 'palpationAbdomen',
      sctid: {
        action: '129434008',
        anatomy: ['818983003'],
      },
      type: 'checklist',
      label: 'Palpation of Abdomen',
      config: {
        options: {
          mass: {
            sctid: '300848003',
            label: 'Mass',
          },
          tenderness: {
            sctid: '247348008',
            label: 'Tenderness',
          },
          hotSkin: {
            sctid: '707793005',
            label: 'Hot Skin',
          },
        },
      },
    },
    {
      id: 'notes',
      type: 'textarea',
      label: 'Notes',
      config: {
        bgColor: '#F8F7F5',
      },
    },
  ],
};

const CONSTITUTIONAL_FORM = {
  fields: [
    {
      id: 'vitalsHeader',
      type: 'header',
      label: 'Interpretation of Vitals',
    },
    {
      id: 'vitals-top-row',
      type: 'row',
      fields: [
        {
          id: 'temperature',
          sctid: {
            action: '703421000',
          },
          type: 'vitalReview',
          label: 'Temperature',
          config: {
            options: {
              normal: {
                sctid: '87273009',
                label: 'Normal',
              },
              abnormal: {
                sctid: '123979008',
                label: 'Abnormal',
              },
            },
            fill: true,
            radio: true,
          },
        },
        {
          id: 'bp',
          sctid: {
            action: '75367002',
          },
          type: 'vitalReview',
          label: 'Blood Pressure',
          config: {
            options: {
              normal: {
                sctid: '2004005',
                label: 'Normal',
              },
              abnormal: {
                sctid: '38936003',
                label: 'Abnormal',
              },
            },
            fill: true,
            radio: true,
          },
        },
      ],
    },
    {
      id: 'vitals-bot-row',
      type: 'row',
      fields: [
        {
          id: 'pulse',
          sctid: {
            action: '8499008',
          },
          type: 'vitalReview',
          label: 'Pulse',
          config: {
            options: {
              normal: {
                sctid: '12146004',
                label: 'Normal',
              },
              abnormal: {
                sctid: '67683007',
                label: 'Abnormal',
              },
            },
            fill: true,
            radio: true,
          },
        },
        {
          id: 'O2',
          sctid: {
            action: '103228002',
          },
          type: 'vitalReview',
          label: 'O2 Saturation',
          config: {
            options: {
              normal: {
                sctid: '448225001',
                label: 'Normal',
              },
              low: {
                sctid: '449171008',
                label: 'Low',
              },
            },
            fill: true,
            radio: true,
          },
        },
      ],
    },
    {
      id: 'alertnessHeader',
      type: 'header',
      label: 'Inspect for Mental Alertness',
    },
    {
      id: 'alertness',
      sctid: {
        action: '248233002',
      },
      type: 'checklist',
      label: 'Alertness',
      config: {
        options: {
          alert: {
            sctid: '248234008',
            label: 'Alert',
          },
          notAlert: {
            sctid: '704426000',
            label: 'Not Alert',
          },
        },
        radio: true,
      },
    },
    {
      id: 'appearanceHeader',
      type: 'header',
      label: 'Inspect General Appearance',
    },
    {
      id: 'appearance-top-row',
      type: 'row',
      fields: [
        {
          id: 'nourishment',
          sctid: {
            action: '363791007',
          },
          type: 'checklist',
          label: 'Nourishment',
          config: {
            options: {
              nourished: {
                sctid: '248324001',
                label: 'Well nourished',
              },
              cachetic: {
                sctid: '238108007',
                label: 'Cachetic',
              },
            },
            fill: true,
            radio: true,
          },
        },
        {
          id: 'mood',
          sctid: {
            action: '363791007',
          },
          type: 'checklist',
          label: 'Mood',
          config: {
            options: {
              calm: {
                sctid: '102894008',
                label: 'Calm',
              },
              distressed: {
                sctid: '224977004',
                label: 'Distressed',
              },
            },
            fill: true,
            radio: true,
          },
        },
      ],
    },
    {
      id: 'appearance-bot-row',
      type: 'row',
      fields: [
        {
          id: 'hygiene',
          sctid: {
            action: '363791007',
          },
          type: 'checklist',
          label: 'Hygiene',
          config: {
            options: {
              groomed: {
                sctid: '398168006',
                label: 'Groomed',
              },
              disheveled: {
                sctid: '78158007',
                label: 'Disheveled',
              },
            },
            fill: true,
            radio: true,
          },
        },
        {
          id: 'wellness',
          sctid: {
            action: '363791007',
          },
          type: 'checklist',
          label: 'Wellness',
          config: {
            options: {
              well: {
                sctid: '102512003',
                label: 'Well',
              },
              toxic: {
                sctid: '56302003',
                label: 'Toxic',
              },
            },
            fill: true,
            radio: true,
          },
        },
      ],
    },
    {
      id: 'notes',
      type: 'textarea',
      label: 'Notes',
      config: {
        bgColor: '#F8F7F5',
      },
    },
  ],
};

const CHEST_FORM = {
  fields: [
    {
      id: 'respiratoryEffort',
      sctid: {
        action: '248565000',
      },
      type: 'checklist',
      label: 'Respiratory Effort',
      config: {
        options: {
          normal: {
            sctid: '22803001',
            label: 'Normal',
          },
          abnormal: {
            sctid: '386813002',
            label: 'Abnormal',
          },
        },
        radio: true,
      },
    },
    {
      id: 'palpationChest',
      sctid: {
        action: '129434008',
        anatomy: ['51185008'],
      },
      type: 'checklist',
      label: 'Palpation of External Chest',
      config: {
        options: {
          normal: {
            sctid: '830011000',
            label: 'Normal',
          },
          chestWallPain: {
            sctid: '102588006',
            label: 'Chest Wall Pain',
          },
          skinSymptom: {
            sctid: '308926009',
            label: 'Skin symptom',
          },
          chestPain: {
            sctid: '29857009',
            label: 'Chest Pain',
          },
        },
      },
    },
    {
      id: 'inquiryHeart',
      sctid: {
        action: '129433002',
        anatomy: ['51185008'],
      },
      type: 'checklist',
      label: 'Inquiry of Heart',
      config: {
        options: {
          painExertion: {
            sctid: '300995000',
            label: 'Pain with Exertion',
          },
          dyspneaExertion: {
            sctid: '60845006',
            label: 'Dyspnea with Exertion',
          },
          diaphoresis: {
            sctid: '52613005',
            label: 'Diaphoresis',
          },
          nausea: {
            sctid: '422587007',
            label: 'Nausea',
          },
          radiatingChestPain: {
            sctid: '10000006',
            label: 'Radiating Chest Pain',
          },
        },
      },
    },
    {
      id: 'inquiryLungs',
      sctid: {
        action: '129433002',
        anatomy: ['74101002'],
      },
      type: 'checklist',
      label: 'Inquiry of Lungs',
      config: {
        options: {
          sob: {
            sctid: '267036007',
            label: 'SOB',
          },
          cough: {
            sctid: '49727002',
            label: 'Cough',
          },
          sputum: {
            sctid: '248596009',
            label: 'Sputum',
          },
          pleuriticPain: {
            sctid: '2237002',
            label: 'Pleuritic Pain',
          },
        },
      },
    },
    {
      id: 'auscultationLungs',
      sctid: {
        action: '129436005',
        anatomy: ['74101002'],
      },
      type: 'checklist',
      label: 'Auscultation of Lungs',
      config: {
        options: {
          normal: {
            sctid: '22803001',
            label: 'Normal',
          },
          distressedBreathing: {
            sctid: '271825005',
            label: 'Distressed Breathing',
          },
          wheezing: {
            sctid: '56018004',
            label: 'Wheezing',
          },
          crackles: {
            sctid: '48409008',
            label: 'Crackles',
          },
          stridor: {
            sctid: '68095009',
            label: 'Stridor',
          },
        },
      },
    },
    {
      id: 'breasts',
      sctid: {
        action: '129433002',
        anatomy: ['63762007'],
      },
      type: 'checklist',
      label: 'Inspection of Breasts',
      config: {
        options: {
          lump: {
            sctid: '89164003',
            label: 'Lump',
          },
          painful: {
            sctid: '53430007',
            label: 'Painful',
          },
          skinTexture: {
            sctid: '274672009',
            label: 'Changes in Skin Texture',
          },
          dischargeNipple: {
            sctid: '54302000',
            label: 'Discharge from Nipple',
          },
        },
        locations: [
          {
            sctid: '7771000',
            label: 'Left',
          },
          {
            sctid: '24028007',
            label: 'Right',
          },
        ],
      },
    },
    {
      id: 'lympathics',
      sctid: {
        action: '129434008',
        anatomy: ['89890002'],
      },
      type: 'checklist',
      label: 'Palpation of Lymphatics',
      config: {
        options: {
          axillary: {
            sctid: '8171009',
            label: 'Axillary Lymph Node',
          },
          supraclavicular: {
            sctid: '76838003',
            label: 'Supraclavicular Lymph Node',
          },
        },
        locations: [
          {
            sctid: '7771000',
            label: 'Left',
          },
          {
            sctid: '24028007',
            label: 'Right',
          },
        ],
      },
    },
    {
      id: 'notes',
      type: 'textarea',
      label: 'Notes',
      config: {
        bgColor: '#F8F7F5',
      },
    },
  ],
};

const ENT_FORM = {
  fields: [
    {
      id: 'leftEarHeader',
      label: 'Left Ear',
      type: 'header',
    },
    {
      id: 'inspectionLeftEar',
      sctid: {
        action: '129433002',
        anatomy: ['89644007'],
      },
      type: 'checklist',
      label: 'Inspection of Left Ear',
      config: {
        options: {
          normal: {
            sctid: '300196000',
            label: 'Normal',
          },
          pain: {
            sctid: '16001004',
            label: 'Pain',
          },
          discharge: {
            sctid: '300132001',
            label: 'Discharge',
          },
          ringing: {
            sctid: '162352007',
            label: 'Ringing',
          },
          hearingLoss: {
            sctid: '14230001000004101',
            label: 'Perception of Hearing Loss',
          },
          skinSymptom: {
            sctid: '308926009',
            label: 'Skin symptom',
          },
        },
      },
    },
    {
      id: 'rightEarHeader',
      label: 'Right Ear',
      type: 'header',
    },
    {
      id: 'inspectionRightEar',
      sctid: {
        action: '129433002',
        anatomy: ['25577004'],
      },
      type: 'checklist',
      label: 'Inspection of Right Ear',
      config: {
        options: {
          normal: {
            sctid: '300196000',
            label: 'Normal',
          },
          pain: {
            sctid: '16001004',
            label: 'Pain',
          },
          discharge: {
            sctid: '300132001',
            label: 'Discharge',
          },
          ringing: {
            sctid: '162352007',
            label: 'Ringing',
          },
          hearingLoss: {
            sctid: '14230001000004101',
            label: 'Perception of Hearing Loss',
          },
          skinSymptom: {
            sctid: '308926009',
            label: 'Skin symptom',
          },
        },
      },
    },
    {
      id: 'noseHeader',
      label: 'Nose',
      type: 'header',
    },
    {
      id: 'inspectionNose',
      sctid: {
        action: '129433002',
        anatomy: '45206002',
      },
      type: 'checklist',
      label: 'Inspection of Nose',
      config: {
        options: {
          normal: {
            sctid: '301198009',
            label: 'Normal',
          },
          flaring: {
            sctid: '248568003',
            label: 'Nasal Flaring',
          },
          bleeding: {
            sctid: '249366005',
            label: 'Bleeding',
          },
          discharge: {
            sctid: '64531003',
            label: 'Discharge',
          },
          skinSymptom: {
            sctid: '308926009',
            label: 'Skin symptom',
          },
        },
      },
    },
    {
      id: 'throatHeader',
      label: 'Throat',
      type: 'header',
    },
    {
      id: 'inspectionThroat',
      sctid: {
        action: '129433002',
        anatomy: '49928004',
      },
      type: 'checklist',
      label: 'Inspection of Throat',
      config: {
        options: {
          normal: {
            sctid: '300280008',
            label: 'Normal',
          },
          edema: {
            sctid: '421581006',
            label: 'Edema',
          },
          sore: {
            sctid: '162397003',
            label: 'Sore',
          },
          dry: {
            sctid: '102618009',
            label: 'Dry',
          },
          exudate: {
            sctid: '301791008',
            label: 'Exudate',
          },
          erythema: {
            sctid: '47441003',
            label: 'Erythema',
          },
          oralLesion: {
            sctid: '1071000119107',
            label: 'Oral lesion',
          },
          stridor: {
            sctid: '68095009',
            label: 'Stridor',
          },
        },
      },
    },
    {
      id: 'mouthHeader',
      label: 'Mouth',
      type: 'header',
    },
    {
      id: 'inspectionMouth',
      sctid: {
        action: '129433002',
        anatomy: '21082005',
      },
      type: 'checklist',
      label: 'Inspection of Mouth',
      config: {
        options: {
          normal: {
            sctid: '162010006',
            label: 'Normal',
          },
          painful: {
            sctid: '102616008',
            label: 'Painful',
          },
          bleeding: {
            sctid: '22490002',
            label: 'Bleeding',
          },
          lingualEdema: {
            sctid: '81950002',
            label: 'Lingual Edema',
          },
          oralLesion: {
            sctid: '1071000119107',
            label: 'Oral Lesion',
          },
          sublingual: {
            sctid: '122861001',
            label: 'Sublingual',
          },
        },
      },
    },
    {
      id: 'notes',
      type: 'textarea',
      label: 'Notes',
      config: {
        bgColor: '#F8F7F5',
      },
    },
  ],
};

const MOVEMENT_CONFIG = {
  options: {
    normal: {
      sctid: '298228000',
      label: 'Normal',
    },
    muscleWeakness: {
      sctid: '26544005',
      label: 'Muscle Weakness',
    },
    paralysis: {
      sctid: '44695005',
      label: 'Paralysis',
    },
    cramping: {
      sctid: '55300003',
      label: 'Cramping',
    },
    pain: {
      sctid: '22253000',
      label: 'Pain',
    },
    contracture: {
      sctid: '57048009',
      label: 'Contracture',
    },
  },
};

const SKIN_CONFIG = {
  options: {
    normal: {
      sctid: '225544001',
      label: 'Normal',
    },
    skinSymptom: {
      sctid: '308926009',
      label: 'Skin Symptom',
    },
    skinColor: {
      sctid: '304232002',
      label: 'Skin Color Poor',
    },
    skinInfestation: {
      sctid: '113421000119109',
      label: 'Skin Infestation',
    },
    skinInfection: {
      sctid: '19824006',
      label: 'Skin Infection',
    },
  },
};

const PALPATION_CONFIG = {
  options: {
    edema: {
      sctid: '424372002',
      label: 'Edema',
    },
    tenderness: {
      sctid: '247348008',
      label: 'Tenderness',
    },
    hotSkin: {
      sctid: '707793005',
      label: 'Hot Skin',
    },
    coldSkin: {
      sctid: '703883009',
      label: 'Cold Skin',
    },
    firm: {
      sctid: '299915008',
      label: 'Firm',
    },
    mass: {
      sctid: '300848003',
      label: 'Mass',
    },
    crepitus: {
      sctid: '2764000',
      label: 'Crepitus',
    },
    numbness: {
      sctid: '44077006',
      label: 'Numbness',
    },
    paresthesia: {
      sctid: '91019004',
      label: 'Paresthesia',
    },
    supple: {
      sctid: '48162006',
      label: 'Supple',
    },
  },
};

const EXTREMITIES_FORM = {
  fields: [
    {
      id: 'leftLowerHeader',
      label: 'Left Lower',
      type: 'header',
    },
    {
      id: 'movementLeftLower',
      sctid: {
        action: '129433002',
        anatomy: ['255324009', '32153003'],
      },
      type: 'checklist',
      label: 'Movement (Left Lower)',
      config: MOVEMENT_CONFIG,
    },
    {
      id: 'inspectionSkinLeftLower',
      sctid: {
        action: '129433002',
        anatomy: ['116370005', '32153003'],
      },
      type: 'checklist',
      label: 'Inspection of Skin (Left Lower)',
      config: SKIN_CONFIG,
    },
    {
      id: 'palpationExtremitiesLeftLower',
      sctid: {
        action: '129434008',
        anatomy: ['32153003'],
      },
      type: 'checklist',
      label: 'Palpation of Extremities (Left Lower)',
      config: PALPATION_CONFIG,
    },
    {
      id: 'rightLowerHeader',
      label: 'Right Lower',
      type: 'header',
    },
    {
      id: 'movementRightLower',
      sctid: {
        action: '129433002',
        anatomy: ['255324009', '62175007'],
      },
      type: 'checklist',
      label: 'Movement (Right Lower)',
      config: MOVEMENT_CONFIG,
    },
    {
      id: 'inspectionSkinRightLower',
      sctid: {
        action: '129433002',
        anatomy: ['116370005', '62175007'],
      },
      type: 'checklist',
      label: 'Inspection of Skin (Right Lower)',
      config: SKIN_CONFIG,
    },
    {
      id: 'palpationExtremitiesRightLower',
      sctid: {
        action: '129434008',
        anatomy: ['62175007'],
      },
      type: 'checklist',
      label: 'Palpation of Extremities (Right Lower)',
      config: PALPATION_CONFIG,
    },
    {
      id: 'leftUpperHeader',
      label: 'Left Upper',
      type: 'header',
    },
    {
      id: 'movementLeftUpper',
      sctid: {
        action: '129433002',
        anatomy: ['255324009', '80768000'],
      },
      type: 'checklist',
      label: 'Movement (Left Upper)',
      config: MOVEMENT_CONFIG,
    },
    {
      id: 'inspectionSkinLeftUpper',
      sctid: {
        action: '129433002',
        anatomy: ['116370005', '80768000'],
      },
      type: 'checklist',
      label: 'Inspection of Skin (Left Upper)',
      config: SKIN_CONFIG,
    },
    {
      id: 'palpationExtremitiesLeftUpper',
      sctid: {
        action: '129434008',
        anatomy: ['80768000'],
      },
      type: 'checklist',
      label: 'Palpation of Extremities (Left Upper)',
      config: PALPATION_CONFIG,
    },
    {
      id: 'rightUpperHeader',
      label: 'Right Upper',
      type: 'header',
    },
    {
      id: 'movementRightUpper',
      sctid: {
        action: '129433002',
        anatomy: ['255324009', '6921000'],
      },
      type: 'checklist',
      label: 'Movement (Right Upper)',
      config: MOVEMENT_CONFIG,
    },
    {
      id: 'inspectionSkinRightUpper',
      sctid: {
        action: '129433002',
        anatomy: ['116370005', '6921000'],
      },
      type: 'checklist',
      label: 'Inspection of Skin (Right Upper)',
      config: SKIN_CONFIG,
    },
    {
      id: 'palpationExtremitiesRightUpper',
      sctid: {
        action: '129434008',
        anatomy: ['6921000'],
      },
      type: 'checklist',
      label: 'Palpation of Extremities (Right Upper)',
      config: PALPATION_CONFIG,
    },
    {
      id: 'notes',
      type: 'textarea',
      label: 'Notes',
      config: {
        bgColor: '#F8F7F5',
      },
    },
  ],
};

const EYES_FORM = {
  fields: [
    {
      id: 'inspectionDucts',
      sctid: {
        action: '129433002',
        anatomy: ['32401001'],
      },
      type: 'checklist',
      label: 'Inspection of Ducts',
      config: {
        options: {
          normal: {
            sctid: '860970003',
            label: 'Normal',
          },
          abnormal: {
            sctid: '737269000',
            label: 'Abnormal',
          },
        },
        locations: [
          {
            sctid: '7771000',
            label: 'Left',
          },
          {
            sctid: '24028007',
            label: 'Right',
          },
        ],
        radio: true,
      },
    },
    {
      id: 'inspectionEyelids',
      sctid: {
        action: '129433002',
        anatomy: ['80243003'],
      },
      type: 'checklist',
      label: 'Inspection of Eyelids',
      config: {
        options: {
          normal: {
            sctid: '860970003',
            label: 'Normal',
          },
          abnormal: {
            sctid: '737269000',
            label: 'Abnormal',
          },
        },
        locations: [
          {
            sctid: '7771000',
            label: 'Left',
          },
          {
            sctid: '24028007',
            label: 'Right',
          },
        ],
        radio: true,
      },
    },
    {
      id: 'inspectionPupils',
      sctid: {
        action: '129433002',
        anatomy: ['392406005'],
      },
      type: 'checklist',
      label: 'Inspection of Pupils',
      config: {
        options: {
          normal: {
            sctid: '301950001',
            label: 'Normal',
          },
          abnormal: {
            sctid: '274093008',
            label: 'Abnormal',
          },
        },
        locations: [
          {
            sctid: '7771000',
            label: 'Left',
          },
          {
            sctid: '24028007',
            label: 'Right',
          },
        ],
        radio: true,
      },
    },
    {
      id: 'inspectionConjunctiva',
      sctid: {
        action: '129433002',
        anatomy: ['29445007'],
      },
      type: 'checklist',
      label: 'Inspection of Conjunctiva',
      config: {
        options: {
          normal: {
            sctid: '860970003',
            label: 'Normal',
          },
          abnormal: {
            sctid: '737269000',
            label: 'Abnormal',
          },
        },
        locations: [
          {
            sctid: '7771000',
            label: 'Left',
          },
          {
            sctid: '24028007',
            label: 'Right',
          },
        ],
        radio: true,
      },
    },
    {
      id: 'vision',
      sctid: {
        action: '705074003',
      },
      type: 'checklist',
      label: 'Vision',
      config: {
        options: {
          normal: {
            sctid: '45089002',
            label: 'Normal',
          },
          abnormal: {
            sctid: '7973008',
            label: 'Abnormal',
          },
        },
        radio: true,
      },
    },
    {
      id: 'notes',
      type: 'textarea',
      label: 'Notes',
      config: {
        bgColor: '#F8F7F5',
      },
    },
  ],
};

const NEURO_FORM = {
  fields: [
    {
      type: 'header',
      label: 'Inspection of Cranial Nerve',
    },
    {
      id: 'facialSymmetry',
      sctid: {
        action: '129433002',
        anatomy: ['25238003'],
      },
      type: 'checklist',
      label: 'Facial Symmetry',
      config: {
        options: {
          symmetrical: {
            sctid: '248172007',
            label: 'Symmetrical',
          },
          asymmetrical: {
            sctid: '15253005',
            label: 'Asymmetrical',
          },
        },
        radio: true,
      },
    },
    {
      id: 'hearing',
      sctid: {
        action: '129433002',
        anatomy: ['25238003'],
      },
      type: 'checklist',
      label: 'Hearing',
      config: {
        options: {
          normal: {
            sctid: '162339002',
            label: 'Hearing Normal',
          },
          hearingLoss: {
            sctid: '14230001000004101',
            label: 'Perception of Hearing Loss',
          },
        },
        radio: true,
      },
    },
    {
      id: 'vision',
      sctid: {
        action: '129433002',
        anatomy: ['45206002'],
      },
      type: 'checklist',
      label: 'Vision',
      config: {
        options: {
          normal: {
            sctid: '45089002',
            label: 'Normal Vision',
          },
          abnormal: {
            sctid: '7973008',
            label: 'Abnormal Vision',
          },
        },
        radio: true,
      },
    },
    {
      id: 'speech',
      sctid: {
        action: '129433002',
        anatomy: ['49928004'],
      },
      type: 'checklist',
      label: 'Speech',
      config: {
        options: {
          normal: {
            sctid: '162293002',
            label: 'No speech problem',
          },
          problem: {
            sctid: '421581006',
            label: 'Speech Problem',
          },
        },
        radio: true,
      },
    },
    {
      id: 'taste',
      sctid: {
        action: '129433002',
        anatomy: ['21082005'],
      },
      type: 'checklist',
      label: 'Taste',
      config: {
        options: {
          normal: {
            sctid: '299901006',
            label: 'Sense of Taste Normal',
          },
          abnormal: {
            sctid: '271801002',
            label: 'Sense of Taste Abnormal',
          },
        },
        radio: true,
      },
    },
    {
      id: 'smell',
      sctid: {
        action: '129433002',
        anatomy: ['21082005'],
      },
      type: 'checklist',
      label: 'Smell',
      config: {
        options: {
          normal: {
            sctid: '299900007',
            label: 'Sense of Smell Normal',
          },
          loss: {
            sctid: '44169009',
            label: 'Loss of Sense of Smell',
          },
        },
        radio: true,
      },
    },
    {
      type: 'header',
    },
    {
      id: 'inspectionMusculature',
      sctid: {
        action: '129433002',
        anatomy: ['79984008'],
      },
      type: 'checklist',
      label: 'Inspection of Musculature',
      config: {
        options: {
          normal: {
            sctid: '827065006',
            label: 'Normal muscle tone',
          },
          atrophy: {
            sctid: '88092000',
            label: 'Muscle atrophy',
          },
        },
        locations: [
          {
            sctid: '7771000',
            label: 'Left',
          },
          {
            sctid: '24028007',
            label: 'Right',
          },
          {
            sctid: '53120007',
            label: 'Upper',
          },
          {
            sctid: '61685007',
            label: 'Lower',
          },
        ],
        radio: true,
      },
    },
    {
      id: 'inspectionGait',
      sctid: {
        action: '63448001',
      },
      type: 'checklist',
      label: 'Inspection of Gait',
      config: {
        options: {
          normal: {
            sctid: '8117002',
            label: 'Normal',
          },
          abnormal: {
            sctid: '22325002',
            label: 'Abnormal',
          },
        },
        radio: true,
      },
    },
    {
      id: 'inspectionUpperExtremity',
      sctid: {
        action: '129433002',
        anatomy: ['53120007'],
      },
      type: 'checklist',
      label: 'Inspection of Upper Extremity',
      config: {
        options: {
          symmetrical: {
            sctid: '298228000',
            label: 'Symmetrical',
          },
          asymmetrical: {
            sctid: '249962002',
            label: 'Asymmetrical',
          },
          numbness: {
            sctid: '44077006',
            label: 'Numbness',
          },
          paresthesia: {
            sctid: '91019004',
            label: 'Paresthesia',
          },
        },
        locations: [
          {
            sctid: '7771000',
            label: 'Left',
          },
          {
            sctid: '24028007',
            label: 'Right',
          },
        ],
      },
    },
    {
      id: 'palpationSpine',
      sctid: {
        action: '129434008',
        anatomy: ['44300000'],
      },
      type: 'checklist',
      label: 'Palpation of Spine',
      config: {
        options: {
          tenderness: {
            sctid: '247348008',
            label: 'Tenderness',
          },
          lumbar: {
            sctid: '298676005',
            label: 'Lumbar Crepitus',
          },
          cervical: {
            sctid: '298480007',
            label: 'Cervical Crepitus',
          },
          thoracic: {
            sctid: '298581009',
            label: 'Thoracic Crepitus',
          },
        },
      },
    },
    {
      id: 'giOutput',
      sctid: {
        action: '122865005',
      },
      type: 'checklist',
      label: 'GI Output',
      config: {
        options: {
          normal: {
            sctid: '300438007',
            label: 'Normal',
          },
          dysfunction: {
            sctid: '235594008',
            label: 'Bowel Dysfunction',
          },
        },
        radio: true,
      },
    },
    {
      id: 'guOutput',
      sctid: {
        action: '21514008',
      },
      type: 'checklist',
      label: 'GU Output',
      config: {
        options: {
          normal: {
            sctid: '300561007',
            label: 'Normal',
          },
          dysfunction: {
            sctid: '40492006',
            label: 'Bladder Dysfunction',
          },
        },
        radio: true,
      },
    },
    {
      id: 'giGuFunction',
      sctid: {
        action: '122865005',
        anatomy: ['21514008'],
      },
      type: 'checklist',
      label: 'GI / GU Function',
      config: {
        options: {
          normal: {
            sctid: '449813001',
            label: 'Numbness of saddle area',
          },
          incontinence: {
            sctid: '48340000',
            label: 'Incontinence',
          },
        },
      },
    },
    {
      id: 'notes',
      type: 'textarea',
      label: 'Notes',
      config: {
        bgColor: '#F8F7F5',
      },
    },
  ],
};

const HEAD_FORM = {
  fields: [
    {
      id: 'inspectionNeck',
      sctid: {
        action: '129433002',
        anatomy: ['45048000'],
      },
      type: 'checklist',
      label: 'Inspection of Neck',
      config: {
        options: {
          normal: {
            sctid: '840674001',
            label: 'Normal',
          },
          mass: {
            sctid: '299703001',
            label: 'Mass',
          },
          abnormalMovement: {
            sctid: '225606002',
            label: 'Abnormal Movement',
          },
          pain: {
            sctid: '81680005',
            label: 'Pain',
          },
          jvd: {
            sctid: '248728000',
            label: 'JVD',
          },
          skinSymptom: {
            sctid: '308926009',
            label: 'Skin Symptom',
          },
        },
      },
    },
    {
      id: 'inspectionHead',
      sctid: {
        action: '129433002',
        anatomy: ['69536005'],
      },
      type: 'checklist',
      label: 'Inspection of Head',
      config: {
        options: {
          normal: {
            sctid: '69536005',
            label: 'Normal',
          },
          headache: {
            sctid: '25064002',
            label: 'Headache',
          },
          // abnormalMovement: {
          //   sctid: '225606002',
          //   label: 'Abnormal Movement',
          // },
          bleeding: {
            sctid: '50960005',
            label: 'Bleeding',
          },
          ecchymosis: {
            sctid: '302227002',
            label: 'Ecchymosis',
          },
          symmetrical: {
            sctid: '248172007',
            label: 'Symmetrical',
          },
          asymmetrical: {
            sctid: '15253005',
            label: 'Asymmetrical',
          },
          skinSymptom: {
            sctid: '308926009',
            label: 'Skin Symptom',
          },
        },
      },
    },
    {
      id: 'palpationLymphatics',
      sctid: {
        action: '129434008',
        anatomy: ['89890002'],
      },
      type: 'checklist',
      label: 'Palpation of Lymphatics',
      config: {
        options: {
          antertiorCervicalLymphNode: {
            sctid: '5727003',
            label: 'Anterior Cervical Lymph Node',
          },
          posteriorCervicalLymphNode: {
            sctid: '279754006',
            label: 'Posterior Cervical Lymph Node',
          },
          antertiorAuricularLymphNode: {
            sctid: '25247006',
            label: 'Anterior Auricular Lymph Node',
          },
          posteriorAuricularLymphNode: {
            sctid: '30793004',
            label: 'Posterior Auricular Lymph Node',
          },
        },
        locations: [
          {
            sctid: '7771000',
            label: 'Left',
          },
          {
            sctid: '24028007',
            label: 'Right',
          },
        ],
      },
    },
    {
      id: 'notes',
      type: 'textarea',
      label: 'Notes',
      config: {
        bgColor: '#F8F7F5',
      },
    },
  ],
};

const PSYCHIATRY_FORM = {
  fields: [
    {
      id: 'judgement',
      sctid: {
        action: '61254005',
      },
      type: 'checklist',
      label: 'Judgement',
      config: {
        options: {
          intact: {
            sctid: '709134004',
            label: 'Judgement Intact',
          },
          impaired: {
            sctid: '38504003',
            label: 'Impaired Judgement',
          },
        },
        radio: true,
      },
    },
    {
      id: 'mood',
      sctid: {
        action: '85256008',
      },
      type: 'checklist',
      label: 'Mood',
      config: {
        options: {
          stable: {
            sctid: '225658008',
            label: 'Stable',
          },
          manic: {
            sctid: '405273008',
            label: 'Manic',
          },
          fearful: {
            sctid: '367545001',
            label: 'Fearful',
          },
          depressed: {
            sctid: '366979004',
            label: 'Depressed',
          },
        },
      },
    },
    {
      id: 'risk',
      sctid: {
        action: '410519009',
      },
      type: 'checklist',
      label: 'At Risk',
      config: {
        options: {
          suicidal: {
            sctid: '225444004',
            label: 'At risk for suicide',
          },
          homicidal: {
            sctid: '225450009',
            label: 'Homicidal ideation',
          },
        },
      },
    },
    {
      id: 'notes',
      type: 'textarea',
      label: 'Notes',
      config: {
        bgColor: '#F8F7F5',
      },
    },
  ],
};

const VASCULAR_FORM = {
  fields: [
    {
      id: 'inquiryVasculature',
      sctid: {
        action: '129433002',
        anatomy: ['29092000'],
      },
      type: 'checklist',
      label: 'Inquiry of Vasculature',
      config: {
        options: {
          normal: {
            sctid: '62222003',
            label: 'normal',
          },
          venous: {
            sctid: '71897006',
            label: 'Venous stasis',
          },
          jvd: {
            sctid: '248728000',
            label: 'JVD',
          },
          edema: {
            sctid: '267038008',
            label: 'Edema',
          },
          varicosities: {
            sctid: '128060009',
            label: 'Varicosities',
          },
          claudication: {
            sctid: '95443002',
            label: 'Claudication',
          },
          hotSkin: {
            sctid: '707793005',
            label: 'Hot Skin',
          },
          coldSkin: {
            sctid: '703883009',
            label: 'Cold Skin',
          },
          petechiae: {
            sctid: '423716004',
            label: 'Petechiae',
          },
          purpura: {
            sctid: '12393003',
            label: 'Purpura',
          },
        },
      },
    },
    {
      id: 'inspectionVascular',
      sctid: {
        action: '129433002',
        anatomy: ['29092000'],
      },
      type: 'checklist',
      label: 'Inspection of Vascular Access Site',
      config: {
        options: {
          infection: {
            sctid: '40733004',
            label: 'Infection',
          },
          bleeding: {
            sctid: '131148009',
            label: 'Bleeding',
          },
          pain: {
            sctid: '22253000',
            label: 'Pain',
          },
          complicationCatheter: {
            sctid: '73862001',
            label: 'Complication of Catheter',
          },
          fistula: {
            sctid: '428794004',
            label: 'Fistula',
          },
        },
      },
    },
    {
      id: 'notes',
      type: 'textarea',
      label: 'Notes',
      config: {
        bgColor: '#F8F7F5',
      },
    },
  ],
};

export const SYSTEMS = {
  neuro: {
    label: 'Neuro / Spine',
    sctid: '394591006',
    query: 'Disability / Numbness / Tingling / Bowel & Bladder Dysfunction',
    form: NEURO_FORM,
  },
  head: {
    label: 'Head & Neck',
    sctid: '361355005',
    query: 'Pain / Pruritis / Disability',
    form: HEAD_FORM,
  },
  ent: {
    label: 'ENT',
    sctid: '385383008',
    query: 'Pain / Pruritis / Durability / Discharge / Hearing',
    form: ENT_FORM,
  },
  eyes: {
    label: 'Eyes',
    sctid: '81745001',
    query: 'Pain / Pruritis / Disability / Visual Acuity / Discharge',
    form: EYES_FORM,
  },
  chest: {
    label: 'Chest',
    sctid: '51185008',
    query: 'Breathing / Pain / Skin Changes',
    form: CHEST_FORM,
  },
  abdomen: {
    label: 'Abdomen',
    sctid: '818983003',
    query: 'Pain, Input/Output',
    form: ABDOMEN_FORM,
  },
  extremities: {
    label: 'Extremities',
    sctid: '2592007',
    query: 'Pain / Weakness / Numbness / Tingling / Rashes / Skin Changes',
    form: EXTREMITIES_FORM,
  },
  vascular: {
    label: 'Vascular',
    sctid: '727614001',
    query: 'Claudication / Edema / Bleeding',
    form: VASCULAR_FORM,
  },
  allergy: {
    label: 'Allergy / Immunology',
    sctid: '94805004',
    query: 'Allergy History',
    form: {
      fields: [
        {
          id: 'notes',
          type: 'textarea',
          label: 'Notes',
          config: {
            bgColor: '#F8F7F5',
          },
        },
      ],
    },
  },
  psychiatry: {
    label: 'Psychiatry',
    sctid: '394587001',
    query: 'Anxiety / Depression / Presence of SI/HI',
    form: PSYCHIATRY_FORM,
  },
  constitutional: {
    label: 'Constitutional',
    sctid: '363791007',
    query: 'Name and Orientation (Place/Time)',
    form: CONSTITUTIONAL_FORM,
  },
};
