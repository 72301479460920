import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useRef,
  useMemo,
} from 'react';
import styled from 'styled-components';
import { lighten } from 'polished';
import { RiSearchLine, RiCloseCircleFill } from 'react-icons/ri';
import { SystemContext } from '../../contexts/SystemContext';
import { useDebounce } from '../../hooks/useDebounce';
import { formatAddress } from '../../hooks/useTextHelper';
import Loader from '../../components/common/Loader';

const SearchSelector = ({
  id,
  value,
  placeholder,
  selectedDisplay,
  onSelect,
  onAutosave,
  multiple,
  mode,
  theme,
  hideSelected,
  disabled = false,
}) => {
  const {
    state: { realmUser },
  } = useContext(SystemContext);

  const containerRef = useRef();
  const codeLabel = useMemo(
    () =>
      mode === 'icd10'
        ? 'billingCode'
        : mode === 'ems'
        ? '_id'
        : 'clinicalCode',
    [mode],
  );

  const [searchTerm, setSearchTerm] = useState('');
  const [results, setResults] = useState();
  const [showResults, setShowResults] = useState(true);
  const [selected, setSelected] = useState(
    value ? value : multiple ? [] : null,
  );
  const [isSearching, setIsSearching] = useState(false);

  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const runSearch = useCallback(async () => {
    if (debouncedSearchTerm.length < 2) {
      setResults();
      return;
    }

    setIsSearching(true);

    const searchFn = () =>
      mode === 'physician'
        ? realmUser.callFunction('searchPhysicians', debouncedSearchTerm)
        : mode === 'pharmacy'
        ? realmUser.callFunction('searchPharmaciesTemp', debouncedSearchTerm)
        : mode === 'hospital'
        ? realmUser.callFunction('searchHospitals', debouncedSearchTerm)
        : mode === 'dme' || mode === 'medicalSupply'
        ? realmUser.callFunction('searchMedicalSuppliers', debouncedSearchTerm)
        : mode === 'medication'
        ? realmUser.callFunction('searchRx', debouncedSearchTerm)
        : mode === 'icd10'
        ? realmUser.callFunction('searchICD10', debouncedSearchTerm)
        : mode === 'ems'
        ? realmUser.callFunction('searchEms', debouncedSearchTerm)
        : realmUser.callFunction('searchSnomed', debouncedSearchTerm, mode);

    const res = await searchFn();
    setResults(!res || res.message ? null : res);
    setIsSearching(false);
  }, [mode, realmUser, debouncedSearchTerm]);

  const onRemoveItem = index => {
    let arr = [...selected];
    arr.splice(index, 1);

    // update parent (Form.js)
    onSelect(id, [...arr]);

    // reset UI
    setSelected([...arr]);
  };

  const onSelectResult = (event, result) => {
    event.stopPropagation();

    let newValue = multiple ? [...selected, result] : result;

    // update parent (Form.js or LookupSelector)
    onSelect(id, newValue);

    // reset UI
    setSelected(newValue);
    setResults();

    // if autosave, force it
    if (onAutosave) onAutosave(id, newValue, true);

    if (multiple) setSearchTerm('');
  };

  useEffect(() => {
    if (debouncedSearchTerm) runSearch();
  }, [debouncedSearchTerm, runSearch]);

  // handle click outside
  const handleClickOutside = e => {
    if (containerRef.current.contains(e.target)) {
      return;
    }

    setShowResults(false);
  };

  const clearResponse = () => {
    setSearchTerm('');
    setSelected();

    // update parent (Form.js)
    onSelect(id, null);
  };

  useEffect(() => {
    if (results) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [results]);

  useEffect(() => {
    if (value) setSelected(value);
  }, [value]);

  return (
    <Container ref={containerRef} disabled={disabled}>
      {selected && !multiple ? (
        <SelectedWrapper>
          <SelectedLabel>
            {selectedDisplay || selected.display || selected.name}
          </SelectedLabel>
          {!disabled ? (
            <ClearButton onClick={clearResponse}>
              <RiCloseCircleFill />
            </ClearButton>
          ) : null}
        </SelectedWrapper>
      ) : (
        <>
          <InputWrapper color={theme}>
            <InputIcon>
              {isSearching ? (
                <Loader size="small" activity />
              ) : (
                <RiSearchLine />
              )}
            </InputIcon>
            <TextInput
              placeholder={placeholder || `Lookup ${id}`}
              onFocus={() => setShowResults(true)}
              onChange={e => setSearchTerm(e.target.value)}
              value={searchTerm}
              disabled={disabled}
            />
          </InputWrapper>
          {multiple && !hideSelected && selected.length > 0 ? (
            <MultiRow>
              {selected.map((item, index) => (
                <MultiItem key={item[codeLabel]} title={item.display}>
                  <MultiItemLabel>
                    {mode === 'icd10'
                      ? `[${item.billingCode}]  ${item.display}`
                      : item.display}
                  </MultiItemLabel>
                  {!disabled ? (
                    <ClearButton onClick={() => onRemoveItem(index)} white>
                      <RiCloseCircleFill />
                    </ClearButton>
                  ) : null}
                </MultiItem>
              ))}
            </MultiRow>
          ) : null}
          {showResults && results && results.length > 0 ? (
            <ResultsBlock>
              <ResultsContainer>
                <ResultsWrapper>
                  {results.map(result => (
                    <Result
                      key={
                        id === 'pharmacy'
                          ? result.ncpdp
                          : [
                              'physician',
                              'hospital',
                              'medicalSupply',
                              'dme',
                            ].includes(id)
                          ? result.npi
                          : result[codeLabel]
                      }
                      active={
                        selected && selected[codeLabel] === result[codeLabel]
                      }
                      onClick={e => onSelectResult(e, result)}>
                      {[
                        'physician',
                        'pharmacy',
                        'hospital',
                        'medicalSupply',
                        'dme',
                      ].includes(id) ? (
                        <>
                          <ResultTitle>
                            {id === 'physician'
                              ? result.displayName
                              : result.name}
                          </ResultTitle>
                          <ResultSubtitle>
                            {formatAddress(result.address)}
                          </ResultSubtitle>
                        </>
                      ) : mode === 'icd10' ? (
                        `[${result.billingCode}]  ${result.display}`
                      ) : mode === 'ems' ? (
                        <>
                          <ResultTitle>{result.agency}</ResultTitle>
                          <ResultSubtitle>
                            {result.primaryDispatch}
                          </ResultSubtitle>
                        </>
                      ) : (
                        result.display
                      )}
                    </Result>
                  ))}
                </ResultsWrapper>
              </ResultsContainer>
            </ResultsBlock>
          ) : null}
        </>
      )}
    </Container>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 53px;
  border-radius: 15px;
  padding: 0 20px;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
`;

const SelectedWrapper = styled(Wrapper)`
  background-color: white;
  border-color: ${props => props.theme.colors.border};
  height: 53px;
`;

const SelectedLabel = styled.p`
  margin: 0;
  flex: 1;
  font-size: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  color: ${props => props.theme.textColors.tertiary};
  font-weight: ${props => props.theme.fontWeights.medium};
  opacity: 0.8;
`;

const ClearButton = styled.button`
  display: flex;
  background-color: transparent;
  border: 0;
  outline: none;
  padding: 0;
  cursor: pointer;

  font-size: 14px;
  font-family: ${props => props.theme.fonts.primary};
  font-weight: ${props => props.theme.fontWeights.bold};

  svg {
    font-size: 22px;
    color: ${props =>
      props.white ? 'rgba(255,255,255,0.4)' : props.theme.colors.blue};
  }

  &:hover svg {
    color: ${props =>
      props.white ? 'white' : lighten(0.1, props.theme.colors.blue)};
  }
`;

const InputWrapper = styled(Wrapper)`
  justify-content: flex-start;
  background-color: ${props =>
    props.color === 'white' ? 'white' : props.theme.backgroundColors.lightAlt};
  border-color: ${props =>
    props.color === 'white' ? props.theme.colors.border : null};
`;

const TextInput = styled.input`
  flex: 1;
  background-color: transparent;
  border: none;
  font-size: 16px;
  line-height: 30px;
  margin: 0 12px;
  color: ${props => props.theme.textColors.primary};
  outline: none;
  padding: 0;

  &::placeholder {
    color: ${props => props.theme.textColors.secondary};
  }
`;

const InputIcon = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;

  svg {
    font-size: 20px;
    color: ${props => props.theme.textColors.tertiary};
  }
`;

const ResultsBlock = styled.div`
  position: absolute;
  top: 55px;
  left: 0;
  right: 0;
  z-index: 300;
  width: 100%;
  padding: 0 12px;
`;

const ResultsContainer = styled.div`
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: hidden;
  border-bottom: 3px solid ${props => props.theme.colors.border};
`;

const ResultsWrapper = styled.div`
  background-color: white;
  max-height: 210px;
  overflow-y: scroll;
`;

const Result = styled.button`
  display: block;
  background-color: ${props =>
    props.active ? props.theme.backgroundColors.primary : 'transparent'};
  padding: 14px 20px;
  width: 100%;
  border: 1px solid ${props => props.theme.colors.border};
  border-top: 0;
  outline: none;
  cursor: pointer;

  text-align: left;
  color: ${props => props.theme.textColors.secondary};
  font-size: 15px;
  font-family: ${props => props.theme.fonts.secondary};
  font-weight: ${props =>
    props.active
      ? props.theme.fontWeights.bold
      : props.theme.fontWeights.regular};

  &:hover {
    background-color: ${props => props.theme.colors.blue};
    color: white;
  }
`;

const ResultTitle = styled.div`
  font-size: 14px;
  font-weight: ${props => props.theme.fontWeights.bold};
  margin-bottom: 3px;
`;

const ResultSubtitle = styled.div`
  font-size: 11px;
`;

const MultiRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 12px;
`;

const MultiItem = styled.div`
  display: inline-flex;
  align-items: center;
  border-radius: 30px;
  background-color: ${props => props.theme.colors.blue};
  margin: 3px;
  padding: 2px 3px 2px 12px;
`;

const MultiItemLabel = styled.span`
  font-size: 13px;
  line-height: 1;
  color: white;
  margin-right: 6px;
  max-width: 360px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Container = styled.div`
  display: block;
  position: relative;
  width: 100%;

  ${({ disabled }) =>
    disabled &&
    `
    pointer-events: none;

    ${ClearButton}, ${Result} {
      pointer-events: none;
      cursor: default;
    }
  `}
`;

export default SearchSelector;
