import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  button {
    font-family: ${props => props.theme.fonts.secondary};
    outline: none;
    border: none;
    cursor: pointer;
  }

  input, textarea, select {
    padding: 16px;
    border: 1px solid ${props => props.theme.colors.border};
    border-radius: 10px;
    font-size: 16px;
    font-family: ${props => props.theme.fonts.secondary};
    color: ${props => props.theme.textColors.primary};
    box-sizing: border-box;
    outline: none;

    &:focus {
      border-color: ${props => props.theme.colors.tertiary};
    }

    &::placeholder {
      color: #D0D0D0;
    }
  }

  textarea {
    resize: vertical;
    min-height: 80px;
    line-height: 1.5;
  }

  fieldset {
    margin-bottom: 10px;
    border: 0;
  }

  label {
    display: block;
    font-family: ${props => props.theme.fonts.secondary};
    font-weight: ${props => props.theme.fontWeights.medium};
    font-size: 11px;
    color: ${props => props.theme.textColors.tertiary};
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 8px;
  }

  a {
    text-decoration: none;
  }

  h1, h2, h3, h4, h5, h6, p {
    color: ${props => props.theme.textColors.primary};
  }
`;

export default GlobalStyle;
