import React, { useRef, useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useLazyQuery } from '@apollo/client';
import AdminHeader from '../components/admin/AdminHeader';
import PageTabs from '../components/common/PageTabs';
import AddButton from '../components/common/AddButton';
import Table from '../components/tables/Table';
import FormModal from '../components/modals/FormModal';
import { PROVIDERS } from '../../constants';
import { ModalContext } from '../contexts/ModalContext';
import { GET_EMS_LIST } from '../api/careteam';
import { EMS_FORM } from '../../forms/careteam';
import { formatToPhone } from '../hooks/useTextHelper';
import ActivityIndicator from '../components/common/ActivityIndicator';

const TABLE = {
  ems: [
    {
      id: 'agency',
      label: 'Agency',
      flex: 1,
      sortable: true,
    },
    {
      id: 'primaryDispatch',
      label: 'Primary Dispatch',
      flex: 1,
    },
    {
      id: 'dispatchNumber',
      label: 'Phone Number',
      width: '200px',
      formatFn: formatToPhone,
    },
    {
      id: 'otherNumber',
      label: 'Other Number',
      width: '200px',
      formatFn: formatToPhone,
    },
  ],
};

const FORMS = {
  ems: {
    form: EMS_FORM,
    editTitle: 'Edit EMS Provider',
    addTitle: 'Add EMS Provider',
  },
};

const ProvidersListPage = () => {
  const isInitialMount = useRef(true);

  const { dispatch: dispatchModal } = useContext(ModalContext);
  const [activeTab, setActiveTab] = useState('ems');

  const [tableData, setTableData] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);

  const [getEMSList] = useLazyQuery(GET_EMS_LIST, {
    onCompleted: data => {
      setTableData(data.emergencyServices);
      setLoading(false);
    },
    onError: err => {
      console.log(err);
      setLoading(false);
      setError(err);
    },
  });

  const onTabChange = tab => {
    setActiveTab(tab);
    setLoading(true);

    if (tab === 'ems') {
      getEMSList();
    } else {
      setLoading(false);
      setTableData(PROVIDERS[tab]);
    }
  };

  const viewProvider = provider => {
    dispatchModal({
      type: 'ADD_MODAL',
      data: {
        component: FormModal,
        props: {
          data: provider || {},
          config: {
            ...FORMS[activeTab],
            mode: provider ? 'edit' : 'add',
            targetId: provider?._id,
          },
        },
      },
    });
  };

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      onTabChange(activeTab);
    }
  }, []);

  return (
    <Container>
      <Main>
        <AdminHeader title="Providers" />
        <Content>
          {/* <PageTabs
            config={[
              { id: 'ems', label: 'EMS' },
              // { id: 'pharmacy', label: 'Pharmacy' },
            ]}
            activeTab={activeTab}
            onTabClick={onTabChange}
            ehr
          /> */}
          <Body>
            {loading ? (
              <CenterWrapper>
                <ActivityIndicator />
              </CenterWrapper>
            ) : error ? (
              <CenterWrapper>
                <ErrorTitle>An error occurred</ErrorTitle>
                <ErrorDescription>{error}</ErrorDescription>
              </CenterWrapper>
            ) : (
              <Table
                data={tableData}
                config={TABLE[activeTab]}
                onClick={viewProvider}
                emptyNotice="No Providers Found"
                ehr
              />
            )}
          </Body>
        </Content>
      </Main>
      <AddButton onClick={() => viewProvider()} />
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.backgroundColors.lightAlt};
`;

const Main = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 30px 40px;
  overflow-y: scroll;
`;

const Content = styled.div`
  padding: 30px 0;
  flex: 1;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 0 20px;
  height: 100%;
`;

const CenterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const ErrorTitle = styled.h1`
  font-weight: ${props => props.theme.fontWeights.medium};
  margin-bottom: 10px;
`;

const ErrorDescription = styled.p`
  color: ${props => props.theme.textColors.secondary};
  line-height: 1.8;
  margin-bottom: 40px;
`;

export default ProvidersListPage;
