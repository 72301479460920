import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { lighten } from 'polished';
import { useQuery } from '@apollo/client';
import { FaPlus } from 'react-icons/fa';
import Loader from '../../common/Loader';
import Symptom from '../../common/Symptom';
import Form from '../../forms/Form';
import { useEventListener } from '../../../hooks/useEventListener';
import SymptomModal from '../../modals/SymptomModal';
import { INTAKE_FORM } from '../../../../forms/intake';
import { GET_INTAKE, UPDATE_INTAKE } from '../../../api/intake';
import { GET_SYMPTOMS } from '../../../api/symptoms';
import { CallContext } from '../../../contexts/CallContext';
import { ModalContext } from '../../../contexts/ModalContext';
import { SystemContext } from '../../../contexts/SystemContext';

const initialState = {
  reason: '',
  contextualFactors: '',
  exacerbatingFactors: '',
  relievingFactors: '',
  medications: [],
  notes: '',
};

const ChartingCarelineIntake = ({ patient }) => {
  const {
    state: { encounter, room, physicianStatus },
  } = useContext(CallContext);
  const { dispatch: dispatchModal } = useContext(ModalContext);
  const {
    state: { realmUser },
  } = useContext(SystemContext);

  const [disabled, setDisabled] = useState(false);

  const { data: intakeData, loading: loadingIntake } = useQuery(GET_INTAKE, {
    variables: { encounter },
  });

  const { data, loading, refetch } = useQuery(GET_SYMPTOMS, {
    variables: { encounter },
  });

  const refreshSymptoms = async () => {
    onAutoSave();
    refetch();

    if (physicianStatus === 'waitingForPhysician') {
      await realmUser.functions.sendPusherEvent(
        `presence-${room}`,
        'update-symptoms',
      );
    }
  };

  const openSymptomModal = item => {
    // open symptom modal
    dispatchModal({
      type: 'ADD_MODAL',
      data: {
        component: SymptomModal,
        props: {
          mode: item ? 'edit' : 'new',
          encounter,
          patient: patient.userId,
          data: item,
          maxWidth: '640px',
        },
        onDismiss: refreshSymptoms,
      },
    });
  };

  const onAutoSave = async () => {
    // if Physician is on but not yet connected
    // update details in Physician's UI as edited
    if (physicianStatus === 'waitingForPhysician') {
      await realmUser.functions.sendPusherEvent(
        `presence-${room}`,
        'update-intake',
      );
    }
  };

  useEventListener('doctorConnected', () => setDisabled(true));

  return (
    <Container>
      <FormContainer>
        {loadingIntake ? null : (
          <FormWrapper>
            <Form
              targetId={encounter}
              form={{ mutation: UPDATE_INTAKE, ...INTAKE_FORM }}
              data={intakeData ? intakeData.encounter : initialState}
              autosave
              onAutoSave={onAutoSave}
              isDisabled={disabled}
            />
          </FormWrapper>
        )}
      </FormContainer>

      <SymptomsWrapper>
        {loading ? (
          <Loader activity size="small" transparent />
        ) : (
          <SymptomsContent>
            <SymptomsHeader>Symptoms</SymptomsHeader>
            {data && data.symptoms && data.symptoms.length > 0 ? (
              <>
                <SymptomsList>
                  {data.symptoms.map(item => (
                    <Symptom
                      key={item._id}
                      config={item}
                      onClick={() => openSymptomModal(item)}
                      disabled={disabled}
                    />
                  ))}
                </SymptomsList>
                <AddSymptomBtn
                  onClick={() => openSymptomModal()}
                  disabled={disabled}>
                  <FaPlus />
                </AddSymptomBtn>
              </>
            ) : (
              <Empty>
                <EmptyTitle>
                  To add a symptom to the encounter, click the button below.
                </EmptyTitle>
                <AddSymptomBtnEmpty
                  onClick={() => openSymptomModal()}
                  disabled={disabled}>
                  Add Symptom
                </AddSymptomBtnEmpty>
              </Empty>
            )}
          </SymptomsContent>
        )}
      </SymptomsWrapper>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
`;

const FormContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: scroll;
  height: 100%;
`;

const FormWrapper = styled.div`
  padding: 20px 0;
`;

const SymptomsWrapper = styled.div`
  position: relative;
  display: block;
  height: 100%;
  width: 320px;
  background-color: ${props => props.theme.backgroundColors.lightAlt};
`;

const SymptomsContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const SymptomsList = styled.div`
  flex: 1;
  overflow-y: scroll;
  padding: 10px 30px 100px;
`;

const Empty = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 100%;
  padding: 0 40px 60px;
`;

const EmptyTitle = styled.label`
  font-size: 14px;
  font-weight: ${props => props.theme.fontWeights.medium};
  color: ${props => props.theme.textColors.tertiary};
  text-transform: none;
  letter-spacing: 0;
  text-align: center;
  line-height: 1.4;
`;

const AddSymptomBtnEmpty = styled.button`
  background-color: ${props => props.theme.colors.blue};
  color: white;
  border: none;
  outline: none;
  border-radius: 6px;
  margin-top: 10px;
  padding: 10px 16px;
  font-size: 13px;
  font-weight: ${props => props.theme.fontWeights.medium};
  cursor: pointer;

  ${({ disabled }) =>
    disabled &&
    `
    cursor: default;
    pointer-events: none;
    opacity: 0.4;
  `}

  &:hover {
    background-color: ${props => lighten(0.05, props.theme.colors.blue)};
  }
`;

const SymptomsHeader = styled.h3`
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  padding: 30px 30px 0;

  &::after {
    content: '';
    display: block;
    margin: 12px auto;
    width: 30px;
    height: 4px;
    border-radius: 8px;
    background-color: ${props => props.theme.colors.border};
  }
`;

const AddSymptomBtn = styled.span`
  position: absolute;
  bottom: 30px;
  left: 50%;
  z-index: 2;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background-color: ${props => props.theme.colors.blue};
  border-radius: 50%;
  cursor: pointer;

  ${({ disabled }) =>
    disabled &&
    `
    cursor: default;
    pointer-events: none;
    opacity: 0.4;
  `}

  &:hover {
    background-color: ${props => lighten(0.05, props.theme.colors.blue)};
  }

  svg {
    color: white;
    font-size: 18px;
  }
`;

export default ChartingCarelineIntake;
