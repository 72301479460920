import React from 'react';
import { FaRegCheckCircle, FaRegTimesCircle } from 'react-icons/fa';
import { GiBrain } from 'react-icons/gi';
import styled from 'styled-components';
import WidgetFooterBtn from '../../../common/WidgetFooterBtn';

const VITALS = ['temperature', 'bp', 'pulse', 'O2'];
const APPEARANCE = ['nourishment', 'mood', 'hygiene', 'wellness'];

const VITALS_LABELS = {
  temperature: 'Temperature',
  bp: 'Blood Pressure',
  pulse: 'Pulse',
  O2: 'O2 Saturation',
};

const getValue = (vitals, key) => {
  if (key === 'temperature') {
    return vitals.temperature.data
      ? `${vitals.temperature.data.value.temp}˚`
      : '-';
  }

  if (key === 'bp') {
    return vitals.bp.data
      ? `${vitals.bp.data.value.sys} / ${vitals.bp.data.value.dia}`
      : '-';
  }

  if (['pulse', 'O2'].includes(key)) {
    if (!vitals.pulseox.data) return '-';
    return key === 'O2'
      ? vitals.pulseox.data.value.spo2
      : vitals.pulseox.data.value.pulse;
  }

  return '';
};

const getAppearanceSuccess = (key, data) => {
  const label = data[key].selected[0].label.toLowerCase();
  if (key === 'nourishment') return label === 'well nourished';
  if (key === 'mood') return label === 'calm';
  if (key === 'hygiene') return label === 'groomed';
  if (key === 'wellness') return label === 'well';
  return false;
};

const VitalBox = ({ label, value, success }) => (
  <VitalItem bad={!success}>
    <VitalValue>{value}</VitalValue>
    <VitalLabel>{label}</VitalLabel>
    <VitalIcon success={success}>
      {success ? <FaRegCheckCircle /> : <FaRegTimesCircle />}
    </VitalIcon>
  </VitalItem>
);

const ConstitutionalSummary = ({ vitals, data, onEdit }) => {
  const isAlert = data.alertness.selected[0].label === 'Alert';

  return (
    <ConstitutionalGrid>
      <VitalsRow>
        {VITALS.map(key => (
          <VitalBox
            key={key}
            label={data[key] ? data[key].label : VITALS_LABELS[key]}
            value={getValue(vitals, key)}
            success={data[key] && data[key].selected[0].label === 'Normal'}
          />
        ))}
      </VitalsRow>
      <ExamRow>
        <AlertColumn bad={!isAlert}>
          <BrainIcon>
            <GiBrain />
          </BrainIcon>
          <AlertLabel>{data.alertness.selected[0].label}</AlertLabel>
          <AlertIcon success={isAlert}>
            {isAlert ? <FaRegCheckCircle /> : <FaRegTimesCircle />}
          </AlertIcon>
        </AlertColumn>
        <AppearanceColumn>
          {APPEARANCE.map(key => {
            const isSuccess = getAppearanceSuccess(key, data);
            return (
              <AppearanceItem key={key} bad={!isSuccess}>
                <AppearanceIcon success={isSuccess}>
                  {isSuccess ? <FaRegCheckCircle /> : <FaRegTimesCircle />}
                </AppearanceIcon>
                <AppearanceItemLabel>
                  <strong>{data[key].label}:</strong>{' '}
                  {data[key].selected[0].label}
                </AppearanceItemLabel>
              </AppearanceItem>
            );
          })}
        </AppearanceColumn>
      </ExamRow>
      <WidgetFooterBtn onClick={onEdit}>Edit</WidgetFooterBtn>
    </ConstitutionalGrid>
  );
};

const ConstitutionalGrid = styled.div`
  display: grid;
  width: 100%;
  grid-template-rows: 100px auto;
  border: 1px solid ${props => props.theme.colors.border};
  border-radius: 10px;
  overflow: hidden;
`;

const VitalsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const VitalItem = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 22px 10px 12px;
  border-right: 1px solid ${props => props.theme.colors.border};
  text-align: center;
  height: 100%;

  background-color: ${props =>
    props.bad ? props.theme.backgroundColors.lightAlt : 'transparent'};

  &:last-child {
    border-right: none;
  }
`;

const Icon = styled.span`
  svg {
    font-size: 14px;
    color: ${props =>
      props.success ? props.theme.colors.green : props.theme.colors.primary};
  }
`;

const VitalIcon = styled(Icon)`
  margin-top: auto;
`;

const VitalValue = styled.span`
  font-size: 18px;
  font-weight: ${props => props.theme.fontWeights.bold};
  color: ${props => props.theme.textColors.primary};
`;

const VitalLabel = styled.span`
  font-size: 9px;
  font-weight: ${props => props.theme.fontWeights.bold};
  text-transform: uppercase;
  letter-spacing: 0.2px;
  color: ${props => props.theme.textColors.secondary};
  margin-top: 6px;
`;

const ExamRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  border-top: 1px solid ${props => props.theme.colors.border};
`;

const AlertColumn = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-right: 1px solid ${props => props.theme.colors.border};
  width: 140px;
  padding: 20px;

  background-color: ${props =>
    props.bad ? props.theme.backgroundColors.lightAlt : 'transparent'};
`;

const BrainIcon = styled.span`
  margin-bottom: 4px;
  svg {
    font-size: 32px;
    color: ${props => props.theme.colors.blue};
  }
`;

const AlertLabel = styled.p`
  font-size: 13px;
  font-weight: ${props => props.theme.fontWeights.bold};
  color: ${props => props.theme.textColors.secondary};
  text-transform: uppercase;
  letter-spacing: 0.1px;
  margin: 0;
`;

const AlertIcon = styled(Icon)`
  margin-top: 6px;
`;

const AppearanceColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const AppearanceItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  padding: 5px 10px;
  border-bottom: 1px solid ${props => props.theme.colors.border};
  background-color: ${props =>
    props.bad ? props.theme.backgroundColors.lightAlt : 'transparent'};

  &:last-child {
    border-bottom: none;
  }
`;

const AppearanceIcon = styled(Icon)`
  display: flex;
  float: left;
  margin-right: 10px;
`;

const AppearanceItemLabel = styled.span`
  font-size: 13px;
  color: ${props => props.theme.textColors.secondary};
  line-height: 1;

  strong {
    font-size: 12px;
    font-weight: ${props => props.theme.fontWeights.bold};
    text-transform: uppercase;
    margin-right: 5px;
  }
`;

export default ConstitutionalSummary;
