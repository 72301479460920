import React from 'react';
import { createGlobalStyle, ThemeProvider } from 'styled-components';

const theme = {
  backgroundColors: {
    primary: 'white',
    primaryRGB: '255,255,255',
    secondary: '#EAE8E4',
    secondaryRGB: '234,232,228',
    tertiary: '#F5F7FF',
    lightAlt: '#F6F8FA',
    lightAltRGB: '246,248,250',
    dark: '#222',
    darker: '#191919',
  },
  colors: {
    primary: '#F05672',
    primaryLight: '#FCAAB6',
    primaryDark: '#319CE2',
    secondary: '#EF1D96',
    secondaryLight: '#38D2E2',
    tertiary: '#FFAA76',
    tertiaryRGB: '255,170,118',
    accent: '#FC7569',
    border: '#EAE8E4',
    borderRGB: '231,229,224',
    icon: '#2F2F2F',
    green: '#75C282',
    yellow: '#FCD47D',
    blue: '#57B3FA',
    blueRGB: '87,179,250',
    blueLight: '#91D4F2',
    pink: '#F28C9C',
    purple: '#726AB9',
    gray: '#555',
    grayLight: '#E8ECED',
    warning: '#FF6152',
    tableHover: '#FAFCFF',
    shadowRGB: '226, 228, 240',
  },
  textColors: {
    primary: '#5B5F73',
    primaryRGB: '84,91,119',
    secondary: '#999FAF',
    secondaryRGB: '166,173,194',
    tertiary: '#9A9FAE',
    tertiaryRGB: '152,159,175',
  },
  fonts: {
    primary: "'PT Sans', sans-serif",
    secondary: "'Roboto', sans-serif",
  },
  fontWeights: {
    regular: 400,
    medium: 500,
    bold: 700,
  },
};

const GlobalStyles = createGlobalStyle`
  body {
    background-color: ${theme.backgroundColors.primary};
    margin: 0;
    font-family: ${theme.fonts.secondary};
  }
  div {
    box-sizing: border-box;
  }
  h1, h2 {
    font-family: ${theme.fonts.primary};
  }
`;

const Theme = ({ children }) => (
  <ThemeProvider theme={theme}>
    <GlobalStyles />
    {children}
  </ThemeProvider>
);

export default Theme;
