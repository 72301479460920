// @flow
import React, { createContext, useReducer } from 'react';

const INITIAL_STATE = {
  current: null,
  log: [],
};

export const LogContext = createContext(INITIAL_STATE);

export default ({ children }) => {
  const reducer = (state, { type, data }) => {
    switch (type) {
      case 'SET_CURRENT_LOG': {
        return {
          ...state,
          current: {
            ...state.current,
            ...data,
          },
        };
      }
      case 'CLEAR_CURRENT_LOG': {
        return {
          ...state,
          current: null,
        };
      }
      case 'SET_LOG': {
        return {
          ...state,
          log: [...state.log, data],
        };
      }
      case 'CLEAR': {
        return INITIAL_STATE;
      }
      default: {
        return state;
      }
    }
  };

  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <LogContext.Provider value={{ state, dispatch }}>
      {children}
    </LogContext.Provider>
  );
};
