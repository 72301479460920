import React from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import { GET_TEAM } from '../../api/team';
import ActivityIndicator from '../common/ActivityIndicator';
import UserIcon from '../common/UserIcon';

const TeamPopover = ({ role, selected, onSelect, onDismiss }) => {
  const { loading, error, data } = useQuery(GET_TEAM, {
    variables: { role },
  });

  const selectMember = member => {
    onSelect(member);
    onDismiss();
  };

  return (
    <Container>
      {loading ? (
        <ActivityIndicator />
      ) : error ? (
        <ErrorText>Error loading Team List</ErrorText>
      ) : (
        <List>
          {data.users.map(member => (
            <TeamMember
              key={member.userId}
              selected={selected && selected.userId === member.userId}
              onClick={() => selectMember(member)}>
              <UserIcon user={member} size="28px" />
              <Name>{`${member.firstName} ${member.lastName}`}</Name>
            </TeamMember>
          ))}
        </List>
      )}
    </Container>
  );
};

const ErrorText = styled.p`
  font-size: 14px;
  color: ${props => props.theme.textColors.secondary};
  line-height: 1.4;
  text-align: center;
  padding: 10px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 8px;

  width: 200px;
  height: 200px;
  overflow: hidden;
  box-shadow: 0 5px 20px 0 rgb(21 27 38 / 8%);

  margin-top: -40px;
  transform: translateX(24px);
`;

const List = styled.div`
  flex: 1;
  overflow-y: scroll;
  width: 100%;
  padding: 4px 0;
`;

const Name = styled.div`
  font-size: 13px;
  color: ${props => props.theme.textColors.primary};
  padding-left: 10px;
`;

const TeamMember = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 8px;
  cursor: pointer;

  background-color: ${props =>
    props.selected ? props.theme.backgroundColors.secondary : 'transparent'};

  &:hover {
    background-color: ${props => props.theme.backgroundColors.tertiary};

    ${Name} {
      color: ${props => props.theme.colors.blue};
    }
  }
`;

export default TeamPopover;
