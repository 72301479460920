import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import AdminHeader from '../components/admin/AdminHeader';
import PageTabs from '../components/common/PageTabs';
// import AddButton from '../components/common/AddButton';
import Table from '../components/tables/Table';
import FormModal from '../components/modals/FormModal';
import { ModalContext } from '../contexts/ModalContext';
import { PROFILE_FORM } from '../../forms/team';
import { GET_TEAM } from '../api/team';
import { formatToPhone } from '../hooks/useTextHelper';

const TABLE = [
  {
    id: 'available',
    label: '',
    width: '36px',
    formatFn: val => <StatusOrb available={val} />,
  },
  {
    id: 'assignment',
    label: 'Current Shift',
    width: '140px',
    formatFn: val => (val ? <Assignment>{val}</Assignment> : ''),
  },
  {
    id: 'firstName',
    label: 'First Name',
    flex: 1,
  },
  {
    id: 'lastName',
    label: 'Last Name',
    flex: 1,
  },
  {
    id: 'phoneNumber',
    label: 'Phone Number',
    width: '240px',
    formatFn: formatToPhone,
  },
  {
    id: 'email',
    label: 'Email Address',
    flex: 1,
  },
];

const TeamPage = () => {
  // const { dispatch: dispatchModal } = useContext(ModalContext);

  const [activeTab, setActiveTab] = useState('careline');
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState();

  const { data, error } = useQuery(GET_TEAM, {
    variables: {
      role: activeTab,
    },
  });

  // const closeModal = () => {};

  // const openProfileModal = member => {
  //   console.log('member: ', member);
  //   dispatchModal({
  //     type: 'ADD_MODAL',
  //     data: {
  //       component: FormModal,
  //       props: {
  //         data: member,
  //         config: {
  //           targetId: member?.id,
  //           type: 'form',
  //           form: PROFILE_FORM,
  //           title: 'Member Profile',
  //         },
  //         maxWidth: '720px',
  //       },
  //       onDismiss: closeModal,
  //     },
  //   });
  // };

  useEffect(() => {
    if (data?.users) {
      let arr = [];
      data.users.map(user => {
        arr.push({
          ...user,
          assignment: user.primary
            ? 'Primary'
            : user.secondary
            ? 'Secondary'
            : user.pool
            ? 'Pool'
            : null,
        });
      });
      setUsers(arr);
      setLoading(false);
    }
    if (error) {
      console.log(error);
      setLoading(false);
    }
  }, [data, error]);

  return (
    <Container>
      <Main>
        <AdminHeader title="Team" />
        <Content>
          <PageTabs
            config={[
              { id: 'careline', label: 'Care Line Reps' },
              { id: 'physician', label: 'Physicians' },
              { id: 'admin', label: 'Administrative' },
            ]}
            activeTab={activeTab}
            onTabClick={tab => setActiveTab(tab)}
            ehr
          />
          <Body>
            <Table
              loading={loading}
              data={users}
              config={TABLE}
              // onClick={openProfileModal}
              emptyNotice="No Team Members Found"
              ehr
            />
          </Body>
        </Content>
      </Main>
      {/* <AddButton onClick={() => openProfileModal()} /> */}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.backgroundColors.lightAlt};
`;

const Main = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 30px 40px;
  overflow-y: scroll;
`;

const Content = styled.div`
  padding-top: 30px;
  flex: 1;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 0 20px;
  height: 100%;
`;

const StatusOrb = styled.span`
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${props =>
    props.available ? props.theme.colors.green : props.theme.colors.border};
`;

const Assignment = styled.span`
  text-transform: uppercase;
  color: ${props => props.theme.textColors.secondary};
  font-weight: ${props => props.theme.fontWeights.medium};
  font-size: 0.75rem;
`;

export default TeamPage;
