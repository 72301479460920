import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { formatTimestamp } from '../../hooks/useTextHelper';

const VideoCallTime = ({ participants }) => {
  const [callTime, setCallTime] = useState(null);

  // ------- CALL TIMER ------

  // start timer once connected
  useEffect(() => {
    let interval = null;
    if (participants.length > 0) {
      interval = setInterval(() => {
        setCallTime(seconds => seconds + 1);
      }, 1000);
    } else if (callTime) {
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [participants]);

  return <CallTime>{callTime ? formatTimestamp(callTime, true) : ''}</CallTime>;
};

const CallTime = styled.p`
  position: relative;
  font-size: 15px;
  font-family: ${props => props.theme.fonts.secondary};
  font-weight: ${props => props.theme.fontWeights.medium};
  line-height: 1;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6), 0 0 2px rgba(0, 0, 0, 0.3);
  color: white;
  margin: 0;
  margin-left: auto;
`;

export default VideoCallTime;
