import React, { useState } from 'react';
import styled from 'styled-components';
import { FaCaretDown, FaCaretRight } from 'react-icons/fa';
import EmptyNotice from './EmptyNotice';
import RequestListItem from '../requests/RequestListItem';

const CollapsibleList = ({ data, label, error }) => {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <Container>
      <ListHeader>
        <CollapseBtn onClick={() => setCollapsed(!collapsed)}>
          {collapsed ? <FaCaretRight /> : <FaCaretDown />}
        </CollapseBtn>
        <Title>{label}</Title>
      </ListHeader>
      {collapsed ? null : error ? (
        <ErrorMsg>{error}</ErrorMsg>
      ) : (
        <List>
          {data && data.length > 0 ? (
            data.map(item => <RequestListItem key={item._id} data={item} />)
          ) : (
            <EmptyWrapper>
              <EmptyNotice lightAlt>No Active Requests</EmptyNotice>
            </EmptyWrapper>
          )}
        </List>
      )}
    </Container>
  );
};

const ErrorMsg = styled.p`
  padding: 30px;
  text-align: center;
  font-size: 15px;
  line-height: 1.4;
  color: ${props => props.theme.textColors.secondary};
`;

const Container = styled.div``;

const ListHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 20px;
`;

const Title = styled.h3`
  margin: 0;
`;

const CollapseBtn = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 5px;
  margin-right: 5px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  align-self: flex-end;

  svg {
    font-size: 18px;
    color: ${props => props.theme.textColors.secondary};
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.7);
    border-color: ${props => props.theme.colors.border};
    svg {
      color: ${props => props.theme.textColors.primary};
    }
  }
`;

const List = styled.div`
  display: block;
  overflow: hidden;
`;

const EmptyWrapper = styled.div`
  padding: 40px 0;
`;

export default CollapsibleList;
