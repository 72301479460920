import gql from 'graphql-tag';

// PERSONAL INFORMATION  (Name, Phone, Address)

/**
 * QUERIES
 */

/**
 * MUTATIONS
 */

// PERSONAL INFORMATION
export const UPDATE_CLINICAL_STUDY = gql`
  mutation UPDATE_CLINICAL_STUDY($id: String!, $clinicalStudy: String!) {
    updateOnePatient(
      query: { userId: $id }
      set: { clinicalStudy: $clinicalStudy }
    ) {
      userId
      clinicalStudy
    }
  }
`;

export const UPDATE_CODE_STATUS = gql`
  mutation UPDATE_CODE_STATUS($id: String!, $codeStatus: String!) {
    updateOnePatient(query: { userId: $id }, set: { codeStatus: $codeStatus }) {
      userId
      codeStatus
    }
  }
`;

export const UPDATE_DOB = gql`
  mutation UPDATE_DOB($id: String!, $birthDate: String!) {
    updateOnePatient(query: { userId: $id }, set: { birthDate: $birthDate }) {
      userId
      birthDate
    }
  }
`;

export const UPDATE_GENDER = gql`
  mutation UPDATE_GENDER($id: String!, $gender: String!) {
    updateOnePatient(query: { userId: $id }, set: { gender: $gender }) {
      userId
      gender
    }
  }
`;

export const UPDATE_HOME_ADDRESS = gql`
  mutation UPDATE_HOME_ADDRESS(
    $id: String
    $line: [String!]
    $city: String!
    $state: String!
    $postalCode: String!
    $notes: String
  ) {
    updateOnePatient(
      query: { userId: $id }
      set: {
        address: {
          home: {
            line: $line
            city: $city
            state: $state
            postalCode: $postalCode
            notes: $notes
          }
        }
      }
    ) {
      userId
      address {
        home {
          line
          city
          postalCode
          state
          notes
        }
      }
    }
  }
`;

export const UPDATE_HOME_PHONE = gql`
  mutation UPDATE_HOME_PHONE($id: String!, $phone: String!) {
    updateOnePatient(
      query: { userId: $id }
      set: { telecom: { home: $phone } }
    ) {
      userId
      telecom {
        home
      }
    }
  }
`;

export const UPDATE_LANGUAGE = gql`
  mutation UPDATE_LANGUAGE($id: String!, $language: String!) {
    updateOnePatient(query: { userId: $id }, set: { language: $language }) {
      userId
      language
    }
  }
`;

export const UPDATE_LIVING_ARRANGEMENT = gql`
  mutation UPDATE_LIVING_ARRANGEMENT(
    $id: String!
    $livingArrangement: String!
  ) {
    updateOnePatient(
      query: { userId: $id }
      set: { livingArrangement: $livingArrangement }
    ) {
      userId
      livingArrangement
    }
  }
`;

export const UPDATE_NAME = gql`
  mutation UPDATE_NAME($id: String!, $given: String!, $family: String!) {
    updateOnePatient(
      query: { userId: $id }
      set: { name: { given: $given, family: $family } }
    ) {
      userId
      name {
        given
        family
      }
    }
  }
`;

export const UPDATE_PHOTO = gql`
  mutation UPDATE_PHOTO($id: String!, $photo: String!) {
    updateOnePatient(query: { userId: $id }, set: { photo: $photo }) {
      userId
      photo
    }
  }
`;

export const UPDATE_RACE = gql`
  mutation UPDATE_RACE($id: String!, $race: String!) {
    updateOnePatient(query: { userId: $id }, set: { race: $race }) {
      userId
      race
    }
  }
`;

export const UPDATE_SSN = gql`
  mutation UPDATE_SSN($id: String!, $full: String!, $last4: String!) {
    updateOnePatient(
      query: { userId: $id }
      set: { ssn: { full: $full, last4: $last4 } }
    ) {
      userId
      ssn {
        full
        last4
      }
    }
  }
`;
