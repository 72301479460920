import React from 'react';
import styled from 'styled-components';
import { FaTimes } from 'react-icons/fa';

const IframeModal = ({ title, url, onDismiss }) => (
  <Container>
    <iframe title={title || 'BetterMedics iFrame'} src={url} />
    <CloseBtn onClick={onDismiss}>
      <FaTimes />
    </CloseBtn>
  </Container>
);

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: white;

  iframe {
    width: 100%;
    height: 100%;
    border: 0;
  }
`;

const CloseBtn = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 30;

  cursor: pointer;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.1);
  border: 2px solid ${props => props.theme.colors.border};

  svg {
    color: ${props => props.theme.textColors.tertiary};
    font-size: 40px;
    opacity: 0.8;
  }

  &:hover svg {
    opacity: 1;
  }
`;

export default IframeModal;
