import React from 'react';
import styled from 'styled-components';
import { formatDistance, parseISO } from 'date-fns';
import EncounterBox from './EncounterBox';

const EncounterSymptoms = ({ symptoms }) => (
  <SymptomsWrapper>
    {symptoms.map(data => {
      const { _id, location, onset, severity, symptom } = data;
      return (
        <EncounterBox
          key={_id}
          borderRadius="8px"
          padding="12px 20px 12px 12px">
          <Symptom>
            {severity ? <Severity>{severity}</Severity> : null}

            <SymptomText>
              <label>{location?.display}</label>
              <p>{symptom?.display || '-'}</p>
              <span>
                {formatDistance(parseISO(onset), new Date(), {
                  addSuffix: true,
                })}
              </span>
            </SymptomText>
          </Symptom>
        </EncounterBox>
      );
    })}
  </SymptomsWrapper>
);

const SymptomsWrapper = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(auto-fill, minmax(200px, max-content));
`;

const Symptom = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 10px;
`;

const Severity = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid ${props => props.theme.backgroundColors.lightAlt};
  font-size: 14px;
  font-weight: bold;
  color: ${props => props.theme.textColors.primary};
  margin-right: 10px;
`;

const SymptomText = styled.div`
  flex: 1;

  label,
  p,
  span {
    white-space: nowrap;
  }

  label {
    font-size: 11px;
    font-weight: normal;
    text-transform: none;
    letter-spacing: 0.2px;
    margin-bottom: 4px;
    color: ${props => props.theme.textColors.primary};
  }
  p {
    margin: 0;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.3;
  }
  span {
    margin-top: 4px;
    font-size: 11px;
    color: ${props => props.theme.textColors.secondary};
  }
`;

export default EncounterSymptoms;
