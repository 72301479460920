import gql from 'graphql-tag';

// MEDICATION INFORMATION
/**
 * FRAGMENTS
 */
const medicationFragments = {
  standard: gql`
    fragment MedicationStandard on Medication {
      _id
      effectiveDateTime
      medication {
        clinicalCode
        display
      }
      timing
      notes
    }
  `,
};

export const MEDICATION_FRAGMENTS = medicationFragments;

/**
 * QUERIES
 */

export const GET_MEDICATIONS = gql`
  query GET_MEDICATIONS($patient: String!) {
    medications(query: { patient: $patient }) {
      ...MedicationStandard
    }
  }
  ${medicationFragments.standard}
`;

/**
 * MUTATIONS
 */

export const ADD_MEDICATION = gql`
  mutation ADD_MEDICATION(
    $id: String!
    $medication: MedicationMedicationInsertInput!
    $timing: String
    $effectiveDateTime: DateTime!
    $notes: String
  ) {
    insertOneMedication(
      data: {
        patient: $id
        medication: $medication
        timing: $timing
        effectiveDateTime: $effectiveDateTime
        notes: $notes
      }
    ) {
      ...MedicationStandard
    }
  }
  ${medicationFragments.standard}
`;

export const UPDATE_MEDICATION = gql`
  mutation UPDATE_MEDICATION(
    $id: ObjectId!
    $medication: MedicationMedicationUpdateInput!
    $timing: String
    $effectiveDateTime: DateTime!
    $notes: String
  ) {
    updateOneMedication(
      query: { _id: $id }
      set: {
        medication: $medication
        timing: $timing
        effectiveDateTime: $effectiveDateTime
        notes: $notes
      }
    ) {
      ...MedicationStandard
    }
  }
  ${medicationFragments.standard}
`;

export const REMOVE_MEDICATION = gql`
  mutation DELETE_MEDICATION($id: ObjectId!) {
    deleteOneMedication(query: { _id: $id }) {
      _id
    }
  }
`;
