import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
} from 'react';
import styled from 'styled-components';
import Loader from '../common/Loader';
import useAmplitude from '../../hooks/useAmplitude';
import { CallContext } from '../../contexts/CallContext';
import { SystemContext } from '../../contexts/SystemContext';

const PreCallView = ({ onConnect }) => {
  const { track } = useAmplitude();

  const [connecting, setConnecting] = useState();
  const [notifySent, setNotifySent] = useState();

  const {
    state: { channel, room },
  } = useContext(CallContext);
  const {
    state: { realmUser },
  } = useContext(SystemContext);

  const isPhysician = useMemo(() => realmUser.customData.role === 'physician', [
    realmUser,
  ]);

  const connectToCall = useCallback(() => {
    setConnecting(true);
    onConnect();
  }, [onConnect]);

  const startCall = async () => {
    if (isPhysician) {
      // send notification to Careline
      await realmUser.functions.sendPusherEvent(
        `presence-${room}`,
        'physician-ready',
      );
      setNotifySent(true);

      // record in Amplitude
      track('Physician Requested to Join Call', {}, true);
    } else {
      connectToCall();
    }
  };

  useEffect(() => {
    if (isPhysician && channel) {
      channel.bind('connect-physician', connectToCall);
    }

    return () => {
      if (isPhysician && channel) {
        channel.unbind('connect-physician', connectToCall);
      }
    };
  }, [isPhysician, channel, connectToCall]);

  return (
    <Container>
      <RoomPreview>
        {connecting ? (
          <LoadingWrapper>
            <Loader dots size="small" call />
          </LoadingWrapper>
        ) : notifySent ? (
          <PreviewTextBlock>
            <Message header>Please standby</Message>
            <Message>
              Care Line has been notified and will bridge you in with the
              patient momentarily.
            </Message>
          </PreviewTextBlock>
        ) : (
          <PreviewTextBlock>
            <Message>
              {isPhysician
                ? 'When ready, let Care Line know you are here.'
                : "You can join the call when you're ready."}
            </Message>
            <ConnectButton onClick={startCall}>
              {isPhysician ? "I'm Ready" : 'Join Now'}
            </ConnectButton>
          </PreviewTextBlock>
        )}
      </RoomPreview>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  pointer-events: auto;
`;

const Message = styled.p`
  font-size: 16px;
  font-weight: ${props =>
    props.header
      ? props.theme.fontWeights.bold
      : props.theme.fontWeights.regular};
  line-height: 1.4;
  letter-spacing: 0.25px;
  color: white;
  text-align: center;
  margin: 0;
  margin-bottom: ${props => (props.header ? '6px' : 0)};
`;

const RoomPreview = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const PreviewTextBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  padding: 26px;
  background-color: rgba(0, 0, 0, 0.3);
  margin: 0 24px;
`;

const ConnectButton = styled.button`
  background-color: white;
  padding: 12px 20px;
  color: black;
  font-family: ${props => props.theme.fonts.secondary};
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  margin-top: 20px;
  border: none;
  border-radius: 4px;
  outline: none;
  cursor: pointer;

  &:active {
    opacity: 0.7;
  }
`;

const LoadingWrapper = styled.div`
  background-color: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
`;

export default PreCallView;
