import gql from 'graphql-tag';

const symptomFields = gql`
  fragment symptomFields on Symptom {
    _id
    location {
      clinicalCode
      display
    }
    onset
    severity
    symptom {
      clinicalCode
      display
    }
  }
`;

export const SYMPTOM_FRAGMENT = symptomFields;

export const GET_SYMPTOMS = gql`
  query GET_SYMPTOMS($encounter: ObjectId!) {
    symptoms(query: { encounter: $encounter }) {
      ...symptomFields
    }
  }
  ${symptomFields}
`;

export const ADD_SYMPTOM = gql`
  mutation ADD_SYMPTOM(
    $id: ObjectId
    $location: SymptomLocationInsertInput
    $onset: DateTime
    $patient: String
    $severity: Int
    $symptom: SymptomSymptomInsertInput
  ) {
    insertOneSymptom(
      data: {
        encounter: $id
        location: $location
        onset: $onset
        patient: $patient
        severity: $severity
        symptom: $symptom
      }
    ) {
      ...symptomFields
    }
  }
  ${symptomFields}
`;

export const UPDATE_SYMPTOM = gql`
  mutation UPDATE_SYMPTOM(
    $_id: ObjectId!
    $location: SymptomLocationUpdateInput
    $onset: DateTime
    $severity: Int
    $symptom: SymptomSymptomUpdateInput
  ) {
    updateOneSymptom(
      query: { _id: $_id }
      set: {
        location: $location
        onset: $onset
        severity: $severity
        symptom: $symptom
      }
    ) {
      ...symptomFields
    }
  }
  ${symptomFields}
`;

export const DELETE_SYMPTOM = gql`
  mutation DELETE_SYMPTOM($id: ObjectId!) {
    deleteOneSymptom(query: { _id: $id }) {
      _id
    }
  }
`;
