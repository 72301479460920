// @flow
import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { AlertContext } from '../../contexts/AlertContext';
import Alert from './Alert';

const AlertCenter = ({ admin }) => {
  const {
    state: { alerts },
    dispatch: dispatchAlert,
  } = useContext(AlertContext);

  const [alert, setAlert] = useState(alerts[0]);

  const removeAlert = () => {
    dispatchAlert({ type: 'REMOVE_OLDEST_ALERT' });

    // clear the current alert so state change adds new alert
    setAlert(null);
  };

  useEffect(() => {
    setAlert(alerts[0]);
  }, [alerts]);

  return (
    <Container admin={admin ? true : false}>
      {alert ? (
        <Alert
          id={alert.id}
          icon={alert.icon}
          message={alert.message}
          removeAlert={removeAlert}
        />
      ) : null}
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  pointer-events: none;

  ${({ admin }) =>
    admin &&
    `
    left: 90px;
    right: 0;
    width: initial;
  `}
`;

export default AlertCenter;
