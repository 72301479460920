import gql from 'graphql-tag';

/**
 * FRAGMENTS
 */

const familyHistoryFields = gql`
  fragment familyHistoryFields on FamilyHistory {
    _id
    conditions {
      clinicalCode
      display
    }
    deceased
    relationship
  }
`;

/**
 * QUERIES
 */

export const GET_FAMILY_HISTORY = gql`
  query GET_FAMILY_HISTORY($patient: String!) {
    familyHistories(query: { patient: $patient }) {
      ...familyHistoryFields
    }
  }
  ${familyHistoryFields}
`;

/**
 * MUTATIONS
 */

export const ADD_FAMILY_HISTORY = gql`
  mutation ADD_FAMILY_HISTORY(
    $id: String!
    $conditions: [FamilyHistoryConditionInsertInput]!
    $deceased: Boolean!
    $relationship: String!
  ) {
    insertOneFamilyHistory(
      data: {
        conditions: $conditions
        deceased: $deceased
        patient: $id
        relationship: $relationship
      }
    ) {
      ...familyHistoryFields
    }
  }
  ${familyHistoryFields}
`;

export const UPDATE_FAMILY_HISTORY = gql`
  mutation UPDATE_FAMILY_HISTORY(
    $id: ObjectId!
    $conditions: [FamilyHistoryConditionUpdateInput]!
    $deceased: Boolean!
    $relationship: String!
  ) {
    updateOneFamilyHistory(
      query: { _id: $id }
      set: {
        conditions: $conditions
        deceased: $deceased
        relationship: $relationship
      }
    ) {
      ...familyHistoryFields
    }
  }
  ${familyHistoryFields}
`;

export const REMOVE_FAMILY_HISTORY = gql`
  mutation DELETE_FAMILY_HISTORY($id: ObjectId!) {
    deleteOneFamilyHistory(query: { _id: $id }) {
      _id
    }
  }
`;
