import React from 'react';
import styled, { keyframes } from 'styled-components';
import PatientHeaderOnboarding from './PatientHeaderOnboarding';
import ChartingOnboarding from '../charting/onboarding/ChartingOnboarding';

const PatientEHR = ({ patient, show, onClose }) => (
  <Container visible={show}>
    <PatientHeaderOnboarding patient={patient} onBack={onClose} />
    <ChartingOnboarding userId={patient.userId} stickyFooter hideVitals ehr />
  </Container>
);

const animIn = keyframes`
  0% { 
    transform: translateX(400px);
    opacity: 0;
  }
  100% { 
    transform: translateX(0);
    opacity: 1;
  }
`;

const animOut = keyframes`
  0% { 
    transform: translateX(0);
    opacity: 1;
  }
  100% { 
    transform: translateX(400px);
    opacity: 0;
  }
`;

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 60;
  height: 100%;
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: ${props => props.theme.backgroundColors.tertiary};

  opacity: ${props => (props.visible ? 0 : 1)};
  pointer-events: ${props => (props.visible ? 'auto' : 'none')};

  animation-duration: 0.3s;
  animation-timing-function: ease-out;
  animation-delay: 0.2s;
  animation-fill-mode: forwards;
  animation-name: ${props => (props.visible ? animIn : animOut)};
`;

export default PatientEHR;
