import React from 'react';
import styled from 'styled-components';
import { capitalize, formatBirthDate } from '../../hooks/useTextHelper';
import { FaTimes } from 'react-icons/fa';
import { CODE_STATUS_TYPES } from '../../../constants';
import UserIcon from '../common/UserIcon';

const PatientHeaderOnboarding = ({ patient, onBack }) => (
  <Header>
    <UserIcon user={patient} size="50px" patient />
    <PatientInfo>
      <PatientName>{`${patient.name.given} ${patient.name.family}`}</PatientName>
      <PatientDetailRow>
        <Detail>{`MRN: #${patient.mrn}`}</Detail>
        <Detail>{capitalize(patient.gender)}</Detail>
        <Detail>{`DOB: ${formatBirthDate(patient.birthDate)}`}</Detail>
        {patient?.codeStatus ? (
          <CodeStatusBadge
            codeColor={CODE_STATUS_TYPES[patient.codeStatus]?.color}>
            {CODE_STATUS_TYPES[patient.codeStatus]?.title}
          </CodeStatusBadge>
        ) : null}
      </PatientDetailRow>
    </PatientInfo>
    {onBack ? (
      <BackBtn onClick={onBack}>
        <FaTimes />
      </BackBtn>
    ) : null}
  </Header>
);

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  background-color: white;
`;

const BackBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  cursor: pointer;

  svg {
    font-size: 32px;
    color: ${props => props.theme.textColors.tertiary};
  }

  &:hover {
    svg {
      color: ${props => props.theme.colors.blue};
    }
  }
`;

const PatientInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  margin-left: 15px;
`;

const PatientName = styled.h3`
  margin: 0;
  padding: 0 0 5px;
`;

const PatientDetailRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Detail = styled.p`
  margin: 0 30px 0 0;
  font-size: 12px;
  color: ${props => props.theme.textColors.secondary};
`;

const CodeStatusBadge = styled.div`
  font-size: 9px;
  font-weight: ${props => props.theme.fontWeights.medium};
  color: white;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 0.5px;
  line-height: 1;
  padding: 5px 7px 4px;
  border-radius: 2px;
  background-color: ${props =>
    props.codeColor || props.theme.textColors.primary};
  opacity: 0.8;
  margin-left: 12px;
`;

export default PatientHeaderOnboarding;
