import React from 'react';
import { useApolloClient } from '@apollo/client';
import { COMPLETE_ONBOARDING } from '../../../api/rooms';
import EndScreenQuestion from './EndScreenQuestion';

const EndScreenDisconnect = ({ patient, onComplete }) => {
  const client = useApolloClient();

  const completeOnboarding = async () => {
    // make server call here to flip onboarded flag to true
    try {
      await client.mutate({
        mutation: COMPLETE_ONBOARDING,
        variables: {
          id: patient?.userId,
        },
      });

      // Amplitude:  set user property onboarded to true
    } catch (err) {
      console.log('Error marking onboarding complete: ', err);
    }

    onComplete();
  };

  return (
    <EndScreenQuestion
      message="Mark patient's onboarding as complete?"
      yesTxt="Yes"
      noTxt="No"
      onYes={completeOnboarding}
      onNo={onComplete}
    />
  );
};

export default EndScreenDisconnect;
