import React, { useState } from 'react';
import styled from 'styled-components';
import { isObject } from '../../hooks/useFormHelper';
import { BsPersonFill } from 'react-icons/bs';
import { CDN_URL, PHOTO_URL } from '../../../constants';

const getName = name => {
  if (isObject(name) && name.given) return name.given;

  return name || '';
};

const UserIcon = ({
  user,
  size = '50px',
  patient,
  video,
  fillFrame,
  inline,
}) => {
  const [loadingError, setLoadingError] = useState(false);

  const name = user?.firstName
    ? user.firstName
    : user?.name
    ? getName(user.name)
    : null;

  let filename =
    patient && user?.photo
      ? `${CDN_URL[process.env.REACT_APP_ENVIRONMENT]}${user?.photo}`
      : user?.photo;

  if (video && !patient)
    filename = user?.photo ? `${PHOTO_URL}${user?.photo}` : null;

  return (
    <Container
      size={size}
      inline={inline}
      background={
        video
          ? 'video'
          : filename && !loadingError
          ? 'transparent'
          : patient
          ? 'tertiary'
          : null
      }
      video={video}
      fillFrame={fillFrame}>
      {filename && !loadingError ? (
        <Photo src={filename} onError={() => setLoadingError(true)} />
      ) : null}
      {patient || !name ? (
        <BsPersonFill />
      ) : (
        <Initials>{name.charAt(0)}</Initials>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: ${props => (props.inline ? 'inline-flex' : 'flex')};
  justify-content: center;
  align-items: center;
  width: ${props => props.size};
  height: ${props => props.size};
  font-size: ${props => props.size};
  border-radius: 50%;
  overflow: hidden;
  background-color: ${props =>
    props.background === 'video'
      ? 'rgba(255, 255, 255, 0.5)'
      : props.background === 'tertiary'
      ? props.theme.colors.tertiary
      : props.background === 'transparent'
      ? 'transparent'
      : props.theme.colors.purple};

  ${({ fillFrame }) =>
    fillFrame &&
    `
    width: 100%;
    height: 100%;
    border-radius: 0;
  `}

  ${({ video }) =>
    video &&
    `
      &:hover {
        &::after {
          display: block;
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 3;
          width: 100%;
          height: 100%;
          background-color: rgba(255, 255, 255, 0.1);
        }
      }
  `}

  svg {
    color: ${props => (props.video ? 'black' : 'white')};
    font-size: ${props => props.size};
    transform: scale(0.6);
    opacity: ${props => (props.video ? 0.5 : 0.75)};
  }
`;

const Photo = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Initials = styled.span`
  color: white;
  font-size: 0.5em;
  font-family: ${props => props.theme.fonts.primary};
  font-weight: ${props => props.theme.fontWeights.bold};
  text-transform: uppercase;
  text-align: center;
  line-height: 1;
`;

export default UserIcon;
