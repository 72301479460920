import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import RequestForm from '../requests/RequestForm';
import ChartingOnboarding from './onboarding/ChartingOnboarding';
import ChartingPhysician from './physician/ChartingPhysician';
import ChartingCareline from './careline/ChartingCareline';
import PatientHeader from '../patient/PatientHeader';
import ModalManager from '../modals/ModalManager';
import Popover from '../popovers/Popover';
import { CallContext } from '../../contexts/CallContext';
import { SystemContext } from '../../contexts/SystemContext';
import VitalsContextProvider from '../../contexts/VitalsContext';
import { GET_CALL_REQUESTS } from '../../api/requests';

const Charting = ({ onPhoto, onChat }) => {
  const {
    state: { _id: callId, patient, callType, channel, room },
    dispatch: dispatchCall,
  } = useContext(CallContext);
  const {
    state: { realmUser },
  } = useContext(SystemContext);

  const { data, error: requestError, refetch } = useQuery(GET_CALL_REQUESTS, {
    variables: { callId },
    skip: callId ? false : true,
  });

  const [onboarding, setOnboarding] = useState();
  const [showRequestForm, setShowRequestForm] = useState();

  const isPhysician = realmUser?.customData?.role === 'physician';
  // const isPhysician = true;

  const onRefreshRequests = async () => {
    refetch();

    try {
      await realmUser.functions.sendPusherEvent(
        `presence-${room}`,
        'update-requests',
        {
          madeBy: realmUser.customData.role,
        },
      );
    } catch (err) {
      console.log('Error sending pusher event (update-requests)', err);
    }

    // tell admin's task list to update with new tasks that may be assigned to them
    try {
      await realmUser.functions.sendPusherEvent('admin-tasks', 'update-list');
    } catch (err) {
      console.log('Error sending pusher event (admin-tasks)', err);
    }
  };

  useEffect(() => {
    if (callType) {
      const isOnboarding = callType === 'onboarding';
      setOnboarding(isOnboarding);
    }
  }, [callType]);

  useEffect(() => {
    // each time data updates, save current requests in local context
    if (data)
      dispatchCall({ type: 'SET_CALL', data: { requests: data.requests } });
  }, [data]);

  useEffect(() => {
    const fetchNewRequests = data => {
      // care line or physician are telling the other's UI to update on new requests
      if (data.madeBy !== realmUser.customData.role) refetch();
    };

    if (channel) channel.bind('update-requests', fetchNewRequests);
    return () => {
      if (channel) channel.unbind('update-requests', fetchNewRequests);
    };
  }, [channel, refetch]);

  return (
    <VitalsContextProvider>
      <Container>
        <Content>
          {patient ? (
            isPhysician ? (
              <ChartingPhysician
                onChat={onChat}
                onRequestForm={() => setShowRequestForm(true)}
              />
            ) : (
              <>
                <PatientHeader
                  mode={callType === 'onboarding' ? 'onboarding' : 'careline'}
                  patient={patient}
                  requestError={requestError}
                  onChat={onChat}
                  onPhoto={onPhoto}
                  onRequestForm={() => setShowRequestForm(true)}
                />
                {onboarding ? (
                  <ChartingOnboarding userId={patient.userId} />
                ) : (
                  <ChartingCareline />
                )}
              </>
            )
          ) : null}
        </Content>

        {showRequestForm && patient && data ? (
          <RequestForm
            patient={patient}
            requests={data.requests}
            onRefresh={onRefreshRequests}
            onClose={() => setShowRequestForm(false)}
          />
        ) : null}

        <Popover />
        <ModalManager />
      </Container>
    </VitalsContextProvider>
  );
};

const Container = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
`;

export default Charting;
