import React from 'react';
import styled, { keyframes } from 'styled-components';
import Form from '../forms/Form';
import List from '../forms/List';

const EditModal = ({
  data,
  config,
  inline,
  stickyFooter,
  inModal,
  onFormSave,
  onClose,
}) => {
  const { targetId, form, formHeader, type, summary, onItemClick } = config;

  return (
    <Container>
      <Content center={form.center} inline={inline}>
        {config ? (
          type === 'list' ? (
            <List
              targetId={targetId}
              data={data}
              summary={summary} // { icon, title, subtitle } of clicked Card
              onClose={onClose}
              inModal={inModal}
              onItemClick={onItemClick}
              {...form} // { form, add, remove, update, formHeader, map, table }
            />
          ) : (
            <FormWrapper inline={inline}>
              <Form
                targetId={targetId}
                form={form}
                headerConfig={formHeader}
                data={data}
                onFormSave={onFormSave}
                onClose={onClose}
                inline={inline}
                stickyFooter={stickyFooter}
                inModal={inModal}
              />
            </FormWrapper>
          )
        ) : null}
      </Content>
    </Container>
  );
};

const fadeIn = keyframes`
  0% {
    opacity: 0;
    padding-top: 80px;
  }
  100% {
    opacity: 1;
    padding-top: 0;
  }
`;

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 50;

  display: flex;
  flex-direction: column;

  overflow-y: ${props => (props.sticky ? 'scroll' : 'auto')};
  background-color: white;
`;

const Content = styled.div`
  flex: 1;
  height: 100%;
  background-color: white;
  animation-name: ${fadeIn};
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  display: ${props => (props.center ? 'flex' : 'block')};
  justify-content: center;
  align-items: center;

  label,
  input {
    text-align: ${props => (props.center ? 'center' : 'left')};
  }

  fieldset {
    margin-bottom: ${props => (props.inline ? 0 : '10px')};
    padding: ${props => (props.inline ? 0 : 'auto')};
  }

  textarea {
    resize: ${props => (props.inline ? 'none' : 'auto')};
  }
`;

const FormWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 0;
`;

export default EditModal;
