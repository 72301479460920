import gql from 'graphql-tag';

// PATIENT REQUESTS  (During an Encounter)

/**
 * FRAGMENTS
 */

const requestFields = gql`
  fragment requestFields on Request {
    _id
    call
    patient {
      name {
        given
        family
      }
      photo
      userId
    }
    type
    description
    urgency
    datetime
    starred
    createdBy {
      role
      firstName
      lastName
      photo
      userId
    }
  }
`;

const requestCommentFields = gql`
  fragment requestCommentFields on RequestComment {
    _id
    author {
      firstName
      lastName
      role
      photo
    }
    comment
    date
    request
  }
`;

/**
 * QUERIES
 */

export const GET_CALL_REQUESTS = gql`
  query GET_CALL_REQUESTS($callId: ObjectId!) {
    requests(query: { call: $callId }) {
      ...requestFields
    }
  }
  ${requestFields}
`;

export const GET_PATIENT_REQUESTS = gql`
  query GET_PATIENT_REQUESTS($patient: ObjectId!) {
    requests(query: { patient: $patient }) {
      ...requestFields
    }
  }
  ${requestFields}
`;

export const GET_REQUESTS = gql`
  query GET_REQUESTS($resolved: Boolean = false) {
    requests(query: { resolved: $resolved }, sortBy: DATETIME_DESC) {
      ...requestFields
    }
  }
  ${requestFields}
`;

export const GET_STARRED_REQUESTS = gql`
  query GET_STARRED_REQUESTS($resolved: Boolean = false) {
    requests(
      query: { resolved: $resolved, starred: true }
      sortBy: DATETIME_DESC
    ) {
      ...requestFields
    }
  }
  ${requestFields}
`;

export const GET_REQUEST_COMMENTS = gql`
  query GET_REQUEST_COMMENTS($id: ObjectId) {
    requestComments(query: { request: $id }, sortBy: DATE_DESC) {
      ...requestCommentFields
    }
  }
  ${requestCommentFields}
`;

/**
 * MUTATIONS
 */

export const ADD_REQUEST_ITEM = gql`
  mutation ADD_REQUEST_ITEM(
    $id: String!
    $type: String
    $description: String
    $urgency: String
    $callId: ObjectId!
    $datetime: DateTime
    $createdBy: String
  ) {
    insertOneRequest(
      data: {
        patient: { link: $id }
        type: $type
        description: $description
        urgency: $urgency
        call: $callId
        datetime: $datetime
        resolved: false
        starred: false
        createdBy: { link: $createdBy }
      }
    ) {
      ...requestFields
    }
  }
  ${requestFields}
`;

export const UPDATE_REQUEST_ITEM = gql`
  mutation UPDATE_REQUEST_ITEM(
    $id: ObjectId!
    $callId: ObjectId!
    $type: String
    $description: String
    $urgency: String
    $datetime: DateTime
  ) {
    replaceOneRequest(
      query: { _id: $id }
      data: {
        type: $type
        description: $description
        urgency: $urgency
        datetime: $datetime
        call: $callId
        resolved: false
      }
    ) {
      ...requestFields
    }
  }
  ${requestFields}
`;

export const UPDATE_STAR_STATUS = gql`
  mutation UPDATE_STAR_STATUS($id: ObjectId!, $starred: Boolean!) {
    updateOneRequest(query: { _id: $id }, set: { starred: $starred }) {
      ...requestFields
    }
  }
  ${requestFields}
`;

export const REMOVE_REQUEST_ITEM = gql`
  mutation DELETE_REQUEST_ITEM($id: ObjectId!) {
    deleteOneRequest(query: { _id: $id }) {
      _id
    }
  }
`;

export const UPDATE_REQUEST_STATUS = gql`
  mutation UPDATE_REQUEST_STATUS($id: ObjectId!, $resolved: Boolean!) {
    updateOneRequest(query: { _id: $id }, set: { resolved: $resolved }) {
      _id
    }
  }
`;

export const ADD_NEW_COMMENT = gql`
  mutation ADD_NEW_COMMENT(
    $author: String!
    $date: DateTime!
    $comment: String!
    $request: ObjectId!
  ) {
    insertOneRequestComment(
      data: {
        author: { link: $author }
        date: $date
        comment: $comment
        request: $request
      }
    ) {
      ...requestCommentFields
    }
  }
  ${requestCommentFields}
`;

export const UPDATE_COMMENT = gql`
  mutation UPDATE_COMMENT($id: ObjectId!, $date: DateTime!, $comment: String!) {
    updateOneRequestComment(
      query: { _id: $id }
      set: { date: $date, comment: $comment }
    ) {
      ...requestCommentFields
    }
  }
  ${requestCommentFields}
`;

export const DELETE_COMMENT = gql`
  mutation DELETE_COMMENT($id: ObjectId!) {
    deleteOneRequestComment(query: { _id: $id }) {
      _id
    }
  }
`;
