import React, { useContext } from 'react';
import styled from 'styled-components';
import { lighten } from 'polished';
import { FaPhoneAlt } from 'react-icons/fa';
import { getAge, capitalize } from '../../hooks/useTextHelper';

import DoctorButton from './DoctorButton';
import { CallContext } from '../../contexts/CallContext';
import { PopoverContext } from '../../contexts/PopoverContext';
import WithTooltip from '../common/WithTooltip';
import DiagnosisPopover from '../popovers/DiagnosisPopover';
import { CODE_STATUS_TYPES } from '../../../constants';
import UserIcon from '../common/UserIcon';

const PatientHeader = ({
  mode,
  patient,
  requestError,
  onChat,
  onPhoto,
  onRequestForm,
}) => {
  const {
    state: { callType, requests, status },
  } = useContext(CallContext);
  const { dispatch: dispatchPopover } = useContext(PopoverContext);

  const onDiagnosis = e => {
    e.preventDefault();

    dispatchPopover({
      type: 'ADD_POPOVER',
      data: {
        component: DiagnosisPopover,
        props: {
          patient: patient,
          bounds: e.target.getBoundingClientRect(),
        },
      },
    });
  };

  return (
    <Profile mode={mode}>
      {patient ? (
        <ContentWrapper>
          <Photo>
            <UserIcon user={patient} size="110px" patient fillFrame />
            {status === 'active' ? (
              <ChangePhoto>
                <ChangePhotoBtn onClick={onPhoto}>Change Photo</ChangePhotoBtn>
              </ChangePhoto>
            ) : null}
          </Photo>
          <PatientInfo>
            <PatientName>
              {patient.name
                ? `${patient.name.given} ${patient.name.family}`
                : ''}
            </PatientName>
            <PatientDetailRow>
              <PatientDetail>{`ID#: ${patient.mrn}`}</PatientDetail>
              <PatientDetail>{`${
                patient.birthDate
                  ? `${getAge(patient.birthDate)} years old`
                  : 'No birthdate entered'
              } | ${
                patient.gender ? capitalize(patient.gender) : ''
              }`}</PatientDetail>
              {patient.height || patient.weight ? (
                <PatientDetail>{`${patient.height || ''}${
                  patient.height && patient.weight ? ' | ' : ''
                }${
                  patient.weight ? `${patient.weight} lbs` : ''
                }`}</PatientDetail>
              ) : null}
            </PatientDetailRow>
            <PatientDetailRow>
              <DiagnosisBtn onClick={onDiagnosis}>View Diagnoses</DiagnosisBtn>
              {patient?.codeStatus ? (
                <CodeStatusBadge
                  codeColor={CODE_STATUS_TYPES[patient.codeStatus]?.color}>
                  {CODE_STATUS_TYPES[patient.codeStatus]?.title}
                </CodeStatusBadge>
              ) : null}
            </PatientDetailRow>
          </PatientInfo>
          {callType !== 'onboarding' ? (
            <ActionButtons>
              {status === 'active' ? (
                ['careline', 'physician'].includes(mode) ? (
                  <>
                    {requestError ? null : (
                      <WithTooltip title="Patient Requests">
                        <RequestBtn onClick={onRequestForm}>
                          Requests
                          {requests.length > 0 ? (
                            <CountOrb>{requests.length}</CountOrb>
                          ) : null}
                        </RequestBtn>
                      </WithTooltip>
                    )}

                    {/* <ChatBtn onClick={onChat}>
                    <FaRegCommentDots />
                  </ChatBtn> */}
                    {mode === 'careline' ? <DoctorButton /> : null}
                  </>
                ) : mode === 'profile' ? (
                  <WithTooltip title="Call Patient">
                    <ActionBtn color="green">
                      <FaPhoneAlt />
                    </ActionBtn>
                  </WithTooltip>
                ) : null
              ) : null}
            </ActionButtons>
          ) : null}
        </ContentWrapper>
      ) : (
        <EmptyState>
          <Photo empty />
          <PatientInfo>
            <EmptyText />
            <EmptyText small />
          </PatientInfo>
        </EmptyState>
      )}
    </Profile>
  );
};

const DiagnosisBtn = styled.button`
  height: 30px;
  border-radius: 20px;
  color: ${props => props.theme.textColors.tertiary};
  font-size: 12px;
  padding: 0 15px;
  background-color: white;
  border: 1px solid ${props => props.theme.colors.border};
  cursor: pointer;

  &:hover {
    background-color: ${props => props.theme.backgroundColors.lightAlt};
  }
`;

const Profile = styled.div`
  display: block;
  padding: ${props => (props.mode === 'careline' ? '20px' : '20px 40px')};
  background-color: ${props => props.theme.backgroundColors.primary};
  border-bottom: 1px solid ${props => props.theme.colors.border};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ContentWrapper = styled(Wrapper)``;

const ChangePhotoBtn = styled.button`
  outline: none;
  border: none;
  border-top: 1px solid ${props => props.theme.colors.border};
  background-color: white;
  width: 100%;
  padding: 6px 0;
  font-size: 10px;
  font-family: ${props => props.theme.fonts.primary};
  font-weight: ${props => props.theme.fontWeights.bold};
  color: ${props => props.theme.textColors.primary};
  text-transform: uppercase;
  letter-spacing: 0.25px;
  cursor: pointer;

  &:hover {
    background-color: ${props => props.theme.colors.blue};
    border-top-color: ${props => props.theme.colors.blue};
    color: white;
  }
`;

const ChangePhoto = styled.div`
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  background-color: rgba(255, 255, 255, 0.5);
  justify-content: center;
  align-items: flex-end;
`;

const Photo = styled.div`
  position: relative;
  display: flex;
  width: 110px;
  height: 110px;
  border-radius: 12px;
  padding: 6px;
  border: 2px solid;
  border-color: ${props => props.codeColor || props.theme.colors.border};
  overflow: hidden;
  background-color: ${props =>
    props.empty ? props.theme.colors.border : 'white'};
  justify-content: center;
  align-items: center;
  opacity: ${props => (props.empty ? 0.5 : 1)};

  svg {
    width: 80px;
    height: 80px;
    color: ${props => props.theme.colors.border};
  }

  img {
    border-radius: 6px;
  }

  &:hover {
    ${ChangePhoto} {
      display: flex;
    }
  }
`;

const PatientInfo = styled.div`
  flex: 1;
  padding: 0 20px;
`;

const CodeStatusBadge = styled.div`
  font-size: 9px;
  font-weight: ${props => props.theme.fontWeights.medium};
  color: white;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 0.5px;
  line-height: 1;
  padding: 5px 7px 4px;
  border-radius: 2px;
  background-color: ${props =>
    props.codeColor || props.theme.textColors.primary};
  opacity: 0.8;
  margin-left: 12px;
`;

const PatientName = styled.p`
  font-family: ${props => props.theme.fonts.primary};
  font-size: 22px;
  font-weight: ${props => props.theme.fontWeights.bold};
  color: ${props => props.theme.textColors.primary};
  margin: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 10px;
`;

const PatientDetailRow = styled.div`
  margin-top: 12px;

  &:last-child {
    margin-left: -6px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

const PatientDetail = styled.p`
  display: inline-block;
  font-size: 15px;
  font-weight: ${props => props.theme.fontWeights.regular};
  color: ${props => props.theme.textColors.secondary};
  margin: 0;

  &:not(:first-child) {
    margin-left: 20px;
  }
`;

const ActionBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props =>
    props.color === 'green'
      ? props.theme.colors.green
      : props.color === 'primary'
      ? props.theme.colors.primary
      : 'white'};
  border: 2px solid;
  border-color: ${props =>
    props.color ? 'transparent' : props.theme.colors.border};
  width: 48px;
  height: 48px;
  border-radius: 18px;
  outline: none;
  font-size: 12px;
  font-weight: ${props => props.theme.fontWeights.bold};
  text-transform: uppercase;
  letter-spacing: 0.2px;
  color: ${props => props.theme.textColors.secondary};
  text-align: center;
  line-height: 1;
  cursor: pointer;

  svg {
    color: ${props =>
      props.color ? 'white' : props.theme.textColors.secondary};
    font-size: 24px;
  }

  &:hover {
    background-color: ${props =>
      props.color === 'green'
        ? lighten(0.1, props.theme.colors.green)
        : props.color === 'primary'
        ? lighten(0.1, props.theme.colors.primary)
        : 'inherit'};

    border-color: ${props =>
      props.color ? 'transparent' : props.theme.colors.blue};

    color: ${props => props.theme.colors.blue};

    svg {
      color: ${props => (props.color ? 'white' : props.theme.colors.blue)};
    }
  }
`;

const ActionButtons = styled.div`
  display: flex;
  padding-right: 10px;

  > div {
    margin-left: 8px;
  }
`;

const ChatBtn = styled(ActionBtn)``;

const RequestBtn = styled(ActionBtn)`
  position: relative;
  width: auto;
  padding: 0 20px;

  &:hover {
    color: ${props => props.theme.colors.blue};
  }
`;

const CountOrb = styled.span`
  position: absolute;
  top: 3px;
  left: 3px;
  z-index: 1;
  transform: translateX(-50%) translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-weight: ${props => props.theme.fontWeights.bold};
  color: white;
  background-color: ${props => props.theme.colors.primary};
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid white;
`;

const EmptyState = styled(Wrapper)``;

const EmptyText = styled.div`
  display: block;
  width: ${props => (props.small ? '80px' : '200px')};
  height: ${props => (props.small ? '20px' : '40px')};
  background-color: ${props => props.theme.colors.border};
  border-radius: ${props => (props.small ? '8px' : '12px')};
  margin-bottom: 12px;
  opacity: 0.6;
`;

export default PatientHeader;
