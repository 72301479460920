import React, {
  useCallback,
  useContext,
  useState,
  useEffect,
  useRef,
} from 'react';
import * as Realm from 'realm-web';
import {
  ApolloProvider,
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  Observable,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import Pusher from 'pusher-js';
import Loader from './components/common/Loader';
import { SystemContext } from './contexts/SystemContext';
import RootSwitch from './router/RootSwitch';
import { APP_ID, PUSHER } from '../constants';

const App = () => {
  const {
    state: { realm, realmUser, userId, twoFactor, pusher },
    dispatch: dispatchSystem,
  } = useContext(SystemContext);

  const isInitialMount = useRef(true);

  const [apolloClient, setApolloClient] = useState();

  // --- setup Pusher ---
  const initializePusher = useCallback(
    async user => {
      if (!user) {
        console.log('ERROR! No realmUser provided to initialize Pusher');
        return;
      }

      let authorizer = (channel, options) => {
        return {
          authorize: async (socketId, callback) => {
            try {
              const auth = await user.functions.pusherAuth(
                socketId,
                channel.name,
              );
              callback(null, auth);
            } catch (err) {
              console.log('Error calling auth endpoint: ', err);
              callback(new Error(`Error calling auth endpoint: ${err}`), {
                auth: '',
              });
            }
          },
        };
      };
      const pusher = new Pusher(PUSHER[process.env.REACT_APP_ENVIRONMENT].app, {
        cluster: PUSHER[process.env.REACT_APP_ENVIRONMENT].cluster,
        authorizer: authorizer,
        // encrypted: true
      });

      dispatchSystem({
        type: 'SET_SYSTEM',
        data: { pusher },
      });
    },
    [dispatchSystem],
  );

  // --- setup Realm ---
  const initializeRealm = useCallback(async () => {
    const app = new Realm.App({
      id: APP_ID[process.env.REACT_APP_ENVIRONMENT],
    });

    // -- SECURITY: Prohibit session starting on new browser tab --
    // when a new tab is opened, sessionStorage will be empty
    if (app.currentUser && !sessionStorage.getItem('sessionStarted')) {
      sessionStorage.setItem('sessionStarted', new Date());
      window.location.href = '/logout';
      return;
    }

    try {
      const twoFactorStatus = app.currentUser
        ? await app.currentUser.functions.get2faStatus()
        : false;

      dispatchSystem({
        type: 'SET_SYSTEM',
        data: {
          realm: app,
          realmUser: app?.currentUser,
          userId: app?.currentUser?.id,
          twoFactor: twoFactorStatus,
        },
      });
    } catch (err) {
      window.location.href = '/logout';
    }
  }, [dispatchSystem]);

  // --- setup GraphQL ---
  const initializeGraphQL = useCallback(async () => {
    const app = Realm.getApp(APP_ID[process.env.REACT_APP_ENVIRONMENT]);

    // setup pusher if user has just logged in
    if (app.currentUser && !pusher) initializePusher(app.currentUser);

    // if already setup or no user is logged in, get out of here
    if (apolloClient || !app.currentUser) return;

    try {
      await app.currentUser.refreshCustomData();
    } catch {
      // something went wonky with session, logout user
      window.location.href = '/logout';
    }

    // Authenticate graphQL
    const graphql_url = `https://us-east-1.aws.realm.mongodb.com/api/client/v2.0/app/${
      APP_ID[process.env.REACT_APP_ENVIRONMENT]
    }/graphql`;

    const authLink = setContext((_, { headers }) => {
      return {
        headers: {
          ...headers,
          authorization: `Bearer ${app.currentUser.accessToken}`,
        },
      };
    });

    const getNewToken = async () => {
      try {
        await app.currentUser.refreshCustomData();
        return app.currentUser.accessToken;
      } catch (e) {
        throw e;
      }
    };

    const errorLink = onError(({ networkError, operation, forward }) => {
      if (
        networkError &&
        networkError.name === 'ServerError' &&
        networkError.statusCode === 401
      ) {
        console.log('in 401 handler');
        const promiseToObservable = promise => {
          return new Observable(subscriber => {
            promise.then(
              token => {
                if (subscriber.closed) {
                  return;
                }
                subscriber.next(token);
                subscriber.complete();
              },
              err => {
                subscriber.error(err);
              },
            );
          });
        };

        return promiseToObservable(getNewToken()).flatMap(newToken => {
          operation.setContext({
            headers: {
              authorization: `Bearer ${newToken}`,
            },
          });

          return forward(operation);
        });
      }
    });

    const httpLink = new HttpLink({ uri: graphql_url });
    const client = new ApolloClient({
      link: ApolloLink.from([errorLink, authLink, httpLink]),
      cache: new InMemoryCache(),
    });
    setApolloClient(client);
  }, [apolloClient, pusher, initializePusher]);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      initializeRealm();
    }
  }, [initializeRealm]);

  // when a user is logged in, userId becomes populated -- setup graphQL
  useEffect(() => {
    if (userId) {
      initializeGraphQL();
    }
  }, [userId, initializeGraphQL]);

  return realm ? (
    apolloClient ? (
      <ApolloProvider client={apolloClient}>
        <RootSwitch
          isAuthenticated={userId && twoFactor}
          role={userId ? realmUser?.customData?.role : null}
        />
      </ApolloProvider>
    ) : userId ? (
      // user is logged in -- hold off on showing a page yet
      <Loader dots />
    ) : (
      // user is not logged in -- okay to add RootSwitch because it'll go to /login
      <RootSwitch isAuthenticated={false} role={realmUser?.customData?.role} />
    )
  ) : null;
};

export default App;
