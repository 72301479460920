import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';
import ActivityIndicator from '../components/common/ActivityIndicator';
import AdminHeader from '../components/admin/AdminHeader';
import AddButton from '../components/common/AddButton';
import NewPatientModal from '../components/modals/NewPatientModal';
import Table from '../components/tables/Table';
import { GET_PATIENTS } from '../api/patients';
import { FormContext } from '../contexts/FormContext';
import { ModalContext } from '../contexts/ModalContext';

const TABLE = [
  {
    id: 'name.family',
    label: 'Last Name',
    flex: 1,
    sortable: true,
  },
  {
    id: 'name.given',
    label: 'First Name',
    flex: 1,
    sortable: true,
  },
  {
    id: 'onboarded',
    label: 'Status',
    flex: 1,
    formatFn: val => (val ? 'Active' : 'New'),
    sortable: true,
  },
  {
    id: 'mrn',
    label: 'Patient MRN',
    width: '200px',
    sortable: true,
  },
];

const PatientsListPage = () => {
  const { dispatch: dispatchModal } = useContext(ModalContext);
  const { dispatch: dispatchForm } = useContext(FormContext);

  const history = useHistory();

  const { data, loading, error, refetch } = useQuery(GET_PATIENTS);

  const addPatient = patient => {
    dispatchModal({
      type: 'ADD_MODAL',
      data: {
        component: NewPatientModal,
        props: {
          patient,
        },
        onDismiss: closeModal,
      },
    });
  };

  const closeModal = () => {
    dispatchForm({ type: 'CLEAR' });
    refetch();
  };

  const viewPatient = patient => {
    if (patient.onboarded) history.push(`/patient/${patient.userId}`);
    else addPatient(patient);
  };

  return (
    <Container>
      <Main>
        <Header>
          <AdminHeader title="Patients" />
        </Header>
        <Body>
          <Content>
            {loading ? (
              <CenterWrapper>
                <ActivityIndicator />
              </CenterWrapper>
            ) : data && data.patients ? (
              <Table
                data={data.patients}
                config={TABLE}
                onClick={viewPatient}
                emptyNotice="No Patients Found"
                ehr
              />
            ) : (
              <CenterWrapper>
                <Error>
                  {error
                    ? error.message
                    : data && data.errors
                    ? data.errors.message
                    : 'An error occurred retrieving data'}
                </Error>
              </CenterWrapper>
            )}
          </Content>
        </Body>
      </Main>
      <AddButton onClick={() => addPatient()} />
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.backgroundColors.lightAlt};
`;

const Main = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Header = styled.div`
  display: block;
  padding: 30px 40px 20px;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: scroll;
`;

const Content = styled.div`
  padding: 20px 40px 0px;
  height: 100%;
`;

const CenterWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const Error = styled.p`
  text-align: center;
  font-size: 15px;
  color: ${props => props.theme.textColors.secondary};
`;

export default PatientsListPage;
