import React, { useEffect, useRef, useContext, useCallback } from 'react';
import styled from 'styled-components';
import { createLocalTracks } from 'twilio-video';
import { BsPersonSquare } from 'react-icons/bs';
import { MediaContext } from '../../contexts/MediaContext';
import { SystemContext } from '../../contexts/SystemContext';
import UserIcon from '../common/UserIcon';

const LocalVideoParticipant = ({ room, videoEnabled }) => {
  const isMounted = useRef(true);

  const videoRef = useRef();

  const {
    state: { audioTrack, videoTrack },
    dispatch: dispatchMedia,
  } = useContext(MediaContext);
  const {
    state: { realmUser },
  } = useContext(SystemContext);

  useEffect(() => {
    const stopVideo = () => {
      try {
        if (videoTrack) {
          videoTrack.disable();
          videoTrack.detach().forEach(el => el.remove());

          if (room) room.localParticipant.unpublishTrack(videoTrack);
        }
      } catch (err) {
        console.log('LocalVideoParticipant.stopVideo error: ', err);
      }
    };

    const attachVideo = track => {
      track.enable();
      if (videoRef.current) videoRef.current.appendChild(track.attach());

      if (room) room.localParticipant.publishTrack(track);
    };

    if (videoEnabled) {
      // if localTracks are already created, attach existing (singleton)
      if (videoTrack) {
        attachVideo(videoTrack);
        return;
      }

      if (isMounted.current) {
        createLocalTracks().then(tracks => {
          if (!isMounted.current) {
            tracks.forEach(track => track.stop());
            return null;
          }

          const _video = tracks[0].kind === 'video' ? tracks[0] : tracks[1];
          const _audio = tracks[0].kind === 'audio' ? tracks[0] : tracks[1];

          dispatchMedia({
            type: 'SET_MEDIA',
            data: {
              videoTrack: _video,
              audioTrack: _audio,
            },
          });

          attachVideo(_video);

          return null;
        });
      }
    } else if (videoTrack) {
      stopVideo();
    }

    window.addEventListener('beforeunload', stopVideo);
    window.addEventListener('popstate', stopVideo);

    return () => {
      window.removeEventListener('beforeunload', stopVideo);
      window.removeEventListener('popstate', stopVideo);
    };
  }, [videoTrack, videoEnabled, room]);

  // When removed from view, KILL all webcam and microphone feeds
  useEffect(() => {
    return () => {
      isMounted.current = false;
      try {
        if (audioTrack) audioTrack.stop();

        if (videoTrack) {
          videoTrack.stop();
          videoTrack.detach().forEach(el => el.remove());
          dispatchMedia({ type: 'CLEAR' });
        }
      } catch (err) {
        console.log('Error unmounting LocalVideoParticipant', err);
      }
    };
  }, [audioTrack, videoTrack]);

  return (
    <Participant>
      <VideoWrapper ref={videoRef} />
      {!videoEnabled ? (
        <PhotoWrapper>
          <UserIcon user={realmUser?.customData} size="90px" />
        </PhotoWrapper>
      ) : null}
    </Participant>
  );
};

const Participant = styled.div`
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  height: 100%;
`;

const PhotoWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
`;

const VideoWrapper = styled.div`
  width: 100%;
  height: 100%;
  transform: scaleX(-1);

  > video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export default LocalVideoParticipant;
