import React, { useEffect, useContext, useState } from 'react';
import styled from 'styled-components';
import { VitalsContext } from '../../contexts/VitalsContext';
import ChecklistInput from './ChecklistInput';

const getValue = (id, vitals) => {
  let val;

  try {
    // blood pressure
    if (id === 'bp') {
      val = vitals.bp.data.value;
      return `${val.sys} / ${val.dia}`;
    }

    // pulse or blood oxygen
    if (['pulse', 'O2'].includes(id)) {
      val = vitals.pulseox.data.value;
      return id === 'pulse' ? val.pulse : val.spo2;
    }

    // temperature
    if (id === 'temperature') {
      val = vitals.temperature.data.value;
      return `${val.temp}˚`;
    }
  } catch (err) {
    console.log('No vital value provided for ', id);
  }

  return '';
};

const VitalReviewInput = ({
  id,
  sctid,
  value,
  label,
  config,
  onChange,
  disabled = false,
}) => {
  const { state: vitals } = useContext(VitalsContext);
  const [empty, setEmpty] = useState(true);

  useEffect(() => {
    if (vitals) {
      try {
        const vitalId = ['pulse', 'O2'].includes(id) ? 'pulseox' : id;
        const hasData =
          vitals[vitalId] && vitals[vitalId].data && vitals[vitalId].data.value;
        if (hasData) setEmpty(false);
      } catch (err) {
        // error likely thrown for having no value
      }
    }
  }, [id, vitals]);

  return empty ? (
    <EmptyNotice>
      <p>
        <strong>{label}</strong>
      </p>
      <p>Vital not collected</p>
    </EmptyNotice>
  ) : (
    <Row>
      <Input>
        <ChecklistInput
          sctid={sctid}
          label={label}
          value={value}
          locationOptions={config.locations}
          options={config.options}
          radio={config.radio}
          onChange={onChange}
          disabled={disabled}
        />
      </Input>
      <Box>{getValue(id, vitals)}</Box>
    </Row>
  );
};

const EmptyNotice = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;

  p {
    text-align: center;
    font-size: 12px;
    color: ${props => props.theme.textColors.secondary};
    margin: 0;

    &:first-child {
      margin-bottom: 4px;
    }
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  height: 100%;
`;

const Input = styled.div`
  flex: 1;
`;

const Box = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 70px;
  height: 70px;
  margin-left: 20px;
  padding: 10px;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.02);
  border: 1px solid ${props => props.theme.colors.border};

  font-size: 18px;
  font-weight: ${props => props.theme.fontWeights.bold};
  color: ${props => props.theme.textColors.primary};
`;

export default VitalReviewInput;
