import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { lighten } from 'polished';
import Field from '../forms/Field';
import ActivityIndicator from '../common/ActivityIndicator';
import ChartingOnboarding from '../charting/onboarding/ChartingOnboarding';
import { SystemContext } from '../../contexts/SystemContext';
import PatientHeaderOnboarding from '../patient/PatientHeaderOnboarding';

const NewPatientModal = ({ patient, onDismiss }) => {
  const {
    state: { realmUser },
  } = useContext(SystemContext);

  const [formData, setFormData] = useState({
    firstName: null,
    lastName: null,
    ssn: null,
    birthDate: null,
    gender: null,
  });
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  const [validation, setValidation] = useState({
    firstName: false,
    lastName: false,
    ssn: false,
    gender: false,
    birthDate: false,
  });
  const [selectedPatient, setSelectedPatient] = useState(patient);

  const onValueChanged = (field, value, isValid) => {
    console.log(field, value);
    setValidation({ ...validation, [field]: isValid });
    setFormData({ ...formData, [field]: value });
  };

  const onSavePatient = async () => {
    if (
      !formData.firstName ||
      !formData.lastName ||
      !formData.ssn ||
      !formData.birthDate ||
      !formData.gender
    ) {
      setError('Form is incomplete');
      return;
    }
    if (!validation.birthDate) {
      setError('Invalid Date of Birth');
      return;
    }
    if (!validation.ssn) {
      setError('Invalid Social Security Number');
      return;
    }

    setError(null);
    setLoading(true);

    const _patient = await realmUser.functions.addPatient(
      formData.firstName,
      formData.lastName,
      formData.ssn,
      formData.birthDate,
      formData.gender,
    );
    setSelectedPatient(_patient);
    setLoading(false);
  };

  return (
    <Container>
      {selectedPatient ? (
        <>
          <PatientHeaderOnboarding patient={selectedPatient} />
          <ChartingOnboarding
            userId={selectedPatient.userId}
            inModal
            hideVitals
          />
        </>
      ) : (
        <Intro error={error}>
          {loading ? (
            <LoadingWrapper>
              <ActivityIndicator />
            </LoadingWrapper>
          ) : (
            <>
              <Title>Create New Patient</Title>
              <Form>
                <Row>
                  <Field
                    id="firstName"
                    label="First Name"
                    type="text-input"
                    config={{ center: true }}
                    value={formData.firstName}
                    onChange={onValueChanged}
                  />
                  <Field
                    id="lastName"
                    label="Last Name"
                    type="text-input"
                    config={{ center: true }}
                    value={formData.lastName}
                    onChange={onValueChanged}
                  />
                  <Field
                    id="ssn"
                    label="Social Security Number"
                    type="social-security"
                    config={{ center: true }}
                    value={formData.ssn}
                    onChange={onValueChanged}
                  />
                </Row>
                <Row>
                  <Field
                    id="gender"
                    label="Gender"
                    type="radio"
                    value={formData.gender}
                    config={{
                      center: true,
                      connected: true,
                      fullWidth: true,
                      options: [
                        { id: 'male', label: 'Male' },
                        { id: 'female', label: 'Female' },
                      ],
                    }}
                    onChange={onValueChanged}
                  />
                  <Field
                    id="birthDate"
                    label="Date of Birth"
                    type="dateOfBirth"
                    config={{ center: true }}
                    value={formData.dob}
                    onChange={onValueChanged}
                  />
                </Row>
                <SaveBtn onClick={onSavePatient}>Add New Patient</SaveBtn>
              </Form>
            </>
          )}

          {error ? <Error>{error}</Error> : null}
        </Intro>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  background-color: ${props => props.theme.backgroundColors.tertiary};
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const Intro = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: ${props => (props.error ? '80px 40px 40px' : '40px')};
  min-height: 400px;
`;

const Form = styled.div``;

const Title = styled.h2`
  font-size: 22px;
  margin: 5px 0 40px;
  color: ${props => props.theme.textColors.primary};
`;

const SaveBtn = styled.button`
  display: inline-block;
  border: none;
  outline: none;
  color: white;
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 24px;
  height: 48px;
  padding: 0 30px;
  background-color: ${props => props.theme.colors.blue};
  margin-top: 30px;
  cursor: pointer;

  &:hover {
    background-color: ${props => lighten(0.03, props.theme.colors.blue)};
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const Error = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: ${props => props.theme.colors.primary};
  color: white;
  font-size: 14px;
  font-weight: ${props => props.theme.fontWeights.bold};
`;

const LoadingWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default NewPatientModal;
