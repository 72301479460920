import React, { useState } from 'react';
import styled from 'styled-components';
import AdminHeader from '../components/admin/AdminHeader';
import AddButton from '../components/common/AddButton';

const ReportsPage = () => (
  <Container>
    <Main>
      <AdminHeader title="Reports" />
      <Content>
        <Body></Body>
      </Content>
    </Main>
    <AddButton />
  </Container>
);

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.backgroundColors.lightAlt};
`;

const Main = styled.div`
  position: relative;
  display: block;
  flex: 1;
  padding: 30px 40px;
  overflow-y: scroll;
`;

const Content = styled.div`
  padding-top: 30px;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 0 20px;
  height: 100%;
`;

export default ReportsPage;
