import React from 'react';
import styled from 'styled-components';

const BAR_SIZE = {
  width: 4,
  height: 12,
};

const SeverityMeter = ({ level }) => {
  const COUNT = level === 'severe' ? 6 : level === 'moderate' ? 4 : 2;

  return (
    <Wrapper>
      <Track>
        {[...Array(3)].map((item, index) => (
          <Bar key={`track-${index}`} track />
        ))}
      </Track>
      <Fill>
        {[...Array(COUNT / 2)].map((item, index) => (
          <Bar key={`fill-${index}`} level={level} />
        ))}
      </Fill>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
`;

const Track = styled.div`
  display: flex;
  flex-direction: row;
`;

const Fill = styled(Track)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
`;

const Bar = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: ${`${BAR_SIZE.width * 3}px`};
  height: ${`${BAR_SIZE.height}px`};
  margin-right: ${`${BAR_SIZE.width}px`};

  &::after,
  &::before {
    content: '';
    display: block;
    background-color: ${props =>
      props.track
        ? props.theme.colors.border
        : props.level === 'mild'
        ? props.theme.colors.green
        : props.level === 'moderate'
        ? props.theme.colors.tertiary
        : props.theme.colors.primary};
    width: ${`${BAR_SIZE.width}px`};
    height: ${`${BAR_SIZE.height}px`};
  }

  &::after {
    margin-left: auto;
  }
`;

export default SeverityMeter;
