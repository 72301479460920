import React, { useEffect, useState, useContext } from 'react';
import styled, { keyframes } from 'styled-components';
import { darken, lighten } from 'polished';
import { useApolloClient, useQuery } from '@apollo/client';
import { format, isToday, parseISO } from 'date-fns';
import { FaPlus, FaTimes } from 'react-icons/fa';
import ChartingPhysicianCareplan from './ChartingPhysicianCareplan';
import ReviewOfSystems from './ReviewOfSystems';
import Field from '../../forms/Field';
import {
  GET_ENCOUNTER_NOTES,
  UPDATE_ENCOUNTER_NOTES,
} from '../../../api/encounters';
import { GET_EXAMS } from '../../../api/exams';
import { GET_SYMPTOMS } from '../../../api/symptoms';
import ActivityIndicator from '../../common/ActivityIndicator';
import { ExamsContext } from '../../../contexts/ExamsContext';
import { ModalContext } from '../../../contexts/ModalContext';
import { VitalsContext } from '../../../contexts/VitalsContext';
import { SYSTEMS } from '../../../../forms/exams';
import {
  formatInitialData,
  decodeReviewOfSystem,
} from '../../../hooks/useFormHelper';
import { findObjectByProperty } from '../../../hooks/useTextHelper';
import SymptomModal from '../../modals/SymptomModal';
import ConstitutionalModal from '../../modals/ConstitutionalModal';
import ConstitutionalSummary from './widgets/ConstitutionalSummary';

const formatDate = date => {
  const today = isToday(date);

  return today
    ? `Today @ ${format(date, 'ha')}`
    : format(date, 'eee, MMM do @ ha');
};

const ChartingPhysicianEncounter = ({
  patient,
  encounter,
  show,
  onTogglePhysicianButtons,
  onClose,
}) => {
  const client = useApolloClient();

  const { state: exams, dispatch: dispatchExams } = useContext(ExamsContext);
  const { dispatch: dispatchModal } = useContext(ModalContext);
  const { state: vitals } = useContext(VitalsContext);

  const [symptoms, setSymptoms] = useState([]);
  const [notes, setNotes] = useState('');
  const [reason, setReason] = useState('');

  const [system, setSystem] = useState(null);
  const [saving, setSaving] = useState(false);

  const {
    loading,
    data: symptomsData,
    error: symptomsError,
    refetch: refetchSymptoms,
  } = useQuery(GET_SYMPTOMS, {
    variables: { encounter },
  });

  const {
    data: examsData,
    error: examsError,
    refetch: refetchExams,
  } = useQuery(GET_EXAMS, {
    variables: { encounter },
  });

  const { data: notesData, error: notesError } = useQuery(GET_ENCOUNTER_NOTES, {
    variables: { id: encounter },
  });

  const openObservations = () => {
    dispatchModal({
      type: 'ADD_MODAL',
      data: {
        component: ConstitutionalModal,
        props: {
          encounter,
          patient,
          data: exams.constitutional,
        },
        onDismiss: refetchExams,
      },
    });
  };

  const openSymptomModal = item => {
    // open symptom modal
    dispatchModal({
      type: 'ADD_MODAL',
      data: {
        component: SymptomModal,
        props: {
          mode: item ? 'edit' : 'new',
          encounter,
          patient: patient.userId,
          data: item,
          maxWidth: '640px',
        },
        onDismiss: refetchSymptoms,
      },
    });
  };

  const saveNotes = async (id, value) => {
    setSaving(true);

    const obj = {
      notes,
      reason,
    };

    obj[id] = value;

    await client.mutate({
      mutation: UPDATE_ENCOUNTER_NOTES,
      variables: {
        id: encounter,
        ...obj,
      },
    });

    setSaving(false);
  };

  useEffect(() => {
    if (examsError) console.error(examsError);

    if (examsData) {
      // clear __type from realm data and store
      const formatted = formatInitialData(examsData);

      const formData = {};
      for (let i = 0; i < formatted.exams.length; i++) {
        const exam = formatted.exams[i];
        const systemObj = exam.system;
        const key = findObjectByProperty(
          SYSTEMS,
          'sctid',
          systemObj.sctid,
          true,
        );
        formData[key] = decodeReviewOfSystem(exam);
      }

      dispatchExams({ type: 'SET_EXAMS', data: formData });
    }
  }, [examsData, examsError]);

  useEffect(() => {
    if (notesError) console.error(notesError);
    if (notesData) {
      setNotes(notesData.encounter?.notes);
      setReason(notesData.encounter?.reason);
    }
  }, [notesData, notesError]);

  useEffect(() => {
    if (symptomsError) console.error(symptomsError);

    if (symptomsData) {
      setSymptoms([...symptomsData.symptoms]);
    }
  }, [symptomsData, symptomsError]);

  return (
    <Container visible={show}>
      <Header>
        <PageTitle>Physician Assessment</PageTitle>
        <SavingNotice>
          {saving ? 'Saving' : 'Information will autosave.'}
          {saving ? <ActivityIndicator size={20} smooth /> : null}
        </SavingNotice>
        <CloseBtn onClick={onClose}>
          <FaTimes />
        </CloseBtn>
      </Header>
      {show && !loading ? (
        <Wrapper>
          <Encounter>
            <Section>
              <Title>Reason for Visit</Title>
              <Field
                id="reason"
                type="textarea"
                value={reason}
                onChange={(id, val) => setReason(val)}
                onBlur={(id, val) => saveNotes(id, val)}
                config={{
                  width: '640px',
                  rows: 3,
                }}
              />
            </Section>

            <Section>
              <Title>Observations</Title>
              {exams.constitutional ? (
                <ConstitutionalSummary
                  vitals={vitals}
                  data={exams.constitutional}
                  onEdit={openObservations}
                />
              ) : (
                <SectionWrapper>
                  <AddBtn full onClick={openObservations}>
                    <AddBtnMessage>
                      Review Patient's general appearance and vitals.
                    </AddBtnMessage>
                    <AddBtnCTA>Begin Constitutional Review</AddBtnCTA>
                  </AddBtn>
                </SectionWrapper>
              )}
            </Section>

            <Section>
              <Title>Symptoms</Title>
              <SectionWrapper>
                {symptoms.map(item => (
                  <BlockBtn
                    key={item._id}
                    symptom
                    onClick={() => openSymptomModal(item)}>
                    <Severity>{item.severity}</Severity>
                    <Symptom>{item.symptom.display}</Symptom>
                    <Location>{item.location.display}</Location>
                    <Date>{formatDate(parseISO(item.onset))}</Date>
                  </BlockBtn>
                ))}
                <AddBtn
                  symptom
                  full={symptoms.length === 0}
                  onClick={() => openSymptomModal()}>
                  <FaPlus />
                  New Symptom
                </AddBtn>
              </SectionWrapper>
            </Section>
            <Section>
              <Title>Exams</Title>

              <SectionWrapper>
                {Object.keys(exams).map(exam =>
                  exam !== 'constitutional' ? (
                    <ExamBtn key={exam} onClick={() => setSystem(exam)} exam>
                      <ExamType>{SYSTEMS[exam].label}</ExamType>
                    </ExamBtn>
                  ) : null,
                )}
                <AddBtn
                  exam
                  full={Object.keys(exams).length === 0}
                  onClick={() => setSystem('new')}>
                  <FaPlus />
                  {Object.keys(exams).length === 0 ? 'Add New Exam' : ''}
                </AddBtn>
              </SectionWrapper>
            </Section>
            <Section>
              <Title>Notes</Title>
              <Field
                id="notes"
                type="textarea"
                value={notes}
                onChange={(id, val) => setNotes(val)}
                onBlur={(id, val) => saveNotes(id, val)}
                config={{
                  width: '640px',
                  rows: 5,
                }}
              />
            </Section>
          </Encounter>

          <ChartingPhysicianCareplan
            patient={patient}
            encounter={encounter}
            onTogglePhysicianButtons={onTogglePhysicianButtons}
          />
        </Wrapper>
      ) : null}

      {system ? (
        <ReviewOfSystems
          patient={patient}
          encounter={encounter}
          system={system}
          onRefresh={refetchExams}
          onClose={() => setSystem(null)}
        />
      ) : null}
    </Container>
  );
};

const animIn = keyframes`
  0% { 
    transform: translateY(400px);
    opacity: 0;
  }
  100% { 
    transform: translateY(0);
    opacity: 1;
  }
`;

const animOut = keyframes`
  0% { 
    transform: translateY(0);
    opacity: 1;
  }
  100% { 
    transform: translateY(400px);
    opacity: 0;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 20px 20px 40px;
  border-bottom: 1px solid ${props => props.theme.colors.border};
`;

const PageTitle = styled.h1`
  margin: 0;
  flex: 1;
  line-height: 1;
`;

const SavingNotice = styled.div`
  font-size: 12px;
  color: ${props => props.theme.textColors.tertiary};
  margin-right: 20px;
  text-align: right;
  display: flex;
  align-items: center;

  > div {
    margin-left: 6px;
  }
`;

const CloseBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  box-shadow: none;
  outline: none;
  border: 0;
  cursor: pointer;

  svg {
    font-size: 22px;
    color: ${props => props.theme.textColors.secondary};
  }

  &:hover {
    background-color: ${props => props.theme.textColors.secondary};

    svg {
      color: white;
    }
  }
`;

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
  height: 100%;
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: white;

  opacity: ${props => (props.visible ? 0 : 1)};
  pointer-events: ${props => (props.visible ? 'auto' : 'none')};

  animation-duration: 0.3s;
  animation-timing-function: ease-out;
  animation-delay: 0.2s;
  animation-fill-mode: forwards;
  animation-name: ${props => (props.visible ? animIn : animOut)};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  overflow: hidden;
`;

const Section = styled.div`
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }

  fieldset {
    padding: 0;

    textarea {
      font-size: 15px;
    }
  }
`;

const Title = styled.h3`
  margin: 0 0 15px;
  font-size: 18px;
`;

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const BlockBtn = styled.button`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border-radius: 10px;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: ${props =>
    lighten(0.05, props.theme.backgroundColors.secondary)};
  padding: 15px 10px 40px;
  overflow: hidden;
  margin: 0 8px 8px 0;

  &:hover {
    background-color: ${props =>
      lighten(0.01, props.theme.backgroundColors.secondary)};
  }
`;

const ExamBtn = styled.button`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border-radius: 10px;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: ${props =>
    lighten(0.05, props.theme.backgroundColors.secondary)};
  padding: 12px 20px;
  overflow: hidden;
  margin: 0 8px 8px 0;
  /* min-width: 130px; */

  &:hover {
    background-color: ${props =>
      lighten(0.01, props.theme.backgroundColors.secondary)};
  }
`;

const ExamType = styled.p`
  color: ${props => props.theme.textColors.primary};
  font-size: 13px;
  font-weight: ${props => props.theme.fontWeights.bold};
`;

const Severity = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.05);
  font-family: ${props => props.theme.fonts.primary};
  font-size: 16px;
  font-weight: ${props => props.theme.fontWeights.bold};
  color: ${props => props.theme.textColors.primary};
  margin-bottom: 9px;
`;

const Symptom = styled.p`
  font-weight: ${props => props.theme.fontWeights.bold};
  font-size: 13px;
  color: ${props => props.theme.textColors.primary};
  margin-top: 0;
  margin-bottom: 5px;
`;

const Location = styled.p`
  color: ${props => props.theme.textColors.secondary};
  font-weight: ${props => props.theme.fontWeights.regular};
  font-size: 11px;
  margin: 0;
`;

const Date = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 8px 0;
  background-color: rgba(0, 0, 0, 0.02);
  text-align: center;
  font-size: 10px;
  color: ${props => props.theme.textColors.tertiary};
`;

const AddBtnCTA = styled.span`
  border-radius: 20px;
  border: 2px solid rgba(0, 0, 0, 0.2);
  font-size: 11px;
  text-transform: uppercase;
  padding: 8px 16px;
  margin-top: 20px;
`;

const AddBtn = styled.button`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 10px;
  background-color: ${props => props.theme.backgroundColors.tertiary};
  overflow: hidden;
  font-family: ${props => props.theme.fonts.secondary};
  font-weight: ${props => props.theme.fontWeights.medium};
  font-size: 11px;
  text-transform: uppercase;
  border: none;
  outline: none;
  cursor: pointer;
  color: ${props => props.theme.textColors.secondary};
  margin-bottom: 8px;
  width: ${props => (props.full ? '406px' : props.exam ? 'auto' : '130px')};
  min-height: ${props => (props.exam ? '65px' : '150px')};
  padding: ${props => (props.exam ? '20px' : '0 10px')};

  svg {
    display: block;
    font-size: ${props => (props.exam && !props.full ? '24px' : '36px')};
    margin-bottom: ${props => (props.exam && !props.full ? '0' : '15px')};
  }

  &:hover {
    background-color: ${props =>
      darken(0.02, props.theme.backgroundColors.tertiary)};
    color: ${props => props.theme.textColors.primary};

    ${AddBtnCTA} {
      background-color: ${props => props.theme.textColors.secondary};
      border-color: ${props => props.theme.textColors.secondary};
      color: white;
    }
  }
`;

const AddBtnMessage = styled.span`
  font-size: 14px;
  font-weight: ${props => props.theme.fontWeights.regular};
  text-align: center;
  text-transform: none;
`;

const Encounter = styled.div`
  background-color: ${props => props.theme.backgroundColors.primary};
  height: 100%;
  flex: 1;
  padding: 30px 40px;
  overflow-y: scroll;

  ${BlockBtn} {
    width: 130px;
    padding: 15px 10px 40px;
  }
`;

export default ChartingPhysicianEncounter;
