import React, { useEffect, useState, useRef, useContext } from 'react';
import styled from 'styled-components';
import { FaCheck, FaPaperPlane, FaPhone, FaTimes } from 'react-icons/fa';
import { format, parseISO } from 'date-fns';
import { useQuery } from '@apollo/client';
import parse from 'html-react-parser';
import ActivityIndicator from '../common/ActivityIndicator';
import { capitalize, formatName } from '../../hooks/useTextHelper';
import { GET_CALL_NOTIFICATIONS } from '../../api/rooms';

const formatMessage = obj => {
  const { primary, secondary, pool, status, role, user } = obj;

  if (status === 'joined')
    return `<b>${formatName(user)}</b> joined the call (${role})`;

  const name = pool ? `${capitalize(role)} Pool` : formatName(user);

  const action = `Text ${status}`;
  const preposition = status === 'sent' ? 'to' : 'by';
  const priorityLabel = pool
    ? ''
    : primary
    ? '(primary)'
    : secondary
    ? '(secondary)'
    : '';

  // ex.  Text sent to Careline Pool ... Text accepted by Bob Johnson (primary)
  return `${action} ${preposition} <b>${name}</b> ${priorityLabel}`;
};

const EncounterTextLogPopover = ({ encounter }) => {
  const { loading, error, data } = useQuery(GET_CALL_NOTIFICATIONS, {
    variables: { encounter },
  });

  const [textLog, setTextLog] = useState();

  useEffect(() => {
    const formatData = () => {
      const callData = data?.calls[0];
      const careline = callData.carelineJoined
        ? {
            date: callData.carelineJoined,
            user: callData.careline,
            status: 'joined',
            role: 'Care Line',
          }
        : null;

      const physician = callData.physicianJoined
        ? {
            date: callData.physicianJoined,
            user: callData.physician,
            status: 'joined',
            role: 'Physician',
          }
        : null;

      const notifications = callData.notifications || [];

      // detect any responseDates and duplciate them so there's a 'sent' and 'accepted'
      let duplicates = [];
      notifications.map(item => {
        if (item.responseDate) {
          const newItem = { ...item };
          newItem.status = 'sent';
          delete newItem['responseDate'];
          duplicates.push(newItem);
        }
      });

      let arr = [];
      if (careline) arr.push(careline);
      if (physician) arr.push(physician);
      arr = [...arr, ...notifications, ...duplicates];
      arr.sort(
        (a, b) =>
          new Date(a.responseDate || a.date) -
          new Date(b.responseDate || b.date),
      );
      setTextLog(arr);
    };

    if (data) formatData();
    if (error) console.log('Call Notification Error: ', error);
  }, [error, data]);

  return (
    <Container>
      {loading ? (
        <ActivityIndicator color="secondary" />
      ) : textLog ? (
        <Wrapper>
          <CallDate>{format(new Date(), 'MMMM d, yyyy')}</CallDate>
          {textLog.map(item => (
            <Item key={`${item.status}-${item.date}`}>
              <ItemDate>
                {format(
                  parseISO(item.responseDate || item.date),
                  'h:mm:ss aaa',
                )}
              </ItemDate>
              <ItemIcon>
                <IconOrb status={item.status}>
                  {item.status === 'accepted' ? (
                    <FaCheck />
                  ) : item.status === 'sent' ? (
                    <FaPaperPlane />
                  ) : item.status === 'declined' ? (
                    <FaTimes />
                  ) : item.status === 'joined' ? (
                    <FaPhone />
                  ) : null}
                </IconOrb>
              </ItemIcon>
              <ItemTitle>{parse(formatMessage(item))}</ItemTitle>
            </Item>
          ))}
        </Wrapper>
      ) : (
        <Error>No content available.</Error>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 8px;

  width: 420px;
  height: 220px;
  padding: 4px 0;
  overflow: hidden;
  border: 1px solid ${props => props.theme.colors.border};
  box-shadow: 0 5px 20px 0 rgb(21 27 38 / 8%);
  overflow-y: scroll;
`;

const Wrapper = styled.div`
  flex: 1;
  align-self: flex-start;
  padding: 12px 16px;
`;

const CallDate = styled.p`
  font-weight: bold;
  font-size: 14px;
  margin: 0 0 16px;
  text-align: center;
  border-bottom: 1px solid ${props => props.theme.colors.border};
  padding-bottom: 10px;
`;

const ItemDate = styled.p`
  min-width: 70px;
  font-size: 12px;
  color: ${props => props.theme.textColors.secondary};
  margin: 0;
  padding-top: 5px;
`;

const ItemIcon = styled.div`
  position: relative;
  margin: 0 10px;

  &::before {
    position: absolute;
    left: 50%;
    margin-left: -1.5px;
    width: 3px;
    height: 100%;
    background-color: ${props => props.theme.backgroundColors.tertiary};
    content: '';
  }
`;

const IconOrb = styled.div`
  position: relative;
  z-index: 1;
  background-color: ${props =>
    props.status === 'accepted'
      ? props.theme.colors.green
      : props.status === 'denied'
      ? props.theme.colors.warning
      : props.status === 'sent'
      ? props.theme.colors.blue
      : props.status === 'joined'
      ? props.theme.colors.tertiary
      : props.theme.colors.gray};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;

  svg {
    font-size: 11px;
    color: white;
    margin-left: ${props => (props.status === 'sent' ? '-1px' : '0')};
  }
`;

const ItemTitle = styled.p`
  font-size: 13px;
  margin: 0;
  line-height: 1.4;
  padding: 3px 0 16px;
`;

const Item = styled.div`
  display: flex;
  flex-direction: row;

  &:last-of-type {
    ${ItemIcon} {
      &::before {
        display: none;
        background-color: transparent;
      }
    }
  }
`;

const Error = styled.p`
  color: ${props => props.theme.textColors.secondary};
  font-size: 13px;
  line-height: 1.4;
  text-align: center;
  margin: 0;
`;

export default EncounterTextLogPopover;
