import React, { useState, useContext, useEffect, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import { FaUser, FaCamera, FaLongArrowAltDown } from 'react-icons/fa';
import { FiAlertOctagon } from 'react-icons/fi';
import { RiTimerFlashLine } from 'react-icons/ri';
import { useApolloClient } from '@apollo/client';
import CropPhoto from './CropPhoto';
import { CallContext } from '../../contexts/CallContext';
import { SystemContext } from '../../contexts/SystemContext';
import ActivityIndicator from '../common/ActivityIndicator';
import { UPDATE_PHOTO } from '../../api/personal';

const MSG = {
  ready: {
    title: 'Capture',
    description:
      "Have the patient look into the camera, then click the button below to capture the patient's photo.",
    icon: <FaUser />,
  },
  capturing: {
    title: 'Capturing Photo',
    description:
      "Please wait while the photo is captured from the patient's device. The patient will see a 5-second countdown before the photo is taken.",
    icon: <RiTimerFlashLine />,
  },
  error: {
    title: 'Server Error',
    description:
      "There was an error capturing the patient's photo, try again using the button below. If the problem persits, we can collect this later.",
    icon: <FiAlertOctagon />,
  },
};

const InstructionItem = ({ step, title, description, icon }) => (
  <Instruction>
    <InstructionContent>
      <CaptureIcon step={step}>{icon}</CaptureIcon>
      <InstructionsTitle>{title}</InstructionsTitle>
      <InstructionsDescription>{description}</InstructionsDescription>
      {step === 'ready' ? (
        <DownArrow>
          <FaLongArrowAltDown />
        </DownArrow>
      ) : null}
    </InstructionContent>
  </Instruction>
);

const PhotoCapture = ({ room, onClose }) => {
  const {
    state: { channel, patient },
    dispatch: dispatchCall,
  } = useContext(CallContext);
  const {
    state: { realmUser },
  } = useContext(SystemContext);

  const client = useApolloClient();
  const blobRef = useRef();

  const [step, setStep] = useState('ready');
  const [photo, setPhoto] = useState();
  const [croppedImg, setCroppedImg] = useState();
  const [saving, setSaving] = useState();

  const startOver = () => {
    setPhoto(null);
    setStep('ready');
  };

  const takePhoto = async () => {
    setStep('capturing');
    await realmUser.functions.sendPusherEvent(`presence-${room}`, 'take-photo');
  };

  const convertImgToBlob = (url, filename) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload = () => {
      canvas.width = img.naturalWidth;
      canvas.height = img.naturalHeight;
      ctx.drawImage(img, 0, 0, img.naturalWidth, img.naturalHeight);

      canvas.toBlob(blob => {
        // successfully converted to blob, send to CropPhoto
        window.URL.revokeObjectURL(blobRef.current);
        blobRef.current = window.URL.createObjectURL(blob);
        setPhoto({ url: blobRef.current, filename });
      }, 'image/jpeg');
    };
    img.src = url;
  };

  const onPhotoComplete = async ({ status, filename, error }) => {
    if (status === 200) {
      // retrieve captured photo from the server and decode it
      const { url } = await realmUser.functions.getPatientPhotoUrl(filename);

      // convert image to canvas to blob
      convertImgToBlob(url, filename);
    } else {
      // there was an error uploading the photo prompt user
      console.log('onPhotoComplete error:  ', error);
      setStep('error');
    }
  };

  const savePhoto = async () => {
    setSaving(true);

    // get upload url
    const {
      authorizationToken,
      uploadUrl,
    } = await realmUser.callFunction('getUploadUrl', [
      photo.filename,
      'image/jpeg',
    ]);

    // upload photo
    await fetch(uploadUrl, {
      method: 'POST',
      headers: {
        Authorization: authorizationToken,
        'Content-Type': 'image/jpeg',
        'X-Bz-File-Name': photo.filename,
        'X-Bz-Content-Sha1': 'do_not_verify',
      },
      body: croppedImg,
    });

    // update photo on patient record
    await client.mutate({
      mutation: UPDATE_PHOTO,
      variables: {
        id: patient.userId,
        photo: photo.filename,
      },
    });

    dispatchCall({
      type: 'SET_PATIENT',
      data: {
        photo: photo.filename,
      },
    });

    onClose();
  };

  useEffect(() => {
    channel.bind('photo-complete', onPhotoComplete);

    return () => {
      channel.unbind('photo-complete', onPhotoComplete);
    };
  }, [channel]);

  return (
    <Container>
      <Header>
        {photo ? (
          <>
            <Title>Crop</Title>
            <Subtitle>
              Use the tool below to center the square around the patient's head
            </Subtitle>
          </>
        ) : null}
        <CancelBtn outline onClick={onClose}>
          Cancel
        </CancelBtn>
      </Header>
      <Body>
        {photo ? (
          <CropPhoto
            photo={photo.url}
            filename={photo.filename}
            onCrop={cropped => setCroppedImg(cropped)}
            unit="%"
          />
        ) : (
          <>
            <InstructionsWrapper>
              <InstructionsSlider step={step}>
                <InstructionItem step="ready" {...MSG.ready} />
                <InstructionItem step={step} {...MSG[step]} />
              </InstructionsSlider>
            </InstructionsWrapper>
            <CaptureBtn capturing={step === 'capturing'} onClick={takePhoto}>
              <FaCamera />
            </CaptureBtn>
          </>
        )}
      </Body>
      <Footer>
        {photo ? (
          saving ? (
            <ActivityIndicator size={40} color="white" />
          ) : (
            <>
              <Button onClick={startOver}>Retake</Button>
              <Button primary onClick={savePhoto}>
                Save Photo
              </Button>
            </>
          )
        ) : null}
      </Footer>
    </Container>
  );
};

const pulse = keyframes`
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.7);
  }
  100% {
    transform: scale(2);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
`;

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 53;
  height: 100%;
  width: 100%;
  background-color: ${props => props.theme.backgroundColors.darker};
  display: flex;
  flex-direction: column;

  &::after {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: -20px;
    z-index: 2;
    width: 20px;
    height: 100%;
    background-color: ${props => props.theme.backgroundColors.dark};
    border-radius: 0 20px 20px 0;
  }

  &::before {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: -20px;
    z-index: 1;
    width: 20px;
    background-color: ${props => props.theme.backgroundColors.darker};
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 30px;
`;

const Title = styled.h1`
  margin: 0;
  color: white;
  padding-right: 20px;
`;

const Subtitle = styled.p`
  margin: 7px 0 0;
  color: white;
  font-size: 15px;
`;

const Body = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  max-width: 100%;
  max-height: 100%;
`;

const InstructionsWrapper = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  overflow: hidden;
`;

const InstructionContent = styled.div`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 500px;
  text-align: center;
`;

const InstructionsSlider = styled.div`
  transform: ${props =>
    props.step === 'ready' ? 'translateX(0)' : 'translateX(-50%)'};
  transition: transform 0.5s ease-out;
  display: flex;
  flex-wrap: nowrap;
  width: 200%;
`;

const Instruction = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 0 30px;
`;

const DownArrow = styled.div`
  text-align: center;
  opacity: 0.4;
  margin-top: 30px;

  > svg {
    color: white;
    font-size: 36px;
  }
`;

const CaptureIcon = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border: solid white;
  border-width: ${props => (props.step === 'ready' ? '4px 4px 14px 4px' : 0)};
  margin-bottom: 30px;

  > svg {
    font-size: ${props => (props.step === 'ready' ? '30px' : '60px')};
    color: white;
  }
`;

const InstructionsTitle = styled.h3`
  display: block;
  font-size: 22px;
  color: white;
  margin: 0;
`;

const InstructionsDescription = styled.p`
  color: white;
  line-height: 1.6;
`;

const Button = styled.button`
  display: inline-flex;
  background-color: ${props =>
    props.outline
      ? 'transparent'
      : props.primary
      ? props.theme.colors.primary
      : 'white'};
  outline: none;
  border: none;
  border-radius: 30px;
  padding: 10px 25px;
  color: ${props => (props.primary ? 'white' : 'black')};
  font-size: 16px;
  opacity: 0.9;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }

  &:last-child {
    margin-left: 30px;
  }
`;

const CancelBtn = styled(Button)`
  align-self: flex-end;
  margin-left: auto !important;
  border: 2px solid white;
  opacity: 0.5;
  color: white;
`;

const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  padding: 30px;
`;

const CaptureBtn = styled.button`
  position: relative;
  background-color: ${props => (props.capturing ? 'white' : 'transparent')};
  border-style: solid;
  border-color: ${props =>
    props.capturing ? 'white' : props.theme.colors.tertiary};
  border-width: ${props => (props.capturing ? '10px' : '4px')};
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-top: 20px;
  outline: none;
  cursor: ${props => (props.capturing ? 'default' : 'pointer')};
  transition: border-width 0.1s ease-in;

  svg {
    color: ${props =>
      props.capturing
        ? props.theme.backgroundColors.darker
        : props.theme.colors.tertiary};
    font-size: 24px;
  }

  &:hover {
    border-width: 10px;

    svg {
      color: ${props =>
        props.capturing ? props.theme.backgroundColors.darker : 'white'};
    }
  }
  &:active {
    background-color: white;

    svg {
      color: ${props =>
        props.capturing
          ? props.theme.backgroundColors.darker
          : props.theme.colors.tertiary};
    }
  }
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    animation-name: ${props => (props.capturing ? pulse : null)};
    animation-duration: 2s;
    animation-timing-function: ease-out;
    animation-iteration-count: infinite;
  }
`;

export default PhotoCapture;
