import React, { forwardRef } from 'react';
import styled from 'styled-components';
import ChecklistInput from './ChecklistInput';
import DateOfBirth from './DateOfBirth';
import DatePicker from './DatePicker';
import DateRange from './DateRange';
import DateTimePicker from './DateTimePicker';
import EMSLookupSelector from './EMSLookupSelector';
import LookupSelector from './LookupSelector';
import MedicationSelector from './MedicationSelector';
import MonthYear from './MonthYear';
import PharmacyFinder from './PharmacyFinder';
import PhysicianLookupSelector from './PhysicianLookupSelector';
import PhoneInput from './PhoneInput';
import PhotoInput from './PhotoInput';
import RadioInput from './RadioInput';
import Scale from './Scale';
import SearchSelector from './SearchSelector';
import Selector from './Selector';
import TimeBlock from './TimeBlock';
import UrgencyMeter from './UrgencyMeter';
import VitalReviewInput from './VitalReviewInput';
import { removeScriptTags } from '../../hooks/useFormHelper';
import SocialSecurityInput from './SocialSecurityInput';

const Field = forwardRef(
  (
    {
      id,
      label,
      type,
      config = {},
      subcategoryOptions,
      placeholder,
      value,
      sctid, // used for checklist item
      onChange,
      onOverwrite,
      onUnset,
      onFocus,
      onBlur,
      defaultValue,
      isDisabled = false,
    },
    ref,
  ) => (
    <Fieldset
      center={config && config.center}
      flexFill={config && config.fill}
      maxWidth={config && config.width ? config.width : null}
      minWidth={config && config.minWidth ? config.minWidth : null}
      backgroundColor={config && config.bgColor ? config.bgColor : null}>
      {label && !['checkbox', 'checklist', 'vitalReview'].includes(type) ? (
        <Label>{label}</Label>
      ) : null}
      {['text-input', 'number', 'email'].includes(type) ? (
        <TextInput
          ref={ref}
          type={type === 'text-input' ? 'text' : type}
          value={value || ''}
          placeholder={placeholder}
          onChange={e => onChange(id, removeScriptTags(e.target.value))}
          onFocus={
            onFocus ? e => onFocus(removeScriptTags(e.target.value)) : null
          }
          onBlur={
            onBlur ? e => onBlur(id, removeScriptTags(e.target.value)) : null
          }
          min={config.min || null}
          max={config.max || null}
          maxLength={config.maxLength}
          disabled={isDisabled}
        />
      ) : type === 'select' ? (
        <Selector
          id={id}
          value={value || config.default}
          fullWidth={config.fullWidth}
          options={
            subcategoryOptions
              ? config.options[subcategoryOptions]
              : config.options
          }
          onChange={val => onChange(id, val)}
          onFocus={onFocus}
          onBlur={onBlur}
          disabled={isDisabled}
        />
      ) : type === 'social-security' ? (
        <SocialSecurityInput
          ref={ref}
          value={value}
          onChange={(val, isValid) => onChange(id, val, isValid)}
          disabled={isDisabled}
        />
      ) : type === 'phone-input' ? (
        <PhoneInput
          ref={ref}
          value={value}
          onChange={val => onChange(id, removeScriptTags(val))}
          onFocus={onFocus}
          onBlur={onBlur ? val => onBlur(id, removeScriptTags(val)) : null}
          disabled={isDisabled}
        />
      ) : type === 'textarea' ? (
        <Textarea
          ref={ref}
          placeholder={placeholder}
          onChange={e => onChange(id, removeScriptTags(e.target.value))}
          onFocus={
            onFocus ? e => onFocus(removeScriptTags(e.target.value)) : null
          }
          onBlur={
            onBlur ? e => onBlur(id, removeScriptTags(e.target.value)) : null
          }
          defaultValue={value || ''}
          resize={config && config.resize ? config.resize : 'vertical'}
          rows={config && config.rows ? config.rows : '2'}
          disabled={isDisabled}
        />
      ) : type === 'radio' ? (
        <RadioInput
          value={value}
          options={config.options}
          fullWidth={config.fullWidth}
          connected={config.connected}
          onChange={val => onChange(id, val)}
          disabled={isDisabled}
        />
      ) : type === 'vitalReview' ? (
        <VitalReviewInput
          id={id}
          sctid={sctid}
          label={label}
          value={value}
          config={config}
          onChange={val => onChange(id, val)}
          disabled={isDisabled}
        />
      ) : type === 'checklist' ? (
        <ChecklistInput
          sctid={sctid}
          label={label}
          value={value}
          locationOptions={config.locations}
          options={config.options}
          radio={config.radio}
          onChange={val => onChange(id, val)}
          disabled={isDisabled}
        />
      ) : type === 'search' ? (
        <SearchSelector
          id={id}
          value={value}
          onSelect={onChange}
          onAutosave={onBlur}
          mode={config.mode ? config.mode : id}
          placeholder={config.placeholder}
          multiple={config.multiple}
          hideSelected={config.hideSelected}
          theme={config.theme}
          disabled={isDisabled}
        />
      ) : type === 'emsLookup' ? (
        <EMSLookupSelector
          id={id}
          value={value}
          onSelect={onChange}
          onUnset={onUnset}
          placeholder={config.placeholder}
          disabled={isDisabled}
        />
      ) : type === 'physicianLookup' ? (
        <PhysicianLookupSelector
          id={id}
          value={value}
          onSelect={onOverwrite}
          placeholder={config.placeholder}
          disabled={isDisabled}
        />
      ) : type === 'lookup' ? (
        <LookupSelector
          id={id}
          value={value}
          mode={config.mode ? config.mode : id}
          hideCard={config.hideCard}
          onSelect={onChange}
          onUnset={onUnset}
          onAutosave={onBlur}
          placeholder={config.placeholder}
          disabled={isDisabled}
        />
      ) : type === 'monthYear' ? (
        <MonthYear
          id={id}
          value={value}
          onChange={val => onChange(id, val)}
          onAutosave={onBlur}
          convert={config && config.convertToDate}
          disabled={isDisabled}
        />
      ) : type === 'time' ? (
        <TimeBlock
          id={id}
          value={value}
          config={config}
          onChange={val => onChange(id, val)}
          onAutosave={onBlur}
          disabled={isDisabled}
        />
      ) : type === 'dateRange' ? (
        <DateRange
          id={id}
          value={value}
          onChange={val => onChange(id, val)}
          onAutosave={onBlur}
          disabled={isDisabled}
        />
      ) : type === 'datepicker' ? (
        <DatePicker
          id={id}
          value={value}
          onChange={val => onChange(id, val)}
          onAutosave={onBlur}
          config={config}
          disabled={isDisabled}
        />
      ) : type === 'datetime' ? (
        <DateTimePicker
          id={id}
          value={value}
          onChange={val => onChange(id, val)}
          onAutosave={onBlur}
          config={config}
          disabled={isDisabled}
        />
      ) : type === 'dateOfBirth' ? (
        <DateOfBirth
          id={id}
          value={value}
          onChange={(val, valid) => onChange(id, val, valid)}
          onAutosave={onBlur}
          convert={config && config.convertToDate}
          disabled={isDisabled}
        />
      ) : type === 'scale' ? (
        <Scale
          id={id}
          value={value || defaultValue}
          min={config ? config.min : 1}
          max={config ? config.max : 10}
          onChange={val => onChange(id, val)}
          onAutosave={onBlur}
          disabled={isDisabled}
        />
      ) : type === 'medications' ? (
        <MedicationSelector
          id={id}
          value={value}
          onChange={val => onChange(id, val)}
          onAutosave={onBlur}
          disabled={isDisabled}
        />
      ) : type === 'pharmacyFinder' ? (
        <PharmacyFinder
          id={id}
          value={value}
          onChange={val => onChange(id, val)}
          onAutosave={onBlur}
          disabled={isDisabled}
        />
      ) : type === 'urgencyMeter' ? (
        <UrgencyMeter
          id={id}
          value={value}
          onChange={val => onChange(id, val)}
          onAutosave={onBlur}
          disabled={isDisabled}
        />
      ) : type === 'photo' ? (
        <PhotoInput
          id={id}
          value={value}
          onChange={val => onChange(id, val)}
          disabled={isDisabled}
        />
      ) : null}
    </Fieldset>
  ),
);

const TextInput = styled.input`
  width: ${props => (props.half ? '50%' : '100%')};
`;

const Textarea = styled.textarea`
  width: 100%;
  resize: ${props => props.resize};
`;

const Fieldset = styled.fieldset`
  display: flex;
  flex-direction: column;
  flex: ${props => (props.flexFill ? '1' : 'initial')};
  align-items: ${props => (props.center ? 'center' : 'flex-start')};
  max-width: ${props => props.maxWidth || 'initial'};
  min-width: ${props => props.minWidth || '200px'};
  background: ${props => `${props.backgroundColor} !important` || 'inherit'};
`;

const Label = styled.label`
  margin-bottom: 14px;
`;

export default Field;
