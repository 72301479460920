import React, {
  useState,
  useContext,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import * as Realm from 'realm-web';
import parse from 'html-react-parser';
import styled from 'styled-components';
import logo from '../../assets/images/logo.svg';
import hero from '../../assets/images/login-hero.jpg';
import { IoShieldCheckmarkOutline } from 'react-icons/io5';
import { SystemContext } from '../contexts/SystemContext';
import TwoFactor from '../components/auth/TwoFactor';
import useAmplitude from '../hooks/useAmplitude';
import { validateEmail } from '../hooks/useFormHelper';
import { APP_ID } from '../../constants';

// min 8 char, 1 upper, 1 lower, 1 digit, 1 special, no space
const REGEX = /^(?=.{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W)\S*$/;

const AUTH_STATES = {
  forgot: {
    title: 'Reset Password',
    subtitle:
      'Fill in your email below to request a new password. An email will be sent to the address below containing a link to verify your email address.',
  },
  change: {
    title: 'Change Password',
    subtitle:
      'Passwords must be at least 8 characters long and include:<br/> 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character',
  },
  login: {
    title: 'Welcome back!',
    subtitle: 'Login to continue',
  },
};

const LoginPage = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const { trackLogin } = useAmplitude();

  const app = useMemo(
    () => Realm.getApp(APP_ID[process.env.REACT_APP_ENVIRONMENT]),
    [],
  );

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('');
  const [focused, setFocused] = useState();
  const [authAction, setAuthAction] = useState(urlParams.get('action'));
  const [alert, setAlert] = useState();
  const [successMessage, setSuccessMessage] = useState(
    'Your password has been successfully updated.',
  );

  const { dispatch: dispatchSystem } = useContext(SystemContext);

  const logUserIn = () => {
    // setting userId and twoFactor below will automatically navigate user to dashboard
    dispatchSystem({
      type: 'SET_SYSTEM',
      data: {
        userId: app.currentUser?.id,
        realmUser: app.currentUser,
        twoFactor: true,
      },
    });
  };

  const loginComplete = async () => {
    await app.currentUser.functions.set2faStatus(true);

    // save timestamp in session, force new browser tabs to login
    sessionStorage.setItem('sessionStarted', new Date());

    // record login in amplitude
    trackLogin(app.currentUser.customData);

    // check if password needs changed (expired or newly setup)
    if (!app.currentUser.customData.passwordChanged) {
      setAuthAction('change');
    } else {
      logUserIn();
    }
  };

  const onResetPassword = async e => {
    e.preventDefault();

    if (!validateEmail(email)) {
      setAlert("You've entered an invalid email.");
      return;
    }

    // matt -- call server to send email
    await app.emailPasswordAuth.callResetPasswordFunction(
      { email, password: 'generic123' },
      null,
    );

    setAlert(
      `A password reset email has been sent to ${email}. Click the link in the email to change your password.`,
    );
    setAuthAction(null);
  };

  const onChangePassword = async e => {
    e.preventDefault();

    if (newPassword !== newPasswordConfirm) {
      setAlert('Passwords do not match.');
      return;
    }

    if (REGEX.test(newPassword)) {
      try {
        const token = urlParams.get('token');
        const tokenId = urlParams.get('tokenId');

        if (token && tokenId) {
          await app.emailPasswordAuth.resetPassword({
            password: newPassword,
            token,
            tokenId,
          });

          setAuthAction('successReset');
        } else {
          await app.emailPasswordAuth.callResetPasswordFunction(
            { email, password: newPassword },
            app.currentUser.id,
          );

          setAuthAction('success');
        }
      } catch (err) {
        console.log('Error changing password: ', err);
        setAlert('Error changing password. Please try again.');
        return;
      }
    } else {
      setAlert('Invalid password.');
      return;
    }

    setSuccessMessage('Your password has been successfully updated.');
  };

  const onSubmitLogin = async e => {
    e.preventDefault();
    setFocused();

    const credentials = Realm.Credentials.emailPassword(email, password);

    try {
      // Authenticate the user
      const app = Realm.getApp(APP_ID[process.env.REACT_APP_ENVIRONMENT]);
      const user = await app.logIn(credentials);

      // Send auth code
      const status = user.functions.sendVerificationCode();
      if (status) {
        setAuthAction('code');
      } else {
        throw new Error('Code unable to be sent');
      }
    } catch (err) {
      setPassword('');
      setAlert(
        'Your email address or password is incorrect. Please try again.',
      );
      console.log('Failed to log in', err);
    }
  };

  return (
    <Container>
      <LeftColumn src={hero} />
      <RightColumn>
        {alert ? (
          <Alert>
            <AlertMsg>{alert}</AlertMsg>
          </Alert>
        ) : null}
        {authAction === 'code' ? (
          <TwoFactor
            onClose={() => setAuthAction(null)}
            onComplete={loginComplete}
          />
        ) : authAction === 'success' ? (
          <SuccessBox>
            <IoShieldCheckmarkOutline />
            <Header>Successful</Header>
            <Subtitle>{successMessage}</Subtitle>
            <Button onClick={logUserIn}>Continue to Dashboard</Button>
          </SuccessBox>
        ) : authAction === 'successReset' ? (
          <SuccessBox>
            <IoShieldCheckmarkOutline />
            <Header>Successful</Header>
            <Subtitle>{successMessage}</Subtitle>
            <Button onClick={() => setAuthAction(null)}>
              Continue to Login
            </Button>
          </SuccessBox>
        ) : (
          <AuthBox>
            <Logo src={logo} />
            <Header>{AUTH_STATES[authAction || 'login'].title}</Header>
            <Subtitle>
              {parse(AUTH_STATES[authAction || 'login'].subtitle)}
            </Subtitle>
            {authAction === 'forgot' ? (
              <form onSubmit={onResetPassword}>
                <FieldSet active={focused === 'email'}>
                  <Label>Email</Label>
                  <Input
                    name="email"
                    type="email"
                    placeholder="Enter your email address"
                    onFocus={() => setFocused('email')}
                    onBlur={() => setFocused('')}
                    onChange={e => setEmail(e.target.value)}
                  />
                </FieldSet>
                <Button type="submit">Reset Password</Button>
                <Link onClick={() => setAuthAction(null)}>Go back</Link>
              </form>
            ) : authAction === 'change' ? (
              <form onSubmit={onChangePassword}>
                <FieldSet active={focused === 'newPassword'}>
                  <Label>New Password</Label>
                  <Input
                    name="newPassword"
                    type="password"
                    onFocus={() => setFocused('newPassword')}
                    onBlur={() => setFocused('')}
                    onChange={e => setNewPassword(e.target.value)}
                  />
                </FieldSet>
                <FieldSet active={focused === 'newPasswordConfirm'}>
                  <Label>Confirm Password</Label>
                  <Input
                    name="newPasswordConfirm"
                    type="password"
                    onFocus={() => setFocused('newPasswordConfirm')}
                    onBlur={() => setFocused('')}
                    onChange={e => setNewPasswordConfirm(e.target.value)}
                  />
                </FieldSet>
                <Button type="submit">Change Password</Button>
              </form>
            ) : (
              <form onSubmit={onSubmitLogin}>
                <FieldSet active={focused === 'email'}>
                  <Label>Email</Label>
                  <Input
                    name="email"
                    type="email"
                    placeholder="Enter your email address"
                    onFocus={() => setFocused('email')}
                    onBlur={() => setFocused('')}
                    onChange={e => setEmail(e.target.value)}
                  />
                </FieldSet>
                <FieldSet active={focused === 'password'}>
                  <Label>Password</Label>
                  <Input
                    name="password"
                    type="password"
                    placeholder="Enter password"
                    value={password}
                    onFocus={() => setFocused('password')}
                    onBlur={() => setFocused('')}
                    onChange={e => setPassword(e.target.value)}
                  />
                </FieldSet>
                <Button type="submit">Login</Button>
                <Link onClick={() => setAuthAction('forgot')}>
                  Forgot your password?
                </Link>
              </form>
            )}
          </AuthBox>
        )}
      </RightColumn>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #f8f8f8;
  flex-direction: row;
  flex: 1;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const LeftColumn = styled(Column)`
  position: relative;
  background: linear-gradient(315deg, #ef1d96, #f9566e);
  width: 40%;

  ::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    display: block;
    background-image: ${props => `url(${props.src})`};
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    opacity: 0.2;
  }
`;

const RightColumn = styled(Column)`
  position: relative;
  background-color: white;
  padding: 0 120px;
  width: 60%;
`;

const Alert = styled.div`
  position: absolute;
  top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: auto;
  padding: 30px 30px 20px;
`;

const AlertMsg = styled.div`
  background-color: ${props => props.theme.colors.primary};
  border-radius: 10px;
  padding: 20px;
  color: white;
  font-size: 15px;
  line-height: 1.3;
  font-weight: ${props => props.theme.fontWeights.bold};
`;

const Logo = styled.img`
  width: 240px;
  margin-bottom: 36px;
  margin-left: -10px;
`;

const AuthBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  padding-bottom: 30px;
`;

const Header = styled.h3`
  font-size: 24px;
  color: ${props => props.theme.textColors.primary};
  margin-bottom: 12px;
`;

const Subtitle = styled.h4`
  margin-top: 0;
  margin-bottom: 36px;
  line-height: 1.3;
  font-size: 17px;
  font-weight: ${props => props.theme.fontWeights.regular};
  color: ${props => props.theme.textColors.secondary};
`;

const FieldSet = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 480px;
  padding: 10px;
  box-sizing: border-box;
  border: solid 2px transparent;
  border-radius: 10px;
  margin: 28px 0;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: -2px;
    border-radius: inherit;
    background: ${props =>
      props.active
        ? 'linear-gradient(30deg, #EF1D96, #F9566E, #FFAA76)'
        : props.theme.colors.border};
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    border-radius: 7px;
  }
`;

const Label = styled.label`
  position: absolute;
  top: -8px;
  left: 15px;
  z-index: 1;
  background-color: white;
  padding: 0 8px;
  font-size: 12px;
  font-weight: ${props => props.theme.fontWeights.bold};
  color: ${props => props.theme.textColors.secondary};
  text-transform: uppercase;
  letter-spacing: 1px;
`;

const Input = styled.input`
  position: relative;
  z-index: 2;
  border: none;
  background-color: transparent;
  color: ${props => props.theme.textColors.primary};
  font-size: 16px;
  padding: 8px;
  height: 32px;
  outline: none;

  &::placeholder {
    color: ${props => `rgba(${props.theme.textColors.secondaryRGB}, 0.7)`};
  }
`;

const Button = styled.button`
  display: block;
  width: 180px;
  height: 48px;
  background: linear-gradient(215deg, #ffaa76, #f9566e);
  border-radius: 6px;
  border: none;
  outline: none;
  font-size: 14px;
  font-family: ${props => props.theme.fonts.secondary};
  font-weight: ${props => props.theme.fontWeights.bold};
  color: white;
  letter-spacing: 0.5px;
  margin-top: 24px;
  cursor: pointer;

  &:hover {
    background: linear-gradient(195deg, #ffaa76, #f9566e);
  }
`;

const Link = styled.a`
  display: inline-block;
  clear: both;
  margin-top: 24px;
  text-decoration: underline;
  color: ${props => props.theme.colors.tertiary};
  font-size: 13px;
  cursor: pointer;
`;

const SuccessBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 100%;

  svg {
    color: ${props => props.theme.colors.green};
    font-size: 92px;
  }
`;

export default LoginPage;
