import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { padDigit } from '../../hooks/useTextHelper';
import Selector from './Selector';

const INITIAL_STATE = {
  hours: 12,
  minutes: 0,
  pm: false,
};

const TimeBlock = ({
  id,
  value,
  config,
  onChange,
  onAutosave,
  disabled = false,
}) => {
  const [time, setTime] = useState(value || INITIAL_STATE);

  const onSelectTime = (prop, value) => {
    const newTimeObj = {
      ...time,
      [prop]: value,
    };
    setTime(newTimeObj);
    onChange(newTimeObj);

    if (onAutosave) onAutosave(id, newTimeObj, true);
  };

  useEffect(() => {
    if (value) setTime(value);
  }, [value]);

  return (
    <Container>
      {config.hoursOnly ? (
        <Selector
          value={time.hours}
          options={[12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1]}
          onChange={val => onSelectTime('hours', val)}
          disabled={disabled}
        />
      ) : (
        <Hour
          type="number"
          value={time.hours}
          min="1"
          max="12"
          onChange={e => onSelectTime('hours', e.target.value)}
          disabled={disabled}
        />
      )}

      <Colon>:</Colon>
      {config.hoursOnly ? (
        <MinutePlaceholder>00</MinutePlaceholder>
      ) : (
        <Minute
          type="number"
          value={padDigit(time.minutes)}
          min="0"
          max="59"
          onChange={e => onSelectTime('minutes', e.target.value)}
          disabled={disabled}
        />
      )}
      <AMPM>
        <Button
          active={!time.pm}
          onClick={() => onSelectTime('pm', false)}
          disabled={disabled}>
          AM
        </Button>
        <Button
          active={time.pm}
          onClick={() => onSelectTime('pm', true)}
          disabled={disabled}>
          PM
        </Button>
      </AMPM>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  > select:first-child {
    margin-right: 5px;
  }
`;

const Number = styled.input`
  margin-right: 6px;
`;

const Hour = styled(Number)``;

const Minute = styled(Number)``;

const MinutePlaceholder = styled.div`
  font-size: 18px;
  color: ${props => props => props.theme.textColors.tertiary};
`;

const Colon = styled.span`
  margin-right: 6px;
  font-size: 20px;
  color: ${props => props.theme.textColors.secondary};
`;

const AMPM = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: 'row';
  border-radius: 6px;
  overflow: hidden;
  border: 1px solid ${props => props.theme.colors.border};
  margin-left: 15px;
`;

const Button = styled.button`
  border: 0;
  outline: none;
  background-color: ${props =>
    props.active
      ? props.theme.colors.blue
      : props.theme.backgroundColors.lightAlt};
  color: ${props =>
    props.active ? 'white' : props.theme.textColors.secondary};
  pointer-events: ${props => (props.active ? 'none' : 'auto')};
  cursor: pointer;

  font-family: ${props => props.theme.fonts.secondary};
  font-weight: ${props => props.theme.fontWeights.medium};

  width: 51px;
  height: 51px;

  border-right: 1px solid;
  border-right-color: ${props =>
    props.active ? 'transparent' : props.theme.colors.border};

  ${({ disabled }) =>
    disabled &&
    `
    pointer-events: none;
    cursor: default;
  `}
`;

export default TimeBlock;
