import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { lighten } from 'polished';
import ActivityIndicator from '../common/ActivityIndicator';
import { SystemContext } from '../../contexts/SystemContext';

const BloodPressureRequest = ({ room, onSave }) => {
  const {
    state: { realmUser },
  } = useContext(SystemContext);

  const [requestingBP, setRequestingBP] = useState();
  const [bpRequestTimedOut, setBPRequestTimedOut] = useState(false);
  const [showManualEntry, setShowManualEntry] = useState(false);
  const [sys, setSys] = useState('');
  const [dia, setDia] = useState('');

  let bpTimer;

  const requestBloodPressure = () => {
    setRequestingBP(true);
    realmUser.functions.sendPusherEvent(`presence-${room}`, 'take-bp');
  };

  // set timeout for requesting blood pressure
  useEffect(() => {
    const requestTimedOut = () => {
      setRequestingBP(false);
      setBPRequestTimedOut(true);
    };

    if (requestingBP) {
      bpTimer = setTimeout(() => {
        requestTimedOut();
      }, 5000);
    } else {
      clearTimeout(bpTimer);
      bpTimer = null;
    }

    return () => clearTimeout(bpTimer);
  }, [requestingBP]);

  const saveManualVitals = () => {
    onSave({ sys, dia, timestamp: new Date() });
  };

  return (
    <Container>
      {requestingBP ? (
        <>
          <p>Request vitals from device.</p>
          <ActivityIndicator size={15} color="secondary" />
        </>
      ) : showManualEntry ? (
        <ManualEntry>
          <Row>
            <Fieldset>
              <label>SYS</label>
              <input
                type="text"
                value={sys}
                onChange={e => setSys(e.target.value.replace(/[^\d]/, ''))}
                maxLength={3}
              />
              <span>mmHg</span>
            </Fieldset>
            <Slash />
            <Fieldset>
              <label>DIA</label>
              <input
                type="text"
                value={dia}
                onChange={e => setDia(e.target.value.replace(/[^\d]/, ''))}
                maxLength={3}
              />
              <span>mmHg</span>
            </Fieldset>
          </Row>
          <Row>
            <Btn multi onClick={saveManualVitals} disabled={!dia || !sys}>
              Save
            </Btn>
            <Btn multi secondary onClick={() => setShowManualEntry(false)}>
              Cancel
            </Btn>
          </Row>
        </ManualEntry>
      ) : bpRequestTimedOut ? (
        <>
          <p>Vitals could not be retrieved from the blood pressure device.</p>
          <Row>
            <Btn multi onClick={() => setShowManualEntry(true)}>
              Manual Entry
            </Btn>
            <Btn multi secondary onClick={() => setBPRequestTimedOut(false)}>
              Retry
            </Btn>
          </Row>
        </>
      ) : (
        <>
          <p>
            Have patient start the device. When the cycle has completed, use the
            button below to request the vital readings.
          </p>
          <Btn onClick={requestBloodPressure}>Request Values from Device</Btn>
        </>
      )}
    </Container>
  );
};

const Fieldset = styled.fieldset`
  position: relative;
  margin-bottom: 0;

  input {
    width: 80px;
    text-align: center;
    padding: 11px 16px 21px;
    text-align: center;
  }
  label {
    text-align: center;
  }
  span {
    position: absolute;
    bottom: 14px;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    font-size: 10px;
    font-family: ${props => props.theme.fonts.primary};
    color: ${props => props.theme.textColors.secondary};
    user-select: none;
    pointer-events: none;
    text-align: center;
  }
`;

const Slash = styled.span`
  width: 4px;
  height: 54px;
  background-color: ${props => props.theme.colors.border};
  transform: rotate(15deg);
  margin: 6px 12px 0;
`;

const ManualEntry = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px 5px;

  p {
    font-size: 13px;
    color: ${props => props.theme.textColors.secondary};
    text-align: center;
    line-height: 1.4;
    margin: 0 0 10px;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Btn = styled.button`
  border: ${props =>
    props.secondary ? `2px solid ${props.theme.colors.blue}` : 'none'};
  outline: none;
  font-size: 11px;
  font-family: ${props => props.theme.fonts.secondary};
  font-weight: ${props => props.theme.fontWeights.bold};
  text-transform: uppercase;
  white-space: nowrap;
  text-overflow: clip;
  color: ${props => (props.secondary ? props.theme.colors.blue : 'white')};
  background-color: ${props =>
    props.secondary ? 'white' : props.theme.colors.blue};
  border-radius: 32px;
  height: 32px;
  line-height: ${props => (props.secondary ? '28px' : '32px')};
  padding: 1px 20px 0;
  letter-spacing: 0.2px;
  cursor: pointer;
  margin: 0 5px;

  &:hover {
    background-color: ${props =>
      props.secondary
        ? props.theme.backgroundColors.lightAlt
        : lighten(0.05, props.theme.colors.blue)};
  }

  ${({ disabled }) =>
    disabled &&
    `
    pointer-events: none;
    cursor: default;
    opacity: 0.3;
  `}
`;

export default BloodPressureRequest;
