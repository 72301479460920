import { generateNumberArray } from '../App/hooks/useFormHelper';

export const ALLERGIES_FORM = {
  fields: [
    {
      id: 'allergen-row',
      type: 'row',
      fields: [
        {
          id: 'allergy',
          type: 'search',
          label: 'Allergen',
          config: {
            fill: true,
          },
        },
        {
          id: 'severity',
          type: 'select',
          config: {
            options: ['mild', 'moderate', 'severe', 'unknown'],
          },
          label: 'Severity',
        },
        {
          id: 'onsetAge',
          label: 'Age Identified',
          type: 'select',
          config: {
            options: ['-', ...generateNumberArray(1, 100)],
          },
        },
      ],
    },
  ],
};
