import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import {
  FaListOl,
  FaRegLemon,
  FaQuestion,
  FaPrescription,
  FaShoppingBasket,
  FaRegComment,
  FaRegStickyNote,
} from 'react-icons/fa';
import { HiOutlineShieldCheck } from 'react-icons/hi';

const getColor = (type, theme) => {
  switch (type) {
    case 'Rx Refill':
      return theme.colors.primary;
    case 'Food/Meals':
      return theme.colors.green;
    case 'Communication Request':
      return theme.textColors.secondary;
    case 'Environmental/Safety':
      return theme.colors.blue;
    case 'DME':
      return theme.colors.tertiary;
    case 'Update from Previous Visit':
      return theme.colors.yellow;
    default:
      return theme.textColors.primary;
  }
};

const getIcon = type => {
  switch (type) {
    case 'Rx Refill':
      return <FaPrescription />;
    case 'DME':
      return <FaShoppingBasket />;
    case 'Food/Meals':
      return <FaRegLemon />;
    case 'Communication Request':
      return <FaRegComment />;
    case 'Environmental/Safety':
      return <HiOutlineShieldCheck />;
    case 'Care Plan Question':
      return <FaQuestion />;
    case 'Update from Previous Visit':
      return <FaListOl />;
    default:
      return <FaRegStickyNote />;
  }
};

const RequestIcon = ({ type }) => {
  const theme = useContext(ThemeContext);
  const color = getColor(type, theme);

  return (
    <Orb enlargeIcon={type === 'Environmental/Safety'} color={color}>
      {getIcon(type)}
    </Orb>
  );
};

const Orb = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color: ${props => props.color};
  border: 3px solid ${props => props.color};
  overflow: hidden;

  svg {
    font-size: ${props => (props.enlargeIcon ? '30px' : '24px')};
    color: white;
  }
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    transform: translateX(-50%) translateY(-50%);
    width: 100%;
    height: 100%;
    border: 3px solid ${props => props.theme.backgroundColors.lightAlt};
    border-radius: 50%;
    box-sizing: border-box;
  }
`;

export default RequestIcon;
