import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { SEARCH_PATIENTS } from '../../api/patients';
import ActivityIndicator from '../common/ActivityIndicator';
import SearchBar from '../common/SearchBar';
import NewPatientModal from '../modals/NewPatientModal';
import PatientModal from '../modals/PatientModal';
import { ModalContext } from '../../contexts/ModalContext';
import UserIcon from '../common/UserIcon';

const SearchPopover = ({ openResultInModal, onDismiss }) => {
  const history = useHistory();
  const { dispatch: dispatchModal } = useContext(ModalContext);

  const [term, setSearchTerm] = useState('');

  // by default, search blank ... pass new search term on refetch
  const { data, loading, error, refetch } = useQuery(SEARCH_PATIENTS, {
    variables: {
      search: term,
    },
  });

  const search = searchTerm => {
    // clear results if less than 3 chars
    if (searchTerm.length < 3) {
      setSearchTerm('');
      return;
    }

    setSearchTerm(searchTerm);
  };

  useEffect(() => {
    refetch();
  }, [term]);

  const onSelect = patient => {
    if (openResultInModal) {
      dispatchModal({
        type: 'ADD_MODAL',
        data: {
          component: PatientModal,
          props: {
            patient,
            fulLHeight: true,
          },
        },
      });
      onDismiss();
      return;
    }

    if (patient.onboarded) {
      history.push(`/patient/${patient.userId}`);
      onDismiss();
      return;
    }

    dispatchModal({
      type: 'ADD_MODAL',
      data: {
        component: NewPatientModal,
        props: {
          data: patient,
        },
      },
    });

    onDismiss();
  };

  return (
    <Container>
      <SearchBar
        white
        placeholder="Look up any patient by Last Name"
        autofocus
        onSearch={search}
      />
      <Results>
        {loading ? (
          <Empty>
            <ActivityIndicator />
          </Empty>
        ) : data && data.patientsSearch.length > 0 ? (
          data.patientsSearch.map(item => (
            <Result key={item.userId} onClick={() => onSelect(item)}>
              <UserIcon user={item} size="40px" patient />
              <Name>{`${item.name.given} ${item.name.family}`}</Name>
            </Result>
          ))
        ) : (
          <Empty>No results found</Empty>
        )}
      </Results>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 8px;

  width: 500px;
  max-height: 400px;
  overflow: hidden;
  box-shadow: 0 5px 20px 0 rgb(21 27 38 / 8%);
  border: 1px solid ${props => props.theme.colors.border};

  transform: translateY(-30px);
`;

const Results = styled.div`
  display: block;
  padding: 20px;
  background-color: ${props => props.theme.backgroundColors.lightAlt};
  overflow-y: scroll;
  border-top: 1px solid ${props => props.theme.colors.border};
`;

const Result = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 20px;
  border-radius: 10px;
  border: 1px solid transparent;
  background-color: white;
  margin-bottom: 10px;
  cursor: pointer;

  &:hover {
    background-color: ${props => props.theme.colors.tableHover};
    border-color: ${props => props.theme.colors.border};
  }
`;

const Photo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-weight: ${props => props.theme.fontWeights.bold};
  color: white;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${props => props.theme.colors.tertiary};
  margin-right: 15px;
`;

const Name = styled.h4`
  margin: 0 0 0 15px;
  padding: 0;
`;

const Empty = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;

  font-size: 12px;
  text-align: center;
  color: ${props => props.theme.textColors.secondary};
`;

export default SearchPopover;
