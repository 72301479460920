import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import {
  FaRegHeart,
  FaRegFlag,
  FaRegThumbsUp,
  FaRegThumbsDown,
  FaRegClipboard,
} from 'react-icons/fa';
import WidgetTabs from '../../../common/WidgetTabs';
import Symptom from '../../../common/Symptom';
import { GET_SYMPTOMS } from '../../../../api/symptoms';
import { CallContext } from '../../../../contexts/CallContext';

const TABS = [
  {
    id: 'notes',
    label: 'Visit Notes',
  },
  {
    id: 'symptoms',
    label: 'Symptoms',
  },
];

const SUMMARY = [
  {
    id: 'reason',
    label: 'Reason for Visit',
    icon: <FaRegHeart />,
    color: 'secondary',
  },
  {
    id: 'contextualFactors',
    label: 'Contextual Factors',
    icon: <FaRegFlag />,
    color: 'tertiary',
  },
  {
    id: 'exacerbatingFactors',
    label: 'Exacerbating Factors',
    icon: <FaRegThumbsDown />,
    color: 'red',
  },
  {
    id: 'relievingFactors',
    label: 'Relieving Factors',
    icon: <FaRegThumbsUp />,
    color: 'green',
  },
  {
    id: 'notes',
    label: 'Other Notes',
    icon: <FaRegClipboard />,
  },
];

const VisitSummaryWidget = ({ encounter, notes }) => {
  const {
    state: { channel },
  } = useContext(CallContext);

  const [activeTab, setActiveTab] = useState(TABS[0].id);

  const { loading, error, data, refetch } = useQuery(GET_SYMPTOMS, {
    variables: { encounter },
  });

  const changeTab = id => setActiveTab(id);

  const openSymptomModal = () => {};

  useEffect(() => {
    if (channel) channel.bind('update-symptoms', refetch);

    return () => {
      if (channel) channel.unbind('update-symptoms', refetch);
    };
  }, [channel, refetch]);

  return (
    <Wrapper activeTab={activeTab}>
      <WidgetTabs
        config={TABS}
        activeTab={activeTab}
        center
        onTabClick={changeTab}
      />
      <Content>
        {activeTab === 'notes' ? (
          <SummaryNotes>
            {SUMMARY.map(item => (
              <SummaryItem key={item.id}>
                <Icon color={item.color}>{item.icon}</Icon>
                <SummaryItemText>
                  <label>{item.label}</label>
                  <p>{notes.encounter[item.id]}</p>
                </SummaryItemText>
              </SummaryItem>
            ))}
          </SummaryNotes>
        ) : loading || data.length === 0 ? null : (
          <SummarySymptoms>
            {data.symptoms.map(item => (
              <Symptom
                key={item._id}
                config={item}
                onClick={() => openSymptomModal(item)}
              />
            ))}
          </SummarySymptoms>
        )}
      </Content>
      {activeTab === 'notes' ? (
        <>
          <TopGradient />
          <BottomGradient />
        </>
      ) : null}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  background-color: ${props =>
    props.activeTab === 'symptoms'
      ? props.theme.backgroundColors.tertiary
      : 'transparent'};
`;

const TopGradient = styled.div`
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  z-index: 4;
  height: 30px;
  pointer-events: none;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
`;

const BottomGradient = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 4;
  height: 60px;
  pointer-events: none;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
`;

const Content = styled.div`
  padding: 0 20px 40px;
  width: 100%;
  flex: 1;
  overflow-y: scroll;
`;

const SummaryNotes = styled.div`
  flex: 1;
`;

const SummaryItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid ${props => props.theme.colors.border};

  &:last-child {
    border-bottom: none;
  }
`;

const SummaryItemText = styled.div`
  flex: 1;
  label {
    font-size: 10px;
    text-transform: uppercase;
  }

  p {
    font-size: 15px;
    line-height: 1.4;
    margin: 0;
  }
`;

const SummarySymptoms = styled.div`
  flex: 1;
  /* display: grid; */
  width: 100%;
  /* grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  grid-auto-rows: minmax(240px, auto); */
  padding: 20px;
`;

const Icon = styled.div`
  float: left;
  margin-right: 20px;

  svg {
    font-size: 22px;
    color: ${props =>
      props.color === 'secondary'
        ? props.theme.colors.secondary
        : props.color === 'blue'
        ? props.theme.colors.blue
        : props.color === 'green'
        ? props.theme.colors.green
        : props.color === 'red'
        ? props.theme.colors.warning
        : props.color === 'tertiary'
        ? props.theme.colors.tertiary
        : props.theme.textColors.secondary};
  }
`;

export default VisitSummaryWidget;
