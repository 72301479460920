import { UPDATE_DAT } from '../App/api/medical';

export const DAT_FORM = {
  fields: [
    {
      id: 'alcohol',
      type: 'text-input',
      label: 'Do you drink alcohol? If yes, how much per week?',
    },
    {
      id: 'tobacco',
      type: 'text-input',
      label: 'Do you use tobacco products? If yes, how much per week?',
    },
    {
      id: 'drugs',
      type: 'text-input',
      label: 'Do you use any illicit drugs?',
    },
  ],
  mutation: UPDATE_DAT,
  maxWidth: '640px',
};
