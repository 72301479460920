import gql from 'graphql-tag';

/**
 * QUERIES
 */

export const GET_PATIENTS = gql`
  query GET_PATIENTS {
    patients {
      name {
        given
        family
      }
      birthDate
      gender
      mrn
      onboarded
      userId
    }
  }
`;

export const SEARCH_PATIENTS = gql`
  query SEARCH_PATIENTS($search: String!) {
    patientsSearch(input: $search) {
      name {
        given
        family
      }
      photo
      birthDate
      gender
      mrn
      userId
      onboarded
    }
  }
`;

export const GET_PATIENT_INFO = gql`
  query GET_PATIENT_INFO($userId: String!) {
    patient(query: { userId: $userId }) {
      userId
      name {
        given
        family
        usual
        middle
      }
      ssn {
        full
        last4
      }
      mrn
      birthDate
      gender
      race
      language
      clinicalStudy
      codeStatus
      conditions {
        billingCode
        display
      }
      address {
        home {
          line
          city
          state
          postalCode
          notes
        }
      }
      telecom {
        home
      }
      livingArrangement
      photo
      hospital {
        address {
          line
          city
          postalCode
          state
        }
        name
        phone
      }
      poa
    }
  }
`;

/**
 * MUTATIONS
 */

export const UPDATE_PRIMARY_CONDITIONS = gql`
  mutation UPDATE_PRIMARY_CONDITIONS(
    $id: String!
    $conditions: [PatientConditionUpdateInput]
  ) {
    updateOnePatient(query: { userId: $id }, set: { conditions: $conditions }) {
      conditions {
        billingCode
        display
      }
    }
  }
`;
