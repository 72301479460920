import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

const RadioInput = ({
  value,
  options,
  connected,
  fullWidth,
  onChange,
  disabled = false,
}) => {
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;

      if (!value) onChange(options[0].id);
    }
  }, [value]);

  return (
    <Wrapper connected={connected} fullWidth={fullWidth}>
      {options.map(option => (
        <Option
          key={option.id}
          active={value === option.id}
          connected={connected}
          onClick={() => onChange(option.id)}
          disabled={disabled}>
          {option.label}
        </Option>
      ))}
    </Wrapper>
  );
};

const Option = styled.button`
  outline: none;
  border: 1px solid;
  border-color: ${props =>
    props.active ? props.theme.colors.blue : props.theme.colors.border};
  border-radius: 10px;
  background-color: ${props =>
    props.connected && props.active ? props.theme.colors.blue : 'white'};

  color: ${props =>
    props.connected && props.active
      ? 'white'
      : props.active
      ? props.theme.colors.blue
      : props.theme.textColors.tertiary};
  text-transform: uppercase;
  font-weight: ${props => props.theme.fontWeights.bold};
  letter-spacing: 0.2px;
  padding: 0 20px;
  margin-right: 10px;
  height: 53px;
  transition: all 0.2s;

  cursor: ${props => (props.active ? 'default' : 'pointer')};
  pointer-events: ${props => (props.active ? 'none' : 'auto')};

  &:hover {
    color: ${props =>
      props.active ? props.theme.colors.blue : props.theme.colors.tertiary};
    border-color: ${props =>
      props.active ? props.theme.colors.blue : props.theme.colors.tertiary};
  }

  ${({ connected, active }) =>
    connected &&
    active &&
    `
    box-shadow: inset -4px 0 3px rgb(0 0 0 / 8%), inset 4px 0 3px rgb(0 0 0 / 8%);
    &:first-child {
      box-shadow: inset -4px 0 3px rgb(0 0 0 / 8%);
    }
    &:last-child {
      box-shadow: inset 4px 0 3px rgb(0 0 0 / 8%);
    }
  `}

  ${({ disabled }) =>
    disabled &&
    `
    pointer-events: none;
    cursor: default;
    opacity: 0.9;
  `}
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  flex: 1;

  ${({ connected }) =>
    connected &&
    `
      ${Option} {
        margin-right: 0;
        border-radius: 0;

        &:first-child {
          border-radius: 10px 0 0 10px;
        }

        &:last-child {
          border-radius: 0 10px 10px 0;
        }
      }
  `}

  ${({ fullWidth }) =>
    fullWidth &&
    `
    ${Option} {
      flex: 1;
    }
  `}
`;

export default RadioInput;
