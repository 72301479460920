import React from 'react';
import styled from 'styled-components';
import { lighten } from 'polished';

const WidgetFooterBtn = ({ onClick, children }) => (
  <ProfileBtn onClick={onClick}>{children}</ProfileBtn>
);

const ProfileBtn = styled.button`
  background: transparent;
  margin-top: auto;
  border: none;
  border-top: 1px solid ${props => props.theme.colors.border};
  cursor: pointer;
  color: ${props => props.theme.colors.blue};
  padding: 16px 0;
  text-transform: uppercase;
  font-weight: ${props => props.theme.fontWeights.bold};
  letter-spacing: 0.3px;
  font-size: 13px;
  width: 100%;

  &:hover {
    color: ${props => lighten(0.1, props.theme.colors.blue)};
  }
`;

export default WidgetFooterBtn;
