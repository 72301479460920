import React from 'react';
import {
  FaBandAid,
  FaConciergeBell,
  FaCarrot,
  FaTasks,
  FaRegComment,
  FaPrescription,
  FaRegCalendarAlt,
} from 'react-icons/fa';

export const CAREPLAN_ACTIONS = {
  // appointment: {
  //   id: 'appointment',
  //   label: 'Schedule Appointment',
  //   title: 'Follow Up Appointment',
  //   icon: <FaRegCalendarAlt />,
  // },
  // basic: {
  //   id: 'basicNeeds',
  //   label: 'Basic Needs',
  //   title: 'Basic Needs',
  //   icon: <FaConciergeBell />,
  // },
  // comms: {
  //   id: 'comms',
  //   label: 'Communication Request',
  //   title: 'Communication Request',
  //   icon: <FaRegComment />,
  // },
  // dme: {
  //   id: 'dme',
  //   label: 'DME Order',
  //   title: 'Supplies Order',
  //   icon: <FaBandAid />,
  // },
  task: {
    id: 'task',
    label: 'Assign Task',
    icon: <FaTasks />,
  },
  medication: {
    id: 'medication',
    label: 'Medication Order',
    icon: <FaPrescription />,
  },
  // nutrition: {
  //   id: 'nutrition',
  //   label: 'Nutrition',
  //   icon: <FaCarrot />,
  // },
};

export const APPOINTMENT_FORM = {
  fields: [
    {
      id: 'date',
      type: 'datetime',
      label: 'Appointment / Follow Up',
      config: {
        datepicker: {
          placement: 'top',
          width: '120px',
          future: true,
        },
      },
    },
    {
      id: 'notice',
      type: 'notice',
      text: "Appointment will be added to the Patient's calendar.",
    },
  ],
};

export const BASIC_FORM = {
  fields: [
    {
      id: 'description',
      type: 'textarea',
      label: 'Description of Request',
      placeholder: 'What is the patient in need of?',
    },
    {
      id: 'notice',
      type: 'notice',
      text:
        'Notes will directed to Admin who will follow up with necessary action.',
    },
  ],
};

export const COMMS_FORM = {
  fields: [
    {
      id: 'description',
      type: 'textarea',
      label: 'Description of Request',
      placeholder: 'Who needs to be contacted and why?',
    },
    {
      id: 'notice',
      type: 'notice',
      text:
        'Notes will directed to Admin who will be in contact with external care providers.',
    },
  ],
};

export const TASK_FORM = {
  fields: [
    {
      id: 'title',
      type: 'text-input',
      label: 'Task Type',
      placeholder: 'ex. Physical Therapy',
    },
    {
      id: 'description',
      type: 'text-input',
      label: 'Description',
      placeholder: 'ex. Stretch in a chair 2x daily',
    },
    {
      id: 'assignmentRow',
      type: 'row',
      fields: [
        // {
        //   id: 'assignedTo',
        //   type: 'radio',
        //   label: 'Assigned To',
        //   config: {
        //     connected: true,
        //     options: [
        //       {
        //         id: 'patient',
        //         label: 'Patient',
        //       },
        //       {
        //         id: 'careline',
        //         label: 'Care Line',
        //       },
        //       {
        //         id: 'admin',
        //         label: 'Admin',
        //       },
        //     ],
        //   },
        // },
        {
          id: 'date',
          type: 'datepicker',
          label: 'Expires On',
          // conditional: {
          //   property: 'assignedTo',
          //   condition: 'equals',
          //   value: 'patient',
          // },
          config: {
            placement: 'top',
            width: '120px',
            future: true,
          },
        },
        // {
        //   id: 'date',
        //   type: 'datepicker',
        //   label: 'Due Date',
        //   conditional: {
        //     property: 'assignedTo',
        //     condition: 'isNot',
        //     value: 'patient',
        //   },
        //   config: {
        //     placement: 'top',
        //     width: '120px',
        //     future: true,
        //   },
        // },
      ],
    },
  ],
};

export const NUTRITION_FORM = {
  fields: [
    {
      id: 'description',
      type: 'textarea',
      label: 'Notes',
      placeholder: 'Describe any noticable needs in food or living conditions.',
    },
    {
      id: 'notice',
      type: 'notice',
      text: 'Notes will be forwarded to RN/Social Worker for follow-up.',
    },
  ],
};

export const MEDICATION_FORM = {};
