import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
} from 'react-router-dom';
import AdminLayout from '../layouts/AdminLayout';
import CarelineHomePage from '../pages/CarelineHomePage';
import AdminHomePage from '../pages/AdminHomePage';
import ErrorPage from '../pages/ErrorPage';
import LoginPage from '../pages/LoginPage';
import PatientsListPage from '../pages/PatientsListPage';
import PatientProfilePage from '../pages/PatientProfilePage';
import ProvidersListPage from '../pages/ProvidersListPage';
import TeamPage from '../pages/TeamPage';
import VideoPage from '../pages/VideoPage';
import LogoutPage from '../pages/LogoutPage';
import CarelineLayout from '../layouts/CarelineLayout';
import ReportsPage from '../pages/ReportsPage';

const RootSwitch = ({ isAuthenticated, role }) => (
  <Router>
    <Switch>
      <Route exact path="/logout" render={() => <LogoutPage />} />
      <LoginRoute exact path="/login" isAuthenticated={isAuthenticated}>
        <LoginPage />
      </LoginRoute>
      <PrivateRoute exact path="/video/:room" isAuthenticated={isAuthenticated}>
        <VideoPage />
      </PrivateRoute>

      <PrivateRoute
        exact
        path="/patients"
        isAuthenticated={isAuthenticated}
        permission="admin"
        role={role}>
        <AdminLayout>
          <PatientsListPage />
        </AdminLayout>
      </PrivateRoute>
      <PrivateRoute
        exact
        path="/patient/:id"
        isAuthenticated={isAuthenticated}
        permission="admin"
        role={role}>
        <AdminLayout>
          <PatientProfilePage />
        </AdminLayout>
      </PrivateRoute>
      <PrivateRoute
        exact
        path="/providers"
        isAuthenticated={isAuthenticated}
        permission="admin"
        role={role}>
        <AdminLayout>
          <ProvidersListPage />
        </AdminLayout>
      </PrivateRoute>
      <PrivateRoute
        exact
        path="/team"
        isAuthenticated={isAuthenticated}
        permission="admin"
        role={role}>
        <AdminLayout>
          <TeamPage />
        </AdminLayout>
      </PrivateRoute>

      <PrivateRoute
        exact
        path="/reports"
        isAuthenticated={isAuthenticated}
        permission="admin"
        role={role}>
        <AdminLayout>
          <ReportsPage />
        </AdminLayout>
      </PrivateRoute>

      <PrivateRoute exact path="/" isAuthenticated={isAuthenticated}>
        {role === 'admin' ? (
          <AdminLayout>
            <AdminHomePage />
          </AdminLayout>
        ) : (
          <CarelineLayout>
            <CarelineHomePage role={role} />
          </CarelineLayout>
        )}
      </PrivateRoute>
      <PrivateRoute path="/oops" isAuthenticated={isAuthenticated}>
        <Page title="Something went wrong">
          <ErrorPage />
        </Page>
      </PrivateRoute>
      <Route
        render={({ location }) => (
          <Redirect to={{ pathname: '/oops', state: { from: location } }} />
        )}
      />
    </Switch>
  </Router>
);

const PrivateRoute = ({
  path,
  exact,
  isAuthenticated,
  permission,
  role,
  children,
}) => (
  <Route
    path={path}
    exact={exact}
    render={({ location }) =>
      isAuthenticated ? (
        !permission || permission === role ? (
          children
        ) : (
          <Redirect to={{ pathname: '/oops', state: { from: location } }} />
        )
      ) : (
        <Redirect to={{ pathname: '/login', state: { from: location } }} />
      )
    }
  />
);

const LoginRoute = ({ isAuthenticated, children }) => (
  <Route
    render={({ location }) =>
      isAuthenticated ? (
        <Redirect to={{ pathname: '/', state: { from: location } }} />
      ) : (
        children
      )
    }
  />
);

const Page = ({ title, children }) => {
  useEffect(() => {
    if (title) document.title = title;
  }, [title]);
  return children;
};

export default RootSwitch;
