import React from 'react';
import styled from 'styled-components';

const EncounterBox = ({ borderRadius, padding, children }) => (
  <Box borderRadius={borderRadius || '10px'} padding={padding || '30px'}>
    {children}
  </Box>
);

const Box = styled.div`
  background-color: white;
  border-radius: ${props => props.borderRadius};
  box-shadow: 0 3px 12px rgba(${props => props.theme.colors.shadowRGB}, 0.4);
  padding: ${props => props.padding};
`;

export default EncounterBox;
