import { UPDATE_VACCINE } from '../App/api/medical';

export const VACCINE_FORM = {
  fields: [
    {
      id: 'covidVaccine',
      type: 'select',
      config: {
        options: [
          'Unspecified',
          'Fully Vaccinated (both doses)',
          'Partially Vaccinated (only 1 dose)',
          'Not Vaccinated',
        ],
      },
      label: 'Have you received the COVID vaccine?',
    },
    {
      id: 'covidBoosters',
      type: 'scale',
      label: 'Have you received any vaccine boosters? If so, how many?',
      config: {
        min: 0,
        max: 4,
      },
      defaultValue: 0,
    },
    {
      id: 'covidPositive',
      type: 'scale',
      label: 'Have you ever tested positive for COVID? If so how many times?',
      config: {
        min: 0,
        max: 5,
      },
      defaultValue: 0,
    },
  ],
  mutation: UPDATE_VACCINE,
  maxWidth: '640px',
};
