import gql from 'graphql-tag';
import { VITAL_FRAGMENT } from './vitals';
import { INTAKE_FRAGMENT } from './intake';
import { SYMPTOM_FRAGMENT } from './symptoms';
import { EXAM_FRAGMENT } from './exams';
import { CAREPLAN_FRAGMENT } from './careplan';

// PATIENT ENCOUNTERS (current & historical)

/**
 * FRAGMENTS
 */
const encounterFragments = {
  basics: gql`
    fragment EncounterBasics on Encounter {
      ama {
        careDescription
        sent
        completed
        revoked
      }
      diagnosis {
        clinicalCode
        display
      }
      emsAction {
        contactMethod
        emergency
        resolve
      }
      location
      patient
      period {
        start
        end
      }
      pharmacy {
        name
        address {
          line
          city
          state
          postalCode
        }
        phone
        fax
        ncpdp
      }
    }
  `,
  history: gql`
    fragment EncounterHistory on Encounter {
      _id
      patient
      period {
        start
        end
      }
      diagnosis {
        clinicalCode
        display
      }
      location
      notes
    }
  `,
  diagnosis: gql`
    fragment EncounterDiagnosis on Encounter {
      _id
      diagnosis {
        clinicalCode
        display
      }
    }
  `,
  notes: gql`
    fragment EncounterNotes on Encounter {
      _id
      reason
      notes
    }
  `,
  emsAction: gql`
    fragment EmsAction on Encounter {
      _id
      emsAction {
        contactMethod
        emergency
        resolve
      }
    }
  `,
  amaAction: gql`
    fragment AmaAction on Encounter {
      _id
      ama {
        careDescription
        sent
        completed
        revoked
      }
    }
  `,
  careTeam: gql`
    fragment CareTeam on Call {
      careline {
        firstName
        lastName
        photo
      }
      physician {
        firstName
        lastName
        photo
      }
    }
  `,
};

export const ENCOUNTER_FRAGMENTS = encounterFragments;

/**
 * QUERIES
 */

export const GET_MEDICAL_HISTORY = gql`
  query GET_MEDICAL_HISTORY($patient: String!) {
    encounters(query: { patient: $patient, location_ne: "BetterMedics" }) {
      ...EncounterHistory
    }
  }
  ${encounterFragments.history}
`;

export const GET_BM_ENCOUNTERS = gql`
  query GET_BM_ENCOUNTERS($patient: String!) {
    encounters(query: { patient: $patient, location: "BetterMedics" }) {
      ...EncounterHistory
    }
  }
  ${encounterFragments.history}
`;

export const GET_ENCOUNTER_DIAGNOSIS = gql`
  query GET_ENCOUNTER_DIAGNOSIS($id: ObjectId!) {
    encounter(query: { _id: $id }) {
      ...EncounterDiagnosis
    }
  }
  ${encounterFragments.diagnosis}
`;

export const GET_ENCOUNTER_NOTES = gql`
  query GET_ENCOUNTER_NOTES($id: ObjectId!) {
    encounter(query: { _id: $id }) {
      ...EncounterNotes
    }
  }
  ${encounterFragments.notes}
`;

export const GET_EMS_ACTION = gql`
  query GET_EMS_ACTION($id: ObjectId!) {
    encounter(query: { _id: $id }) {
      ...EmsAction
    }
  }
  ${encounterFragments.emsAction}
`;

export const GET_ENCOUNTER_ROLLUP = gql`
  query GET_ENCOUNTER_ROLLUP($encounter: ObjectId!) {
    encounter(query: { _id: $encounter }) {
      ...EncounterBasics
      ...intakeFields
    }
    vitals(query: { encounter: $encounter }) {
      ...vitalFields
    }
    symptoms(query: { encounter: $encounter }) {
      ...symptomFields
    }
    careplans(query: { encounter: $encounter }) {
      ...careplanFields
    }
    exams(query: { encounter: $encounter }) {
      ...examFields
    }
    careteam: call(query: { encounter: $encounter }) {
      ...CareTeam
    }
  }
  ${encounterFragments.basics}
  ${encounterFragments.careTeam}
  ${INTAKE_FRAGMENT}
  ${VITAL_FRAGMENT}
  ${SYMPTOM_FRAGMENT}
  ${EXAM_FRAGMENT}
  ${CAREPLAN_FRAGMENT}
`;

/**
 * MUTATIONS
 */

export const ADD_TO_MEDICAL_HISTORY = gql`
  mutation ADD_TO_MEDICAL_HISTORY(
    $id: String!
    $period: EncounterPeriodInsertInput!
    $diagnosis: [EncounterDiagnosisInsertInput!]
    $location: String!
    $notes: String = ""
  ) {
    insertOneEncounter(
      data: {
        patient: $id
        period: $period
        diagnosis: $diagnosis
        location: $location
        notes: $notes
      }
    ) {
      ...EncounterHistory
    }
  }
  ${encounterFragments.history}
`;

export const UPDATE_MEDICAL_HISTORY = gql`
  mutation UPDATE_MEDICAL_HISTORY(
    $id: ObjectId!
    $period: EncounterPeriodUpdateInput!
    $diagnosis: [EncounterDiagnosisUpdateInput!]
    $location: String!
    $notes: String = ""
  ) {
    updateOneEncounter(
      query: { _id: $id }
      set: {
        period: $period
        diagnosis: $diagnosis
        location: $location
        notes: $notes
      }
    ) {
      ...EncounterHistory
    }
  }
  ${encounterFragments.history}
`;

// USED FOR PHYSICIAN ASSESSMEENT
export const UPDATE_ENCOUNTER_DIAGNOSIS = gql`
  mutation UPDATE_ENCOUNTER_DIAGNOSIS(
    $id: ObjectId!
    $diagnosis: [EncounterDiagnosisUpdateInput!]
  ) {
    updateOneEncounter(query: { _id: $id }, set: { diagnosis: $diagnosis }) {
      ...EncounterDiagnosis
    }
  }
  ${encounterFragments.diagnosis}
`;

export const UPDATE_ENCOUNTER_NOTES = gql`
  mutation UPDATE_ENCOUNTER_NOTES(
    $id: ObjectId!
    $notes: String = ""
    $reason: String = ""
  ) {
    updateOneEncounter(
      query: { _id: $id }
      set: { notes: $notes, reason: $reason }
    ) {
      ...EncounterNotes
    }
  }
  ${encounterFragments.notes}
`;

export const REMOVE_FROM_MEDICAL_HISTORY = gql`
  mutation REMOVE_FROM_MEDICAL_HISTORY($id: ObjectId!) {
    deleteOneEncounter(query: { _id: $id }) {
      _id
    }
  }
`;

export const UPDATE_EMS_ACTION = gql`
  mutation UPDATE_EMS_ACTION(
    $id: ObjectId!
    $contactMethod: String
    $emergency: String
    $resolve: String
  ) {
    updateOneEncounter(
      query: { _id: $id }
      set: {
        emsAction: {
          contactMethod: $contactMethod
          emergency: $emergency
          resolve: $resolve
        }
      }
    ) {
      ...EmsAction
    }
  }
  ${encounterFragments.emsAction}
`;
