import React from 'react';
import styled from 'styled-components';

const FormHeader = ({ icon, label, title }) => (
  <Container>
    <FormHeaderWrapper>
      {icon ? <FormHeaderIcon>{icon}</FormHeaderIcon> : null}
      <FormHeaderContent>
        {label ? <FormHeaderLabel>{label}</FormHeaderLabel> : null}
        {title ? <FormHeaderTitle>{title}</FormHeaderTitle> : null}
      </FormHeaderContent>
    </FormHeaderWrapper>
  </Container>
);

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  padding-top: 40px;
`;

const FormHeaderWrapper = styled.div`
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  padding: 10px;
`;

const FormHeaderIcon = styled.div`
  background-color: ${props => props.theme.backgroundColors.lightAlt};
  width: 50px;
  height: 50px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;

  svg {
    font-size: 24px;
    color: ${props => props.theme.colors.blue};
  }
`;

const FormHeaderContent = styled.div`
  flex: 1;
  border-left: 1px solid ${props => props.theme.colors.border};
  padding: 0 20px;
`;

const FormHeaderLabel = styled.h5`
  margin: 0;
  color: ${props => props.theme.colors.blue};
  font-size: 10px;
  font-weight: ${props => props.theme.fontWeights.medium};
  text-transform: uppercase;
  letter-spacing: 0.2px;
`;

const FormHeaderTitle = styled.h3`
  margin: 3px 0 0;
  font-size: 18px;
  font-weight: ${props => props.theme.fontWeights.medium};
`;

export default FormHeader;
