import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import {
  FaUserMd,
  FaUserNurse,
  FaHospital,
  FaBookMedical,
  FaMedkit,
  FaMoneyBill,
  FaAmbulance,
} from 'react-icons/fa';
import { MdLocalPharmacy, MdErrorOutline } from 'react-icons/md';
import EmergencyContactCard from './EmergencyContactCard';
import Grid from '../../common/Grid';
import Loader from '../../common/Loader';
import { useEventListener } from '../../../hooks/useEventListener';
import {
  decodeAddress,
  decodeEmergencyContacts,
} from '../../../hooks/useFormHelper';
import {
  capitalize,
  formatToPhone,
  formatEMS,
  formatHospital,
  formatPharmacy,
  formatPayer,
} from '../../../hooks/useTextHelper';

import {
  CASE_MANAGER_FORM,
  DME_FORM,
  EMERGENCY_CONTACT_FORM,
  EMS_PATIENT_FORM,
  HOSPITAL_FORM,
  PHARMACY_FORM,
  PHYSICIAN_FORM,
  PAYER_FORM,
} from '../../../../forms/careteam';
import { GET_CARE_TEAM } from '../../../api/careteam';

const formatPerson = (person, mode) => {
  if (!person) {
    return `No ${mode === 'physician' ? 'Physician' : 'Case Manager'} listed.`;
  }

  const { firstName, lastName, phone } = person;

  return `${capitalize(firstName)} ${capitalize(lastName)}<br>${formatToPhone(
    phone,
  )}`;
};

const ChartingOnboardingCareTeam = ({ patient }) => {
  const { loading, error, data, refetch } = useQuery(GET_CARE_TEAM, {
    variables: { patient: patient.userId },
  });

  const [config, setConfig] = useState();

  const buildLayout = useCallback(() => {
    // data is returned from useQuery, setup cards for grid layout with personal patient info filled in
    const {
      patient: {
        emergencyContacts,
        generalPractitioner,
        caseManager,
        pharmacy,
        hospital,
        medicalSupplier,
        payer,
        ems,
      },
    } = data;

    const layout = {
      emergencyContact: {
        targetId: patient.userId,
        form: EMERGENCY_CONTACT_FORM,
        formHeader: {
          title: 'Emergency Contacts',
          icon: <FaBookMedical />,
          label: 'Care Team',
        },
        data: decodeEmergencyContacts(emergencyContacts),
        columnSize: '1/3',
        custom: <EmergencyContactCard data={emergencyContacts} />,
      },
      physician: {
        targetId: patient.userId,
        form: PHYSICIAN_FORM,
        formHeader: {
          title: 'Primary Care Physician',
          icon: <FaUserMd />,
          label: 'Care Team',
        },
        data: {
          ...generalPractitioner,
          ...decodeAddress(
            generalPractitioner ? generalPractitioner.address : null,
          ),
        },
        summary: {
          title: 'Physician',
          subtitle: formatPerson(generalPractitioner, 'physician'),
          icon: <FaUserMd />,
        },
      },
      caseManager: {
        targetId: patient.userId,
        label: 'Case Manager',
        form: CASE_MANAGER_FORM,
        formHeader: {
          title: 'Case Manager',
          icon: <FaUserNurse />,
          label: 'Care Team',
        },
        data: caseManager,
        summary: {
          title: 'Case Manager',
          subtitle: formatPerson(caseManager, 'caseManager'),
          icon: <FaUserNurse />,
        },
      },
      hospital: {
        targetId: patient.userId,
        form: HOSPITAL_FORM,
        formHeader: {
          title: 'Preferred Hospital',
          icon: <FaHospital />,
          label: 'Care Team',
        },
        data: { hospital },
        summary: {
          title: 'Hospital',
          subtitle: formatHospital(hospital),
          icon: <FaHospital />,
        },
      },
      ems: {
        targetId: patient.userId,
        form: EMS_PATIENT_FORM,
        formHeader: {
          title: 'EMS',
          icon: <FaAmbulance />,
          label: 'Care Team',
        },
        data: { ems },
        summary: {
          title: 'EMS',
          subtitle: formatEMS(ems),
          icon: <FaAmbulance />,
        },
      },
      pharmacy: {
        targetId: patient.userId,
        form: PHARMACY_FORM,
        formHeader: {
          title: 'Pharmacy',
          icon: <MdLocalPharmacy />,
          label: 'Care Team',
        },
        data: { pharmacy },
        summary: {
          title: 'Pharmacy',
          subtitle: formatPharmacy(pharmacy),
          icon: <MdLocalPharmacy />,
        },
      },
      dme: {
        targetId: patient.userId,
        form: DME_FORM,
        formHeader: {
          title: 'Preferred Medical Supplier',
          icon: <FaMedkit />,
          label: 'Care Team',
        },
        data: { dme: medicalSupplier },
        summary: {
          title: 'Medical Supplier',
          subtitle: formatHospital(medicalSupplier),
          icon: <FaMedkit />,
        },
      },
      payer: {
        targetId: patient.userId,
        form: PAYER_FORM,
        formHeader: {
          title: 'Payer',
          icon: <FaMoneyBill />,
          label: 'Insurance Provider',
        },
        data: payer,
        summary: {
          title: 'Payer',
          subtitle: formatPayer(payer),
          icon: <FaMoneyBill />,
        },
      },
    };

    setConfig({ cards: layout });
  }, [data, patient.userId]);

  // when useQuery comes back, data is available and can be processed
  useEffect(() => {
    if (data) buildLayout();
  }, [data, patient, buildLayout]);

  const refreshList = () => {
    refetch();
  };

  useEventListener('refreshListData', refetch);

  return (
    <Container>
      {loading ? (
        <CenterWrapper>
          <Loader size="small" activity transparent />
        </CenterWrapper>
      ) : config ? (
        <Grid config={config} />
      ) : error ? (
        <CenterWrapper>
          <ErrorIcon>
            <MdErrorOutline />
          </ErrorIcon>
          <ErrorMessage>{error.message}</ErrorMessage>
          <TryAgain onClick={refreshList}>Try Again</TryAgain>
        </CenterWrapper>
      ) : null}
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  width: 100%;
  border-top: 1px solid ${props => props.theme.colors.border};
`;

const CenterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const ErrorIcon = styled.div`
  svg {
    font-size: 42px;
    color: ${props => props.theme.textColors.tertiary};
  }
`;

const ErrorMessage = styled.p`
  margin: 20px 0 50px;
  color: ${props => props.theme.textColors.tertiary};
  text-align: center;
`;

const TryAgain = styled.span`
  color: ${props => props.theme.colors.blue};
  font-size: 15px;
  margin-top: 20px;
  cursor: pointer;
`;

export default ChartingOnboardingCareTeam;
