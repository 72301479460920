import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import { useApolloClient, useQuery } from '@apollo/client';
import { parseISO, lightFormat } from 'date-fns';
import parse from 'html-react-parser';
import { FaCheck, FaHospital, FaTimes } from 'react-icons/fa';
import { capitalize, formatToPhone } from '../../hooks/useTextHelper';
import { UPDATE_EMS_ACTION, GET_EMS_ACTION } from '../../api/encounters';
import { CallContext } from '../../contexts/CallContext';
import useAmplitude from '../../hooks/useAmplitude';

const parseEMS = ems => {
  if (!ems) return '[ Not provided ]';

  let str = '';

  if (ems.primaryDispatch) str += ems.primaryDispatch;
  if (ems.dispatchNumber) str += `<br>${formatToPhone(ems.dispatchNumber)}`;
  if (ems.otherNumber) str += `<br>${formatToPhone(ems.otherNumber)}`;
  if (ems.secondaryDispatch) {
    str += `<br><br>${ems.secondaryDispatch}`;
    str += `<br>${ems.secondaryDispatchNumber}`;
  }
  return str;
};

const EMSModal = ({ onDismiss }) => {
  const {
    state: { homeStatus, patient, encounter },
  } = useContext(CallContext);

  const client = useApolloClient();
  const { track } = useAmplitude();

  const [formData, setFormData] = useState({
    contactMethod: 'patient',
    emergency: '',
    resolve: '',
  });
  const [saving, setSaving] = useState(false);
  const [focused, setFocused] = useState();
  const [valid, setValid] = useState(false);

  const { data } = useQuery(GET_EMS_ACTION, {
    variables: { id: encounter },
  });

  const onFieldChange = (field, value) => {
    setFormData((prevState, props) => {
      return {
        ...prevState,
        [field]: value,
      };
    });
  };

  const onSave = async () => {
    setSaving(true);

    await client.mutate({
      mutation: UPDATE_EMS_ACTION,
      variables: {
        id: encounter,
        ...formData,
      },
    });

    setSaving(false);

    track(
      'EMS Form Completed',
      { 'Call Placed By': capitalize(formData?.contactMethod) },
      true,
    );

    onDismiss();
  };

  useEffect(() => {
    const isValid =
      formData.contactMethod !== '' &&
      formData.emergency !== '' &&
      formData.resolve !== '';

    setValid(isValid);

    if (data) {
      if (data.encounter.emsAction) {
        setFormData(data.encounter.emsAction);
      }
    }
  }, [formData, data]);

  return (
    <Container>
      <LeftColumn>
        <Info>
          <InfoHeader>
            <InfoIcon>
              <FaHospital />
            </InfoIcon>
          </InfoHeader>

          <InfoContent>
            <Item>
              <strong>EMS</strong>
              {parse(parseEMS(patient.ems))}
            </Item>
            <Item>
              <strong>Hospital</strong> {patient.hospital?.name}
              <br />
              {patient.hospital?.address?.line[0]}
            </Item>
            <Item>
              <strong>Patient Date of Birth</strong>
              {lightFormat(parseISO(patient.birthDate), 'M/d/yyyy')}
            </Item>
            <Item>
              <strong>Patient Phone</strong>
              {formatToPhone(patient.telecom?.home)}
            </Item>
            <Item>
              <strong>Patient Address</strong> {patient.address?.home?.line[0]}
              {patient.address?.home?.line[1]}
              <br />
              {patient.address?.home?.city}, {patient.address?.home?.state}{' '}
              {patient.address?.home?.postalCode}
            </Item>
            {patient.address?.home?.notes !== '' ? (
              <Item>
                <strong>Directions to Door</strong>{' '}
                {patient.address?.home?.notes}
              </Item>
            ) : null}
            <Item>
              <strong>Home Status</strong> {homeStatus || '[ Not provided ]'}
            </Item>
            <Item>
              <strong>Power of Attorney</strong> {patient.poa ? 'Yes' : 'No'}
            </Item>
          </InfoContent>
        </Info>
      </LeftColumn>
      <RightColumn>
        <FormWrapper>
          <Title>EMS Action Form</Title>
          <Fieldset active>
            <Label>Contact Method</Label>
            <Options>
              <OptionBtn
                active={formData.contactMethod === 'patient'}
                onClick={() => onFieldChange('contactMethod', 'patient')}>
                Patient / Family
              </OptionBtn>
              <OptionBtn
                active={formData.contactMethod === 'bettermedics'}
                onClick={() => onFieldChange('contactMethod', 'bettermedics')}>
                BetterMedics
              </OptionBtn>
            </Options>
          </Fieldset>
          <Fieldset active={focused === 'emergency'}>
            <Label>Description of Emergency</Label>
            <Textarea
              defaultValue={formData.emergency}
              onChange={e => onFieldChange('emergency', e.target.value)}
              onBlur={() => setFocused()}
              onFocus={() => setFocused('emergency')}
            />
          </Fieldset>
          <Fieldset active={focused === 'resolve'}>
            <Label>Description of Resolve</Label>
            <Textarea
              defaultValue={formData.resolve}
              onChange={e => onFieldChange('resolve', e.target.value)}
              onBlur={() => setFocused()}
              onFocus={() => setFocused('resolve')}
            />
          </Fieldset>
        </FormWrapper>
        <FormFooter valid={valid}>
          <SaveBtn disabled={!valid} onClick={onSave}>
            <FaCheck />
          </SaveBtn>
        </FormFooter>
      </RightColumn>
      <CloseBtn onClick={onDismiss}>
        <FaTimes />
      </CloseBtn>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  background-color: ${props => props.theme.backgroundColors.secondary};
  display: flex;
  flex-direction: row;
  max-height: 540px;
  overflow: hidden;
`;

const LeftColumn = styled.div`
  position: relative;
  z-index: 3;
`;

const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Title = styled.h2`
  margin: 0 0 30px;
  font-size: 22px;
  color: ${props => props.theme.textColors.primary};
  text-align: center;
`;

const FormWrapper = styled.div`
  flex: 1;
  overflow-y: scroll;
  padding: 30px 30px 0;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: white;
  border-radius: 0 20px 20px 0;
  width: 280px;
  height: 100%;
  overflow-y: scroll;
`;

const InfoHeader = styled.div`
  padding: 30px 30px 0;
`;

const InfoIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  background-color: ${props => props.theme.colors.warning};
  border: 2px solid ${props => props.theme.colors.warning};
  border-radius: 50%;
  box-shadow: inset 0 0 0 2px white;
  margin: 0 auto;

  svg {
    color: white;
    font-size: 24px;
  }
`;

const InfoContent = styled.div`
  flex: 1;
  padding: 30px;
`;

const Item = styled.div`
  font-size: 14px;
  line-height: 1.5;
  color: ${props => props.theme.textColors.secondary};
  margin-bottom: 25px;

  strong {
    font-weight: ${props => props.theme.fontWeights.bold};
    font-size: 11px;
    text-transform: uppercase;
    display: block;
    margin-bottom: 1px;
    color: ${props => props.theme.textColors.tertiary};
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const Options = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const OptionBtn = styled.button`
  display: block;
  margin: 0 0 0 5px;
  background-color: ${props => (props.active ? '#FFF8F7' : 'white')};
  border-radius: 10px;
  border: 2px solid;
  border-color: ${props =>
    props.active ? props.theme.colors.warning : props.theme.colors.border};
  padding: 15px 8px;
  outline: none;
  flex: 1;
  color: ${props =>
    props.active
      ? props.theme.colors.warning
      : props.theme.textColors.tertiary};

  font-size: 11px;
  font-weight: ${props => props.theme.fontWeights.bold};
  text-transform: uppercase;
  letter-spacing: 0.7px;
  cursor: pointer;

  box-shadow: ${props =>
    props.active
      ? `inset 0 0 0 3px ${props.theme.backgroundColors.secondary}`
      : 'none'};

  &:first-child {
    margin-right: 5px;
    margin-left: 0;
  }
`;

const Fieldset = styled.fieldset`
  text-align: center;
  width: 100%;
  padding: 0;
  margin-bottom: 30px;

  opacity: ${props => (props.active ? 1 : 0.8)};
`;

const Label = styled.label`
  color: ${props => props.theme.textColors.primary};
`;

const Textarea = styled.textarea`
  resize: none;
  width: 100%;
  font-size: 14px;
  padding: 12px 14px;
`;

const CloseBtn = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  border: 0;
  outline: none;
  background-color: transparent;
  opacity: 0.5;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }

  svg {
    font-size: 20px;
    color: ${props => props.theme.textColors.tertiary};
    opacity: 0.8;
  }
`;

const FormFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  margin-left: -25px;
  margin-right: -25px;
  padding: 15px;
  background-color: ${props =>
    props.valid ? 'rgba(255, 255, 255, 0.4)' : 'rgba(255, 255, 255, 0.2)'};
`;

const SaveBtn = styled.button`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.colors.warning};
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  border: none;
  outline: none;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};

  svg {
    color: white;
    font-size: 18px;
  }
`;

export default EMSModal;
