import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useCallback,
} from 'react';
import styled, { keyframes } from 'styled-components';
import { lighten, darken } from 'polished';
import { useApolloClient } from '@apollo/client';
import { FaCheck, FaTimes } from 'react-icons/fa';
import { MdArrowBack } from 'react-icons/md';
import ActivityIndicator from '../common/ActivityIndicator';
import Checkbox from './Checkbox';
import FormHeader from './FormHeader';
import Field from './Field';
import PreferredProvider from './PreferredProvider';
import Alert from '../common/Alert';
import Loader from '../common/Loader';
import ModalDialog from '../modals/ModalDialog';
import {
  formatInitialData,
  runFormValidation,
} from '../../hooks/useFormHelper';

import { AlertContext } from '../../contexts/AlertContext';
import { LogContext } from '../../contexts/LogContext';
import { ModalContext } from '../../contexts/ModalContext';

const Form = ({
  targetId,
  form, // { fields, mutation, maxWidth, minHeight, appendData, extraData }
  data, // form's initial data
  actionType, // 'add', 'edit'
  headerConfig, // { icon, title, label }
  inline, // optional: inline editing used on cards when editing single field only
  onFormSave, // optional:  extra fn to be called when form has saved
  onClose, // optional: if form has a close/back button
  onDelete, // optional: when record is deleted
  autosave, // optional: saves form onBlur (when diff from onFocus value)
  onAutoSave, // optional: hits function when saving
  stickyFooter, // optional: boolean from scrollSpy to say when bottom has been reached
  scrollForm, // optional: when parent container expects form to scroll itself
  inModal, // if form appears in modal (assists with deleting record)
  isDisabled = false, // if form should lock for any reason
}) => {
  const firstFieldRef = useRef();
  const isInitialMount = useRef(true);
  const autosaveFieldVal = useRef();

  const client = useApolloClient();

  const { dispatch: dispatchAlert } = useContext(AlertContext);
  const {
    state: { current: currentLog },
    dispatch: dispatchLog,
  } = useContext(LogContext);
  const { dispatch: dispatchModal } = useContext(ModalContext);

  const [formData, setFormData] = useState();
  const [formOption, setFormOption] = useState(
    form && form.option ? form.option.value : null,
  );
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState();

  // powered by forms.js -- required mutation(s) query (as defined by: api/*)
  const onSave = async autosaveFieldObj => {
    if (form.validation) {
      // run form validation before submitting
      const valid = runFormValidation(formData, form.validation);
      if (!valid.status) {
        setError(valid.message);
        return;
      }
    }

    // pass formData to server
    setSaving(true);

    // encodeData (forms.js) -- optional field for an encoding function to map values to server format (see: hooks/useFormHelper)
    const finalFormData = form.encodeData
      ? form.encodeData(formData)
      : formData;

    // when passing in the form, you can add extraData to pass any additional fields on mutation
    let appendData = form.extraData || {};
    if (appendData.hasOwnProperty('datetime')) appendData.datetime = new Date();
    const variables = {
      id: targetId,
      ...appendData,
      ...finalFormData,
      ...autosaveFieldObj,
    };

    // Hack for medication on REQUEST FORM (not to be confused with normal 'medications' - plural)
    if (variables.medication && Array.isArray(variables.medication)) {
      variables.medication = { link: [...variables.medication] };
    }

    console.log('variables: ', variables);

    try {
      if (form.precursor) {
        // if precursor is in form object (forms.js) call that mutation first
        const { data } = await client.mutate({
          mutation: form.precursor.mutation,
          variables,
        });

        // then pass that data to the form's save mutation
        await client.mutate({
          mutation: form.mutation,
          variables: {
            id: targetId,
            [form.precursor.saveVariable]:
              data[form.precursor.returnVariable]._id,
          },
        });
      } else {
        // otherwise just save form data to form's save mutation
        await client.mutate({
          mutation: form.mutation,
          variables,
        });
      }

      if (onAutoSave) onAutoSave();
      if (onFormSave) {
        // actionType: 'add' | 'edit' ... was form adding to list or updating record
        // formOption: optional second save (e.g. update address on permanent record)
        onFormSave({ actionType, ...variables }, formOption);
      }

      // save log audit info
      dispatchLog({
        type: 'SET_LOG',
        data: {
          ...currentLog,
          after: finalFormData,
          timestamp: new Date(),
        },
      });
    } catch (err) {
      const msg =
        err.message.indexOf('NoMatchingRuleFound') !== -1
          ? 'Error: You do not have permissions to complete this action.'
          : 'Server Error: Data could not be saved.';

      dispatchAlert({
        type: 'ADD_ALERT',
        data: {
          message: msg,
        },
      });

      console.log(err);
    }

    // hide loader
    setSaving(false);

    // close the edit view after save completes
    if (!autosave) {
      closeForm(true);
    }
  };

  const closeClick = e => {
    // prevent secondary click actions in Card.js
    e.stopPropagation();
    closeForm();
  };

  const closeForm = saved => {
    // wipe audit log clean for next adjustment
    dispatchLog({ type: 'CLEAR_CURRENT_LOG' });
    // tell parent to close form and indicate if save took place
    onClose(saved);
  };

  const onChange = (field, value) => {
    // console.log('onChange: ', field, value);
    setFormData((prevState, props) => {
      return {
        ...prevState,
        [field]: value,
      };
    });
  };

  const onOverwrite = value => {
    setFormData(value);
  };

  const onUnset = async () => {
    if (form.unset) {
      try {
        await client.mutate({
          mutation: form.unset,
          variables: {
            id: targetId,
          },
        });
        closeForm(true);
      } catch (err) {
        console.log('Unset failed:  ', err);
      }
    }
  };

  const onFocus = value => {
    autosaveFieldVal.current = value;
  };

  const onBlur = (id, value, forceSave) => {
    // forceSave comes from fields such as SearchSelector
    // where focus/blur is not available
    if (forceSave || autosaveFieldVal.current !== value) {
      const autosaveFieldObj = forceSave ? { [id]: value } : null;
      onSave(autosaveFieldObj);
    }
  };

  const onConfirmDelete = () => {
    // save log audit info
    dispatchLog({
      type: 'SET_LOG',
      data: {
        action: 'delete',
        before: currentLog ? currentLog.before : null,
        timestamp: new Date(),
      },
    });
    dispatchLog({ type: 'CLEAR_CURRENT_LOG' });

    // report back to parent
    onDelete();
  };

  const onShowDeleteConfirm = useCallback(() => {
    if (inModal) {
      setShowConfirmDelete(true);
    } else {
      dispatchModal({
        type: 'ADD_MODAL',
        data: {
          component: ModalDialog,
          props: {
            message:
              'Are you sure you want to delete this record? Doing so will remove it permanently.',
            cancelLabel: 'Cancel',
            confirmLabel: 'Delete',
            maxWidth: '400px',
            borderRadius: '10px',
          },
          onConfirm: onConfirmDelete,
        },
      });
    }
  }, [inModal]);

  /******************************
   *   CONDITIONAL FORM FIELD
   ******************************/

  const conditionalCheck = obj => {
    switch (obj.condition) {
      case 'equals':
        return obj.value.includes(formData[obj.property]);
      case 'isNot':
        return !obj.value.includes(formData[obj.property]);
      case 'exists':
        return formData[obj.property] && formData[obj.property] !== null;
      default:
        return true;
    }
  };

  const checkConditionals = check => {
    if (Array.isArray(check)) {
      for (let i = 0; i < check.length; i++) {
        const current = conditionalCheck(check[i]);
        if (!current) return false;
      }
      return true;
    }

    return conditionalCheck(check);
  };

  /***********************
   *   INIT FORM DATA
   ***********************/

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;

      const cleanedData = formatInitialData(data);

      // setup previous state in audit log
      const initLogData = data ? { before: cleanedData } : {};
      dispatchLog({
        type: 'SET_CURRENT_LOG',
        data: {
          ...initLogData,
          action: data ? 'edit' : 'add',
        },
      });

      setFormData(cleanedData);

      if (firstFieldRef.current) firstFieldRef.current.focus();
    }
  }, []);

  const buildForm = useCallback(
    (fields, data, startIndex) => {
      return fields.map((field, index) => {
        const conditionMet = field.conditional
          ? checkConditionals(field.conditional)
          : true;

        // only add conditional fields if condition has been met
        return conditionMet ? (
          field.type === 'header' ? (
            // if type = header, display section header instead of form field
            <SectionHeader key={`header-${field.label}`}>
              {field.label ? <SectionTitle>{field.label}</SectionTitle> : null}
              <SectionDivider noLabel={!field.label} />
            </SectionHeader>
          ) : field.type === 'notice' ? (
            <Fieldset key={`notice-${field.id}`}>
              <Notice>{field.text}</Notice>
            </Fieldset>
          ) : field.type === 'row' ? (
            // if type = row, run nested through this function again
            <FieldRow key={field.id} fill={field.fill ? 'true' : null}>
              {buildForm(field.fields, data, index)}
            </FieldRow>
          ) : field.type === 'divider' ? (
            <Divider
              key={field.id}
              fillColor={field.form ? field.form.fillColor : null}
            />
          ) : field.type === 'preferredProvider' &&
            form.extraData &&
            form.extraData.patient ? (
            <PreferredProvider
              key={field.id}
              mode={field.mode}
              patient={form.extraData.patient}
            />
          ) : (
            <Field
              key={field.id}
              ref={startIndex + index === 0 ? firstFieldRef : null}
              value={field.type === 'physicianLookup' ? data : data[field.id]}
              onChange={onChange}
              onOverwrite={onOverwrite}
              onFocus={autosave ? onFocus : null}
              onBlur={autosave ? onBlur : null}
              subcategoryOptions={
                field.form && field.form.subcategory
                  ? data[field.form.subcategory]
                  : null
              } // used for <Selector> subcategory options
              isDisabled={isDisabled}
              {...field}
            />
          )
        ) : null;
      });
    },
    [form, formData, checkConditionals],
  );

  return (
    <Container>
      {error ? (
        <ErrorWrapper>
          <Alert message={error} removeAlert={() => setError()} showClose />
        </ErrorWrapper>
      ) : null}
      <FormWrapper scroll={scrollForm} maxWidth={form.maxWidth}>
        {headerConfig ? <FormHeader {...headerConfig} /> : null}
        {formData ? (
          <>
            <FormContent
              inline={inline}
              minHeight={form.minHeight}
              stickyFooter={stickyFooter}>
              {buildForm(form.fields, formData, 0)}

              {form.option ? (
                <Fieldset>
                  <Checkbox
                    checked={formOption}
                    onChange={() => setFormOption(!formOption)}>
                    {form.option.label}
                  </Checkbox>
                </Fieldset>
              ) : null}
            </FormContent>
            {autosave ? null : (
              <Footer inline={inline} sticky={stickyFooter}>
                {onDelete ? (
                  <DeleteButton onClick={onShowDeleteConfirm}>
                    <FooterBtnLabel>Delete Record</FooterBtnLabel>
                  </DeleteButton>
                ) : null}
                {inline ? (
                  <>
                    <SquareBtn inverse onClick={closeClick}>
                      <FaTimes />
                    </SquareBtn>
                    <SquareBtn onClick={() => onSave()}>
                      {saving ? <Loader activity white /> : <FaCheck />}
                    </SquareBtn>
                  </>
                ) : (
                  <>
                    {form && form.unset ? (
                      <FooterBtn clear onClick={onUnset}>
                        <FooterBtnLabel>Clear Record</FooterBtnLabel>
                      </FooterBtn>
                    ) : null}
                    <FooterBtn inverse onClick={closeClick}>
                      <MdArrowBack />
                      <FooterBtnLabel>Back</FooterBtnLabel>
                    </FooterBtn>
                    <FooterBtn onClick={() => onSave()}>
                      <FooterBtnLabel>Save</FooterBtnLabel>
                    </FooterBtn>
                  </>
                )}
                {showConfirmDelete ? (
                  <ConfirmDelete>
                    <p>
                      Are you sure you want to delete this record? Doing so will
                      remove it permanently.
                    </p>
                    <FooterBtn onClick={() => setShowConfirmDelete(false)}>
                      Cancel
                    </FooterBtn>
                    <DeleteButton onClick={onConfirmDelete}>
                      Yes, Delete
                    </DeleteButton>
                  </ConfirmDelete>
                ) : null}
              </Footer>
            )}
          </>
        ) : null}
      </FormWrapper>
      {!inline && saving ? (
        autosave ? (
          <AutosaveIndicator>
            <AutosaveLabel>Saving</AutosaveLabel>
            <ActivityIndicator size={16} color="#9A9FAE" />
          </AutosaveIndicator>
        ) : (
          <SavingContainer>
            <Loader size="small" transparent />
          </SavingContainer>
        )
      ) : null}
    </Container>
  );
};

const slideUp = keyframes`
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
`;

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const FormWrapper = styled.div`
  // for forms that should fill 100% height
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  ${({ scroll }) =>
    scroll &&
    `
  overflow-y: scroll;
  `}

  > div {
    max-width: ${props => props.maxWidth || 'initial'};
  }
`;

const FormContent = styled.div`
  width: 100%;
  padding: 20px 40px;

  min-height: ${props => props.minHeight || 'initial'};

  // for forms that should fill 100% height
  ${({ inline, minHeight }) =>
    (inline || minHeight) && {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    }}

  ${({ stickyFooter }) =>
    stickyFooter &&
    `
    padding-bottom: 80px;
  `}
`;

const FieldRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  ${({ fill }) =>
    fill &&
    `
    > fieldset {
      flex: 1;
    }
  `}
`;

const Fieldset = styled.fieldset`
  flex: ${props => (props.flexFill ? '1' : 'initial')};
`;

const FooterBtnLabel = styled.span`
  font-family: ${props => props.theme.fonts.secondary};
  font-size: 16px;
  font-weight: ${props => props.theme.fontWeights.medium};
  letter-spacing: 0.2px;
`;

const FooterBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background-color: ${props =>
    props.inverse ? 'transparent' : props.theme.colors.blue};
  height: 52px;
  padding: 0 30px;
  margin-left: 12px;
  border: 0;
  outline: none;
  cursor: pointer;

  svg {
    margin: 0 5px 0 -5px;
    font-size: 18px;
    color: ${props =>
      props.inverse ? props.theme.textColors.tertiary : 'white'};
  }

  &:hover {
    background-color: ${props =>
      props.inverse
        ? props.theme.backgroundColors.lightAlt
        : lighten(0.05, props.theme.colors.blue)};

    ${FooterBtnLabel} {
      color: ${props =>
        props.inverse || props.clear ? props.theme.colors.blue : 'white'};
    }

    svg {
      color: ${props => props.theme.colors.blue};
    }
  }

  ${FooterBtnLabel} {
    color: ${props =>
      props.inverse || props.clear ? props.theme.textColors.tertiary : 'white'};
  }

  ${({ clear, theme }) =>
    clear &&
    `
    margin-right: auto;
    background-color: ${theme.backgroundColors.lightAlt};

    &:hover {
      background-color: ${darken(0.04, theme.backgroundColors.lightAlt)};
    }
  `}
`;

const SquareBtn = styled(FooterBtn)`
  height: 44px;
  width: 44px;
  border-radius: 15px;
  background-color: ${props => (props.inverse ? 'transparent' : 'white')};
  border: 2px solid;
  border-color: ${props => (props.inverse ? 'white' : 'transparent')};

  padding: 0;

  svg {
    margin: 0;
    color: ${props => (props.inverse ? 'white' : props.theme.colors.blue)};
  }

  &:hover {
    border-width: 4px;
    background-color: ${props => (props.inverse ? 'transparent' : 'white')};

    svg {
      font-size: 21px;
      color: ${props => (props.inverse ? 'white' : props.theme.colors.blue)};
    }
  }
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: ${props => (props.inline ? '16px' : '24px')};
  background-color: ${props =>
    props.inline ? props.theme.colors.blue : 'white'};
  transform: translateY(100%);
  animation-name: ${slideUp};
  animation-delay: 0.1s;
  animation-duration: 0.3s;
  animation-iteration-count: 1;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  margin: 0;
  margin-top: auto;
  width: 100%;

  border-top: 1px solid;
  border-top-color: ${props =>
    props.sticky ? props.theme.colors.border : 'transparent'};
  transition: border-top-color 0.2s ease-out;

  ${({ inline, sticky }) =>
    !inline &&
    sticky &&
    `
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 220;
    max-width: 100% !important;
  `}
`;

const DeleteButton = styled(FooterBtn)`
  margin-right: auto;
  background-color: white;
  cursor: pointer;

  border: 2px solid ${props => props.theme.colors.primary};

  ${FooterBtnLabel} {
    color: ${props => props.theme.colors.primary};
  }

  &:hover {
    background-color: ${props => props.theme.colors.primary};

    ${FooterBtnLabel} {
      color: white;
    }
  }
`;

const SavingContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 200;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.7);
`;

const AutosaveIndicator = styled.div`
  position: sticky;
  left: 50%;
  bottom: 30px;
  z-index: 5;
  transform: translateX(-50%);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: 1px solid ${props => props.theme.colors.border};
  padding: 6px 12px;
  border-radius: 25px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
`;

const AutosaveLabel = styled.span`
  font-size: 13px;
  font-weight: ${props => props.theme.fontWeights.bold};
  color: ${props => props.theme.textColors.secondary};
  margin-right: 5px;
`;

const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  margin: 30px 0;

  &:first-of-type {
    margin-top: 0;
  }
`;

const SectionTitle = styled.h2`
  font-size: 18px;
  color: ${props => props.theme.textColors.secondary};
  margin: 0;
`;

const SectionDivider = styled.div`
  flex: 1;
  background-color: ${props => props.theme.backgroundColors.tertiary};
  height: 3px;
  border-radius: 20px;
  margin-left: ${props => (props.noLabel ? '0' : '20px')};
`;

const Notice = styled.p`
  font-size: 12px;
  color: ${props => props.theme.textColors.tertiary};
`;

const ConfirmDelete = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  background-color: ${props => props.theme.colors.warning};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 20px;

  p {
    color: white;
    font-size: 15px;
    line-height: 1.4;
    padding-right: 20px;
  }

  ${FooterBtn} {
    border: 2px solid white;
    background-color: transparent;
    color: white;
  }

  ${DeleteButton} {
    background-color: white;
    color: ${props => props.theme.colors.warning};
    margin-right: 0;
  }
`;

const Divider = styled.div`
  display: block;
  width: 100%;
  height: 1px;
  background-color: ${props => props.fillColor || props.theme.colors.border};
  margin: 20px 0 30px;
`;

const ErrorWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 50;
`;

export default Form;
