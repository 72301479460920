import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';
import useAmplitude from '../../../hooks/useAmplitude';
import { formatToPhone } from '../../../hooks/useTextHelper';
import { DISCONNECT_REASON } from '../../../api/rooms';
import EndScreenConfirmBtn from './EndScreenConfirmBtn';
import Field from '../../forms/Field';
import { DISCONNECT_OPTIONS } from '../../../../constants';
import { CallContext } from '../../../contexts/CallContext';

const EndScreenDisconnected = ({ onComplete }) => {
  const { track } = useAmplitude();

  const {
    state: { patient, room },
    dispatch: dispatchCall,
  } = useContext(CallContext);

  const [reason, setReason] = useState();
  const [notes, setNotes] = useState();

  const [disconnectReason] = useMutation(DISCONNECT_REASON, {
    onCompleted() {
      onComplete();

      dispatchCall({
        type: 'SET_CALL',
        data: {
          status: 'ended',
        },
      });
    },
    onError(err) {
      console.log('Error with Disconnect Reason: ', err);
    },
  });

  const saveReason = () => {
    disconnectReason({ variables: { id: room, reason, notes } });

    track('Call Marked Incomplete', { Reason: reason }, true);
  };

  return (
    <Wrapper>
      <PhoneNotice>Call the patient back on the number below:</PhoneNotice>
      <PhoneNumber>{formatToPhone(patient.telecom.home)}</PhoneNumber>
      <OptionsGrid>
        {DISCONNECT_OPTIONS.map(option => (
          <OptionBtn
            key={option.id}
            active={reason === option.id}
            onClick={() => setReason(option.id)}>
            {option.label}
          </OptionBtn>
        ))}
      </OptionsGrid>
      <ReasonForDisconnect>
        <Field
          id="notes"
          type="textarea"
          label="Notes"
          value={notes}
          onChange={(id, value) => setNotes(value)}
          config={{
            resize: 'none',
          }}
        />
      </ReasonForDisconnect>
      <EndScreenConfirmBtn
        onClick={saveReason}
        disabled={!reason}
        btnWidth="200px">
        Save
      </EndScreenConfirmBtn>
    </Wrapper>
  );
};

const PhoneNumber = styled.div`
  font-size: 24px;
  font-weight: ${props => props.theme.fontWeights.bold};
  text-align: center;
  margin-bottom: 50px;
  color: ${props => props.theme.textColors.primary};
`;

const PhoneNotice = styled.p`
  margin-top: 0;
  margin-bottom: 25px;
`;

const ReasonForDisconnect = styled.fieldset`
  padding: 0 0 20px;

  label {
    text-align: center;
  }
`;

const OptionsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  gap: 10px;
  padding-bottom: 30px;
`;

const OptionBtn = styled.button`
  outline: none;
  background-color: white;
  border: 1px solid ${props => props.theme.colors.border};
  border-radius: 24px;
  color: ${props => props.theme.textColors.secondary};
  font-weight: ${props => props.theme.fontWeights.bold};
  cursor: pointer;

  height: 42px;
  padding: 0 20px;
  font-size: 13px;

  &:hover {
    color: ${props => props.theme.textColors.primary};
    background-color: ${props => props.theme.backgroundColors.lightAlt};
  }

  ${({ theme, active }) =>
    active &&
    `
    background-color: ${theme.colors.blue};
    color: white;
    border-color: ${theme.colors.blue};

    &:hover {
      color: white;
      background-color: ${theme.colors.blue};
    }
  `}
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 10px;
  width: 100%;

  p {
    text-align: center;
    font-size: 18px;
    margin-bottom: 40px;
  }
`;

export default EndScreenDisconnected;
