export const REQUEST_FORM = {
  fields: [
    {
      id: 'medications-row',
      type: 'row',
      fields: [
        {
          id: 'type',
          type: 'select',
          config: {
            options: [
              'General Request',
              'Communication Request',
              'DME',
              'Environmental/Safety',
              'Food/Meals',
              'Update from Previous Visit',
            ],
          },
          label: 'Type of Request',
        },
        {
          id: 'urgency',
          type: 'urgencyMeter',
          label: 'Urgency',
        },
      ],
    },
    {
      id: 'medication',
      type: 'medications',
      label: 'Medication(s)',
      conditional: {
        property: 'type',
        condition: 'equals',
        value: 'Rx Refill',
      },
    },
    {
      id: 'pharmacy',
      type: 'pharmacyFinder',
      label: 'Pharmacy Location',
      conditional: {
        property: 'type',
        condition: 'equals',
        value: 'Rx Refill',
      },
    },
    {
      id: 'medicalSupply',
      type: 'preferredProvider',
      mode: 'medicalSupplier',
      conditional: {
        property: 'type',
        condition: 'equals',
        value: 'DME',
      },
    },
    {
      id: 'description',
      type: 'textarea',
      label: 'Notes',
    },
  ],
  maxWidth: '640px',
};
