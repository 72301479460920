import React from 'react';
import styled, { keyframes } from 'styled-components';
import {
  FaBatteryEmpty,
  FaBatteryFull,
  FaBatteryHalf,
  FaBatteryQuarter,
  FaBatteryThreeQuarters,
} from 'react-icons/fa';

const BatteryMeter = ({ title, value, color, size, ipad }) => (
  <Container value={value} title={title} color={color} size={size} ipad={ipad}>
    {value === 3 ? (
      <FaBatteryFull />
    ) : value === 2 ? (
      <FaBatteryThreeQuarters />
    ) : value === 1 ? (
      <FaBatteryHalf />
    ) : value === 0 ? (
      <FaBatteryQuarter />
    ) : (
      <FaBatteryEmpty />
    )}
  </Container>
);

const blink = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  51% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
`;

const Container = styled.div`
  display: block;
  position: absolute;
  top: 20px;
  right: 20px;
  opacity: 1;

  animation-name: ${props => (props.value <= 0 ? blink : '')};
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;

  svg {
    font-size: ${props => props.size || '18px'};
    color: ${props =>
      props.color
        ? props.color
        : props.value > 1
        ? props.theme.colors.green
        : props.value === 1
        ? props.theme.colors.tertiary
        : props.theme.colors.primary};
  }

  ${({ ipad }) =>
    ipad &&
    `
    position: relative;
    top: 0;
    right: 0;
  `}
`;

export default BatteryMeter;
