import React, { useEffect, useCallback, useState, useRef } from 'react';
import styled from 'styled-components';

const Scale = ({
  id,
  value,
  min,
  max,
  onChange,
  onAutosave,
  disabled = false,
}) => {
  const isInitialMount = useRef(true);

  const [active, setActive] = useState(value);
  const [options, setOptions] = useState([]);

  const onSelect = useCallback(
    num => {
      setActive(num);
      onChange(num);

      if (onAutosave) onAutosave(id, num, true);
    },
    [id, onChange, onAutosave, setActive],
  );

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;

      let arr = [];
      for (let i = min; i < max + 1; i++) {
        arr.push(i);
      }
      setOptions(arr);

      if (value) onSelect(value);
    }
  }, [max, min, value, onSelect]);

  return (
    <Wrapper disabled={disabled}>
      {options.map(num => (
        <Option
          key={`option-${num}`}
          onClick={() => onSelect(num)}
          active={num.toString() === active.toString()}>
          {num}
        </Option>
      ))}
    </Wrapper>
  );
};

const Option = styled.button`
  position: relative;
  width: 51px;
  height: 51px;
  background-color: ${props =>
    props.active ? props.theme.colors.blue : 'white'};
  border: none;
  outline: none;
  font-family: ${props => props.theme.fonts.secondary};
  font-size: 16px;
  font-weight: ${props =>
    props.active ? props.theme.fontWeights.bold : 'inherit'};
  color: ${props => (props.active ? 'white' : props.theme.textColors.primary)};
  cursor: pointer;
  pointer-events: ${props => (props.active ? 'none' : 'auto')};
  border-right: 1px solid;
  border-right-color: ${props =>
    props.active ? props.theme.colors.blue : props.theme.colors.border};

  box-shadow: ${props =>
    props.active ? 'inset 0 0px 2px 1px rgba(0, 0, 0, 0.14)' : 'none'};

  &:hover {
    background-color: ${props => props.theme.backgroundColors.lightAlt};
  }

  &:last-child {
    border-right-color: transparent;
  }
`;

const Wrapper = styled.div`
  display: inline-flex;
  border-radius: 6px;
  overflow: hidden;
  border: 1px solid ${props => props.theme.colors.border};

  ${({ disabled }) =>
    disabled &&
    `
    ${Option} {
      pointer-events: none;
      cursor: default;
      opacity: 0.9;
    }
  `}
`;

export default Scale;
