import React, { useMemo, useContext } from 'react';
import styled from 'styled-components';
import { darken } from 'polished';
import { useQuery } from '@apollo/client';
import { format, parseISO, formatDistanceStrict } from 'date-fns';
import parse from 'html-react-parser';
import { MdInfoOutline } from 'react-icons/md';
import EncounterDetailsPopover from '../popovers/EncounterDetailsPopover';
import EncounterTextLogPopover from '../popovers/EncounterTextLogPopover';
import { GET_PATIENT_INFO } from '../../api/patients';
import { PopoverContext } from '../../contexts/PopoverContext';
import { SystemContext } from '../../contexts/SystemContext';
import {
  camelToReadable,
  formatAddress,
  formatName,
} from '../../hooks/useTextHelper';
import UserIcon from '../common/UserIcon';

const EncounterHeader = ({
  encounterId,
  patientId,
  encounterAddress,
  encounterPhone,
  period,
  careline,
  physician,
  ems,
  ama,
}) => {
  const { dispatch: dispatchPopover } = useContext(PopoverContext);
  const {
    state: { realmUser },
  } = useContext(SystemContext);
  const { loading, data } = useQuery(GET_PATIENT_INFO, {
    variables: { userId: patientId },
  });

  const duration = useMemo(() => {
    if (!period?.end) return null;

    const formatDuration = ({ start, end }, unit) => {
      return formatDistanceStrict(parseISO(start), parseISO(end), { unit });
    };

    const minutes = formatDuration(period, 'minute');

    // report call duration in seconds for calls less than one minute
    if (minutes === '0 minutes') {
      return formatDuration(period);
    }

    return minutes;
  }, [period]);

  const showTextLogPopover = event => {
    dispatchPopover({
      type: 'ADD_POPOVER',
      data: {
        component: EncounterTextLogPopover,
        props: {
          encounter: encounterId,
          bounds: event.target.getBoundingClientRect(),
          force: { right: true },
        },
      },
    });
  };

  const openDetails = (event, details) => {
    // prep details into array of obj:  { label: key, value }
    let arr = [];
    Object.keys(details).map(key => {
      // override: display 'bettermedics' value properly
      const value =
        details[key] === 'bettermedics' ? 'BetterMedics' : details[key];

      if (key !== '__typename') {
        arr.push({
          label: camelToReadable(key),
          value,
        });
      }
    });

    dispatchPopover({
      type: 'ADD_POPOVER',
      data: {
        component: EncounterDetailsPopover,
        props: {
          data: arr,
          bounds: event.target.getBoundingClientRect(),
          force: { right: true },
        },
      },
    });
  };

  const patient = data?.patient;

  return (
    <Header id="Header">
      {loading ? null : (
        <>
          <UserIcon user={patient} size="74px" patient />
          <CallInfo>
            <CallDate>
              <p>
                <strong>
                  {format(parseISO(period.start), "MMM d, yyyy 'at' h:mm aa")}
                </strong>
              </p>
              {duration ? <p>({duration})</p> : null}
            </CallDate>
            <CallInfoRow>
              <CallInfoBlock>
                <p>
                  <strong>Patient Name:</strong> {formatName(patient)}
                </p>
                <p>
                  <strong>Encounter ID:</strong> {encounterId}
                </p>
              </CallInfoBlock>
              <CallInfoBlock>
                <p>
                  <strong>Patient Phone:</strong>{' '}
                  {encounterPhone || patient?.telecom?.home}
                </p>
                <AddressRow>
                  <p>
                    <strong>Patient Address:</strong>
                  </p>
                  <p>
                    {parse(
                      formatAddress(
                        encounterAddress || patient?.address?.home,
                        true,
                      ),
                    )}
                  </p>
                </AddressRow>
              </CallInfoBlock>
              <CallInfoBlock>
                <TextRowWrapper>
                  <div>
                    <p>
                      <strong>Care Line:</strong> {formatName(careline)}
                    </p>
                    <p>
                      <strong>Physician:</strong> {formatName(physician, true)}
                    </p>
                  </div>
                  {realmUser?.customData?.role === 'admin' ? (
                    <TextLogBtn
                      onClick={showTextLogPopover}
                      title="View Text Log">
                      <MdInfoOutline />
                    </TextLogBtn>
                  ) : null}
                </TextRowWrapper>
              </CallInfoBlock>
            </CallInfoRow>
          </CallInfo>
          <Actions>
            <ActionBadge
              active={ems}
              onClick={ems ? e => openDetails(e, ems) : null}>
              EMS
            </ActionBadge>
            <ActionBadge
              active={ama}
              onClick={ama ? e => openDetails(e, ama) : null}>
              AMA
            </ActionBadge>
          </Actions>
        </>
      )}
    </Header>
  );
};

const Header = styled.div`
  display: flex;
  flex-direction: row;
  background-color: white;
  box-shadow: 0 3px 12px rgba(226, 228, 240, 0.4);
  border-radius: 10px;
  padding: 16px 20px;
  min-height: 106px;
`;

const CallDate = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 0 10px;

  p {
    margin: 0 10px 0 0;

    &:last-of-type {
      font-size: 0.9rem;
    }
  }
  strong {
    font-weight: bold;
  }
`;

const CallInfo = styled.div`
  flex: 1;
  margin-left: 12px;
`;

const CallInfoRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const CallInfoBlock = styled.div`
  flex: 1;

  p {
    font-size: 12px;
    color: ${props => props.theme.textColors.primary};
    margin: 0 0 5px;

    strong {
      font-weight: bold;
      margin-right: 4px;
    }

    &:last-of-type {
      margin: 0;
    }
  }
`;

const TextRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TextLogBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  padding: 5px;
  cursor: pointer;
  svg {
    color: ${props => props.theme.colors.blue};
    font-size: 16px;
  }
`;

const AddressRow = styled.div`
  display: flex;
  margin-top: 5px;

  p:first-of-type {
    margin-right: 4px;
  }
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ActionBadge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 40px;
  border-radius: 10px;

  letter-spacing: 0.2px;
  font-size: 11px;
  font-weight: bold;
  color: ${props => props.theme.colors.border};
  margin-left: 10px;

  border: 2px solid ${props => props.theme.backgroundColors.lightAlt};
  pointer-events: none;

  ${({ active, theme }) =>
    active &&
    `
		border: none;
		background-color: ${theme.colors.tertiary};
		color: white;
    pointer-events: all;
    cursor: pointer;

    &:hover {
      background-color: ${darken(0.05, theme.colors.tertiary)};
    }
	`}
`;

export default EncounterHeader;
