import React, { useRef, useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import { darken } from 'polished';
import { useMutation } from '@apollo/client';
import parse from 'html-react-parser';
import { FaChevronDown } from 'react-icons/fa';
import { formatDistanceToNow, parseISO } from 'date-fns';
import { removeScriptTags } from '../../hooks/useFormHelper';
import { formatName, convertLineBreaks } from '../../hooks/useTextHelper';
import UserIcon from '../common/UserIcon';
import ContextPopover from '../popovers/ContextPopover';
import { PopoverContext } from '../../contexts/PopoverContext';
import { UPDATE_COMMENT, DELETE_COMMENT } from '../../api/requests';

const ACTIONS = [
  { id: 'edit', label: 'Edit Comment' },
  { id: 'delete', label: 'Delete Comment' },
];

const RequestModalComment = ({ data, onChange }) => {
  console.log('comment: ', data);

  const noteRef = useRef();

  const { dispatch: dispatchPopover } = useContext(PopoverContext);
  const [editMode, setEditMode] = useState(false);
  const [note, setNote] = useState(data.comment);
  const [contextOpen, setContextOpen] = useState(false);

  const [deleteComment] = useMutation(DELETE_COMMENT, {
    onCompleted() {
      // after delete
      onChange();
    },
  });

  const [updateComment] = useMutation(UPDATE_COMMENT, {
    onCompleted() {
      // after edit
      setEditMode(false);
      onChange();
    },
  });

  const onSaveEdit = () => {
    updateComment({
      variables: {
        id: data._id,
        date: new Date(),
        comment: note,
      },
    });
  };

  const onCancelEdit = () => {
    setEditMode(false);
    setNote(data.comment);
  };

  const onContextAction = id => {
    if (id === 'delete') {
      deleteComment({ variables: { id: data._id } });
    } else if (id === 'edit') {
      setEditMode(true);
    }
  };

  const openContextMenu = e => {
    e.preventDefault();

    setContextOpen(true);

    dispatchPopover({
      type: 'ADD_POPOVER',
      data: {
        component: ContextPopover,
        props: {
          actions: ACTIONS,
          bounds: e.target.getBoundingClientRect(),
          force: { right: true },
          onAction: onContextAction,
        },
        onDismiss: () => setContextOpen(false),
      },
    });
  };

  useEffect(() => {
    if (editMode) {
      noteRef.current.focus();
      noteRef.current.selectionStart = noteRef.current.selectionEnd = 100000;
    }
  }, [editMode]);

  return (
    <Comment>
      <UserIcon size="38px" user={data.author} inline />
      <CommentMsg>
        <AuthorLine>
          <AuthorName>{formatName(data.author)}</AuthorName>
          <CommentDate>{formatDistanceToNow(parseISO(data.date))}</CommentDate>
        </AuthorLine>
        {editMode ? (
          <Fieldset active={editMode}>
            <textarea
              ref={noteRef}
              value={note}
              onChange={e => setNote(removeScriptTags(e.target.value))}
              rows={3}
            />
            <Actions>
              <CommentBtn inverse onClick={onCancelEdit}>
                Cancel
              </CommentBtn>
              <CommentBtn disabled={note.length < 1} onClick={onSaveEdit}>
                Save changes
              </CommentBtn>
            </Actions>
          </Fieldset>
        ) : (
          <CommentBody>{parse(convertLineBreaks(data.comment))}</CommentBody>
        )}
      </CommentMsg>
      <OptionsBtn active={contextOpen} onClick={openContextMenu}>
        <FaChevronDown />
      </OptionsBtn>
    </Comment>
  );
};

const OptionsBtn = styled.span`
  display: ${props => (props.active ? 'flex' : 'none')};
  position: absolute;
  top: 10px;
  right: 10px;
  border: 0;
  outline: none;
  cursor: pointer;
  background-color: transparent;
  width: 20px;
  height: 24px;
  border-radius: 3px;
  justify-content: center;
  align-items: center;

  svg {
    color: ${props => props.theme.textColors.secondary};
    font-size: 12px;
    line-height: 1;
    opacity: 0.7;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.02);

    svg {
      opacity: 1;
    }
  }
`;

const Fieldset = styled.div`
  display: flex;
  flex-direction: column;
  height: 140px;
  border: 1px solid ${props => props.theme.colors.tertiary};
  border-radius: 10px;
  overflow: hidden;
  background-color: white;
  flex: 1;
  margin-top: 5px;

  textarea {
    resize: none;
    font-size: 15px;
    border: none;
    outline: none;
    width: 100%;
    transition: all 0.17s ease-in;
  }
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 6px 12px 8px;
`;

const CommentBtn = styled.button`
  background-color: ${props =>
    props.inverse ? 'white' : props.theme.colors.blue};
  border: ${props =>
    props.inverse ? `1px solid ${props.theme.colors.border}` : 'none'};
  border-radius: 4px;
  color: ${props =>
    props.inverse ? props.theme.textColors.secondary : 'white'};
  font-size: 12px;
  cursor: pointer;
  height: 32px;
  padding: 0 15px;
  margin-left: 6px;

  &:hover {
    background-color: ${props =>
      props.inverse ? '#EDEDED' : darken(0.05, props.theme.colors.blue)};
  }
`;

const Comment = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 10px 20px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.02);

    ${OptionsBtn} {
      display: flex;
    }
  }
`;

const CommentMsg = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 16px;
`;

const AuthorLine = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 5px;
`;

const AuthorName = styled.span`
  margin-right: 6px;
  font-size: 13px;
  font-weight: ${props => props.theme.fontWeights.bold};
  color: ${props => props.theme.textColors.primary};
`;

const CommentDate = styled.span`
  font-size: 13px;
  color: ${props => props.theme.textColors.secondary};
`;

const CommentBody = styled.p`
  margin-top: 6px;
  font-size: 14px;
`;

export default RequestModalComment;
