import React, { useState } from 'react';
import styled from 'styled-components';
import { lighten } from 'polished';
import { MdClose } from 'react-icons/md';
import Field from '../forms/Field';

const ModalDialog = ({
  message,
  cancelIcon,
  confirmIcon,
  cancelLabel,
  confirmLabel,
  onConfirm,
  onDismiss,
  cancelColor,
  confirmColor = 'red',
  onBack,
  dark,
  notesField,
  maxWidth,
}) => {
  const [notes, setNotes] = useState('');

  return (
    <Modal dark={dark} maxWidth={maxWidth}>
      <Message>{message}</Message>
      {notesField ? (
        <Field
          id="notes"
          value={notes}
          label={notesField}
          type="textarea"
          onChange={(id, val) => setNotes(val)}
          config={{
            resize: 'none',
          }}
        />
      ) : null}
      <EndButtonRow>
        {onDismiss ? (
          cancelIcon ? (
            <CircleButton onClick={onDismiss}>{cancelIcon}</CircleButton>
          ) : (
            <Button
              color={cancelColor}
              inverse={dark ? false : true}
              onClick={onDismiss}>
              {cancelLabel}
            </Button>
          )
        ) : null}

        {onConfirm ? (
          confirmIcon ? (
            <CircleButton onClick={() => onConfirm(notes)} color={confirmColor}>
              {confirmIcon}
            </CircleButton>
          ) : (
            <Button color={confirmColor} onClick={() => onConfirm(notes)}>
              {confirmLabel}
            </Button>
          )
        ) : null}
      </EndButtonRow>
      {onBack ? (
        <BackBtn dark={dark} onClick={onBack}>
          <MdClose />
        </BackBtn>
      ) : null}
    </Modal>
  );
};

const Modal = styled.div`
  position: relative;
  z-index: 2;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: ${props => (props.dark ? 'rgba(0, 0, 0, 0.6)' : 'white')};
  box-shadow: ${props =>
    props.dark ? 'none' : '0 2px 0px 3px rgba(0, 0, 0, 0.05)'};
  padding: 40px 30px;
  max-width: ${props => props.maxWidth || '400px'};

  p {
    color: ${props => (props.dark ? 'white' : props.theme.textColors.primary)};
  }

  fieldset {
    width: 100%;
    margin-top: 25px;

    label {
      text-align: center;
    }
  }
`;

const Message = styled.p`
  margin: 0;
  color: white;
  text-align: center;
  line-height: 1.5;
`;

const EndButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
`;

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  height: 52px;
  padding: 0 30px;
  border-width: ${props => (props.color === 'inverseRed' ? '2px' : 0)};
  border-style: solid;
  border-color: ${props =>
    props.color === 'inverseRed' ? props.theme.colors.primary : 'transparent'};
  outline: none;
  margin: 0 10px;
  cursor: pointer;

  color: ${props =>
    props.color === 'inverseRed'
      ? props.theme.colors.primary
      : props.inverse
      ? props.theme.textColors.tertiary
      : 'white'};
  font-size: 15px;
  font-family: ${props => props.theme.fonts.secondary};
  font-weight: ${props => props.theme.fontWeights.medium};

  background-color: ${props =>
    props.color === 'red'
      ? props.theme.colors.primary
      : props.color === 'green'
      ? props.theme.colors.green
      : props.inverse || props.color === 'inverseRed'
      ? 'transparent'
      : props.theme.colors.gray};

  &:hover {
    background-color: ${props =>
      props.color === 'red'
        ? lighten(0.05, props.theme.colors.primary)
        : props.color === 'green'
        ? lighten(0.05, props.theme.colors.green)
        : props.inverse
        ? props.theme.backgroundColors.lightAlt
        : lighten(0.05, props.theme.colors.gray)};

    color: ${props =>
      props.color === 'inverseRed'
        ? props.theme.colors.primary
        : props.inverse
        ? props.theme.colors.blue
        : 'white'};
    border-color: ${props =>
      props.color === 'inverseRed'
        ? props.theme.colors.primary
        : 'transparent'};
  }
`;

const CircleButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  padding: 0;
  border-radius: 50%;

  svg {
    color: white;
    font-size: 26px;
  }
`;

const BackBtn = styled.div`
  position: absolute;
  top: -8px;
  left: -8px;
  z-index: 2;
  background-color: ${props =>
    props.dark ? props.theme.colors.gray : 'white'};
  border-width: ${props => (props.dark ? 0 : '2px')};
  border-style: solid;
  border-color: ${props =>
    props.dark ? 'transparent' : props.theme.colors.border};
  width: 32px;
  height: 32px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  svg {
    font-size: 16px;
    color: ${props => (props.dark ? 'white' : props.theme.textColors.tertiary)};
  }

  &:hover {
    background-color: ${props =>
      props.dark
        ? lighten(0.05, props.theme.colors.gray)
        : props.theme.backgroundColors.lightAlt};

    svg {
      color: ${props => (props.dark ? 'white' : props.theme.colors.blue)};
    }
  }
`;

export default ModalDialog;
