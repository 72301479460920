import React, { useState, useContext, useEffect, useRef } from 'react';
import styled from 'styled-components';
import parse from 'html-react-parser';
import { useQuery } from '@apollo/client';
import Checkbox from './Checkbox';
import SearchSelector from './SearchSelector';
import { GET_CARE_TEAM } from '../../api/careteam';
import { CallContext } from '../../contexts/CallContext';
import { formatAddress } from '../../hooks/useTextHelper';
import { lighten } from 'polished';

const PharmacySelector = ({
  id,
  value,
  onChange,
  onAutosave,
  disabled = false,
}) => {
  const isInitialMount = useRef(true);
  const {
    state: { patient },
  } = useContext(CallContext);

  const [initialPharmacyNPI, setInitialPharmacyNPI] = useState();
  const [savePreferred, setSavePreferred] = useState();

  const [editing, setEditing] = useState();
  const [pharmacy, setPharmacy] = useState(value);

  const { loading, error, data } = useQuery(GET_CARE_TEAM, {
    variables: { patient: patient.userId },
  });

  const updatePharmacy = (id, selected) => {
    console.log(selected);
    setPharmacy(selected);
    setEditing(false);

    onChange(selected);

    if (onAutosave) onAutosave(id, selected, true);
  };

  useEffect(() => {
    if (data && isInitialMount.current) {
      const _pharmacy = data.patient.pharmacy;
      setPharmacy(_pharmacy);

      setInitialPharmacyNPI(_pharmacy ? _pharmacy.npi : '');

      isInitialMount.current = false;
    }
  }, [data]);

  return (
    <Container>
      {loading ? null : (
        <Wrapper>
          {editing || !pharmacy ? (
            <>
              <SearchSelector
                id="pharmacy"
                mode="pharmacy"
                onSelect={updatePharmacy}
                disabled={disabled}
              />
              {pharmacy && !disabled ? (
                <Row>
                  <CancelBtn onClick={() => setEditing(false)}>
                    Cancel
                  </CancelBtn>
                </Row>
              ) : null}
            </>
          ) : (
            <Current>
              <FieldBox>
                <PharmacyName>
                  {parse(
                    `<b>${pharmacy.name}</b><br>${formatAddress(
                      pharmacy.address,
                      true,
                    )}<br>${pharmacy.phone}`,
                  )}
                </PharmacyName>
                {disabled ? null : (
                  <EditBtn onClick={() => setEditing(true)}>Edit</EditBtn>
                )}
              </FieldBox>
              {initialPharmacyNPI !== pharmacy.npi ? (
                <Preferred>
                  <Checkbox
                    checked={savePreferred}
                    onChange={() => setSavePreferred(!savePreferred)}
                    disabled={disabled}>
                    Save as preferred pharmacy
                  </Checkbox>
                </Preferred>
              ) : null}
            </Current>
          )}
        </Wrapper>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
`;

const Wrapper = styled.div``;

const Current = styled.div``;

const PharmacyName = styled.p`
  display: block;
  margin: 0;
  font-size: 13px;
  line-height: 1.5;
  color: ${props => props.theme.textColors.secondary};
`;

const Btn = styled.button`
  background: transparent;
  border: none;
  outline: none;
  color: ${props => props.theme.colors.blue};
  font-size: 14px;
  font-weight: ${props => props.theme.fontWeights.bold};
  cursor: pointer;
  margin-left: auto;

  &:hover {
    color: ${props => lighten(0.1, props.theme.colors.blue)};
  }
`;

const EditBtn = styled(Btn)``;

const CancelBtn = styled(Btn)`
  margin-top: 10px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const FieldBox = styled(Row)`
  border: 1px solid ${props => props.theme.colors.border};
  padding: 16px;
  border-radius: 10px;
  font-size: 16px;
  box-sizing: border-box;
  outline: none;
`;

const Preferred = styled.div`
  margin: 10px;
`;

export default PharmacySelector;
