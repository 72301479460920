import gql from 'graphql-tag';

const examFields = gql`
  fragment examFields on Exam {
    _id
    system {
      sctid
      label
    }
    notes
    inspections {
      type
      label
      sctid {
        action
        anatomy
      }
      locations {
        label
        sctid
      }
      selected {
        label
        sctid
      }
    }
  }
`;

export const EXAM_FRAGMENT = examFields;

export const GET_EXAMS = gql`
  query GET_EXAMS($encounter: ObjectId!) {
    exams(query: { encounter: $encounter }) {
      ...examFields
    }
  }
  ${examFields}
`;

export const ADD_EXAM = gql`
  mutation ADD_EXAM(
    $id: ObjectId!
    $patient: String!
    $system: ExamSystemInsertInput
    $inspections: [ExamInspectionInsertInput]
    $notes: String
  ) {
    insertOneExam(
      data: {
        encounter: $id
        patient: $patient
        system: $system
        inspections: $inspections
        notes: $notes
      }
    ) {
      ...examFields
    }
  }
  ${examFields}
`;

export const UPDATE_EXAM = gql`
  mutation UPDATE_EXAM(
    $_id: ObjectId!
    $system: ExamSystemUpdateInput
    $inspections: [ExamInspectionUpdateInput]
    $notes: String
  ) {
    updateOneExam(
      query: { _id: $_id }
      set: { system: $system, inspections: $inspections, notes: $notes }
    ) {
      ...examFields
    }
  }
  ${examFields}
`;

export const DELETE_EXAM = gql`
  mutation DELETE_EXAM($id: ObjectId!) {
    deleteOneExam(query: { _id: $id }) {
      _id
    }
  }
`;
