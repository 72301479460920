// @flow
import React, { createContext, useReducer } from 'react';

const INITIAL_STATE = {};

export const SettingsContext = createContext(INITIAL_STATE);

export default ({ children }) => {
  const reducer = (state, { type, data }) => {
    switch (type) {
      case 'SET_SETTINGS': {
        return {
          ...state,
          ...data,
        };
      }
      case 'CLEAR': {
        return INITIAL_STATE;
      }
      default: {
        return state;
      }
    }
  };

  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <SettingsContext.Provider value={{ state, dispatch }}>
      {children}
    </SettingsContext.Provider>
  );
};
