import { useCallback, useEffect, useRef } from 'react';
import UIfx from 'uifx';
import callConnectAudio from '../../assets/sounds/call-connect.mp3';
import callConnectingAudio from '../../assets/sounds/call-connecting.mp3';
import callDisconnectAudio from '../../assets/sounds/call-disconnect.mp3';
import callEnterAudio from '../../assets/sounds/call-enter.mp3';
import callLeaveAudio from '../../assets/sounds/call-leave.mp3';
import callErrorAudio from '../../assets/sounds/call-error.mp3';
import callNotificationAudio from '../../assets/sounds/call-notification.mp3';
import popAudio from '../../assets/sounds/pop.mp3';
import photoAudio from '../../assets/sounds/photo.wav';

const SOUNDS = {
  connect: {
    file: callConnectAudio,
    ref: null,
  },
  connecting: {
    file: callConnectingAudio,
    ref: null,
  },
  enter: {
    file: callEnterAudio,
    ref: null,
  },
  leave: {
    file: callLeaveAudio,
    ref: null,
  },
  disconnect: {
    file: callDisconnectAudio,
    ref: null,
  },
  error: {
    file: callErrorAudio,
    ref: null,
  },
  notification: {
    file: callNotificationAudio,
    ref: null,
  },
  pop: {
    file: popAudio,
    ref: null,
  },
  photo: {
    file: photoAudio,
    ref: null,
  },
};

// const soundRef = new UIfx(
//   soundFile,
//   {
//     volume: 0.4, // number between 0.0 ~ 1.0
//     throttleMs: 100
//   }
// );

/**
 * HOW TO USE:
 *
 * import useSound from './hooks/useSound';
 * const { playSound } = useSound();
 * playSound('nameOfSound');
 */

const useSound = () => {
  const isInitialMount = useRef(true);

  // currently only one audio can be looping ... could tweak this later
  const loopingAudio = useRef();
  const looping = useRef();

  const playSound = name => SOUNDS[name].ref.play();

  const loopSound = useCallback(name => {
    try {
      looping.current = name;
      loopingAudio.current = new Audio(SOUNDS[name].file);

      // force calling volume to 0.6 (should pass variable if needing to extend the use)
      loopingAudio.current.volume = 0.6;
      loopingAudio.current.addEventListener('ended', repeatLoop);
      loopingAudio.current.play();
    } catch (err) {
      console.log('background audio error: ', err);
      console.log('sound file: ', name);
    }
  }, []);

  const repeatLoop = useCallback(() => {
    loopSound(looping.current);
  }, [loopSound]);

  const stopLoopingSound = useCallback(() => {
    if (loopingAudio.current) {
      looping.current = null;
      loopingAudio.current.removeEventListener('ended', repeatLoop);
    }
  }, [repeatLoop]);

  useEffect(() => {
    const loadAudioTracks = () => {
      Object.keys(SOUNDS).map(key => {
        SOUNDS[key].ref = new UIfx(SOUNDS[key].file);
        return SOUNDS[key];
      });
    };

    if (isInitialMount.current) {
      isInitialMount.current = false;
      loadAudioTracks();
    }

    return () => stopLoopingSound();
  }, [stopLoopingSound]);

  return { playSound, loopSound, stopLoopingSound };
};

export default useSound;
