import React, {
  useRef,
  useEffect,
  useState,
  useCallback,
  useContext,
} from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import { FaBluetoothB } from 'react-icons/fa';
import { FcRefresh } from 'react-icons/fc';
import { GET_VITALS } from '../../api/vitals';
import ActivityIndicator from '../common/ActivityIndicator';
import WithTooltip from '../common/WithTooltip';
import VitalBox from '../vitals/VitalBox';
import { CallContext } from '../../contexts/CallContext';
import { SystemContext } from '../../contexts/SystemContext';

const VITALS = {
  temperature: {
    label: 'Temperature',
    events: {
      connected: 'thermometer-connected',
      disconnected: 'thermometer-disconnected',
      ready: 'thermometer-ready',
    },
  },
  pulseox: {
    label: 'Pulse',
    events: {
      connected: 'pulseox-connected',
      disconnected: 'pulseox-disconnected',
      ready: 'pulseox-ready',
    },
  },
  bp: {
    label: 'Blood Pressure',
    events: {
      connected: 'bp-connected',
      disconnected: 'bp-disconnected',
      ready: 'bp-ready',
    },
  },
};

const ChartingVitals = ({ encounter }) => {
  const isInitialMount = useRef(true);
  let refreshTimer;
  // const [refreshing, setRefreshing] = useState(false);

  const {
    state: { room },
  } = useContext(CallContext);
  const {
    state: { realmUser },
  } = useContext(SystemContext);

  const [vitals, setVitals] = useState(VITALS);

  // when onboarding, encounter is not present, skip query
  const { loading, error, data, refetch } = useQuery(GET_VITALS, {
    variables: { encounter },
    skip: encounter ? false : true,
  });

  // const resetDeviceConnections = useCallback(async () => {
  //   try {
  //     await realmUser.functions.sendPusherEvent(
  //       `presence-${room}`,
  //       'reset-vitals',
  //     );
  //   } catch (err) {
  //     console.log('Error requesting vitals be reset', err);
  //   }
  // }, [room, realmUser]);

  useEffect(() => {
    let _vitals = VITALS;

    // when data returns from server add 'data' object to each VITALS config
    if (data && data.vitals) {
      for (let i = 0; i < data.vitals.length; i++) {
        const vital = data.vitals[i];
        _vitals[vital.type].data = vital;
      }
      setVitals({ ..._vitals });
    }
  }, [data]);

  // const onRefreshClick = () => {
  //   // set all vital boxes back to disconnected
  //   window.dispatchEvent(new Event('disconnectVital'));

  //   // request restart of vital devices via iPad
  //   resetDeviceConnections();

  //   // show spinner for 2s on button so it's not pressed rapidly
  //   setRefreshing(true);
  //   refreshTimer = setTimeout(() => {
  //     setRefreshing(false);
  //   }, 2000);
  // };

  useEffect(() => {
    const getDeviceStatuses = async () => {
      // to setup Physician UI, ask iPad to report current status of devices
      try {
        realmUser.functions.sendPusherEvent(
          `presence-${room}`,
          'get-device-statuses',
        );
      } catch (err) {
        console.log('Pusher error requesting device statuses', err);
      }
    };

    if (isInitialMount.current) {
      isInitialMount.current = false;
      getDeviceStatuses();
    }

    return () => clearTimeout(refreshTimer);
  }, [realmUser, room]);

  return (
    <Container>
      {loading ? (
        <LoadingWrapper>
          <ActivityIndicator />
        </LoadingWrapper>
      ) : (
        <Content>
          <VitalsList>
            {Object.keys(vitals).map(id => (
              <VitalBox
                {...vitals[id]}
                key={id}
                id={id}
                data={vitals[id].data ? vitals[id].data.value : null}
                device={vitals[id].data ? vitals[id].data.device : null}
                vitalId={vitals[id].data ? vitals[id].data._id : null}
                onRefresh={encounter ? refetch : null}
              />
            ))}
          </VitalsList>
          {/* <RefreshBtnWrapper>
            <WithTooltip title="Refresh Device Connections" left>
              <RefreshBtn onClick={onRefreshClick} isRefreshing={refreshing}>
                {refreshing ? (
                  <ActivityIndicator size={18} color="secondary" />
                ) : (
                  <RefreshBtnIcon>
                    <FcRefresh />
                    <FaBluetoothB />
                  </RefreshBtnIcon>
                )}
              </RefreshBtn>
            </WithTooltip>
          </RefreshBtnWrapper> */}
        </Content>
      )}
    </Container>
  );
};

const RefreshBtnWrapper = styled.div`
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 3;
`;

const RefreshBtnIcon = styled.span`
  position: relative;

  svg {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(-50%) translateY(-50%);

    color: ${props => props.theme.textColors.secondary};
    font-size: 16px;

    &:first-child {
      font-size: 30px;

      g {
        fill: ${props => props.theme.textColors.secondary} !important;
        opacity: 0.5;
      }
    }
  }
`;

const RefreshBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid ${props => props.theme.colors.border};
  cursor: pointer;
  outline: none;
  pointer-events: ${props => (props.isRefreshing ? 'none' : 'auto')};

  &:hover {
    background-color: ${props => props.theme.colors.blue};
    border-color: ${props => props.theme.colors.blue};

    ${RefreshBtnIcon} {
      svg {
        color: white;

        &:first-child g {
          fill: white !important;
          opacity: 0.6;
        }
      }
    }
  }
`;

const Container = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

const Content = styled(Container)``;

const VitalsList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  grid-auto-rows: minmax(240px, auto);
  padding: 40px;
`;

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 100%;
  height: 100%;
`;

export default ChartingVitals;
