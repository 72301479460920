// @flow
import React, { useReducer, createContext } from 'react';

export const FormContext = createContext();

/**
 * Form Context.
 *
 * Used in Onboarding to tell Grandparent to open form in main display area.
 * This will open a local <EditModal /> with a Form/List/Table embedded
 *
 * Object structure:
 * @param {Object}    config        form's config object
 * @param {Object}    formData          form's initial data
 *
 */

export default ({ children }) => {
  const reducer = (state, { type, data }) => {
    switch (type) {
      case 'ADD_FORM': {
        return {
          ...state,
          ...data,
        };
      }
      case 'UPDATE_DATA': {
        // used when recent form entry makes a change for a list/table
        return {
          ...state,
          formData: data,
        };
      }
      case 'CLEAR': {
        return null;
      }
      default: {
        return state;
      }
    }
  };

  const [state, dispatch] = useReducer(reducer, null);

  return (
    <FormContext.Provider value={{ state, dispatch }}>
      {children}
    </FormContext.Provider>
  );
};
