import { UPDATE_ADDRESS, UPDATE_PHONE_NUMBER } from '../App/api/intake';
import {
  UPDATE_CLINICAL_STUDY,
  UPDATE_CODE_STATUS,
  UPDATE_DOB,
  UPDATE_GENDER,
  UPDATE_HOME_ADDRESS,
  UPDATE_HOME_PHONE,
  UPDATE_LANGUAGE,
  UPDATE_LIVING_ARRANGEMENT,
  UPDATE_NAME,
  UPDATE_RACE,
  UPDATE_SSN,
} from '../App/api/personal';
import { encodeAddress, encodeSSN } from '../App/hooks/useFormHelper';
import { STATES, RACES, LANGUAGES, CLINICAL_STUDY_GROUPS } from '../constants';

const LOCATION_FIELDS = [
  {
    id: 'street1',
    type: 'text-input',
    label: 'Street',
    map: {
      type: 'array',
      id: 'line',
      num: 0,
    },
  },
  {
    id: 'street2',
    type: 'text-input',
    label: 'Street 2',
    map: {
      type: 'array',
      id: 'line',
      num: 1,
    },
  },
  {
    id: 'address-row',
    type: 'row',
    fields: [
      {
        id: 'city',
        type: 'text-input',
        label: 'City',
        config: {
          fill: true,
        },
      },
      {
        id: 'state',
        type: 'select',
        config: {
          options: STATES,
          minWidth: 'auto',
        },
        label: 'State',
      },
      {
        id: 'postalCode',
        type: 'text-input',
        label: 'Zip',
        config: {
          width: '100px',
        },
      },
    ],
  },
  {
    id: 'notes',
    type: 'textarea',
    label: "Directions to patient's door",
    placeholder: 'Go to third floor, 4th door on the left',
  },
];

export const LOCATION_FORM = {
  fields: LOCATION_FIELDS,
  mutation: UPDATE_HOME_ADDRESS,
  encodeData: encodeAddress,
};

export const TEMP_ADDRESS_FORM = {
  fields: LOCATION_FIELDS,
  option: {
    id: 'updatePermanently',
    label: 'Save as permanent address change',
    value: false,
  },
  mutation: UPDATE_ADDRESS,
  encodeData: encodeAddress,
};

export const PHONE_FORM = {
  fields: [
    {
      id: 'phone',
      type: 'phone-input',
      label: 'Phone Number',
      config: {
        center: true,
      },
    },
  ],
  mutation: UPDATE_HOME_PHONE,
};

export const TEMP_PHONE_FORM = {
  fields: [
    {
      id: 'phone',
      type: 'phone-input',
      label: 'Phone Number',
    },
  ],
  option: {
    id: 'updatePermanently',
    label: 'Save as permanent phone number change',
    value: false,
  },
  mutation: UPDATE_PHONE_NUMBER,
};

export const GENDER_FORM = {
  fields: [
    {
      id: 'gender',
      type: 'radio',
      label: 'Gender',
      config: {
        connected: true,
        center: true,
        options: [
          { id: 'male', label: 'Male' },
          { id: 'female', label: 'Female' },
        ],
      },
    },
  ],
  mutation: UPDATE_GENDER,
};

export const DOB_FORM = {
  fields: [
    {
      id: 'birthDate',
      type: 'dateOfBirth',
      label: 'Date of Birth',
      config: {
        center: true,
      },
    },
  ],
  mutation: UPDATE_DOB,
};

export const LIVING_ARRANGEMENT_FORM = {
  fields: [
    {
      id: 'livingArrangement',
      type: 'textarea',
      label: 'Living Arrangement',
      placeholder:
        'Define the household situation. Who lives with the patient?',
      config: {
        center: true,
      },
    },
  ],
  mutation: UPDATE_LIVING_ARRANGEMENT,
};

export const FAMILY_HISTORY_FORM = {
  fields: [
    {
      id: 'row',
      type: 'row',
      fields: [
        {
          id: 'relationship',
          type: 'select',
          config: {
            options: ['Father', 'Mother', 'Brother', 'Sister', 'Child'],
            fullWidth: true,
          },
          label: 'Relationship',
        },
        {
          id: 'deceased',
          type: 'radio',
          label: 'Living or Deceased',
          config: {
            options: [
              {
                id: false,
                label: 'Living',
              },
              {
                id: true,
                label: 'Deceased',
              },
            ],
          },
        },
      ],
    },
    {
      id: 'conditions',
      type: 'search',
      label: 'Type of Illness',
      config: {
        multiple: true,
      },
    },
  ],
  maxWidth: '640px',
};

export const NAME_FORM = {
  fields: [
    {
      id: 'name-row',
      type: 'row',
      fields: [
        {
          id: 'given',
          type: 'text-input',
          label: 'First Name',
          config: {
            fill: true,
          },
        },
        {
          id: 'family',
          type: 'text-input',
          label: 'Last Name',
          config: {
            fill: true,
          },
        },
      ],
    },
  ],
  mutation: UPDATE_NAME,
};

export const RACES_FORM = {
  fields: [
    {
      id: 'race',
      type: 'select',
      config: {
        options: RACES,
        minWidth: 'auto',
        center: true,
      },
      label: 'Race / Ethnicity',
    },
  ],
  mutation: UPDATE_RACE,
};

export const LANGUAGE_FORM = {
  fields: [
    {
      id: 'language',
      type: 'select',
      config: {
        options: LANGUAGES,
        minWidth: 'auto',
        center: true,
      },
      label: 'Primary Language',
    },
  ],
  mutation: UPDATE_LANGUAGE,
};

export const SSN_FORM = {
  fields: [
    {
      id: 'ssn',
      type: 'social-security',
      label: 'Social Security Number',
      config: {
        center: true,
      },
    },
  ],
  mutation: UPDATE_SSN,
  encodeData: encodeSSN,
};

export const CODE_STATUS_FORM = {
  fields: [
    {
      id: 'codeStatus',
      type: 'select',
      config: {
        options: ['Full Code', 'DNR-CCA', 'DNR-CC'],
        minWidth: 'auto',
        center: true,
      },
      label: 'Code Status',
    },
  ],
  mutation: UPDATE_CODE_STATUS,
};

export const CLINICAL_STUDY_FORM = {
  fields: [
    {
      id: 'clinicalStudy',
      type: 'select',
      config: {
        options: CLINICAL_STUDY_GROUPS,
        minWidth: 'auto',
        center: true,
      },
      label: 'Clinical Study',
    },
  ],
  mutation: UPDATE_CLINICAL_STUDY,
};
