import React from 'react';
import styled from 'styled-components';
import EndScreenConfirmBtn from './EndScreenConfirmBtn';

const EndScreenQuestion = ({ message, yesTxt, noTxt, onYes, onNo }) => (
  <Wrapper>
    <p>{message}</p>
    <Buttons>
      <EndScreenConfirmBtn onClick={onYes}>{yesTxt}</EndScreenConfirmBtn>
      <EndScreenConfirmBtn onClick={onNo} inverse>
        {noTxt}
      </EndScreenConfirmBtn>
    </Buttons>
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 10px;
  width: 100%;

  p {
    text-align: center;
    font-size: 18px;
    margin-bottom: 40px;
  }
`;

const Buttons = styled.div`
  width: 100%;
`;

export default EndScreenQuestion;
