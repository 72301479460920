import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import DatePicker from './DatePicker';
import TimeBlock from './TimeBlock';

const DateTimePicker = ({
  id,
  value,
  onChange,
  onAutosave,
  config,
  disabled = false,
}) => {
  const isInitialMount = useRef(true);

  const [date, setDate] = useState(
    value ? new Date(value) : new Date().setHours(0, 0, 0, 0),
  );
  const [time, setTime] = useState({
    hours: 12,
    minutes: 0,
    pm: false,
  });

  const onDateTimeChange = (property, val) => {
    if (property === 'date') {
      setDate(val);
    }

    const d = property === 'date' ? new Date(val) : new Date(date);
    const h = property === 'time' ? parseInt(val.hours) : time.hours;
    const m = property === 'time' ? parseInt(val.minutes) : time.minutes;
    const pm = property === 'time' ? val.pm : time.pm;
    const add = pm ? 12 : 0;

    if (h) d.setHours(h + add);
    if (m) d.setMinutes(m);

    onChange(d);

    if (onAutosave) onAutosave(id, d, true);
  };

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      return;
    }

    if (value) {
      const d = new Date(value);
      let h = d.getHours();
      const ampm = h > 11;
      if (h > 12) h = h - 12;
      if (h === 0) h = 12;

      setTime({
        hours: h,
        minutes: d.getMinutes(),
        pm: ampm,
      });
    }
  }, [value]);

  return (
    <Row>
      <DatePickerWrapper>
        <DatePicker
          value={date}
          onChange={val => onDateTimeChange('date', val)}
          config={config.datepicker}
          disabled={disabled}
        />
      </DatePickerWrapper>

      <TimeBlock
        value={time}
        onChange={val => onDateTimeChange('time', val)}
        config={config.time || {}}
        disabled={disabled}
      />
    </Row>
  );
};

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const DatePickerWrapper = styled.div`
  padding-right: 24px;
`;

export default DateTimePicker;
