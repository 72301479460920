import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { padDigit } from '../../hooks/useTextHelper';
import Field from './Field';

const DAYS_IN_MONTH = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

const getDaysInMonth = month => {
  if (!month) return 31;
  const m = parseInt(month) - 1;
  return DAYS_IN_MONTH[m];
};

const verifyDOB = birthdate => {
  if (!birthdate) return false;

  // check appropriate length
  const arr = birthdate.split('-');
  if (arr[0].length !== 4 || arr[1].length !== 2 || arr[2].length !== 2) {
    return false;
  }

  // check if any are null
  let emptyCheck = true;
  arr.forEach(date => {
    if (!date || date === 'null') {
      emptyCheck = false;
      return;
    }
  });
  if (!emptyCheck) return false;

  // check appropriate min/max on month
  const month = parseInt(arr[1]);
  if (month < 1 || month > 12) return false;

  // check appropraite min/max on day
  const totalDaysInMonth = getDaysInMonth(month);
  const day = parseInt(arr[2]);
  if (day < 1 || day > totalDaysInMonth) return false;

  return true;
};

const DateOfBirth = ({ id, value, onChange, disabled }) => {
  const isInitialMount = useRef(true);
  const [data, setData] = useState({ day: null, month: null, year: null });

  const onValChange = (field, val) => {
    const newData = { ...data, [field]: val };
    setData(newData);

    const newValue = `${newData.year}-${newData.month}-${newData.day}`;
    const isValid = verifyDOB(newValue);

    onChange(newValue, isValid);
  };

  const onValBlur = (field, val) => {
    if (field === 'year' && val.length !== 4) {
      onValChange(field, null);
    } else if (['day', 'month'].includes(field)) {
      if (val.length === 0) return;
      if (val.length === 1) {
        const paddedVal = padDigit(val);
        onValChange(field, paddedVal);
      }
    }
  };

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;

      if (!value) return;

      const arr = value.split('-');
      const obj = {
        year: arr[0],
        month: arr[1],
        day: arr[2],
      };
      setData(obj);
    }
  }, [value]);

  return (
    <Container>
      <Field
        id="month"
        type="number"
        placeholder="MM"
        value={data.month}
        onChange={onValChange}
        onBlur={onValBlur}
        config={{
          min: 1,
          max: 12,
          maxLength: 2,
        }}
        disabled={disabled}
      />
      <Field
        id="day"
        type="number"
        placeholder="DD"
        value={data.day}
        onChange={onValChange}
        onBlur={onValBlur}
        maxLength={2}
        config={{
          min: 1,
          max: getDaysInMonth(data.month),
          maxLength: 2,
        }}
        disabled={disabled}
      />
      <Field
        id="year"
        type="number"
        placeholder="YYYY"
        value={data.year}
        onChange={onValChange}
        onBlur={onValBlur}
        config={{
          maxLength: 4,
        }}
        disabled={disabled}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;

  > fieldset {
    padding: 0;
    width: 90px;
    min-width: min-content;
  }
`;

export default DateOfBirth;
