import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import { lighten } from 'polished';
import { useQuery, useMutation } from '@apollo/client';
import { FaCheck, FaTimes, FaStar, FaRegStar } from 'react-icons/fa';
import { format, parseISO } from 'date-fns';
import { GET_CARE_TEAM } from '../../api/careteam';
import {
  GET_REQUEST_COMMENTS,
  UPDATE_REQUEST_STATUS,
  UPDATE_STAR_STATUS,
} from '../../api/requests';
import { formatName, formatToPhone } from '../../hooks/useTextHelper';
import UserIcon from '../common/UserIcon';
import RequestModalChatBar from '../requests/RequestModalChatBar';
import RequestModalComment from '../requests/RequestModalComment';

const ContactInfo = ({ type, label, person }) => (
  <PatientRow>
    <PatientLabel>{`${label}:`}</PatientLabel>
    <PatientDetail>
      <span>
        {type === 'physician' ? person.displayName : formatName(person)}
      </span>
      <span>{formatToPhone(person.phone)}</span>
    </PatientDetail>
  </PatientRow>
);

const RequestModal = ({ request, onDismiss }) => {
  const [starred, setStarred] = useState(request.starred || false);

  const { data } = useQuery(GET_CARE_TEAM, {
    variables: { patient: request.patient.userId },
  });

  const { data: dataComments, refetch: refetchComments } = useQuery(
    GET_REQUEST_COMMENTS,
    {
      variables: { id: request._id },
    },
  );

  const comments = useMemo(
    () => (dataComments ? dataComments.requestComments : null),
    [dataComments],
  );

  const [markResolved] = useMutation(UPDATE_REQUEST_STATUS, {
    onCompleted() {
      // now marked as resolved, remove it from list and close modal
      window.dispatchEvent(new Event('refreshRequestData'));
      onDismiss();
    },
  });

  const [updateStarStatus] = useMutation(UPDATE_STAR_STATUS, {
    onCompleted() {
      setStarred(!starred);
      window.dispatchEvent(new Event('refreshRequestData'));
    },
  });

  const markComplete = () => {
    markResolved({ variables: { id: request._id, resolved: true } });
  };

  const onStarClick = () => {
    updateStarStatus({
      variables: {
        id: request._id,
        starred: starred,
      },
    });
  };

  const openPatientProfile = () => {
    window.open(`/patient/${request.patient.userId}`, '_blank');
  };

  return (
    <Container>
      <Header>
        <CompleteBtn onClick={markComplete}>
          <FaCheck /> Mark complete
        </CompleteBtn>
        <StarredBtn onClick={onStarClick}>
          {request?.starred ? <FaStar /> : <FaRegStar />}
        </StarredBtn>
        <CloseBtn onClick={onDismiss}>
          <FaTimes />
        </CloseBtn>
      </Header>
      <Content>
        <PatientInfo onClick={openPatientProfile} title="View Patient Profile">
          <Patient>
            <PhotoWrapper>
              <UserIcon user={request?.patient} size="38px" patient />
            </PhotoWrapper>
            <PatientName>{formatName(request?.patient)}</PatientName>
          </Patient>
          <PatientRow>
            <PatientLabel>Call Date:</PatientLabel>
            <PatientDetail>
              {format(parseISO(request?.datetime), "MMMM d, yyyy 'at' h:mm a")}
            </PatientDetail>
          </PatientRow>
          {data?.patient ? (
            <>
              {data?.patient?.generalPractitioner ? (
                <ContactInfo
                  type="physician"
                  label="Primary Physician"
                  person={data?.patient?.generalPractitioner}
                />
              ) : null}
              {data?.patient?.caseManager ? (
                <ContactInfo
                  label="Case Manager"
                  person={data?.patient?.caseManager}
                />
              ) : null}
            </>
          ) : null}
        </PatientInfo>
        <DetailsWrapper>
          <Title>{request?.description}</Title>
          <Row>
            <Label>Request Type</Label>
            <Detail>{request?.type}</Detail>
          </Row>
          <Row>
            <Label>Priority</Label>
            <Detail urgency={request?.urgency}>{request?.urgency}</Detail>
          </Row>
          <Row>
            <Label>Created By</Label>
            <Detail>
              <Author>
                <UserIcon user={request?.createdBy} size="24px" inline />
                <AuthorName>{formatName(request?.createdBy)}</AuthorName>
              </Author>
            </Detail>
          </Row>
        </DetailsWrapper>
        {comments?.length > 0 ? (
          <ChatLog>
            {comments.map(item => (
              <RequestModalComment
                key={item._id}
                data={item}
                onChange={refetchComments}
              />
            ))}
          </ChatLog>
        ) : null}
      </Content>
      <RequestModalChatBar
        requestId={request?._id}
        onNewComment={refetchComments}
      />
    </Container>
  );
};

const ChatLog = styled.div`
  background-color: ${props => props.theme.backgroundColors.lightAlt};
  padding: 20px 0 80px;
  margin: 0 -30px;
`;

const PatientInfo = styled.div`
  margin: 12px;
  padding: 24px;
  border-radius: 8px;
  background-color: white;
  border: 1px solid ${props => props.theme.colors.border};
  border-bottom-width: 3px;
  cursor: pointer;

  &:hover {
    border-color: ${props => props.theme.colors.tertiary};
  }
`;

const PatientLabel = styled.div`
  font-size: 13px;
  color: ${props => props.theme.textColors.tertiary};
  width: 140px;
  padding-right: 8px;
`;

const PatientDetail = styled.div`
  flex: 1;
  font-size: 13px;
  color: ${props => props.theme.textColors.tertiary};
  margin: 0;

  span {
    display: block;
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const DetailsWrapper = styled.div`
  padding-top: 15px;
  padding-bottom: 25px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 18px 0;
`;

const PatientRow = styled(Row)`
  margin: 15px 0;
  align-items: flex-start;

  &:last-child {
    margin-bottom: 0;
  }
`;

const Label = styled.div`
  font-size: 14px;
  color: ${props => props.theme.textColors.tertiary};
  width: 120px;
  padding-right: 8px;
`;

const Detail = styled.div`
  flex: 1;
  font-size: 14px;
  line-height: 1.4;
  color: ${props =>
    props.urgency === 'high'
      ? props.theme.colors.primary
      : props.urgency === 'medium'
      ? props.theme.colors.tertiary
      : props.urgency === 'low'
      ? props.theme.colors.green
      : props.theme.textColors.primary};
  text-transform: ${props => (props.urgency ? 'capitalize' : 'initial')};
`;

const Title = styled.p`
  font-size: 20px;
  font-weight: ${props => props.theme.fontWeights.medium};
  color: ${props => props.theme.textColors.primary};
  margin-bottom: 36px;
`;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: white;
  height: 600px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 10px 10px 20px;
  border-bottom: 1px solid ${props => props.theme.colors.border};
`;

const CompleteBtn = styled.button`
  display: block;
  background-color: white;
  border: 1px solid ${props => props.theme.colors.border};
  border-radius: 5px;
  font-size: 13px;
  color: ${props => props.theme.textColors.secondary};
  height: 40px;
  padding: 0 15px;
  cursor: pointer;
  margin-right: auto;

  svg {
    font-size: 12px;
    color: ${props => props.theme.textColors.secondary};
    margin-right: 10px;
    margin-top: 1px;
    float: left;
  }

  &:hover {
    color: ${props => props.theme.colors.blue};

    svg {
      color: ${props => props.theme.colors.blue};
    }
  }
`;

const CloseBtn = styled.button`
  outline: none;
  border: none;
  margin-left: 6px;
  background: transparent;
  width: 40px;
  height: 40px;
  cursor: pointer;

  svg {
    font-size: 20px;
    color: ${props => props.theme.textColors.secondary};
  }

  &:hover {
    svg {
      color: ${props => props.theme.textColors.primary};
    }
  }
`;

const Content = styled.div`
  flex: 1;
  overflow-y: scroll;
  padding: 30px 30px 0;
  margin-bottom: 65px;
`;

const Patient = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
`;

const PhotoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
`;

const Author = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const AuthorName = styled.span`
  font-size: 14px;
  color: ${props => props.theme.textColors.primary};
  margin-left: 6px;
`;

const PatientName = styled.div`
  font-size: 16px;
  color: ${props => props.theme.textColors.primary};
`;

const StarredBtn = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;

  svg {
    font-size: 20px;
    color: ${props =>
      props.starred
        ? props.theme.colors.tertiary
        : props.theme.textColors.secondary};
  }

  &:hover {
    svg {
      color: ${props =>
        props.starred
          ? lighten(0.1, props.theme.colors.tertiary)
          : props.theme.colors.tertiary};
    }
  }
`;

export default RequestModal;
