import gql from 'graphql-tag';

const careplanFields = gql`
  fragment careplanFields on Careplan {
    _id
    assignedTo
    condition {
      clinicalCode
      display
    }
    date
    description
    title
    type
  }
`;

export const CAREPLAN_FRAGMENT = careplanFields;

export const GET_CAREPLAN = gql`
  query GET_CAREPLAN($encounter: ObjectId!) {
    careplans(query: { encounter: $encounter }) {
      ...careplanFields
    }
  }
  ${careplanFields}
`;

export const ADD_CAREPLAN = gql`
  mutation ADD_CAREPLAN(
    $id: ObjectId!
    $patient: String
    $assignedTo: String
    $date: DateTime
    $description: String
    $title: String
    $type: String
    $condition: CareplanConditionInsertInput
  ) {
    insertOneCareplan(
      data: {
        encounter: $id
        patient: $patient
        assignedTo: $assignedTo
        date: $date
        description: $description
        title: $title
        type: $type
        condition: $condition
      }
    ) {
      ...careplanFields
    }
  }
  ${careplanFields}
`;

export const UPDATE_CAREPLAN = gql`
  mutation UPDATE_CAREPLAN(
    $_id: ObjectId!
    $assignedTo: String
    $date: DateTime
    $description: String
    $title: String
    $type: String
  ) {
    updateOneCareplan(
      query: { _id: $_id }
      set: {
        assignedTo: $assignedTo
        date: $date
        description: $description
        title: $title
        type: $type
      }
    ) {
      ...careplanFields
    }
  }
  ${careplanFields}
`;

export const ADD_CAREPLAN_DIAGNOSIS = gql`
  mutation UPDATE_CAREPLAN(
    $_id: ObjectId!
    $condition: CareplanConditionUpdateInput
  ) {
    updateOneCareplan(query: { _id: $_id }, set: { condition: $condition }) {
      ...careplanFields
    }
  }
  ${careplanFields}
`;

export const DELETE_CAREPLAN = gql`
  mutation DELETE_CAREPLAN($id: ObjectId!) {
    deleteOneCareplan(query: { _id: $id }) {
      _id
    }
  }
`;

export const DELETE_CONDITION_CAREPLANS = gql`
  mutation DELETE_CONDITION_CAREPLANS(
    $encounter: ObjectId!
    $condition: String!
  ) {
    deleteManyCareplans(
      query: { encounter: $encounter, condition: { clinicalCode: $condition } }
    ) {
      deletedCount
    }
  }
`;
