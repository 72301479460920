import React from 'react';
import styled from 'styled-components';
import UserIcon from './UserIcon';

const ContactCard = ({ info }) => (
  <Card>
    <Column>
      <Photo>
        <UserIcon user={info} size="70px" patient />
      </Photo>
    </Column>
    <Column>
      <Name>{`${info?.name?.given} ${info?.name?.family}`}</Name>
      <Phone>{info?.telecom?.home}</Phone>
    </Column>
  </Card>
);

const Card = styled.div`
  background: white;
  box-shadow: 0 5px 20px 0 rgb(21 27 38 / 8%);
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 20px 10px 10px;
  margin-bottom: 50px;
`;

const Photo = styled.span`
  display: block;
  border-radius: 50%;
  border: 2px solid white;
  width: 74px;
  height: 74px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  align-items: flex-start;
`;

const Name = styled.div`
  font-size: 22px;
  font-weight: bold;
  color: ${props => props.theme.textColors.primary};
`;

const Phone = styled.div`
  font-size: 17px;
  font-weight: bold;
  color: ${props => props.theme.textColors.primary};
  margin-top: 12px;
`;

export default ContactCard;
