import React, { useContext } from 'react';
import styled from 'styled-components';
import Card from './Card';
import { FormContext } from '../../contexts/FormContext';
import { SystemContext } from '../../contexts/SystemContext';

const Grid = ({ config, onClick }) => {
  const { cards, template } = config;
  const { dispatch: dispatchForm } = useContext(FormContext);
  const {
    state: { realmUser },
  } = useContext(SystemContext);

  const onEdit = key => {
    // if onClick override was passed, use it instead
    // used when not wanting the card to load a form/list
    // but would rather the click go to a new page (see: ChartingOnboarding)
    if (onClick) {
      onClick(key);
      return;
    }

    const page = cards[key];

    dispatchForm({
      type: 'ADD_FORM',
      data: {
        formData: page.data,
        config: {
          key,
          targetId: page.targetId,
          form: page.list || page.table || page.form,
          type: page.list || page.table ? 'list' : 'form',
          summary: page.summary,
          formHeader: page.formHeader,
        },
      },
    });
  };

  return (
    <Wrapper template={template}>
      {Object.keys(cards).map(key => {
        const { restrictForRoles } = cards[key];
        const restricted = restrictForRoles
          ? restrictForRoles.includes(realmUser?.customData?.role)
          : false;
        return restricted ? null : (
          <Card key={key} {...cards[key]} onClick={() => onEdit(key)} />
        );
      })}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: ${props => props.template || '1fr 1fr'};
  grid-gap: 20px;
  grid-auto-rows: minmax(240px, auto);
  padding: 40px;
`;

export default Grid;
