import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { FaTimes, FaRunning, FaCheckCircle } from 'react-icons/fa';
import { useMutation } from '@apollo/client';
import { ADD_EXAM, UPDATE_EXAM, DELETE_EXAM } from '../../../api/exams';
import { SYSTEMS } from '../../../../forms/exams';
import {
  createInitialData,
  encodeReviewOfSystem,
} from '../../../hooks/useFormHelper';
import { ExamsContext } from '../../../contexts/ExamsContext';
import ReviewOfSystemsForm from './ReviewOfSystemsForm';

const ReviewOfSystems = ({
  patient,
  encounter,
  system,
  onRefresh,
  onClose,
}) => {
  const { state: exams, dispatch: dispatchExams } = useContext(ExamsContext);

  const [systemName, setSystemName] = useState(system);
  const [formData, setFormData] = useState({});
  const [config, setConfig] = useState();

  const [deleteExam] = useMutation(DELETE_EXAM, {
    onCompleted() {
      // dispatchExams({ type: 'DELETE_EXAM', data: { key: systemName } });
      onBack(true);
    },
  });

  const onDelete = id => {
    deleteExam({ variables: { id } });
  };

  const onBack = refresh => {
    setSystemName('');
    setConfig();

    if (refresh) onRefresh();
  };

  useEffect(() => {
    const systemConfig = SYSTEMS[systemName];
    if (systemConfig) {
      setFormData(exams[systemName] || createInitialData(systemConfig.form));
    }
    setConfig(systemConfig);
  }, [systemName]);

  return (
    <Container>
      <Cover onClick={onClose} />
      <Content>
        <Box>
          <PageWrapper slide={config}>
            <Intro>
              <IntroHeader>
                <IntroIcon>
                  <FaRunning />
                </IntroIcon>
                <IntroTitle>Review of Systems</IntroTitle>
              </IntroHeader>
              <IntroContent>
                <PrimaryWrapper>
                  {Object.keys(SYSTEMS).map(key =>
                    key !== 'constitutional' ? (
                      <SystemBtn
                        key={key}
                        active={exams[key]}
                        onClick={() => setSystemName(key)}>
                        {SYSTEMS[key].label}
                        {exams[key] ? <FaCheckCircle /> : null}
                      </SystemBtn>
                    ) : null,
                  )}
                </PrimaryWrapper>
              </IntroContent>
            </Intro>
            {config ? (
              <FormPage>
                <ReviewOfSystemsForm
                  patient={patient}
                  encounter={encounter}
                  system={config}
                  formData={formData}
                  onBack={onBack}
                />
              </FormPage>
            ) : null}
          </PageWrapper>
          <CloseBtn onClick={onClose}>
            <FaTimes />
          </CloseBtn>
        </Box>
      </Content>
    </Container>
  );
};

const AbsoluteFill = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 50;
`;

const Cover = styled(AbsoluteFill)`
  background-color: white;
  pointer-events: all;
  opacity: 0.8;
`;

const Container = styled(AbsoluteFill)``;

const Content = styled(AbsoluteFill)`
  padding: 3rem;
  width: 100%;
  height: 100%;
`;

const Box = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: white;
  box-shadow: 0 20px 50px 10px
    ${props => `rgba(${props.theme.textColors.primaryRGB},0.3)`};
  overflow: hidden;
`;

const CloseBtn = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;

  svg {
    color: ${props => props.theme.textColors.secondary};
    opacity: 0.7;
  }

  &:hover {
    svg {
      opacity: 1;
    }
  }
`;

const PageWrapper = styled.div`
  height: 100%;
  width: 200%;

  transform: ${props => (props.slide ? `translateX(-50%)` : null)};
  transition: all 0.4s ease-in;
`;

const FormPage = styled.div`
  position: relative;
  /* background-color: white; */
  height: calc(100% - 74px);
  width: 50%;
  float: left;
`;

const Intro = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.backgroundColors.tertiary};
  padding: 40px;
  height: 100%;
  width: 50%;
  float: left;
`;

const IntroHeader = styled.div`
  text-align: center;
  padding-bottom: 20px;
`;

const IntroTitle = styled.h3`
  font-size: 18px;
  color: ${props => props.theme.textColors.primary};
`;

const IntroIcon = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background-color: white;
  border: 1px solid ${props => props.theme.colors.border};

  svg {
    color: ${props => props.theme.textColors.secondary};
    font-size: 24px;
  }
`;

const IntroContent = styled.div`
  flex: 1;
`;

const PrimaryWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  height: 100%;
`;

const SystemBtn = styled.button`
  position: relative;
  background-color: ${props =>
    props.active ? props.theme.colors.blue : 'white'};
  border-radius: 10px;
  padding: 10px;
  border: 1px solid;
  border-color: ${props =>
    props.active ? props.theme.colors.blue : props.theme.colors.border};

  color: ${props => (props.active ? 'white' : props.theme.textColors.primary)};
  font-size: 15px;
  font-weight: ${props => props.theme.fontWeights.medium};
  cursor: pointer;

  &:hover {
    border-color: ${props => props.theme.colors.blue};
    color: ${props => (props.active ? 'white' : props.theme.colors.blue)};
  }

  svg {
    position: absolute;
    top: 8px;
    right: 8px;
    font-size: 14px;
    color: white;
  }
`;

export default ReviewOfSystems;
