import React, { useRef, useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { lighten } from 'polished';
import { useApolloClient } from '@apollo/client';
import { formatDistanceToNow, parseISO } from 'date-fns';
import {
  FaHeartbeat,
  FaThermometerHalf,
  FaRedoAlt,
  FaTimes,
} from 'react-icons/fa';
import { ImMeter } from 'react-icons/im';
import useAmplitude from '../../hooks/useAmplitude';
import { useEventListener } from '../../hooks/useEventListener';
import { CallContext } from '../../contexts/CallContext';
import { SystemContext } from '../../contexts/SystemContext';
import BloodPressure from '../vitals/BloodPressure';
import BloodPressureRequest from './BloodPressureRequest';
import PulseOx from '../vitals/PulseOx';
import Temperature from '../vitals/Temperature';
import WithTooltip from '../common/WithTooltip';

import { ADD_VITAL, UPDATE_VITAL } from '../../api/vitals';

import { TEST_VITALS } from '../../../constants';
import { capitalize } from '../../hooks/useTextHelper';

const TEST_MODE = false;

const getIcon = id =>
  id === 'pulseox' ? (
    <FaHeartbeat />
  ) : id === 'temperature' ? (
    <FaThermometerHalf />
  ) : (
    <ImMeter />
  );

const formatVitalValuesForSave = (type, data) => {
  if (type === 'temperature') {
    return { temp: data.temp };
  } else if (type === 'pulseox') {
    return {
      pi: data.pi,
      pulse: data.pulse,
      spo2: data.spo2,
      pleth: data.pleth,
    };
  } else if (type === 'bp') {
    return {
      sys: data.sys,
      dia: data.dia,
    };
  }
  return {};
};

/**
 *  Summary card shown when collecting vitals.
 */

const VitalBox = ({
  id,
  label,
  events,
  device,
  data,
  widget,
  vitalId,
  disabled,
  onRefresh,
}) => {
  const isMounted = useRef(true);
  const {
    state: { callType, channel, patient, encounter, room, physicianStatus },
  } = useContext(CallContext);
  const {
    state: { realmUser },
  } = useContext(SystemContext);

  const client = useApolloClient();
  const { track } = useAmplitude();

  const [connected, setConnected] = useState(false);
  const [saved, setSaved] = useState(data ? true : false);
  const [timeAgo, setTimeAgo] = useState('');
  const [vitalData, setVitalData] = useState(
    TEST_MODE ? TEST_VITALS[id] : data || null,
  );

  const saveVital = async saveData => {
    const mutation = data ? UPDATE_VITAL : ADD_VITAL;
    const variables = {
      _id: vitalId,
      id: patient.userId,
      datetime: saveData.timestamp,
      device,
      encounter,
      type: id,
      value: formatVitalValuesForSave(id, saveData),
    };
    try {
      await client.mutate({ mutation, variables });

      // successfully saved, log in Amplitude
      const eventName = `${label} Collected`;
      let eventProps = {
        Device: device,
        'Recorded By': capitalize(realmUser?.customData?.role),
      };
      if (id !== 'temperature')
        eventProps = { ...eventProps, Battery: saveData.battery };
      track(eventName, eventProps, true);
    } catch (err) {
      console.log('Error saving vital: ', err);
    }

    setSaved(true);
    if (onRefresh) onRefresh();

    // if Physician is on but not yet connected
    // update details in Physician's UI as edited
    if (physicianStatus === 'waitingForPhysician') {
      try {
        await realmUser.functions.sendPusherEvent(
          `presence-${room}`,
          'update-vitals',
        );
      } catch (err) {
        console.log('Pusher error, update-vitals:', err);
      }
    }
  };

  const retake = () => {
    setSaved(false);
    setVitalData(null);
  };

  useEffect(() => {
    const updateReading = data => {
      // if data is already saved, don't overwrite (prevents against pulseox firing late)
      if (saved) return;

      console.log(`Vital Reading (${id}): `, data);
      if (isMounted.current) setVitalData(data);
    };

    const connectDevice = () => {
      if (isMounted.current) setConnected(true);
    };

    const disconnectDevice = () => {
      if (isMounted.current) setConnected(false);
    };

    if (channel && !disabled) {
      channel.bind(events.connected, connectDevice);
      channel.bind(events.disconnected, disconnectDevice);
      channel.bind(events.ready, updateReading);
    }

    return () => {
      if (channel) {
        channel.unbind(events.connected, connectDevice);
        channel.unbind(events.disconnected, disconnectDevice);
        channel.unbind(events.ready, updateReading);
      }
    };
  }, [channel, disabled, saved, id]);

  useEffect(() => {
    console.log('connection status changed: ', id, connected);
  }, [connected]);

  useEffect(() => {
    if (!vitalData || !vitalData.timestamp || saved || !isMounted.current)
      return;

    let time;
    try {
      time =
        typeof vitalData.timestamp === 'string'
          ? parseISO(vitalData.timestamp)
          : vitalData.timestamp;
    } catch (err) {
      console.log(
        'Timestamp error: ',
        vitalData.timestamp,
        typeof vitalData.timestamp,
      );
    }

    let timer;
    setTimeAgo(formatDistanceToNow(time, { addSuffix: true }));
    timer = setInterval(
      () => setTimeAgo(formatDistanceToNow(time, { addSuffix: true })),
      60000,
    );

    return () => clearInterval(timer);
  }, [vitalData, saved]);

  useEffect(() => {
    if (data && isMounted.current) {
      setVitalData(data);
      setSaved(true);
    }
  }, [data]);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEventListener('disconnectVital', () => setConnected(false));

  return (
    <Container widget={widget} saved={saved}>
      <Wrapper>
        <Info
          ready={vitalData || (id === 'bp' && connected)}
          isBP={id === 'bp'}
          connected={connected}>
          <IconBlock>{getIcon(id)}</IconBlock>
          <Title>{label}</Title>
        </Info>
        {vitalData ? (
          <Content>
            {id === 'temperature' ? (
              <Temperature saved={saved} widget={widget} {...vitalData} />
            ) : id === 'pulseox' ? (
              <PulseOx active={!saved} widget={widget} {...vitalData} />
            ) : id === 'bp' ? (
              <BloodPressure saved={saved} widget={widget} {...vitalData} />
            ) : null}
          </Content>
        ) : id === 'bp' && connected ? (
          <Content>
            <BloodPressureRequest room={room} onSave={saveVital} />
          </Content>
        ) : null}
        {disabled ? null : (
          <Footer>
            <StatusArea complete={vitalData} connected={connected}>
              <StatusLabel>Status:</StatusLabel>
              <Status connected={connected}>
                {connected && vitalData
                  ? 'On'
                  : connected
                  ? 'Connected'
                  : 'Off'}
              </Status>
            </StatusArea>

            {vitalData ? (
              <Timestamp>
                {vitalData.timestamp && !saved && !widget ? timeAgo : null}
              </Timestamp>
            ) : null}

            {vitalData ? (
              <FooterButtons>
                {saved ? (
                  <WithTooltip title="Retake">
                    <RetestBtn onClick={retake}>
                      <FaRedoAlt />
                    </RetestBtn>
                  </WithTooltip>
                ) : callType !== 'onboarding' ? (
                  <SaveBtnWrapper>
                    <SaveBtn onClick={() => saveVital(vitalData)}>Save</SaveBtn>
                    <WithTooltip title="Clear">
                      <ClearBtn onClick={() => setVitalData()}>
                        <FaTimes />
                      </ClearBtn>
                    </WithTooltip>
                  </SaveBtnWrapper>
                ) : null}
              </FooterButtons>
            ) : null}
          </Footer>
        )}
      </Wrapper>
    </Container>
  );
};

const Timestamp = styled.div`
  flex: 1;
  color: ${props => props.theme.textColors.tertiary};
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 0.5px;
  opacity: 0.6;
`;

const IconBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s;

  > svg {
    color: ${props => props.theme.colors.blue};
    font-size: 36px;
  }
`;

const Title = styled.h4`
  font-size: 18px;
  color: ${props => props.theme.textColors.primary};
  margin-bottom: 0;
`;

const Info = styled.div`
  display: flex;
  flex-direction: ${props => (props.ready ? 'row' : 'column')};
  justify-content: center;
  align-items: center;
  flex: ${props => (props.ready ? 'initial' : 1)};
  padding-top: ${props => (props.ready ? '15px' : 0)};

  ${Title} {
    font-size: ${props => (props.ready ? '15px' : '18px')};
    margin-top: ${props => (props.ready ? '3px' : '21px')};
  }

  ${IconBlock} {
    margin-right: ${props => (props.ready ? '10px' : 0)};
    svg {
      font-size: ${props =>
        props.widget ? '22px' : props.ready ? '28px' : '36px'};
      color: ${props =>
        props.ready || props.connected
          ? props.theme.colors.blue
          : props.theme.textColors.tertiary};
    }
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;

  background-color: ${props =>
    props.saved ? props.theme.backgroundColors.tertiary : 'transparent'};
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  border-top: 1px solid ${props => props.theme.colors.border};
  padding: 10px 15px;
  width: 100%;
  background-color: white;
`;

const StatusLabel = styled.span`
  color: ${props => props.theme.textColors.tertiary};
  margin-right: 3px;
`;

const Status = styled.span`
  color: ${props =>
    props.connected ? props.theme.colors.green : props.theme.colors.primary};
`;

const StatusArea = styled.div`
  text-align: center;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: ${props => (props.complete ? 'flex-start' : 'center')};
  height: 32px;
  min-width: 54px;

  > span {
    font-size: 11px;
    line-height: 1;
    font-family: ${props => props.theme.fonts.secondary};
    font-weight: ${props => props.theme.fontWeights.medium};
    text-transform: uppercase;
    letter-spacing: 2px;
  }

  ${({ complete, connected, theme }) =>
    complete &&
    `
    ${Status} {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      letter-spacing: 0;
      border-radius: 50%;
      width: 28px;
      height: 28px;
      font-size: 9px;
      font-weight: bold;
      letter-spacing: 0.5px;
      border: 2px solid;
      border-color: ${connected ? theme.colors.green : theme.colors.primary};
    }

    ${StatusLabel} {
      display: none;
    }
  `}
`;

const SaveBtnWrapper = styled.div`
  position: relative;
  display: flex;
`;

const SaveBtn = styled.button`
  position: relative;
  z-index: 1;
  border: none;
  outline: none;
  font-size: 11px;
  font-family: ${props => props.theme.fonts.secondary};
  font-weight: ${props => props.theme.fontWeights.bold};
  text-transform: uppercase;
  color: white;
  background-color: ${props => props.theme.colors.blue};
  border-radius: 32px;
  height: 32px;
  padding: 0 13px;
  letter-spacing: 0.2px;
  cursor: pointer;

  &:hover {
    background-color: ${props => lighten(0.05, props.theme.colors.blue)};
  }
`;

const ClearBtn = styled.button`
  margin-left: -18px;
  padding: 0 8px 0 21px;
  background-color: white;
  border: 1px solid ${props => props.theme.colors.border};
  border-radius: 0 32px 32px 0;
  height: 32px;

  svg {
    line-height: 1;
    font-size: 14px;
    color: ${props => props.theme.textColors.tertiary};
  }

  &:hover {
    border-color: ${props => props.theme.colors.blue};
    background-color: ${props => props.theme.backgroundColors.tertiary};
    svg {
      color: ${props => props.theme.colors.blue};
    }
  }
`;

const RetestBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.colors.blue};
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: none;
  outline: none;
  cursor: pointer;

  svg {
    color: white;
    font-size: 15px;
  }

  &:hover {
    background-color: ${props => lighten(0.05, props.theme.colors.blue)};
  }
`;

const FooterButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  min-width: 54px;
`;

const Wrapper = styled.div`
  position: relative;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  border-radius: 20px;
  border: ${props =>
    props.widget ? 'none' : `1px solid ${props.theme.colors.border}`};
  background-color: ${props => (props.widget ? 'transparent' : 'white')};
  overflow: hidden;

  ${({ widget }) =>
    widget &&
    `
    ${IconBlock} {
      > svg {
        font-size: 22px;
      }
    }
  `}

  ${({ saved, theme }) =>
    saved &&
    `&::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      background-color: rgba(${theme.backgroundColors.secondaryRGB}, 0.2);
    }
  `}
`;

export default VitalBox;
