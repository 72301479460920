export const isObject = obj => {
  return Object.prototype.toString.call(obj) === '[object Object]';
};

export const isObjectEmpty = obj => {
  let empty = true;
  Object.keys(obj).map(key => {
    if (obj[key] && obj[key] !== '') {
      empty = false;
      return;
    }
  });

  return empty;
};

export const createInitialData = fields => {
  // takes a form config's fields array and structures the initial object with empty values
  // used only for fresh, non-editable forms (like patient's encounter form)
  let obj = {};
  for (let i = 0; i < fields.length; i++) {
    if (fields[i].type === 'row') {
      const nested = createInitialData(fields[i].fields);
      obj = { ...obj, ...nested };
    } else {
      const id = fields[i].id;
      obj[id] = null;
    }
  }
  return obj;
};

export const formatInitialData = obj => {
  // occassionally random properties are added to object from db
  // remove unnecessary properties from object to protect saving data back to db
  const ignoreNames = ['__typename'];
  let cleaned = {};
  for (let i in obj) {
    // ignore specific additions using array above
    if (!ignoreNames.includes(i)) {
      // check for nested objects and deep clean each
      if (Array.isArray(obj[i])) {
        let arr = [];
        // loop through array
        obj[i].forEach(element => {
          const child =
            typeof element === 'object' ? formatInitialData(element) : element;
          arr.push(child);
        });
        cleaned[i] = arr;
      } else {
        cleaned[i] =
          typeof obj[i] === 'object' && obj[i] !== null
            ? formatInitialData(obj[i])
            : obj[i];
      }
    }
  }
  return cleaned;
};

export const decodeAddress = address => {
  if (!address) return {};

  // break apart address to work with form UI
  return {
    street1: address.line ? address.line[0] : '',
    street2: address.line ? address.line[1] : '',
    ...address,
  };
};

export const encodeAddress = ({
  street1,
  street2,
  city,
  state,
  postalCode,
  notes,
}) => {
  // put address data back the way server expects it before updating
  let line = [street1];
  if (street2 && street2 !== '') line.push(street2);

  let address = { line, city, state, postalCode, notes };
  if (notes) address = { ...address, notes };

  return address;
};

export const encodePhysician = value => {
  return {
    ...value,
    address: encodeAddress(value),
  };
};

export const encodeSSN = value => {
  let data = {
    ...value,
    ...value.ssn,
  };
  delete data.ssn;
  return data;
};

export const generateNumberArray = (min, max, reverse) => {
  let list = [];
  for (let i = min; i <= max; i++) {
    if (reverse) list.unshift(i);
    else list.push(i);
  }
  return list;
};

export const generateReverseYears = (num, reverse) => {
  const curYear = new Date().getFullYear();
  return generateNumberArray(curYear - num, curYear, reverse);
};

export const decodeAllergen = obj => {
  return {
    allergen: {
      clinicalCode: obj.clinicalCode,
      description: obj.description,
    },
    ...obj,
  };
};

export const moveToRoot = obj => {
  // take contents of object and move everything to root level
  return Object.keys(obj).reduce((prev, key) => {
    return { ...prev, ...obj[key] };
  }, {});
};

const extractEmergencyContactInfo = (prefix, obj) => {
  return {
    firstName: obj[prefix + 'firstName'],
    lastName: obj[prefix + 'lastName'],
    relationship: obj[prefix + 'relationship'],
    phoneNumber: obj[prefix + 'phoneNumber'],
  };
};

export const encodeEmergencyContacts = obj => {
  // move each to individual object
  let primary = extractEmergencyContactInfo('primary_', obj);
  let secondary = extractEmergencyContactInfo('secondary_', obj);

  // check if object properties are undefined or blank strings
  let arr = [];
  if (!isObjectEmpty(primary)) arr.push(primary);
  if (!isObjectEmpty(secondary)) arr.push(secondary);

  return { emergencyContacts: arr };
};

const formatEmergencyContacts = (prefix, contact) => {
  return !contact
    ? {}
    : {
        [prefix + 'firstName']: contact.firstName,
        [prefix + 'lastName']: contact.lastName,
        [prefix + 'relationship']: contact.relationship,
        [prefix + 'phoneNumber']: contact.phoneNumber,
      };
};

export const decodeEmergencyContacts = contacts => {
  if (!contacts) return [{}, {}];
  return {
    ...formatEmergencyContacts('primary_', contacts[0]),
    ...formatEmergencyContacts('secondary_', contacts[1]),
  };
};

export const encodeReviewOfSystem = formData => {
  // deconstruct formData to match what server expects for Review of System
  const newFormData = {};
  const arr = [];

  for (let key in formData) {
    if (isObject(formData[key]) && formData[key].sctid) {
      // if formData property is an object and contains 'sctid', add to 'inspections' array
      // append the original object key as a new property 'type'
      const obj = {
        ...formData[key],
        type: key,
      };
      arr.push(obj);
    } else {
      // copy property as is over to new object
      newFormData[key] = formData[key];
    }
  }

  // add the 'inspections' array to newFormData and send back
  newFormData.inspections = arr;

  return newFormData;
};

export const decodeReviewOfSystem = data => {
  let obj = {
    _id: data._id,
    notes: data.notes,
  };

  for (let i = 0; i < data.inspections.length; i++) {
    const copy = { ...data.inspections[i] };
    const type = data.inspections[i].type;
    delete copy.type;
    obj[type] = {
      ...copy,
    };
  }
  return obj;
};

export const removeScriptTags = str => {
  let newStr = str.replace(new RegExp('<script', 'g'), '');
  return newStr.replace(new RegExp('</script', 'g'), '');
};

export const validateEmail = email => {
  if (
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
      email,
    )
  ) {
    return true;
  }

  return false;
};

export const validatePassword = password => {
  if (
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[ -/:-@[-`{-~]).{8,64}$/.test(
      password,
    )
  ) {
    // Password is:  8-64 chars, 1 upper, 1 lower, 1 special, no spaces
    return true;
  }

  return false;
};

const validationCheck = (data, obj) => {
  switch (obj.condition) {
    case 'equals':
      return obj.value.includes(data[obj.property]);
    case 'isNot':
      return !obj.value.includes(data[obj.property]);
    case 'exists':
      return data[obj.property] && data[obj.property] !== null;
    default:
      return true;
  }
};

export const runFormValidation = (data, validation) => {
  const v = Array.isArray(validation) ? validation : [validation];

  for (let i = 0; i < v.length; i++) {
    const current = validationCheck(data, v[i]);
    if (!current) {
      return {
        status: false,
        message: v[i].message || 'Form is invalid',
      };
    }
  }

  return { status: true };
};
