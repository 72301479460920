import React from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';
import { ADD_EXAM, UPDATE_EXAM, DELETE_EXAM } from '../../../api/exams';
import Form from '../../forms/Form';
import { encodeReviewOfSystem } from '../../../hooks/useFormHelper';

const ReviewOfSystemsForm = ({
  patient,
  encounter,
  formData,
  system,
  onBack,
}) => {
  const [deleteExam] = useMutation(DELETE_EXAM, {
    onCompleted() {
      onBack(true);
    },
  });

  const onDelete = id => {
    deleteExam({ variables: { id } });
  };

  return (
    <Container>
      <FormHeader>
        <FormTitle>{system.label}</FormTitle>
        <FormQuery>Query: {system.query}</FormQuery>
      </FormHeader>
      <FormWrapper>
        {system.form ? (
          <Form
            targetId={encounter}
            form={{
              mutation: formData._id ? UPDATE_EXAM : ADD_EXAM,
              extraData: {
                patient: patient.userId,
                system: { sctid: system.sctid, label: system.label },
              },
              encodeData: encodeReviewOfSystem,
              ...system.form,
            }}
            data={formData}
            onClose={onBack}
            onDelete={formData._id ? () => onDelete(formData._id) : null}
            stickyFooter={true}
            scrollForm
          />
        ) : null}
      </FormWrapper>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  background-color: white;
  height: 100%;
`;

const FormHeader = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  z-index: 5;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 25px 40px;
  background-color: white;
  border-bottom: 1px solid ${props => props.theme.colors.border};
`;

const FormTitle = styled.h2`
  font-size: 18px;
  margin: 0;
  color: ${props => props.theme.textColors.primary};
`;

const FormQuery = styled.p`
  flex: 1;
  font-size: 14px;
  margin: 0;
  padding-left: 20px;
`;

const FormWrapper = styled.div`
  height: 100%;

  fieldset {
    margin-top: 0;
    margin-bottom: 20px;
    padding: 20px;
    background: ${props => props.theme.backgroundColors.tertiary};
    border-radius: 5px;
  }
`;

export default ReviewOfSystemsForm;
