// @flow
import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import { FaTimes } from 'react-icons/fa';

const Alert = ({ id, icon, message, removeAlert, showClose }) => {
  const isInitialMount = useRef(true);

  // on component mount, animate in, wait 3s, animate out
  useEffect(() => {
    let timeout;

    if (isInitialMount.current) {
      isInitialMount.current = false;

      timeout = setTimeout(() => {
        removeAlert();
      }, 500000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [id]);

  return (
    <Container>
      <Wrapper>
        <Message>{message}</Message>
        {showClose ? (
          <CloseBtn onClick={removeAlert}>
            <FaTimes />
          </CloseBtn>
        ) : null}
      </Wrapper>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: auto;
  padding: 30px 30px 20px;
`;

const Wrapper = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.colors.primary};
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 20px;
`;

const Message = styled.div`
  padding: 5px;
  color: white;
  font-size: 15px;
  line-height: 1.3;
  font-weight: ${props => props.theme.fontWeights.bold};
  flex: 1;
`;

const CloseBtn = styled.button`
  border: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  line-height: 1;
  padding: 5px;
  margin-left: 5px;

  svg {
    color: white;
    font-size: 16px;
    opacity: 0.7;
  }

  &:hover {
    svg {
      opacity: 1;
    }
  }
`;

export default Alert;
