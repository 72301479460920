import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

// 1/11/2023: temporary hack for misspelled 'hygene' remove in future
const TYPES = [
  'alertness',
  'nourishment',
  'mood',
  'hygene',
  'hygiene',
  'wellness',
];

const checkAbnormalValue = (type, value) => {
  switch (type) {
    case 'alertness':
      return value === 'Not Alert';
    case 'nourishment':
      return value === 'Cachetic';
    case 'mood':
      return value === 'Distressed';
    case 'hygene':
    case 'hygiene':
      // 1/11/2023: temporary hack for misspelled 'hygene' remove in future
      return value === 'Disheveled';
    case 'wellness':
      return value === 'Toxic';
    default:
      return false;
  }
};

const EncounterObservations = ({ exams }) => {
  const [data, setData] = useState();

  useEffect(() => {
    // combine objects on init to parse data into readable object
    const findConstitutional = () => {
      if (!exams) return null;

      const CONSTITUTIONAL_SCTID = '363791007';

      for (let i = 0; i < exams.length; i++) {
        if (exams[i].system?.sctid === CONSTITUTIONAL_SCTID) {
          return exams[i];
        }
      }

      return null;
    };

    // let vitalsData = {};

    // 1. check if constituational exam is complete
    const constitutional = findConstitutional();
    if (!constitutional) return;

    // 2. parse array to setup object
    const { inspections, notes } = constitutional;
    let observationsData = { notes };

    for (let i = 0; i < inspections.length; i++) {
      const { type, label, selected } = inspections[i];
      if (TYPES.includes(type)) {
        observationsData[type] = {
          // 1/11/2023: temporary hack for misspelled 'hygene' remove in future
          label: label === 'Hygene' ? 'Hygiene' : label,
          abnormal: checkAbnormalValue(type, selected[0].label),
          value: selected[0].label,
        };
      }
    }

    setData(observationsData);
  }, [exams]);

  return data ? (
    <>
      {TYPES.map(type => {
        const exam = data[type];

        return exam ? (
          <Observation key={type} abnormal={exam.abnormal}>
            <span>{exam.label}</span>
            <strong>{exam.value}</strong>
          </Observation>
        ) : null;
      })}
      {data.notes ? <ObservationNote>{data.notes}</ObservationNote> : null}
    </>
  ) : null;
};

const Observation = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 13px;
  padding: 5px 0 5px 20px;
  border-bottom: 1px solid ${props => props.theme.colors.border};
  color: ${props => props.theme.textColors.primary};

  span {
    flex: 1;
    text-transform: uppercase;
    font-size: 11px;
  }

  strong {
    font-weight: bold;
    text-align: right;
    color: ${props =>
      props.abnormal ? props.theme.colors.warning : 'inherit'};
  }

  &::after {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    content: '';
    display: block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: ${props =>
      props.abnormal ? props.theme.colors.warning : props.theme.colors.green};
  }
`;

const ObservationNote = styled.p`
  font-size: 12px;
  line-height: 1.4;
  font-style: italic;
  margin-top: 20px;
  color: ${props => props.theme.textColors.secondary};
`;

export default EncounterObservations;
