import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { differenceInSeconds } from 'date-fns';
import { formatTimestamp } from '../../../hooks/useTextHelper';

const EndScreenHeader = ({ status, startTime }) => {
  const [callTime, setCallTime] = useState('--:--');

  useEffect(() => {
    // record timestamp for call duration
    const diff = differenceInSeconds(new Date(), startTime);
    const timestamp = formatTimestamp(diff, true);
    setCallTime(timestamp);
  }, [startTime]);

  return (
    <>
      <Title>
        {status === 'ended'
          ? 'Call Ended'
          : status === 'disconnected'
          ? 'Patient unexpectedly disconnected from the call.'
          : 'Patient ended the call.'}
      </Title>
      <CallTime>{callTime}</CallTime>
    </>
  );
};

const Title = styled.h1`
  font-weight: ${props => props.theme.fontWeights.medium};
  text-align: center;
`;

const CallTime = styled.div`
  font-size: 18px;
  color: ${props => props.theme.textColors.secondary};
  text-align: center;
  margin-top: 10px;
`;

export default EndScreenHeader;
