import React, { useContext, useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { lighten } from 'polished';

import { CallContext } from '../../contexts/CallContext';
import { SystemContext } from '../../contexts/SystemContext';
import { useEventListener } from '../../hooks/useEventListener';

import DoctorIcon from '../../../assets/images/doctor.svg';
import DoctorIconFill from '../../../assets/images/doctor-fill.svg';
import { ModalContext } from '../../contexts/ModalContext';
import ModalDialog from '../modals/ModalDialog';
import WithTooltip from '../common/WithTooltip';

const getStatusTitle = status => {
  switch (status) {
    case 'ready':
      return 'Physician Ready';
    case 'paging':
      return 'Paging Physician';
    case 'default':
      return 'Request Physician';
    default:
      return '';
  }
};

const DoctorButton = () => {
  const [doctorStatus, setDoctorStatus] = useState('default');

  const {
    state: { room, channel, status: callStatus, physicianStatus },
    dispatch: dispatchCall,
  } = useContext(CallContext);
  const { dispatch: dispatchModal } = useContext(ModalContext);
  const {
    state: { realmUser },
  } = useContext(SystemContext);

  const physicianIsReady = () => {
    setDoctorStatus('ready');
  };

  const forceCloseModal = () => {
    dispatchModal({ type: 'CLEAR' });
  };

  const connectPhysician = async () => {
    await realmUser.functions.sendPusherEvent(
      `presence-${room}`,
      'connect-physician',
    );
  };

  const confirmCancelRequest = async notes => {
    const reason = notes !== '' ? notes : 'Reason not provided.';

    if (doctorStatus === 'paging') {
      console.log('Room', room);
      // need to notify server right here to send a "nevermind" text to physician
      await realmUser.functions.cancelPhysician(room, notes);
    } else if (doctorStatus === 'ready') {
      // physician is on webapp, disconnect their service to notify them they are no longer needed
      try {
        await realmUser.functions.sendPusherEvent(
          `presence-${room}`,
          'disconnect-physician',
          { reason },
        );
      } catch (err) {
        console.log('Error disconnecting Physician', err);
      }

      // if Physician is staring at call list, update it with new status
      try {
        await realmUser.functions.sendPusherEvent('calls', 'calls-updated');
      } catch (err) {
        console.log('Error updating call list', err);
      }
    }

    setDoctorStatus('default');
    dispatchCall({
      type: 'SET_CALL',
      data: {
        physicianStatus: null,
        physician: null, // clear physician from call record (in event new physician is added)
      },
    });
  };

  const showCancelRequestModal = replaceModal => {
    dispatchModal({
      type: replaceModal ? 'REPLACE_MODAL' : 'ADD_MODAL',
      data: {
        component: ModalDialog,
        props: {
          message:
            'The Physician has been notified, are you sure you want to cancel the request?',
          cancelLabel: 'Close',
          confirmLabel: 'Yes, Cancel Request',
          maxWidth: '480px',
          borderRadius: '10px',
          notesField: 'Reason for Cancelling',
        },
        onConfirm: confirmCancelRequest,
      },
    });
  };

  const onDoctorClick = async () => {
    if (doctorStatus === 'ready') {
      dispatchModal({
        type: 'ADD_MODAL',
        data: {
          component: ModalDialog,
          props: {
            message:
              'The Physician is ready and will be connected as soon as you click CONNECT below.',
            cancelLabel: 'Cancel Request',
            cancelColor: 'inverseRed',
            confirmLabel: 'Connect Physician',
            confirmColor: 'green',
            maxWidth: '480px',
            borderRadius: '10px',
            onBack: forceCloseModal,
          },
          onConfirm: connectPhysician,
          onDismiss: () => showCancelRequestModal(true),
          disableDismissOutside: true,
          replaceOnDismiss: true,
        },
      });
    } else if (doctorStatus === 'paging') {
      // show modal asking why careline is trying to cancel
      showCancelRequestModal();
    } else {
      setDoctorStatus('paging');
      dispatchCall({
        type: 'SET_CALL',
        data: {
          physicianStatus: 'waitingForPhysician',
        },
      });

      // page doctor
      await realmUser.functions.notifyPhysicians(room);
    }
  };

  const onConnect = () => {
    // Physician just connected to call
    setDoctorStatus('connected');
  };

  const onDisconnect = () => {
    // Physician just disconnected to call -- go back to waiting/paging
    setDoctorStatus('paging');
  };

  // below are broadcast from VideoCall when Physician connects/disconnects
  useEventListener('doctorConnected', onConnect);
  useEventListener('doctorDisconnected', onDisconnect);

  useEffect(() => {
    if (realmUser.customData.role === 'careline') {
      channel.bind('physician-ready', physicianIsReady);
    }

    return () => {
      channel.unbind('physician-ready', physicianIsReady);
    };
  }, [channel]);

  return doctorStatus !== 'connected' ? (
    <Wrapper>
      <WithTooltip title={getStatusTitle(doctorStatus)}>
        <DoctorBtn
          status={doctorStatus}
          onClick={onDoctorClick}
          disabled={callStatus !== 'active'}>
          {doctorStatus === 'ready' ? (
            <DoctorBtnIconFill src={DoctorIconFill} />
          ) : (
            <DoctorBtnIconLine src={DoctorIcon} />
          )}
        </DoctorBtn>
      </WithTooltip>
    </Wrapper>
  ) : null;
};

const pulse = keyframes`
  0% {
    border-color: #EAE8E4;
    box-shadow: none;
  }
  40%, 60% {
    border-color: #FFAA76;
    box-shadow: 0 0 15px rgba(255,170,118, 0.4);
  }
  100% {
    border-color: #EAE8E4;
    box-shadow: none;
  }
`;

const pulseReady = keyframes`
  0% {
    box-shadow: none;
  }
  40%, 60% {
    box-shadow: 0 0 0 4px rgba(117,194,130, 0.4);
  }
  100% {
    box-shadow: none;
  }
`;

const DoctorBtnIcon = styled.img`
  display: block;
  width: 30px;
`;

const DoctorBtnIconLine = styled(DoctorBtnIcon)``;
const DoctorBtnIconFill = styled(DoctorBtnIcon)`
  color: ${props => props.theme.colors.green};
`;

const DoctorBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props =>
    props.status === 'ready' ? props.theme.colors.green : 'white'};
  border: 2px solid;
  border-color: ${props =>
    props.status === 'ready'
      ? props.theme.colors.green
      : props.theme.colors.border};
  width: 48px;
  height: 48px;
  border-radius: 18px;
  outline: none;
  cursor: pointer;
  /* pointer-events: ${props =>
    props.status === 'paging' ? 'none' : 'auto'}; */

  animation-name: ${props =>
    props.status === 'paging'
      ? pulse
      : props.status === 'ready'
      ? pulseReady
      : 'none'};
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-out;

  ${DoctorBtnIcon} {
    filter: ${props =>
      props.status === 'paging'
        ? 'invert(64%) sepia(72%) saturate(356%) hue-rotate(331deg) brightness(103%) contrast(101%)'
        : props.status === 'ready'
        ? 'invert(100%) sepia(0%) saturate(14%) hue-rotate(60deg) brightness(106%) contrast(101%)'
        : 'invert(65%) sepia(13%) saturate(267%) hue-rotate(187deg) brightness(94%) contrast(92%)'};
  }

  ${({ disabled }) =>
    disabled &&
    `
    opacity: 0.5;
    animation-name: 'none';
    pointer-events: none;

    ${DoctorBtnIcon} {
      filter: none;
      opacity: 0.25;
    }
  `}

  &:hover {
    background-color: ${props =>
      props.status === 'ready'
        ? lighten(0.07, props.theme.colors.green)
        : 'white'};
    border-color: ${props =>
      props.status === 'default' ? props.theme.colors.blue : 'transparent'};

    ${DoctorBtnIcon} {
      ${({ status }) =>
        status === 'default' &&
        `
        filter: invert(81%) sepia(34%) saturate(7413%) hue-rotate(181deg) brightness(102%) contrast(96%);
      `}
    }
  }
`;

const Wrapper = styled.div``;

export default DoctorButton;
