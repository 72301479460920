import React from 'react';
import styled from 'styled-components';
import EncounterBox from './EncounterBox';

const EncounterIntakeBox = ({ encounter }) => {
  const { reason, contextualFactors, exacerbatingFactors, relievingFactors } =
    encounter || {};
  const empty =
    !reason && !contextualFactors && !exacerbatingFactors && !relievingFactors;

  return (
    <IntakeBox>
      <EncounterBox>
        <h2>Intake</h2>
        {empty ? (
          <Empty>
            <em>-- No data recorded --</em>
          </Empty>
        ) : (
          <>
            <label>Reason for Visit</label>
            <p>{reason || <em>-- Not reported --</em>}</p>
            <label>Contextual Factors</label>
            <p>{contextualFactors || <em>-- Not reported --</em>}</p>
            <label>Exacerbating Factors</label>
            <p>{exacerbatingFactors || <em>-- Not reported --</em>}</p>
            <label>Relieving Factors</label>
            <p>{relievingFactors || <em>-- Not reported --</em>}</p>
          </>
        )}
      </EncounterBox>
    </IntakeBox>
  );
};

const IntakeBox = styled.div`
  h2 {
    font-size: 18px;
    font-weight: bold;
    margin: 0 0 20px;
  }
  label {
    text-transform: none;
    font-weight: normal;
    letter-spacing: 0.2px;
    font-size: 13px;
  }
  p {
    font-size: 14px;
    font-weight: bold;
    margin: 0 0 20px;

    em {
      font-size: 0.9em;
      color: ${props => props.theme.textColors.secondary};
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

const Empty = styled.p`
  text-align: center;
  padding: 80px 0;
  margin: 0;
`;

export default EncounterIntakeBox;
