import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from 'react';
import styled from 'styled-components';
import { MdArrowBack, MdClose, MdCallEnd } from 'react-icons/md';
import Video from 'twilio-video';
import { useParams, useHistory } from 'react-router-dom';
import SelfieGrid from './SelfieGrid';
import VideoControlBar from './VideoControlBar';
import useAmplitude from '../../hooks/useAmplitude';
import useSound from '../../hooks/useSound';
import useWindowSize from '../../hooks/useWindowSize';
import { CallContext } from '../../contexts/CallContext';
import { MediaContext } from '../../contexts/MediaContext';
import { SystemContext } from '../../contexts/SystemContext';
import LocalVideoParticipant from './LocalVideoParticipant';
import RemoteVideoParticipant from './RemoteVideoParticipant';
import PreCallView from './PreCallView';
import VideoCallTime from './VideoCallTime';
import ConfirmModal from '../modals/ConfirmModal';

const RATIO = 768 / 1024;
const VIDEO_PADDING = 102;

const VideoCall = ({ showPhotoCapture, onEmptyRoom, onEndCall }) => {
  const isInitialMount = useRef(true);
  const history = useHistory();
  const { track } = useAmplitude();

  const { playSound, loopSound, stopLoopingSound } = useSound();
  const { height: windowHeight } = useWindowSize();

  const [connected, setConnected] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [room, setRoom] = useState(null);
  const [participants, setParticipants] = useState([]);

  const [audioEnabled, setAudioEnabled] = useState(true);
  const [videoEnabled, setVideoEnabled] = useState(true);

  const [showEndConfirm, setShowEndConfirm] = useState(false);
  const [showPreventEnd, setShowPreventEnd] = useState(false);
  const [navigatingAway, setNavigatingAway] = useState(false);

  const VIDEO_HEIGHT = windowHeight - VIDEO_PADDING;
  const TABLET_DIMENSIONS = {
    height: `${VIDEO_HEIGHT}px`,
    width: `${VIDEO_HEIGHT * RATIO}px`,
  };

  const {
    state: { status, physicianStatus, startTime, channel, requests },
    dispatch: dispatchCall,
  } = useContext(CallContext);

  const {
    state: { audioTrack, videoTrack },
  } = useContext(MediaContext);

  const {
    state: { realmUser },
  } = useContext(SystemContext);
  const { room: roomId } = useParams();

  const goBack = () => {
    setVideoEnabled(false);
    setNavigatingAway(true);
  };

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }

    if (navigatingAway) history.push('/');
  }, [navigatingAway]);

  /********************
   *   ADJUST AUDIO
   ********************/

  const toggleAudio = () => {
    if (room) {
      room.localParticipant.audioTracks.forEach(publication => {
        if (audioEnabled) {
          publication.track.disable();
        } else {
          publication.track.enable();
        }
      });
    }

    setAudioEnabled(!audioEnabled);
  };

  /*******************
   *   CONNECT CALL
   *******************/

  // connect on page load, disconnect/cleanup on page exit
  const participantConnected = participant => {
    const identity = JSON.parse(participant.identity);
    console.log(`${identity.name} (${identity.role}) has joined the room`);

    if (identity.role !== 'patient') {
      let obj = {
        [identity.role]: {
          ...identity,
          startTime: new Date(),
        },
      };
      if (identity.role === 'physician')
        obj.physicianStatus = 'physicianConnected';
      dispatchCall({
        type: 'SET_CALL',
        data: obj,
      });
    }

    if (identity.role === 'physician') {
      window.dispatchEvent(new Event('doctorConnected'));
    }

    playSound('enter');
    setParticipants(prevParticipants => [...prevParticipants, participant]);
  };

  const participantDisconnected = participant => {
    const identity = JSON.parse(participant.identity);
    dispatchCall({
      type: 'SET_ROLE_ENDTIME',
      data: {
        role: identity.role,
        endTime: new Date(),
      },
    });

    if (identity.role === 'physician') {
      // -- pertains only to care line reps --
      window.dispatchEvent(new Event('doctorDisconnected'));
      // set call status back to 'active' so paging doctor button reappears
      dispatchCall({
        type: 'SET_CALL',
        data: {
          physicianStatus: null,
        },
      });
    }

    console.log(`${identity.name} (${identity.role}) has left the room`);
    playSound('leave');
    setParticipants(prevParticipants =>
      prevParticipants.filter(p => p !== participant),
    );
  };

  const connectVideo = useCallback(async () => {
    setConnected(true);
    loopSound('connecting');

    console.log('connecting ...');

    try {
      // fetch token
      const { token } = await realmUser.callFunction('joinCall', roomId);

      if (!audioEnabled && audioTrack) audioTrack.disable();

      Video.connect(token, {
        name: roomId,
        video: videoEnabled,
        tracks: [audioTrack, videoTrack],
      }).then(
        currentRoom => {
          console.log('Connected to room: ', currentRoom);
          stopLoopingSound();
          setErrorMessage();

          if (currentRoom.participants.size === 0) {
            // if no one on line, room was abandoned by patient. disconnect.
            console.log('Empty room detected');
            onEmptyRoom();
            return;
          }

          playSound('connect');
          setRoom(currentRoom);
          currentRoom.on('participantConnected', participantConnected);
          currentRoom.on('participantDisconnected', participantDisconnected);
          // consider adding boolean param to disable sound on next line if it's annoying
          currentRoom.participants.forEach(participantConnected);

          // record call connection in Amplitude
          const eventName =
            realmUser.customData.role === 'physician'
              ? 'Physician Joined Call'
              : 'Call Started';
          track(eventName, {}, true);

          // record when call started
          let obj = {
            startTime: new Date(),
            status: 'active',
          };
          if (realmUser.customData.role === 'physician')
            obj.physicianStatus = 'physicianConnected';
          dispatchCall({
            type: 'SET_CALL',
            data: obj,
          });
        },
        error => {
          stopLoopingSound();
          playSound('error');
          setConnected(false);
          setErrorMessage(error.message);
          console.log('there was an error:  ', error.message);
          console.log('token: ', token);
        },
      );
    } catch (e) {
      console.log(e);
      // return error here because token could not be fetched
    }
  }, [
    realmUser,
    playSound,
    stopLoopingSound,
    onEmptyRoom,
    audioTrack,
    videoTrack,
    videoEnabled,
    audioEnabled,
    roomId,
  ]);

  /***************
   *   END CALL
   ***************/

  const endCall = useCallback(async () => {
    // hide confirm end modal if showing
    setShowEndConfirm(false);

    // only end call for PATIENT if:
    //  • Physician ends call
    //  • Careline ends call and Physician is NOT on the line
    if (
      realmUser.customData.role === 'physician' ||
      physicianStatus !== 'physicianConnected'
    ) {
      // update server that call has ended
      await realmUser.functions.endCall(roomId);

      // tell patient's app call has ended -- will show a "Call Ended" screen on iPad
      // in event care line is still on call, will end it for them as well
      await realmUser.functions.sendPusherEvent(
        `presence-${roomId}`,
        'end-call',
      );

      // if call is still showing active on care line view, clear it.
      await realmUser.functions.sendPusherEvent('calls', 'calls-updated');
    }

    console.log('room.localParticipant.state: ', room.localParticipant.state);
    if (room && room.localParticipant.state === 'connected') {
      // disconnect from each participant in the room's individual feed
      room.localParticipant.tracks.forEach(trackPublication => {
        trackPublication.track.stop();
        trackPublication.unpublish();
      });

      // disconnect from the room
      try {
        console.log('calling currentRoom.disconnect');
        room.disconnect();
      } catch (error) {}
    }

    // tell parent view to remove call and switch to DisconnectedCall view
    onEndCall();
  }, [status, physicianStatus, room, requests, startTime, onEndCall]);

  useEffect(() => {
    const alertEndCall = event => {
      if (room && status === 'active') {
        // call is still active, prompt user if they want to end the call
        event.preventDefault();

        const prompt =
          "You're on an active call with a patient, are you sure you want to leave and end the call?";
        event.returnValue = prompt;
        return prompt;
      }
    };

    const disconnectFromRoom = () => {
      try {
        console.log('disconnectFromRoom');
        room.disconnect();
      } catch (error) {}
    };

    window.addEventListener('beforeunload', alertEndCall);
    window.addEventListener('pagehide', alertEndCall);
    window.addEventListener('unload', disconnectFromRoom);

    return () => {
      window.removeEventListener('beforeunload', alertEndCall);
      window.removeEventListener('pagehide', alertEndCall);
      window.removeEventListener('unload', disconnectFromRoom);
    };
  }, [room, status, endCall]);

  // store patient's iPad battery level
  useEffect(() => {
    const updateBattery = data => {
      if (data) {
        dispatchCall({
          type: 'SET_PATIENT_BATTERY',
          data,
        });
      }
    };

    if (channel) channel.bind('update-ipad-battery', updateBattery);

    return () => {
      if (channel) channel.unbind('update-ipad-battery', updateBattery);
    };
  }, [channel, dispatchCall]);

  // when component unmounts, kill connection to room
  useEffect(() => {
    return () => {
      if (room) {
        try {
          room.disconnect();
        } catch (err) {
          console.log(err);
        }
      }
    };
  }, [room]);

  return (
    <Container>
      <VideoWrapper style={TABLET_DIMENSIONS}>
        <VideoLayer id="videoLayer">
          {participants.map(participant => (
            <RemoteVideoParticipant
              key={participant.sid}
              participant={participant}
            />
          ))}
          <SelfCamWrapper size={participants.length > 0 ? 'small' : 'large'}>
            <LocalVideoParticipant room={room} videoEnabled={videoEnabled} />
          </SelfCamWrapper>
        </VideoLayer>

        <InfoLayer>
          {errorMessage ? (
            <ErrorView>
              <Message>{errorMessage}</Message>
              <ConnectButton onClick={connectVideo}>Reconnect</ConnectButton>
            </ErrorView>
          ) : status === 'ended' ? (
            <EndCallView />
          ) : connected ? (
            <CallInfo>
              <VideoCallTime participants={participants} />
            </CallInfo>
          ) : (
            <PreCallView onConnect={connectVideo} />
          )}
        </InfoLayer>

        {showPreventEnd ? (
          <ConfirmModal
            message="Waiting for Physician to join, you cannot leave this room."
            cancelLabel="Close"
            onDismiss={() => setShowPreventEnd(false)}
            dark
          />
        ) : showEndConfirm ? (
          <ConfirmModal
            message="Are you sure you want to end this call?"
            cancelIcon={<MdClose />}
            confirmIcon={<MdCallEnd />}
            onDismiss={() => setShowEndConfirm(false)}
            onConfirm={endCall}
            dark
          />
        ) : null}

        {status === 'active' ? null : (
          <BackButton onClick={goBack}>
            <MdArrowBack />
          </BackButton>
        )}

        {showPhotoCapture ? <SelfieGrid /> : null}
      </VideoWrapper>

      {status !== 'ended' ? (
        <VideoControlBar
          status={status}
          audioEnabled={audioEnabled}
          videoEnabled={videoEnabled}
          onAudio={toggleAudio}
          onVideo={() => setVideoEnabled(!videoEnabled)}
          endConfirmShowing={showEndConfirm}
          onEnd={() =>
            physicianStatus === 'waitingForPhysician'
              ? setShowPreventEnd(true)
              : setShowEndConfirm(true)
          }
        />
      ) : null}
    </Container>
  );
};

const VideoLayer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const SelfCamWrapper = styled.div`
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
  background-color: ${props => props.theme.backgroundColors.darker};
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);

  ${({ size }) =>
    size === 'small' &&
    `
    top: 20px;
    right: 20px;
    width: 108px;
    height: 108px;
  `}
`;

const InfoLayer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
  pointer-events: none;
`;

const CallInfo = styled.div`
  position: absolute;
  bottom: 0;
  display: flex;
  padding: 12px 16px;
  align-items: center;
  width: 100%;
`;

const Container = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 20px;
  background-color: ${props => props.theme.backgroundColors.dark};
`;

const VideoWrapper = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 20px;
  background-color: ${props => props.theme.backgroundColors.darker};
`;

const Message = styled.p`
  font-size: 16px;
  letter-spacing: 0.25px;
  color: white;
  text-align: center;
  margin: 0;
`;

const ConnectButton = styled.button`
  background-color: white;
  padding: 12px 20px;
  color: black;
  font-family: ${props => props.theme.fonts.secondary};
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  margin-top: 20px;
  border: none;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  pointer-events: auto;

  &:active {
    opacity: 0.7;
  }
`;

const BackButton = styled.button`
  border: 2px solid #333;
  background-color: #222;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  outline: none;
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 25;
  opacity: 0.8;

  svg {
    font-size: 50px;
    color: white;
  }

  &:hover {
    opacity: 1;
  }
`;

const EndCallView = styled.div``;
const ErrorView = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  text-align: center;
`;

export default VideoCall;
