import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

const BTNS = ['low', 'medium', 'high'];

const UrgencyMeter = ({
  id,
  value,
  onChange,
  onAutosave,
  disabled = false,
}) => {
  const isInitialMount = useRef(true);

  const [active, setActive] = useState(value);

  const onClick = mode => {
    setActive(mode);
    onChange(mode);

    if (onAutosave) onAutosave(id, mode, true);
  };

  useEffect(() => {
    if (isInitialMount.current) {
      // default to 'medium' if not previously set
      if (!value) onClick('medium');

      isInitialMount.current = false;
    }
  }, []);

  return (
    <ButtonGroup disabled={disabled}>
      {BTNS.map(mode => (
        <Button
          key={mode}
          mode={mode}
          active={active === mode}
          onClick={() => onClick(mode)}>
          {mode}
        </Button>
      ))}
    </ButtonGroup>
  );
};

const Button = styled.span`
  border: none;
  outline: none;
  padding: 16px;
  font-size: 12px;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  font-family: ${props => props.theme.fonts.primary};
  font-weight: ${props => props.theme.fontWeights.bold};
  color: ${props =>
    props.active ? 'white' : props.theme.textColors.secondary};

  pointer-events: ${props => (props.active ? 'none' : 'auto')};
  cursor: pointer;

  flex: ${props => (props.mode === 'medium' ? 1 : 'auto')};

  background-color: ${props =>
    !props.active
      ? props.theme.backgroundColors.lightAlt
      : props.mode === 'high'
      ? props.theme.colors.primary
      : props.mode === 'medium'
      ? props.theme.colors.tertiary
      : props.theme.colors.green};

  &:first-child {
    padding-left: 20px;
  }

  &:last-child {
    padding-right: 20px;
  }
`;

const ButtonGroup = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;

  border-radius: 40px;
  overflow: hidden;
  border: 2px solid ${props => props.theme.colors.border};

  ${({ disabled }) =>
    disabled &&
    `
    ${Button} {
      cursor: default;
      pointer-events: none;
      opacity: 0.8;
    }
  `}
`;

export default UrgencyMeter;
