import React, { useRef } from 'react';
import styled from 'styled-components';
import { getAge } from '../../../../hooks/useTextHelper';
import WidgetFooterBtn from '../../../common/WidgetFooterBtn';
import { CODE_STATUS_TYPES } from '../../../../../constants';
import WithTooltip from '../../../common/WithTooltip';
import UserIcon from '../../../common/UserIcon';

const ProfileWidget = ({ patient, openEHR }) => {
  const contentRef = useRef();

  return (
    <Wrapper>
      {patient?.codeStatus ? (
        <CodeStatusWrapper>
          <WithTooltip
            title={CODE_STATUS_TYPES[patient.codeStatus]?.title}
            left>
            <CodeStatusBadge
              codeColor={CODE_STATUS_TYPES[patient.codeStatus]?.color}>
              {CODE_STATUS_TYPES[patient.codeStatus]?.abbr}
            </CodeStatusBadge>
          </WithTooltip>
        </CodeStatusWrapper>
      ) : null}
      <ProfileContent
        overflow={
          contentRef.current && contentRef.current.clientHeight > 300
            ? 'true'
            : 'false'
        }>
        <ProfileContentWrapper ref={contentRef}>
          <PhotoWrapper>
            <UserIcon user={patient} size="80px" patient />
          </PhotoWrapper>
          <Name>
            {patient.name ? `${patient.name.given} ${patient.name.family}` : ''}
          </Name>
          <PatientDetail>{`${getAge(
            patient.birthDate,
          )} | ${patient.gender.charAt(0)}`}</PatientDetail>
          {patient.conditions ? (
            <Conditions>
              {patient.conditions.map(condition => (
                <Diagnosis
                  key={condition.billingCode}
                  title={condition.billingCode}>
                  {condition.display}
                </Diagnosis>
              ))}
            </Conditions>
          ) : null}
        </ProfileContentWrapper>
      </ProfileContent>
      <WidgetFooterBtn onClick={openEHR}>View EHR</WidgetFooterBtn>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;

const CodeStatusWrapper = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 1;
`;

const CodeStatusBadge = styled.div`
  font-size: 9px;
  font-weight: ${props => props.theme.fontWeights.medium};
  color: white;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 0.5px;
  line-height: 1;
  padding: 5px 6px 4px;
  border-radius: 3px;
  background-color: ${props =>
    props.codeColor || props.theme.textColors.primary};
`;

const PhotoWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const Name = styled.p`
  display: block;
  font-family: ${props => props.theme.fonts.primary};
  font-size: 18px;
  font-weight: ${props => props.theme.fontWeights.bold};
  color: ${props => props.theme.textColors.primary};
  margin: 10px 0 0;
`;

const PatientDetail = styled.p`
  font-size: 13px;
  color: ${props => props.theme.textColors.tertiary};
  text-transform: uppercase;
  margin-top: 6px;
`;

const ProfileContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: ${props =>
    props.overflow === 'true' ? 'flex-start' : 'center'};
  align-items: center;
  text-align: center;
  padding: 25px;
  overflow-y: scroll;
`;

const ProfileContentWrapper = styled.div`
  position: relative;
`;

const Conditions = styled.div``;

const Diagnosis = styled.p`
  font-size: 12px;
  color: ${props => props.theme.textColors.tertiary};
  text-align: center;
  background-color: ${props => props.theme.backgroundColors.tertiary};
  padding: 6px;
  border-radius: 4px;
`;

export default ProfileWidget;
