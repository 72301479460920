import React from 'react';
import styled, { keyframes } from 'styled-components';
import { triangle } from 'polished';
import { FiPhoneCall } from 'react-icons/fi';
import GoogleMapReact from 'google-map-react';

const GOOGLE_MAPS_API = 'AIzaSyCD6Y6UoSHE2cgfhvxSFoN0YJBqvTztBe4';

const Map = ({ coordinates }) => (
  <GoogleMapReact
    bootstrapURLKeys={{ key: GOOGLE_MAPS_API }}
    defaultCenter={{ lat: coordinates.latitude, lng: coordinates.longitude }}
    defaultZoom={15}
    yesIWantToUseGoogleMapApiInternals>
    <Pin lat={coordinates.latitude} lng={coordinates.longitude}>
      <FiPhoneCall />
    </Pin>
  </GoogleMapReact>
);

const pulse = keyframes`
  0% {
    transform: translateY(-100%) translateX(-50%) scale(0.95);
    box-shadow: 0 0 0 0 rgba(87, 179, 250, 0.7);
  }
  70% {
    transform: translateY(-100%) translateX(-50%) scale(1);
    box-shadow: 0 0 0 14px rgba(87, 179, 250, 0);
  }
  100% {
    transform: translateY(-100%) translateX(-50%) scale(0.95);
    box-shadow: 0 0 0 0 rgba(87, 179, 250, 0);
  }
`;

const Pin = styled.div`
  position: relative;
  background-color: white;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(-100%) translateX(-50%) scale(1);

  animation-name: ${pulse};
  animation-duration: 2s;
  animation-iteration-count: infinite;

  border: 1px solid rgba(87, 179, 250, 0.2);

  svg {
    color: ${props => props.theme.colors.blue};
    font-size: 28px;
  }

  &::before {
    content: '';
    position: absolute;
    bottom: -7px;
    left: 50%;
    transform: translateX(-50%) translateY(100%);
    width: 20px;
    height: 3px;
    background-color: rgba(50, 50, 50, 0.4);
    border-radius: 100%;
    filter: blur(3px);
  }

  &::after {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%) translateY(100%);
    content: '';
    ${triangle({
      pointingDirection: 'bottom',
      width: '10px',
      height: '8px',
      foregroundColor: 'white',
    })};
  }
`;

export default Map;
