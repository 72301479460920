import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { RiSearchLine } from 'react-icons/ri';
import { useDebounce } from '../../hooks/useDebounce';

const SearchBar = ({ white, placeholder = 'Search', autofocus, onSearch }) => {
  const inputRef = useRef();

  const [searchTerm, setSearchTerm] = useState('');

  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  useEffect(() => {
    if (debouncedSearchTerm) onSearch(debouncedSearchTerm);
  }, [debouncedSearchTerm]);

  useEffect(() => {
    // focus search field automatically
    if (autofocus && inputRef.current) inputRef.current.focus();
  }, [autofocus, inputRef]);

  return (
    <SearchWrapper>
      <SearchInputWrapper white={white}>
        <RiSearchLine />
        <SearchInput
          ref={inputRef}
          placeholder={placeholder}
          onChange={e => setSearchTerm(e.target.value)}
          value={searchTerm}
        />
      </SearchInputWrapper>
    </SearchWrapper>
  );
};

const SearchWrapper = styled.div`
  position: relative;
  display: block;
  max-width: 360px;
  width: 100%;
`;

const SearchInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 15px;
  background-color: ${props =>
    props.white ? 'white' : props.theme.backgroundColors.lightAlt};
  padding: 10px 20px;

  svg {
    font-size: 18px;
    margin-right: 10px;
  }
`;

const SearchInput = styled.input`
  flex: 1;
  background-color: transparent;
  border: none;
  font-size: 16px;
  line-height: 30px;
  color: ${props => props.theme.textColors.primary};
  outline: none;
  padding: 0;

  &::placeholder {
    color: ${props => props.theme.textColors.secondary};
  }
`;

export default SearchBar;
