import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react';
import styled from 'styled-components';
import { differenceInSeconds } from 'date-fns';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import Charting from '../components/charting/Charting';
import EncounterManager from '../components/encounter/EncounterManager';
import CallEndScreen from '../components/video/CallEndScreen';
import PhotoCapture from '../components/video/PhotoCapture';
import VideoCall from '../components/video/VideoCall';
import { CallContext } from '../contexts/CallContext';
import { SystemContext } from '../contexts/SystemContext';
import { ROOM_PATIENT_INFO } from '../api/rooms';
import ErrorMessage from '../components/common/ErrorMessage';
import AlertCenter from '../components/common/AlertCenter';
import useAmplitude from '../hooks/useAmplitude';

const ERRORS = {
  badId: {
    title: "The patient room code you entered doesn't work.",
    description:
      'Make sure you entered an active patient room code in the URL, for example: https://bettermedics.org/video/<strong>xxxyyyyzzz</strong>',
  },
  disconnected: {
    title: 'Call Disconnected',
    description:
      'The call with the patient has become disconnected.<br />Please call the patient back on their phone number below.',
  },
  physicianNotNeeded: {
    title: 'Appointment Cancelled by Care Line',
    description:
      "This patient has been marked as no longer in need of Physician care. The Care Line representative's reasoning is listed below.",
  },
  carelineDisconnected: {
    title: 'Care Line Rep Disconnected',
    description:
      "The Care Line representative disconnected from the call before connecting you. They will need to start the patient's call over. Please exit and wait for a new call request.",
  },
};

const VideoPage = () => {
  const channel = useRef();
  const { room } = useParams();
  const { track } = useAmplitude();

  const {
    state: { status, careline, encounter, startTime },
    dispatch: dispatchCall,
  } = useContext(CallContext);
  const {
    state: { realmUser, pusher },
  } = useContext(SystemContext);

  const [roomPatient, setRoomPatient] = useState();
  const [showPhotoCapture, setShowPhotoCapture] = useState(false);
  const [showFlash, setShowFlash] = useState();
  const [showChat, setShowChat] = useState(false);
  const [forceEndCall, setForceEndCall] = useState(false);

  const [error, setError] = useState();

  const { data, error: queryError, loading } = useQuery(ROOM_PATIENT_INFO, {
    variables: { room },
  });

  const onEndCall = callStatus => {
    dispatchCall({
      type: 'SET_CALL',
      data: {
        status: callStatus,
      },
    });
  };

  const onOpenPhoto = () => {
    setShowChat(false);
    setShowPhotoCapture(true);
  };

  useEffect(() => {
    let flashTimer;
    if (showFlash) {
      flashTimer = setTimeout(() => {
        setShowFlash(false);
      }, 1000);
    }

    return () => clearTimeout(flashTimer);
  }, [showFlash]);

  useEffect(() => {
    if (queryError) {
      console.log('ROOM_PATIENT_INFO error: ', queryError);
      // assuming bad id error for now, but there could be others to plan for
      setError(ERRORS.badId);
      return;
    }
    if (data) {
      if (
        data.call &&
        data.call.status === 'active' &&
        realmUser.customData.role === 'careline'
      ) {
        // careline refreshed browser or somehow came back to an active call...
        // update call status to ended
        // will also take careline back to dashboard
        dispatchCall({
          type: 'SET_CALL',
          data: {
            ...data.call,
            status: 'ended',
          },
        });
        setForceEndCall(true);
        return;
      }

      // separate patient from call object so useEffect has a memo version that won't change on deep object changes to CallContext state
      setRoomPatient(data.call.patient);
      dispatchCall({
        type: 'SET_CALL',
        data: {
          ...data.call,
        },
      });
      console.log('VideoPage getCall: ', data.call);
      setError(null);

      // report in Amplitude that user has joined lobby
      const eventName =
        realmUser.customData.role === 'careline'
          ? 'CareLine Operator Joined Lobby'
          : 'Physician Joined Lobby';
      track(eventName, { 'Call Status': data?.call?.status }, true);
    }
  }, [room, data, queryError, realmUser]);

  const onShouldDisconnectPhysician = useCallback(
    data => {
      if (realmUser.customData.role === 'physician') {
        let err = ERRORS.physicianNotNeeded;
        err.description = `${err.description}<br /><br />${data.reason}`;
        setError(err);
      }
    },
    [realmUser],
  );

  useEffect(() => {
    const onMemberRemoved = member => {
      console.log('Member Removed: ', member, status);
      if (
        realmUser.customData.role === 'physician' &&
        (!careline ||
          (member.id === careline.id && !['active', 'ended'].includes(status)))
      ) {
        // logged in as physician, care line exited the room before you connected
        setError(ERRORS.carelineDisconnected);
      } else if (member.id === roomPatient.userId && status !== 'ended') {
        track(
          'Patient Disconnected',
          {
            'Patient ID': roomPatient.userId,
            'Duration in Seconds': differenceInSeconds(new Date(), startTime),
          },
          true,
        );
        dispatchCall({
          type: 'SET_CALL',
          data: {
            status: 'disconnected',
          },
        });
      }
    };

    const updateAMAStatus = async data => {
      dispatchCall({
        type: 'SET_AMA',
        data: {
          status: 'completed',
          timestamp: data.timestamp,
        },
      });

      await realmUser.functions.updateAMAStatus(encounter, 'completed');

      // track in Amplitude
      track('AMA Form Completed', {}, true);
    };

    const onCallEndedByPhysician = () => {
      if (realmUser.customData.role === 'careline') {
        dispatchCall({
          type: 'SET_CALL',
          data: {
            status: 'ended',
          },
        });
      }
    };

    if (roomPatient) {
      channel.current = pusher.subscribe(`presence-${room}`);
      channel.current.bind('pusher:member_removed', onMemberRemoved);
      channel.current.bind('disconnect-physician', onShouldDisconnectPhysician);
      channel.current.bind('end-call', onCallEndedByPhysician);
      if (realmUser.customData.role === 'physician')
        channel.current.bind('ama-complete', updateAMAStatus);
      dispatchCall({
        type: 'SET_CALL',
        data: {
          channel: channel.current,
        },
      });
    }

    return () => {
      if (roomPatient) {
        console.log('unsubscribeToPusherChannel');
        channel.current.unbind('pusher:member_removed', onMemberRemoved);
        channel.current.unbind(
          'disconnect-physician',
          onShouldDisconnectPhysician,
        );
        channel.current.unbind('end-call', onCallEndedByPhysician);
        if (realmUser.customData.role === 'physician')
          channel.current.unbind('ama-complete', updateAMAStatus);
        pusher.unsubscribe(`presence-${room}`);
      }
    };
  }, [room, roomPatient, status, startTime, pusher, realmUser]);

  useEffect(() => {
    const onBackButtonEvent = e => {
      e.preventDefault();

      window.alert('Browser back button disabled.');
      window.history.pushState(null, null, window.location.pathname);
    };

    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', onBackButtonEvent);

    return () => window.removeEventListener('popstate', onBackButtonEvent);
  }, []);

  const isDisconnected =
    status && ['disconnected', 'ended', 'patientCancelled'].includes(status);

  return (
    <Container>
      {error ? (
        <ErrorMessage {...error} contact={data?.patient} />
      ) : (
        <>
          <Column>
            <Cell
              collapseFrame={
                status === 'ended' &&
                realmUser?.customData?.role === 'physician'
              }>
              {loading ? null : isDisconnected ? (
                <CallEndScreen forceEnd={forceEndCall} />
              ) : status ? (
                <VideoCall
                  showPhotoCapture={showPhotoCapture}
                  onEmptyRoom={() => onEndCall('disconnected')}
                  onEndCall={() => onEndCall('ended')}
                />
              ) : null}
            </Cell>
          </Column>
          <Column full>
            <Cell>
              <Charting
                onPhoto={onOpenPhoto}
                onChat={() => setShowChat(true)}
              />
              {showPhotoCapture ? (
                <PhotoCapture
                  room={room}
                  onClose={() => setShowPhotoCapture(false)}
                />
              ) : null}
              {/* {data && data.call.onboarding ? (
                <Chat isVisible={showChat} onClose={() => setShowChat(false)} />
              ) : null} */}
            </Cell>
            <AlertCenter />
          </Column>
          <EncounterManager />
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  flex: 1;
`;

const Column = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: ${props => (props.full ? '100%' : 'auto')};
  height: 100%;
`;

const Cell = styled.div`
  position: relative;
  flex: 1;
  width: 100%;
  min-width: ${props => (props.collapseFrame ? '300px' : '460px')};
  height: 100%;
  transition: width 0.25s ease-in;
`;

export default VideoPage;
