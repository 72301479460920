import { useRef, useEffect } from 'react';

// support SSR - check if DOM is ready
const isBrowser = typeof window !== 'undefined';

const getScrollPosition = target => {
  if (!isBrowser || !target.current) return { top: 0, left: 0, bottom: null };

  return {
    top: target.current.scrollTop,
    left: target.current.scrollLeft,
    bottom:
      target.current.scrollHeight -
      target.current.scrollTop -
      target.current.clientHeight,
  };
};

const useScrollSpy = ({
  target, // useRef of scrollable div
  onChange, // callback when state changes btwn active/inactive (passes boolean)
  toBottom, // boolean: specific tracking for when max scroll is reached
  targetValue, // value to measure scrollPos against
  throttle, // time in ms for debounce
  horizontal, // horizontal scrolling mode
}) => {
  const isInitialMount = useRef(true);
  const position = useRef(getScrollPosition(target));

  let throttleTimeout = null;

  const callback = () => {
    // if previous state does not equal new state, update state
    const direction = horizontal ? 'left' : 'top';
    const wasActive = toBottom
      ? position.current.bottom === 0
      : position.current[direction] > targetValue;

    const newPos = getScrollPosition(target);

    const isActiveNow = toBottom
      ? newPos.bottom === 0
      : newPos[direction] > targetValue;

    if (wasActive !== isActiveNow) onChange(isActiveNow);

    // set values for next scroll
    position.current = newPos;
    throttleTimeout = null;
  };

  // tracks if browser is resized
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      callback();
    }

    const handleScroll = () => {
      if (throttle) {
        if (throttleTimeout === null) {
          throttleTimeout = setTimeout(callback, throttle);
        }
      } else {
        callback();
      }
    };

    target.current.addEventListener('scroll', handleScroll);

    // unmount -- kill listener
    return () => {
      if (target.current)
        target.current.removeEventListener('scroll', handleScroll);
    };
  }, []);
};

export default useScrollSpy;
