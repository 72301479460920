import React from 'react';
import { FaTimes } from 'react-icons/fa';
import styled from 'styled-components';

const DiagnosisPopover = ({ patient, onDismiss }) => (
  <Container>
    <Header>
      <Title>Diagnoses</Title>
      <CloseBtn onClick={onDismiss}>
        <FaTimes />
      </CloseBtn>
    </Header>

    <List>
      {!patient || !patient.conditions || patient.conditions.length === 0 ? (
        <Empty>No diagnoses</Empty>
      ) : (
        <>
          {patient.conditions.map(condition => {
            const { billingCode, display } = condition;
            return (
              <ListItem key={billingCode}>
                <Condition>{display}</Condition>
                <Code>{billingCode}</Code>
              </ListItem>
            );
          })}
        </>
      )}
    </List>
  </Container>
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.backgroundColors.lightAlt};
  border-radius: 8px;

  width: 500px;
  height: 400px;
  overflow: hidden;
  box-shadow: 0 5px 20px 0 rgb(21 27 38 / 18%);

  margin-top: 20px;
  transform: translateX(20px);
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 30px 20px 20px;
  background-color: white;
  border-bottom: 1px solid ${props => props.theme.colors.border};
`;

const Title = styled.h3`
  margin: 0;
  flex: 1;
`;

const CloseBtn = styled.button`
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  margin-left: auto;
  line-height: 1;

  svg {
    font-size: 22px;
    color: ${props => props.theme.textColors.secondary};
    opacity: 0.8;
  }

  &:hover {
    svg {
      opacity: 1;
    }
  }
`;

const List = styled.div`
  flex: 1;
  overflow-y: scroll;
  width: 100%;
  padding: 12px 20px;
`;

const ListItem = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 10px 10px;
  border: 1px solid ${props => props.theme.colors.border};
  margin-bottom: 20px;
  background-color: white;
`;

const Condition = styled.div`
  display: block;
  font-size: 14px;
  font-weight: ${props => props.theme.fontWeights.bold};
  color: ${props => props.theme.textColors.primary};
  margin-bottom: 5px;
  line-height: 1.3;
`;

const Code = styled.div`
  display: block;
  font-size: 13px;
  color: ${props => props.theme.textColors.secondary};
`;

const Empty = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  font-size: 14px;
  color: ${props => props.theme.textColors.secondary};
  text-align: center;
  height: 100%;
  width: 100%;
`;

export default DiagnosisPopover;
