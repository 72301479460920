import React, { useState, useContext, useEffect, useRef, useMemo } from 'react';
import styled from 'styled-components';
import { FaCheckCircle, FaRegCircle, FaPen } from 'react-icons/fa';
import { MdLocationOff } from 'react-icons/md';
import { useApolloClient } from '@apollo/client';
import Map from '../../Map';
import EditModal from '../../modals/EditModal';
import { decodeAddress } from '../../../hooks/useFormHelper';
import {
  TEMP_ADDRESS_FORM,
  TEMP_PHONE_FORM,
  PHONE_FORM,
  LOCATION_FORM,
} from '../../../../forms/personal';
import { HOME_STATUS_FORM } from '../../../../forms/intake';
import { CallContext } from '../../../contexts/CallContext';

const ChartingCarelineLocation = ({ onVerify }) => {
  const client = useApolloClient();

  const isInitialMount = useRef(true);
  const openForm = useRef();
  const {
    state: { homeStatus, patient, encounter, verifiedStatus },
    dispatch: dispatchCall,
  } = useContext(CallContext);

  const { location } = patient;
  const address = useMemo(
    () =>
      patient.address && patient.address.hasOwnProperty('home')
        ? patient.address.home
        : null,
    [patient],
  );
  const phone = useMemo(
    () =>
      patient.telecom && patient.telecom.hasOwnProperty('home')
        ? patient.telecom.home
        : null,
    [patient],
  );

  const [verified, setVerified] = useState({
    location: verifiedStatus.location,
    phone: verifiedStatus.location,
    homeStatus: verifiedStatus.location,
  });

  const [editMode, setEditMode] = useState(null);

  const onEdit = (formName, form, data) => {
    openForm.current = formName;

    const formattedData = formName === 'address' ? decodeAddress(data) : data;

    setEditMode({
      config: {
        targetId: encounter,
        form,
      },
      data: formattedData,
      type: 'form',
    });
  };

  const onVerifyField = (section, status) => {
    setVerified({
      ...verified,
      [section]: status,
    });
  };

  const onFormSave = async (variables, permanentChange) => {
    console.log(variables);

    if (permanentChange && openForm.current !== 'homeStatus') {
      const mutation =
        openForm.current === 'address'
          ? LOCATION_FORM.mutation
          : PHONE_FORM.mutation;
      try {
        await client.mutate({
          mutation,
          variables: {
            ...variables,
            id: patient.userId,
          },
        });
      } catch (err) {
        console.log('could not update patient record: ', err);
      }
    }

    delete variables.id;

    const newData =
      openForm.current === 'address'
        ? { address: { home: variables } }
        : openForm.current === 'phone'
        ? { telecom: { home: variables.phone } }
        : { homeStatus: variables.homeStatus };

    const callType =
      openForm.current === 'homeStatus' ? 'SET_CALL' : 'SET_PATIENT';

    dispatchCall({
      type: callType,
      data: newData,
    });

    openForm.current = null;
  };

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }

    const allVerified = Object.values(verified).every(Boolean);
    onVerify(allVerified);
  }, [verified]);

  return (
    <Container>
      <LocationInfo>
        <TextItem verified={verified.location}>
          <Label>Verify Location</Label>
          {address ? (
            <>
              <Text>{address.line ? address.line[0] : ''}</Text>
              <Text>
                {address.line && address.line[1] ? `${address.line[1]}` : ''}
              </Text>
              <Text>{`${address.city}, ${address.state} ${address.postalCode}`}</Text>
              <Text reduced>{address.directions}</Text>
            </>
          ) : (
            <Text>-</Text>
          )}
          <StatusOrb
            onClick={() => onVerifyField('location', !verified.location)}>
            {verified.location ? <FaCheckCircle /> : <FaRegCircle />}
          </StatusOrb>
          <EditBtn
            onClick={() => onEdit('address', TEMP_ADDRESS_FORM, address)}>
            <FaPen />
          </EditBtn>
        </TextItem>
        <TextItem verified={verified.phone}>
          <Label>Verify Phone</Label>
          <Text>{phone || '-'}</Text>
          <StatusOrb onClick={() => onVerifyField('phone', !verified.phone)}>
            {verified.phone ? <FaCheckCircle /> : <FaRegCircle />}
          </StatusOrb>
          <EditBtn onClick={() => onEdit('phone', TEMP_PHONE_FORM, { phone })}>
            <FaPen />
          </EditBtn>
        </TextItem>
        <TextItem verified={verified.homeStatus}>
          <Label>Verify Home Status</Label>
          <Text>{homeStatus || <em>[ Not entered ]</em>}</Text>
          <StatusOrb
            onClick={() => onVerifyField('homeStatus', !verified.homeStatus)}>
            {verified.homeStatus ? <FaCheckCircle /> : <FaRegCircle />}
          </StatusOrb>
          <EditBtn
            onClick={() =>
              onEdit('homeStatus', HOME_STATUS_FORM, { homeStatus })
            }>
            <FaPen />
          </EditBtn>
        </TextItem>
        {editMode ? (
          <EditModal
            data={editMode.data}
            config={editMode.config}
            onFormSave={onFormSave}
            onClose={() => setEditMode(null)}
          />
        ) : null}
      </LocationInfo>
      <LocationMap>
        {location ? (
          <Map coordinates={location} />
        ) : (
          <LocationError>
            <MdLocationOff />
            <LocationLabel>GPS location not shared</LocationLabel>
          </LocationError>
        )}
      </LocationMap>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: row;
`;

const LocationInfo = styled.div`
  position: relative;
  padding: 40px 20px;
  flex: 1;
  overflow-y: scroll;
  height: calc(100% - 0px);
`;

const LocationMap = styled.div`
  height: 100%;
  width: 320px;
  border-left: 1px solid ${props => props.theme.colors.border};
  background-color: ${props => props.theme.backgroundColors.lightAlt};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StatusOrb = styled.div`
  position: absolute;
  background-color: #fefeff;
  line-height: 1;
  cursor: ${props => (props.onClick ? 'pointer' : 'default')};
  pointer-events: ${props => (props.onClick ? 'auto' : 'none')};
`;

const TextItem = styled.div`
  position: relative;
  margin-bottom: 46px;
  padding-left: 75px;
  padding-right: 50px;

  label {
    color: ${props =>
      props.verified
        ? props.theme.colors.green
        : props.theme.textColors.tertiary};
  }

  svg {
    font-size: ${props => (props.verified ? '26px' : '18px')};
    color: ${props =>
      props.verified ? props.theme.colors.green : props.theme.colors.border};
  }

  ${StatusOrb} {
    top: ${props => (props.verified ? '-7px' : '-3px')};
    left: ${props => (props.verified ? '20px' : '24px')};
  }

  &:hover {
    label {
      color: ${props =>
        props.verified
          ? props.theme.colors.green
          : props.disabled
          ? props.theme.textColors.tertiary
          : props.theme.colors.primary};
    }

    ${({ disabled, verified, theme }) =>
      !disabled &&
      `
      ${StatusOrb} {
        top: -7px;
        left: 20px;

        svg {
          font-size: 26px;
          color: ${verified ? theme.colors.green : theme.colors.primary};
        }
      }
    `}
  }
`;

const Label = styled.label`
  display: block;
  font-family: ${props => props.theme.fonts.secondary};
  font-weight: ${props => props.theme.fontWeights.medium};
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 15px;
`;

const Text = styled.p`
  color: ${props => props.theme.textColors.primary};
  font-size: ${props => (props.reduced ? '18px' : '24px')};
  font-weight: ${props => props.theme.fontWeights.bold};
  line-height: 1.5;
  margin: 0;
  margin-top: ${props => (props.reduced ? '24px' : '0')};

  em {
    font-style: italic;
  }
`;

const EditBtn = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-30%);
  width: 44px;
  height: 44px;
  border-radius: 50%;
  display: flex;
  background-color: white;
  justify-content: center;
  align-items: center;
  border: 2px solid ${props => props.theme.colors.border};
  cursor: pointer;

  &:hover {
    border-color: ${props => props.theme.colors.blue};

    svg {
      color: ${props => props.theme.colors.blue};
    }
  }

  svg {
    font-size: 16px;
    color: ${props => props.theme.textColors.secondary};
  }
`;

const LocationError = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  svg {
    font-size: 36px;
    color: ${props => props.theme.textColors.tertiary};
    opacity: 0.8;
  }
`;

const LocationLabel = styled.p`
  margin: 20px 0 0;
  font-size: 14px;
  color: ${props => props.theme.textColors.tertiary};
  text-align: center;
`;

export default ChartingCarelineLocation;
