import React from 'react';
import styled from 'styled-components';

const SelfieGrid = () => {
  return (
    <Container>
      <SelfieBox>
        <Text>Video pausing for photo</Text>
      </SelfieBox>
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0.2;
  pointer-events: none;
`;

const SelfieBox = styled.div`
  display: flex;
  width: 460px;
  height: 460px;
  border: 7px dashed white;
  justify-content: center;
  align-items: center;

  > svg {
    color: white;
    height: 40%;
    width: 40%;
    opacity: 0.5;
  }
`;

const Text = styled.div`
  text-align: center;
  font-size: 24px;
  color: white;
  text-shadow: 1px 2px rgba(0, 0, 0, 0.4);
`;

export default SelfieGrid;
