import React, { useState, useEffect, useContext } from 'react';
import { useQuery } from '@apollo/client';
import { GET_MEDICATIONS } from '../../../../api/medications';
import TableWidget from '../../../tables/TableWidget';
import { CallContext } from '../../../../contexts/CallContext';

const OTC_TABLE = [
  {
    id: 'display',
    label: 'Name',
    flex: 1,
  },
];

const DAILY_TABLE = [
  {
    id: 'medication.display',
    label: 'Name',
    flex: 1,
  },
];

const MedicationsWidget = ({ patient, encounter, medications }) => {
  const {
    state: { channel },
  } = useContext(CallContext);

  const [activeTab, setActiveTab] = useState('otc');

  const { loading, error, data: daily, refetch } = useQuery(GET_MEDICATIONS, {
    variables: { patient },
  });

  useEffect(() => {
    if (channel) channel.bind('update-medications', refetch);

    return () => {
      if (channel) channel.unbind('update-medications', refetch);
    };
  }, [channel, refetch]);

  const changeTab = id => setActiveTab(id);

  const onEdit = () => {};

  return (
    <TableWidget
      title="Medications"
      onEdit={onEdit}
      loading={loading}
      data={activeTab === 'otc' ? medications : daily.medications}
      config={activeTab === 'otc' ? OTC_TABLE : DAILY_TABLE}
      emptyNotice="No medications listed"
      activeTab={activeTab}
      tabs={[
        {
          id: 'otc',
          label: 'OTC',
        },
        {
          id: 'daily',
          label: 'Daily',
        },
      ]}
      onTabChange={changeTab}
    />
  );
};

export default MedicationsWidget;
