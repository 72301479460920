import React, { useContext } from 'react';
import styled from 'styled-components';
import { FaAmbulance, FaAsterisk } from 'react-icons/fa';
import { GoStop } from 'react-icons/go';
import {
  MdVideocam,
  MdVideocamOff,
  MdMic,
  MdMicOff,
  MdCallEnd,
  MdZoomOutMap,
} from 'react-icons/md';
import { CallContext } from '../../contexts/CallContext';
import { ModalContext } from '../../contexts/ModalContext';
import { SystemContext } from '../../contexts/SystemContext';
import AMAModal from '../modals/AMAModal';
import EMSModal from '../modals/EMSModal';
import WithTooltip from '../common/WithTooltip';

const VideoControlBar = ({
  status,
  audioEnabled,
  videoEnabled,
  onAudio,
  onVideo,
  endConfirmShowing,
  onEnd,
}) => {
  const {
    state: { callType, physicianStatus, ama },
  } = useContext(CallContext);
  const { dispatch: dispatchModal } = useContext(ModalContext);
  const {
    state: { realmUser },
  } = useContext(SystemContext);

  const openModal = modal => {
    // open ems modal
    dispatchModal({
      type: 'ADD_MODAL',
      data: {
        component: modal === 'ems' ? EMSModal : AMAModal,
        props: {
          maxWidth: '640px',
        },
      },
    });
  };

  console.log('status: ', status);

  return (
    <ControlBar>
      <ControlButton
        onClick={onVideo}
        active={videoEnabled}
        disabled={['error', 'connecting'].includes(status)}>
        {videoEnabled ? <MdVideocam /> : <MdVideocamOff />}
      </ControlButton>
      <ControlButton
        onClick={onAudio}
        active={audioEnabled}
        disabled={['error', 'connecting'].includes(status)}>
        {audioEnabled ? <MdMic /> : <MdMicOff />}
      </ControlButton>
      <CallButtons>
        {callType !== 'onboarding' && status === 'active' ? (
          <>
            {realmUser && realmUser.customData.role === 'physician' ? null : (
              <WithTooltip title="Call EMS">
                <ControlButton emergency onClick={() => openModal('ems')}>
                  <FaAmbulance />
                </ControlButton>
              </WithTooltip>
            )}
            {realmUser &&
            realmUser.customData.role === 'physician' &&
            ama &&
            ama.status !== 'complete' ? (
              <WithTooltip title="Refusing Care">
                <ControlButton
                  disabled={ama && ['sent', 'completed'].includes(ama.status)}
                  emergency
                  onClick={() => openModal('ama')}>
                  <GoStop />
                </ControlButton>
              </WithTooltip>
            ) : null}
          </>
        ) : null}
        {status === 'active' &&
        (realmUser.customData.role !== 'physician' ||
          (realmUser.customData.role === 'physician' &&
            physicianStatus === 'physicianConnected')) ? (
          <WithTooltip title="End Call">
            <CallEndButton disabled={endConfirmShowing} onClick={onEnd}>
              <MdCallEnd />
            </CallEndButton>
          </WithTooltip>
        ) : null}
      </CallButtons>
    </ControlBar>
  );
};

const ControlBar = styled.div`
  display: flex;
  padding: 20px 0 0;
`;

const ControlButton = styled.div`
  display: inline-flex;
  margin-right: 15px;
  background-color: ${props =>
    props.disabled
      ? 'black'
      : props.active
      ? '#333'
      : props.primary
      ? props.theme.colors.primary
      : props.blue
      ? props.theme.colors.blue
      : props.tertiary
      ? props.theme.colors.tertiary
      : 'white'};
  color: ${props =>
    props.whiteText
      ? 'white'
      : props.emergency
      ? props.theme.colors.primary
      : 'black'};
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 42px;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
  opacity: ${props => (props.disabled ? 0.4 : 1)};

  font-size: 12px;
  font-weight: ${props => props.theme.fontWeights.bold};
  text-transform: uppercase;

  svg {
    color: ${props =>
      props.active || props.disabled || props.primary
        ? 'white'
        : props.emergency
        ? props.theme.colors.primary
        : 'black'};
    font-size: 24px;
  }

  &:active {
    opacity: 0.7;
  }
`;

const CallEndButton = styled(ControlButton)`
  background-color: ${props => props.theme.colors.primary};
  margin-right: inherit;
  margin-left: auto;

  opacity: ${props => (props.disabled ? 0.2 : 1)};
  pointer-events: ${props => (props.disabled ? 'none' : 'all')};
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};

  svg {
    color: white;
  }
`;

const CallButtons = styled.div`
  display: inline-flex;
  margin-left: auto;

  > div {
    margin-left: 15px;
    margin-right: 0;

    ${ControlButton} {
      margin-right: 0;
    }
  }
`;

export default VideoControlBar;
