import React from 'react';
import styled from 'styled-components';

const EmptyNotice = ({ lightAlt, secondary, marginBottom, children }) => (
  <Empty lightAlt={lightAlt} secondary={secondary} marginBottom={marginBottom}>
    {children}
  </Empty>
);

const Empty = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${props =>
    props.secondary ? 'white' : props.theme.textColors.primary};
  padding: 40px 20px;
  border-radius: 20px;
  background-color: ${props =>
    props.secondary
      ? 'rgba(255,255,255,0.15)'
      : props.lightAlt
      ? '#F0F1F3'
      : props.theme.backgroundColors.lightAlt};
  min-width: 200px;
  max-width: 480px;
  margin: 0 auto;
  margin-bottom: ${props =>
    props.marginBottom ? `${props.marginBottom}px` : 0};
  text-align: center;
  line-height: 1.4;
`;

export default EmptyNotice;
