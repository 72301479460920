import React from 'react';
import styled from 'styled-components';
import { lighten } from 'polished';
import { FaPlus } from 'react-icons/fa';

const AddButton = ({ onClick }) => (
  <Button onClick={onClick}>
    <FaPlus />
  </Button>
);

const Button = styled.button`
  display: block;
  border: 0;
  outline: 0;
  width: 70px;
  height: 70px;
  border-radius: 40px;

  position: fixed;
  right: 30px;
  bottom: 30px;
  z-index: 200;

  justify-content: center;
  align-items: center;

  background-color: ${props => props.theme.colors.primary};
  cursor: pointer;

  box-shadow: 0 10px 30px rgba(249, 86, 110, 0.3);

  svg {
    font-size: 28px;
    line-height: 1;
    color: white;
  }

  &:hover {
    background-color: ${props => lighten(0.04, props.theme.colors.primary)};
  }

  &:active {
    box-shadow: 0 8px 25px rgba(249, 86, 110, 0.3);
    background-color: ${props => lighten(0.08, props.theme.colors.primary)};
  }
`;

export default AddButton;
