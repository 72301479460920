import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import { GET_INTAKE } from '../../../api/intake';
import { GET_VITALS } from '../../../api/vitals';
import Loader from '../../common/Loader';
import VitalBox from '../../vitals/VitalBox';
import AllergiesWidget from './widgets/AllergiesWidget';
import MedicationsWidget from './widgets/MedicationsWidget';
import ProfileWidget from './widgets/ProfileWidget';
import VisitSummaryWidget from './widgets/VisitSummaryWidget';
import { CallContext } from '../../../contexts/CallContext';
import { VitalsContext } from '../../../contexts/VitalsContext';
import { SystemContext } from '../../../contexts/SystemContext';
import { CODE_STATUS_TYPES } from '../../../../constants';

const VITALS = {
  temperature: {
    label: 'Temperature',
    columns: '1/2',
    events: {
      connected: 'thermometer-connected',
      disconnected: 'thermometer-disconnected',
      ready: 'thermometer-ready',
    },
  },
  pulseox: {
    label: 'Pulse',
    columns: '2/3',
    events: {
      connected: 'pulseox-connected',
      disconnected: 'pulseox-disconnected',
      ready: 'pulseox-ready',
    },
  },
  bp: {
    label: 'Blood Pressure',
    columns: '4/6',
    events: {
      connected: 'bp-connected',
      disconnected: 'bp-disconnected',
      ready: 'bp-ready',
    },
  },
};

const ChartingPhysicianIntake = ({ patient, encounter, openEHR }) => {
  const {
    state: { channel, status, physicianStatus, room },
    dispatch: dispatchCall,
  } = useContext(CallContext);
  const { dispatch: dispatchVitals } = useContext(VitalsContext);
  const {
    state: { realmUser },
  } = useContext(SystemContext);

  const [vitals, setVitals] = useState(VITALS);

  const { loading, data, refetch } = useQuery(GET_INTAKE, {
    variables: { encounter },
  });

  const {
    loading: loadingVitals,
    data: vitalsData,
    refetch: refreshVitals,
  } = useQuery(GET_VITALS, {
    variables: { encounter },
  });

  useEffect(() => {
    let _vitals = VITALS;

    // when data returns from server add 'data' object to each VITALS config
    if (vitalsData && vitalsData.vitals) {
      for (let i = 0; i < vitalsData.vitals.length; i++) {
        const vital = vitalsData.vitals[i];
        _vitals[vital.type].data = vital;
      }
      setVitals({ ..._vitals });

      dispatchVitals({ type: 'SET_VITALS', data: _vitals });
    }
  }, [vitalsData]);

  useEffect(() => {
    const getDeviceStatuses = async () => {
      // to setup Physician UI, ask iPad to report current status of devices
      try {
        realmUser.functions.sendPusherEvent(
          `presence-${room}`,
          'get-device-statuses',
        );
      } catch (err) {
        console.log('Pusher error requesting device statuses', err);
      }
    };

    if (channel) {
      channel.bind('update-intake', refetch);
      channel.bind('update-vitals', refreshVitals);

      getDeviceStatuses();
    }

    return () => {
      if (channel) {
        channel.unbind('update-intake', refetch);
        channel.unbind('update-vitals', refreshVitals);
      }
    };
  }, [channel, refetch, realmUser, room]);

  useEffect(() => {
    // if encounter-level patient updates were saved, add them to sessioned patient record
    if (data) {
      const encounterPatientUpdates = {};
      if (data.encounter.phoneNumber)
        encounterPatientUpdates.telecom = { phone: data.encounter.phoneNumber };
      if (data.encounter.address)
        encounterPatientUpdates.address = { home: data.encounter.address };
      dispatchCall({
        type: 'SET_PATIENT',
        data: encounterPatientUpdates,
      });
      dispatchCall({
        type: 'SET_CALL',
        data: { homeStatus: data.encounter.homeStatus },
      });
    }
  }, [data]);

  return loading ? null : (
    <Container>
      <CodeStatus codeColor={CODE_STATUS_TYPES[patient.codeStatus]?.color} />
      <Content>
        <WidgetGrid>
          <Box columns="1/3">
            <ProfileWidget patient={patient} openEHR={openEHR} />
          </Box>
          <Box columns="3/7">
            <VisitSummaryWidget encounter={encounter} notes={data} />
          </Box>

          {Object.keys(vitals).map(id => (
            <Box key={id} columns="span 2">
              {loadingVitals ? (
                <Loader size="small" gray />
              ) : (
                <VitalBox
                  {...vitals[id]}
                  id={id}
                  widget
                  data={vitals[id].data ? vitals[id].data.value : null}
                  device={vitals[id].data ? vitals[id].data.device : null}
                  vitalId={vitals[id].data ? vitals[id].data._id : null}
                  disabled={
                    status !== 'active' ||
                    physicianStatus !== 'physicianConnected'
                  }
                  onRefresh={refreshVitals}
                />
              )}
            </Box>
          ))}
          <Box columns="1/4">
            <MedicationsWidget
              patient={patient.userId}
              encounter={encounter}
              medications={data.encounter.medications}
            />
          </Box>
          <Box columns="4/7">
            <AllergiesWidget patient={patient.userId} encounter={encounter} />
          </Box>
        </WidgetGrid>
      </Content>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: ${props => props.theme.backgroundColors.secondary};
  overflow: hidden;
`;

const CodeStatus = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  width: 680px;
  height: 680px;
  border-radius: 50%;
  background-color: ${props => props.codeColor || props.theme.colors.blue};
  transform: translateX(-35.3%) translateY(-50.1%);
`;

const Content = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  height: 100%;
  width: 100%;
  padding-right: 20px;
  overflow-y: scroll;
`;

const WidgetGrid = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: 380px 254px 480px;
  grid-gap: 30px;
  grid-auto-rows: minmax(120px, 480px);
  padding: 30px 30px 120px;
`;

const Box = styled.div`
  position: relative;
  background-color: white;
  box-shadow: 0 4px 6px 4px rgba(0, 0, 0, 0.02);
  border-radius: 20px;

  grid-column: ${props => props.columns || 'auto'};
  grid-row: ${props => props.rows || 'auto'};
  overflow: hidden;
`;

export default ChartingPhysicianIntake;
