import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { lighten } from 'polished';
import { useMutation } from '@apollo/client';
import { FaStar, FaRegStar } from 'react-icons/fa';
import { formatName } from '../../hooks/useTextHelper';
import WithTooltip from '../common/WithTooltip';
import RequestModal from '../modals/RequestModal';
import UserIcon from '../common/UserIcon';
import { AlertContext } from '../../contexts/AlertContext';
import { ModalContext } from '../../contexts/ModalContext';
import { UPDATE_STAR_STATUS } from '../../api/requests';

const RequestListItem = ({ data }) => {
  const { dispatch: dispatchAlert } = useContext(AlertContext);
  const { dispatch: dispatchModal } = useContext(ModalContext);

  const [starred, setStarred] = useState(false);

  const [updateStarStatus] = useMutation(UPDATE_STAR_STATUS, {
    onCompleted() {
      setStarred(!starred);
      window.dispatchEvent(new Event('refreshRequestData'));
    },
  });

  const onStarClick = (event, requestId) => {
    event.stopPropagation();

    updateStarStatus({
      variables: {
        id: requestId,
        starred: !starred,
      },
    });
  };

  const openTask = () => {
    if (!data.patient) {
      dispatchAlert({
        type: 'ADD_ALERT',
        data: {
          message:
            'Request does not have a patient assigned. Cannot open request.',
        },
      });
      return;
    }

    dispatchModal({
      type: 'ADD_MODAL',
      data: {
        component: RequestModal,
        props: {
          request: data,
          maxWidth: '640px',
        },
      },
    });
  };

  useEffect(() => {
    if (data) {
      setStarred(data.starred);
    }
  }, [data]);

  return (
    <ListItem onClick={openTask}>
      <Icon>
        <WithTooltip title={formatName(data?.patient)}>
          <UserIcon user={data?.patient} size="50px" patient />
        </WithTooltip>
      </Icon>
      <TaskInfo>
        <Category>{data.type}</Category>
        <TaskTitle>{data.description}</TaskTitle>
      </TaskInfo>
      {data.createdBy ? (
        <Author>
          <UserIcon user={data.createdBy} size="24px" inline />
          <AuthorName>{formatName(data.createdBy)}</AuthorName>
        </Author>
      ) : null}

      <Starred active={starred} onClick={e => onStarClick(e, data._id)}>
        <WithTooltip title={starred ? 'Remove Star' : 'Add Star'} left>
          {starred ? <FaStar /> : <FaRegStar />}
        </WithTooltip>
      </Starred>
    </ListItem>
  );
};

const ListItem = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  border: 1px solid transparent;
  cursor: pointer;

  margin-bottom: 15px;

  &:hover {
    border-color: ${props => props.theme.colors.border};
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const Icon = styled.span`
  display: flex;
  padding: 25px 30px;
`;

const TaskInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 30px 20px 0;
  flex: 1;
`;

const Category = styled.p`
  margin: 0 0 6px;
  font-size: 10px;
  color: ${props => props.theme.colors.blue};
  letter-spacing: 2px;
  text-transform: uppercase;
`;

const TaskTitle = styled.div`
  font-size: 14px;
  color: ${props => props.theme.textColors.primary};
  font-weight: ${props => props.theme.fontWeights.bold};
`;

const Author = styled.div`
  margin-left: auto;
  align-self: flex-end;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 15px 10px 20px;
`;

const AuthorName = styled.div`
  font-size: 12px;
  margin-left: 6px;
  color: ${props => props.theme.textColors.tertiary};
`;

const Starred = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;

  svg {
    font-size: 20px;
    color: ${props =>
      props.active ? props.theme.colors.tertiary : props.theme.colors.border};
  }

  &:hover {
    svg {
      color: ${props =>
        props.active
          ? lighten(0.1, props.theme.colors.tertiary)
          : props.theme.colors.tertiary};
    }
  }
`;

export default RequestListItem;
