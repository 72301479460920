import gql from 'graphql-tag';
import { ALLERGY_FRAGMENTS } from './allergies';
import { ENCOUNTER_FRAGMENTS } from './encounters';
import { MEDICATION_FRAGMENTS } from './medications';

// FULL MEDICAL INFORMATION  (Allergies, Medications, History)

/**
 * QUERIES
 */

export const MEDICAL_RECORD = gql`
  query GET_MEDICAL_RECORD($patient: String!) {
    allergies(query: { patient: $patient }) {
      ...AllergyStandard
    }
    medicalVisits(input: { patient: $patient }) {
      ...EncounterHistory
    }
    callHistory: encounters(query: { patient: $patient, location: "BetterMedics", OR: [{ diagnosis_exists: true }, { reason_exists: true }] }, sortBy: _ID_DESC) {
      careteam {
        careline {
          firstName
          lastName
          photo
        }
        physician {
          firstName
          lastName
          photo
        }
      }
      ...EncounterHistory
    }
    medications(query: { patient: $patient }) {
      ...MedicationStandard
    }
    dat: patient(query: { userId: $patient }) {
      drugs
      alcohol
      tobacco
    }
    vaccine: patient(query: { userId: $patient }) {
      covidVaccine
      covidBoosters
      covidPositive
    }
  }
  ${ALLERGY_FRAGMENTS.standard}
  ${ENCOUNTER_FRAGMENTS.history}
  ${MEDICATION_FRAGMENTS.standard}
`;

/**
 * MUTATIONS
 */

export const UPDATE_DAT = gql`
  mutation UPDATE_DAT(
    $id: String!
    $alcohol: String
    $tobacco: String
    $drugs: String
  ) {
    updateOnePatient(
      query: { userId: $id }
      set: { alcohol: $alcohol, tobacco: $tobacco, drugs: $drugs }
    ) {
      userId
      alcohol
      tobacco
      drugs
    }
  }
`;

export const UPDATE_VACCINE = gql`
  mutation UPDATE_VACCINE(
    $id: String!
    $covidVaccine: String
    $covidBoosters: String
    $covidPositive: String
  ) {
    updateOnePatient(
      query: { userId: $id }
      set: {
        covidVaccine: $covidVaccine
        covidBoosters: $covidBoosters
        covidPositive: $covidPositive
      }
    ) {
      userId
      covidVaccine
      covidBoosters
      covidPositive
    }
  }
`;
