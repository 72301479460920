import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { darken } from 'polished';
import { useMutation } from '@apollo/client';
import { removeScriptTags } from '../../hooks/useFormHelper';
import UserIcon from '../common/UserIcon';
import { SystemContext } from '../../contexts/SystemContext';
import { ADD_NEW_COMMENT } from '../../api/requests';

const RequestModalChatBar = ({ requestId, onNewComment }) => {
  const {
    state: { realmUser, userId },
  } = useContext(SystemContext);

  const [chatExpanded, setChatExpanded] = useState(false);
  const [note, setNote] = useState('');

  const [postComment] = useMutation(ADD_NEW_COMMENT, {
    onCompleted() {
      // reset view
      setChatExpanded(false);
      setNote('');

      onNewComment();
    },
  });

  const onComment = () => {
    postComment({
      variables: {
        author: userId,
        comment: note,
        date: new Date(),
        request: requestId,
      },
    });
  };

  return (
    <ChatBar>
      <UserIcon size="42px" user={realmUser.customData} inline />
      <Fieldset expanded={chatExpanded}>
        <textarea
          value={note}
          placeholder="Leave a note for yourself..."
          onChange={e => setNote(removeScriptTags(e.target.value))}
          onFocus={() => setChatExpanded(true)}
          onBlur={() => setChatExpanded(false)}
          rows={chatExpanded ? 3 : 1}
        />
        <ChatActions>
          <CommentBtn disabled={note.length < 1} onClick={onComment}>
            Comment
          </CommentBtn>
        </ChatActions>
      </Fieldset>
    </ChatBar>
  );
};

const ChatActions = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 6px 12px 8px;
`;

const CommentBtn = styled.button`
  background-color: ${props => props.theme.colors.blue};
  border: none;
  border-radius: 4px;
  color: white;
  font-size: 12px;
  cursor: pointer;
  height: 32px;
  padding: 0 15px;

  &:hover {
    background-color: ${props => darken(0.05, props.theme.colors.blue)};
  }
`;

const Fieldset = styled.div`
  display: flex;
  flex-direction: column;
  transition: all 0.2s ease-in;
  height: 140px;
  border: 1px solid ${props => props.theme.colors.tertiary};
  border-radius: 10px;
  overflow: hidden;
  background-color: white;
  flex: 1;
  margin-left: 10px;

  ${({ expanded, theme }) =>
    !expanded &&
    `
    line-height: 1;
    height: 44px;
    border-color: ${theme.colors.border};

    textarea {
      padding-top: 10px;
    }
  `}

  textarea {
    resize: none;
    font-size: 15px;
    border: none;
    outline: none;
    width: 100%;
    transition: all 0.17s ease-in;
  }
`;

const ChatBar = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: ${props => props.theme.backgroundColors.lightAlt};
  padding: 10px 24px;
  box-shadow: ${props =>
    `0 -5px 5px rgba(${props.theme.colors.borderRGB}, 0.2)`};
  border-top: 1px solid ${props => `rgba(${props.theme.colors.borderRGB}, 0.4)`};
  display: flex;
  flex-direction: row;
`;

export default RequestModalChatBar;
