import { UPDATE_HOME_STATUS } from '../App/api/intake';

export const INTAKE_FORM = {
  fields: [
    {
      id: 'reason',
      type: 'text-input',
      label: 'Reason for Visit',
      placeholder: 'ex. Sore throat, ear ache and not feeling well',
    },
    {
      id: 'contextualFactors',
      type: 'text-input',
      label: 'Contextual Factors',
      placeholder: 'ex. Just got out of nursing home',
    },
    {
      id: 'exacerbatingFactors',
      type: 'text-input',
      label: 'Exacerbating Factors',
      placeholder: 'ex. Worse at night when trying to swallow',
    },
    {
      id: 'relievingFactors',
      type: 'text-input',
      label: 'Relieving Factors',
      placeholder: 'ex. Put cold compress, then hot compress',
    },
    {
      id: 'medications',
      type: 'search',
      label: 'OTC Medications in Last 24 Hours',
      config: {
        placeholder: 'Lookup Medications',
        mode: 'medications',
        multiple: true,
        width: '510px',
      },
    },
    {
      id: 'notes',
      type: 'textarea',
      label: 'Notes',
    },
  ],
  maxWidth: '640px',
};

export const HOME_STATUS_FORM = {
  fields: [
    {
      id: 'homeStatus',
      type: 'textarea',
      label: 'Home Status',
      placeholder:
        'Who is currently with the patient? Are they able to assist with care?',
      config: {
        rows: 5,
      },
    },
  ],
  center: true,
  mutation: UPDATE_HOME_STATUS,
};
