import React from 'react';
import styled from 'styled-components';

const ContextPopover = ({ actions, onAction, onDismiss }) => {
  const onActionClick = id => {
    onAction(id); // do action
    onDismiss(); // close popover
  };

  return (
    <Container>
      {actions.map(action => (
        <ActionBtn key={action.id} onClick={() => onActionClick(action.id)}>
          {action.label}
        </ActionBtn>
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: block;
  background-color: white;
  border-radius: 8px;

  max-width: 500px;
  min-width: 185px;
  padding: 4px 0;
  overflow: hidden;
  border: 1px solid ${props => props.theme.colors.border};
  box-shadow: 0 5px 20px 0 rgb(21 27 38 / 8%);
`;

const ActionBtn = styled.div`
  cursor: pointer;
  padding: 14px 20px;
  font-size: 14px;

  &:hover {
    background-color: ${props => props.theme.backgroundColors.lightAlt};
  }

  &:not(:first-child) {
    border-top: 1px solid ${props => props.theme.colors.border};
  }
`;

export default ContextPopover;
