import React, { useState, useContext, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { FaPills, FaRegCalendarAlt, FaSearch } from 'react-icons/fa';
import { ModalContext } from '../contexts/ModalContext';
import { PopoverContext } from '../contexts/PopoverContext';
import { SystemContext } from '../contexts/SystemContext';
import logo from '../../assets/images/logo.svg';
import bgLeft from '../../assets/images/background-bot-left.jpg';
import bgRight from '../../assets/images/background-top-right.jpg';
import EncounterManager from '../components/encounter/EncounterManager';
import ContextPopover from '../components/popovers/ContextPopover';
import IframeModal from '../components/modals/IframeModal';
import ModalManager from '../components/modals/ModalManager';
import ScheduleModal from '../components/modals/ScheduleModal';
import TimeoutModal from '../components/modals/TimeoutModal';
import Popover from '../components/popovers/Popover';
import SearchPopover from '../components/popovers/SearchPopover';
import UserIcon from '../components/common/UserIcon';
import WithTooltip from '../components/common/WithTooltip';
import useAutoLogout from '../hooks/useAutoLogout';
import {
  BRAVADO_URL,
  BRAVADO_AUTH_URL,
  BRAVADO_KEY,
  DOCUMENT_TITLE,
} from '../../constants';

const ACTIONS = [{ id: 'logout', label: 'Logout' }];

const CarelineLayout = ({ children }) => {
  const { dispatch: dispatchModal } = useContext(ModalContext);
  const {
    state: { realmUser },
  } = useContext(SystemContext);

  const { dispatch: dispatchPopover } = useContext(PopoverContext);
  const history = useHistory();
  const [showTimeout, setShowTimeout] = useState(false);

  const isPhysician = useMemo(
    () => (realmUser?.customData?.role === 'physician' ? true : false),
    [realmUser],
  );

  const isSupervisor = useMemo(() => realmUser?.customData?.supervisor, [
    realmUser,
  ]);

  const openPopoverContext = e => {
    e.preventDefault();

    dispatchPopover({
      type: 'ADD_POPOVER',
      data: {
        component: ContextPopover,
        props: {
          actions: ACTIONS,
          bounds: e.target.getBoundingClientRect(),
          onAction: onContextAction,
        },
      },
    });
  };

  const showTimeoutModal = useCallback(() => {
    setShowTimeout(true);
  }, []);

  const hideTimeoutModal = useCallback(() => {
    document.title = DOCUMENT_TITLE;
    setShowTimeout(false);
  }, []);

  const logout = useCallback(() => {
    document.title = DOCUMENT_TITLE;
    history.replace('/logout');
  }, []);

  const onContextAction = id => {
    if (id === 'logout') logout();
  };

  const openBravado = async () => {
    const bravadoToken = await realmUser.functions.getBravadoUserToken();
    if (!bravadoToken) {
      window.open(
        `${
          BRAVADO_AUTH_URL[process.env.REACT_APP_ENVIRONMENT]
        }/oauth/authorize?clientId=${
          BRAVADO_KEY[process.env.REACT_APP_ENVIRONMENT]
        }&responseType=authorizationCode&state=${realmUser.id}&scope=full`,
        '_blank',
      );
      return false;
      // I may be able to just create the authorize url here
    }
    // @Matt - build iframe url
    const url = `${
      BRAVADO_URL[process.env.REACT_APP_ENVIRONMENT]
    }/#!/app/patient-encounter/select-patient?embed=1&jwt=${bravadoToken}`;

    dispatchModal({
      type: 'ADD_MODAL',
      data: {
        component: IframeModal,
        props: { url, fullHeight: true, title: 'Bravado' },
      },
    });
  };

  const openSchedule = () => {
    dispatchModal({
      type: 'ADD_MODAL',
      data: {
        component: ScheduleModal,
        props: {
          maxWidth: '840px',
        },
      },
    });
  };

  const openSearch = e => {
    e.preventDefault();

    dispatchPopover({
      type: 'ADD_POPOVER',
      data: {
        component: SearchPopover,
        props: {
          force: { right: true },
          bounds: e.target.getBoundingClientRect(),
          openResultInModal: realmUser?.customData?.role !== 'admin',
        },
      },
    });
  };

  // -- configure auto logout --
  useAutoLogout(showTimeoutModal, logout, hideTimeoutModal);

  return (
    <Container>
      <Background>
        <BgLeft src={bgLeft} />
        <BgRight src={bgRight} />
      </Background>
      <Header>
        <Logo src={logo} />

        <Aux>
          {isSupervisor ? (
            <>
              <SupervisorBtn>
                <WithTooltip title="Manage Schedule" bottom>
                  <OutlineBtn onClick={openSchedule}>
                    <FaRegCalendarAlt />
                  </OutlineBtn>
                </WithTooltip>
              </SupervisorBtn>
              <SupervisorBtn>
                <WithTooltip title="Patient Lookup" bottom>
                  <OutlineBtn onClick={openSearch}>
                    <FaSearch />
                  </OutlineBtn>
                </WithTooltip>
              </SupervisorBtn>
            </>
          ) : null}

          {isPhysician ? (
            <WithTooltip title="Manage Prescriptions" left>
              <OutlineBtn onClick={openBravado}>
                <FaPills />
              </OutlineBtn>
            </WithTooltip>
          ) : null}

          <Profile onClick={openPopoverContext}>
            <UserIcon user={realmUser.customData} />
          </Profile>
        </Aux>
      </Header>
      <Content>{children}</Content>
      <Footer>
        <Copyright>{`© ${new Date().getFullYear()} BetterMedics, Inc.`}</Copyright>
      </Footer>

      <EncounterManager />
      <ModalManager />
      <Popover />
      {showTimeout ? <TimeoutModal /> : null}
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.backgroundColors.primary};
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
`;

const BgLeft = styled.img`
  position: absolute;
  left: 0;
  bottom: 0;
  display: block;
  width: 590px;
  height: 433px;
`;

const BgRight = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 446px;
  height: 301px;
`;

const Header = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3;
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 30px 40px;
`;

const Logo = styled.img`
  display: block;
  width: 200px;
`;

const Aux = styled.div`
  display: flex;
  margin-left: auto;
  justify-content: space-between;
`;

const SupervisorBtn = styled.div`
  margin-left: 16px;
`;

const OutlineBtn = styled.button`
  display: flex;
  background-color: white;
  border: 2px solid ${props => props.theme.colors.border};
  border-radius: 50%;
  width: 52px;
  height: 52px;
  cursor: pointer;

  svg {
    margin: auto;
    font-size: 20px;
    line-height: 1;
    color: ${props => props.theme.textColors.secondary};
  }

  &:hover {
    border-color: ${props => props.theme.colors.blue};
    svg {
      color: ${props => props.theme.colors.blue};
    }
  }
`;

const Profile = styled.div`
  cursor: pointer;
  margin-left: 20px;
`;

const Content = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 90px;
  height: calc(100% - 90px);
`;

const Footer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Copyright = styled.p`
  font-size: 11px;
  color: ${props => props.theme.textColors.secondary};
  text-align: center;
  padding: 16px;
  margin: 0;
  background-color: white;
  border: 1px solid #f5f7ff;
  border-radius: 50px;
`;

export default CarelineLayout;
