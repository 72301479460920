import React from 'react';
import styled from 'styled-components';
import { BsFillPersonFill } from 'react-icons/bs';

const PhotoInput = ({ id, value, onChange, disabled = false }) => {
  const uploadPhoto = () => {};
  const removePhoto = () => {
    onChange(null);
  };

  return (
    <Wrapper>
      <Photo>
        <Input type="file" onChange={onChange} disabled={disabled} />
        <Avatar>{value ? <Image src={value} /> : <BsFillPersonFill />}</Avatar>
      </Photo>
      <Content>
        <Options disabled={disabled}>
          <Link onClick={uploadPhoto}>Upload New Photo</Link>
          {value ? (
            <>
              <span>&dist;</span>
              <Link onClick={removePhoto}>Remove Photo</Link>
            </>
          ) : null}
        </Options>
        <p>Photos help other members recognize you across BetterMedics</p>
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Input = styled.input`
  display: block;
  width: 100%;
  height: 100%;
  background: transparent;
  border: none;
  padding-left: 60px;
`;

const Avatar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: ${props => props.theme.backgroundColors.lightAlt};
  pointer-events: none;

  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    font-size: 48px;
    color: ${props => props.theme.colors.border};
    pointer-events: none;
  }
`;

const Photo = styled.div`
  position: relative;
  width: 60px;
  height: 60px;
  border-radius: 80px;
  overflow: hidden;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
`;

const Content = styled.div`
  flex: 1;
  padding-left: 20px;

  p {
    color: ${props => props.theme.textColors.tertiary};
    font-size: 12px;
    margin: 0;
  }
`;

const Link = styled.button`
  background: transparent;
  font-size: 14px;
  color: ${props => props.theme.colors.blue};
  cursor: pointer;
  border: none;
  outline: none;
  padding: 0;
`;

const Options = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;

  span {
    color: ${props => props.theme.textColors.tertiary};
    font-weight: ${props => props.theme.fontWeights.bold};
    padding: 0 6px;
  }

  ${({ disabled }) =>
    disabled &&
    `
    ${Link} {
      pointer-events: none;
      cursor: default;
      opacity: 0.8;
    }
  `}
`;

export default PhotoInput;
