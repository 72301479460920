import React from 'react';
import styled from 'styled-components';
import { MdOutlineUnfoldMore } from 'react-icons/md';
import { RiArrowDropDownFill, RiArrowDropUpFill } from 'react-icons/ri';

const TableHeader = ({ config, sort, sortDirection, onSort, widget, ehr }) =>
  config.length > 1 ? (
    <Row widget={widget} ehr={ehr}>
      {config &&
        config.map(column => (
          <CellHeader
            key={column.id}
            active={column.sortable && sort === column.id}
            onClick={column.sortable ? () => onSort(column.id) : null}
            widget={widget}
            style={{
              ...(column.flex && { flex: column.flex }),
              ...(column.width && { width: column.width }),
              ...(column.align === 'right' && { justifyContent: 'flex-end' }),
            }}
            sortable={column.sortable}>
            <Label>{column.label}</Label>
            {!column.sortable ? null : sort !== column.id ? (
              <InactiveSortIcon>
                <MdOutlineUnfoldMore />
              </InactiveSortIcon>
            ) : sortDirection === 'dsc' ? (
              <RiArrowDropUpFill />
            ) : (
              <RiArrowDropDownFill />
            )}
          </CellHeader>
        ))}
    </Row>
  ) : null;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  /* padding: ${props =>
    props.widget ? '0 25px' : props.ehr ? '0' : '0 20px'}; */
  /* padding-left: 10px; */
  padding: 0 20px 5px;
`;

const CellHeader = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;
  cursor: ${props => (props.sortable ? 'pointer' : 'default')};
  padding-right: 10px;

  svg {
    line-height: 1;
    font-size: 28px;
    color: ${props =>
      props.active
        ? props.theme.textColors.primary
        : props.theme.textColors.tertiary};
    pointer-events: none;
  }

  label {
    color: ${props =>
      props.active
        ? props.theme.textColors.primary
        : props.theme.textColors.tertiary};
    margin-bottom: 0;
    line-height: 28px;
    pointer-events: none;
    ${({ widget }) =>
      widget &&
      `
      font-size: 11px;
      opacity: 0.8;
      letter-spacing: 1px;
    `}
  }

  &:hover {
    svg,
    label {
      color: ${props =>
        props.sortable
          ? props.theme.textColors.primary
          : props.theme.textColors.tertiary};
    }
  }

  &:last-child {
    padding-right: 0;
  }

  @media (max-width: 1199px) {
    width: 90px;
  }
`;

const Label = styled.label`
  font-family: ${props => props.theme.fonts.primary};
  text-transform: uppercase;
  font-size: 13px;
  font-weight: ${props => props.theme.fontWeights.bold};
  letter-spacing: 0.2px;
`;

const InactiveSortIcon = styled.span`
  svg {
    font-size: 16px;
    margin-top: 1px;
    margin-left: 6px;
  }
`;

export default TableHeader;
