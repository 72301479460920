import React, { useState, useContext, useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { format, parseISO } from 'date-fns';
import { useApolloClient, useQuery, useMutation } from '@apollo/client';
import { FaPlus, FaRegEdit, FaTimes } from 'react-icons/fa';
import Field from '../../forms/Field';
import { formatInitialData } from '../../../hooks/useFormHelper';
import CareplanModal from '../../modals/CareplanModal';
import CareplanAssignDiagnosisModal from '../../modals/CareplanAssignDiagnosisModal';
import ActivityIndicator from '../../common/ActivityIndicator';
import { CallContext } from '../../../contexts/CallContext';
import { ModalContext } from '../../../contexts/ModalContext';
import { SystemContext } from '../../../contexts/SystemContext';
import { CAREPLAN_ACTIONS } from '../../../../forms/careplan';
import { BRAVADO_URL } from '../../../../constants';
import {
  GET_CAREPLAN,
  DELETE_CONDITION_CAREPLANS,
  ADD_CAREPLAN_DIAGNOSIS,
} from '../../../api/careplan';
import {
  GET_ENCOUNTER_DIAGNOSIS,
  UPDATE_ENCOUNTER_DIAGNOSIS,
} from '../../../api/encounters';

const parseDescription = item => {
  switch (item.type) {
    case 'appointment':
      return format(parseISO(item.date), "MMMM d, yyyy 'at' h:mm a");
    default:
      return item.description || '';
  }
};

const ChartingPhysicianCareplan = ({
  patient,
  encounter,
  onTogglePhysicianButtons,
}) => {
  const client = useApolloClient();
  const isInitialMount = useRef(true);

  const {
    state: { channel },
    dispatch: dispatchCall,
  } = useContext(CallContext);
  const { dispatch: dispatchModal } = useContext(ModalContext);
  const {
    state: { realmUser },
  } = useContext(SystemContext);

  const { data: diagnosisData, error: diagnosisError } = useQuery(
    GET_ENCOUNTER_DIAGNOSIS,
    {
      variables: { id: encounter },
    },
  );
  const {
    loading,
    data: careplanData,
    error: careplanError,
    refetch: refreshList,
  } = useQuery(GET_CAREPLAN, {
    variables: { encounter },
  });

  const [diagnosis, setDiagnosis] = useState();
  const [actions, setActions] = useState({});

  const [showIframe, setShowIframe] = useState(false);
  const [missingCareplanConditions, setMissingCareplanConditions] = useState();

  const [bravadoEncounterID, setBravadoEncounterID] = useState();
  const [bravadoToken, setBravadoToken] = useState();
  const bravadoIframe = useMemo(
    () =>
      bravadoToken && bravadoEncounterID
        ? `${
            BRAVADO_URL[process.env.REACT_APP_ENVIRONMENT]
          }/#!/app/patient-encounter/${bravadoEncounterID}/discharge/prescription?embed=1&jwt=${bravadoToken}`
        : null,
    [bravadoToken, bravadoEncounterID],
  );

  const [deleteConditionFromCareplan] = useMutation(
    DELETE_CONDITION_CAREPLANS,
    {
      onCompleted() {
        refreshList();
      },
    },
  );

  const closeIframe = async () => {
    onTogglePhysicianButtons(true);
    setShowIframe(false);

    const newPrescription = await realmUser.functions.checkBravadoPrescriptions(
      bravadoEncounterID,
    );
    if (newPrescription) {
      refreshList();
    }
  };

  const closeModal = async openBravado => {
    if (openBravado) {
      if (!bravadoToken) {
        const token = await realmUser.functions.getBravadoUserToken();
        setBravadoToken(token);
      }
      onTogglePhysicianButtons(false);
      setShowIframe(true);
    } else refreshList();
  };

  const openModal = (condition, item) => {
    dispatchModal({
      type: 'ADD_MODAL',
      data: {
        component: CareplanModal,
        props: {
          mode: item ? 'edit' : 'new',
          patient: patient.userId,
          encounter,
          condition,
          bravadoToken,
          data: item,
          maxWidth: '640px',
        },
        onDismiss: closeModal,
      },
    });
  };

  const saveDiagnosis = async (id, val) => {
    setDiagnosis(val);

    await client.mutate({
      mutation: UPDATE_ENCOUNTER_DIAGNOSIS,
      variables: {
        id: encounter,
        diagnosis: val,
      },
    });
  };

  // Detect unassigned Care Plan items (medications from Bravado)
  useEffect(() => {
    if (!missingCareplanConditions) return;

    // WHAT IF THERE'S ONLY ONE DIAGNOSIS? AUTO ASSIGN NEEDED

    // items detected without a diagnosis/condition attached -- these must be assigned
    dispatchModal({
      type: 'ADD_MODAL',
      data: {
        component: CareplanAssignDiagnosisModal,
        props: {
          data: missingCareplanConditions,
          maxWidth: '640px',
        },
        onDismiss: () => {
          setMissingCareplanConditions();
          closeModal();
        },
        disableDismissOutside: true,
      },
    });
  }, [missingCareplanConditions]);

  // Format Diagnosis Data
  useEffect(() => {
    if (diagnosisError) console.error(diagnosisError);

    if (diagnosisData) {
      const formatted = formatInitialData(diagnosisData.encounter);
      setDiagnosis(formatted.diagnosis);
    }
  }, [diagnosisData, diagnosisError]);

  // Format Care Plan items to matching diagnosis
  useEffect(() => {
    const autoAssignToDiagnosis = async (careplanId, diag) => {
      const cleanedDiagnosis = diag['__typename']
        ? formatInitialData(diag)
        : diag;
      await client.mutate({
        mutation: ADD_CAREPLAN_DIAGNOSIS,
        variables: {
          _id: careplanId,
          condition: cleanedDiagnosis,
        },
      });
    };

    if (careplanError) console.error(careplanError);

    if (careplanData && diagnosis) {
      // separate careplan actions into groups by diagnosis
      let sortedActions = {};

      // 1. take every diagnosis and add a key for its label - set to empty array
      if (diagnosis) {
        diagnosis.map(condition => {
          sortedActions[condition.display] = [];
        });
      }

      // 2. match each careplan item to its diagnosis, if no match found, add to missingItems
      let missingItems = [];
      let deletedConditions = {};

      careplanData.careplans.map(item => {
        const conditionName = item.condition?.display;
        const clinicalCode = item.condition?.clinicalCode;
        if (conditionName) {
          // matching diagnosis found, still valid, add it to match
          if (sortedActions[conditionName]) {
            sortedActions[conditionName].push(item);
          } else if (clinicalCode && !deletedConditions[clinicalCode]) {
            // diagnosis for this item has been deleted, save clinicalCode to delete all related careplan items
            deletedConditions[clinicalCode] = {};
          }
        } else {
          // care plan item is missing a diagnosis, separate it for now
          if (diagnosis.length === 1) {
            // only one diagnosis -- just auto-assign careplan item to it
            autoAssignToDiagnosis(item._id, diagnosis[0]);
          } else missingItems.push(item);
        }
      });

      // if any deleted conditions found, loop through and delete those nested careplan items
      Object.keys(deletedConditions).map(key => {
        deleteConditionFromCareplan({
          variables: { encounter, condition: key },
        });
      });

      // update UI mapping of data to generate list
      setActions(sortedActions);

      // log missingItems so CareplanAssignmentModal will open
      if (missingItems.length > 0)
        setMissingCareplanConditions({ diagnosis, items: missingItems });

      // update local copy of careplans for call (needed for end call analytics)
      dispatchCall({
        type: 'SET_CALL',
        data: { careplan: careplanData.careplans },
      });
    }
  }, [careplanData, careplanError, diagnosis, encounter]);

  // Initialize Bravado
  useEffect(() => {
    const initBravado = async () => {
      try {
        const token = await realmUser.functions.getBravadoUserToken();
        setBravadoToken(token);

        const {
          bravadoEncounter,
        } = await realmUser.functions.createBravadoEncounter(encounter);
        setBravadoEncounterID(bravadoEncounter);
      } catch (err) {
        console.log('Error initializing Bravado', err);
      }
    };

    if (isInitialMount.current) {
      isInitialMount.current = false;
      initBravado();
    }
  }, [realmUser]);

  return (
    <Container>
      <Header>
        <Title>Diagnosis</Title>
      </Header>

      <FieldWrapper>
        <Field
          id="diagnosis"
          type="search"
          value={diagnosis}
          onChange={saveDiagnosis}
          config={{
            fill: true,
            multiple: true,
            placeholder: 'Search Conditions',
            theme: 'white',
          }}
        />
      </FieldWrapper>

      <Header>
        <Title>Care Plan</Title>
      </Header>

      {loading ? (
        <Empty>
          <ActivityIndicator />
        </Empty>
      ) : !diagnosis || diagnosis.length === 0 ? (
        <Empty>
          <EmptyMsg>
            Add a diagnosis to begin assigning careplan actions.
          </EmptyMsg>
        </Empty>
      ) : (
        <Wrapper>
          {diagnosis.map(condition => {
            const list = actions[condition.display] || [];
            return (
              <Section key={condition.clinicalCode}>
                <ConditionHeader>
                  <ConditionTitle>{condition.display}</ConditionTitle>
                  <ConditionCount>{list ? list.length : 0}</ConditionCount>
                </ConditionHeader>
                <AddBtn onClick={() => openModal(condition)}>
                  <FaPlus />
                </AddBtn>
                <CarePlanActions>
                  {list
                    ? list.map(item => (
                        <ActionItem
                          key={item._id}
                          mode={item.type}
                          onClick={() => openModal(condition, item)}>
                          <ActionBody>
                            <ActionTitle>
                              {item.title || CAREPLAN_ACTIONS[item.type].title}
                            </ActionTitle>
                            <ActionDescription>
                              {parseDescription(item)}
                            </ActionDescription>
                          </ActionBody>
                          <ActionIcon>
                            {CAREPLAN_ACTIONS[item.type].icon}
                          </ActionIcon>
                          <EditIcon>
                            <FaRegEdit />
                          </EditIcon>
                        </ActionItem>
                      ))
                    : null}
                </CarePlanActions>
              </Section>
            );
          })}
        </Wrapper>
      )}

      {showIframe && bravadoIframe ? (
        <IframeWrapper>
          <iframe src={bravadoIframe} title="Bravado" />
          <CloseBtn onClick={closeIframe}>
            <FaTimes />
          </CloseBtn>
        </IframeWrapper>
      ) : null}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: ${props => props.theme.backgroundColors.tertiary};
  width: 360px;
  transition: width 0.2s ease-in;
  overflow-y: scroll;
  padding-bottom: 200px;
`;

const Wrapper = styled.div`
  // flex: 1;
  padding: 20px 30px 20px;
`;

const Header = styled.div`
  padding: 20px 30px 0;
`;

const Title = styled.h2`
  margin: 0;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(166, 173, 194, 0.2);
  text-align: center;
`;

const Section = styled.div`
  margin-bottom: 40px;
`;

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 12px 12px 0;
`;

const ConditionHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
`;

const ConditionTitle = styled.h3`
  font-weight: ${props => props.theme.fontWeights.medium};
  font-size: 16px;
  margin: 0;
  flex: 1;
  padding-right: 10px;
`;

const ConditionCount = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: ${props => props.theme.fonts.primary};
  font-weight: ${props => props.theme.fontWeights.bold};
  font-size: 12px;
  color: ${props => props.theme.textColors.secondary};
  background-color: rgba(0, 0, 0, 0.05);
  width: 30px;
  height: 30px;
  border-radius: 3px;
`;

const CarePlanActions = styled.div``;

const ActionIcon = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    font-size: 18px;
    color: ${props => props.theme.textColors.secondary};
  }
`;

const EditIcon = styled(ActionIcon)`
  display: none;

  svg {
    color: ${props => props.theme.colors.blue};
  }
`;

const ActionItem = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  background-color: white;
  padding: 20px 20px 20px 25px;
  border-radius: 3px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.07);
  margin-bottom: 15px;
  cursor: pointer;

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 15px;
    left: 0;
    bottom: 15px;
    width: 4px;
    background-color: ${props =>
      props.mode === 'appointment'
        ? props.theme.colors.green
        : props.mode === 'task'
        ? props.theme.colors.tertiary
        : props.mode === 'comms'
        ? props.theme.colors.yellow
        : 'white'};
  }

  &:hover {
    background-color: ${props =>
      `rgba(${props.theme.backgroundColors.tertiaryRGB}, 0.1)`};

    ${ActionIcon} {
      display: none;
    }

    ${EditIcon} {
      display: flex;
    }
  }
`;

const ActionTitle = styled.p`
  font-size: 13px;
  font-weight: ${props => props.theme.fontWeights.bold};
  margin: 0;
  flex: 1;
`;

const ActionBody = styled.div`
  flex: 1;
`;

const ActionDescription = styled.p`
  font-size: 13px;
  margin-top: 7px;
  margin-bottom: 0;
  line-height: 1.4;
`;

const Empty = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
`;

const EmptyMsg = styled.div`
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.03);
  padding: 20px;
  font-size: 14px;
  line-height: 1.4;
  color: ${props => props.theme.textColors.secondary};
  text-align: center;
  margin-bottom: 30px;
`;

const AddBtn = styled.button`
  display: block;
  width: 100%;
  background-color: rgba(166, 173, 194, 0.2);
  border: none;
  outline: none;
  border-radius: 5px;
  padding: 12px;
  margin-bottom: 15px;
  color: ${props => props.theme.textColors.secondary};
  cursor: pointer;

  &:hover {
    background-color: rgba(166, 173, 194, 0.3);
    color: ${props => props.theme.textColors.primary};
  }
`;

const IframeWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
  background-color: white;

  iframe {
    width: 100%;
    height: 100%;
    border: 0;
  }
`;

const CloseBtn = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 30;

  cursor: pointer;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.1);
  border: 2px solid ${props => props.theme.colors.border};

  svg {
    color: ${props => props.theme.textColors.tertiary};
    font-size: 40px;
    opacity: 0.8;
  }

  &:hover svg {
    opacity: 1;
  }
`;

export default ChartingPhysicianCareplan;
