import React, { useReducer } from 'react';
import { ThemeProvider } from 'styled-components';
import { lighten } from 'polished';
import { DateSingleInput } from '@datepicker-react/styled';

const TODAY = new Date();

const reducer = (state, action) => {
  switch (action.type) {
    case 'focusChange':
      return { ...state, showDatepicker: action.payload };
    case 'dateChange':
      return action.payload;
    default:
      throw new Error();
  }
};

const DatePicker = ({
  id,
  value,
  onChange,
  onAutosave,
  config,
  disabled = false,
}) => {
  const initialState = {
    date: value ? new Date(value) : null,
    showDatepicker: false,
  };

  const RANGE = {
    maxBookingDate: config && !config.future ? TODAY : null,
    minBookingDate: config && config.future ? TODAY : null,
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const onDateChange = data => {
    if (disabled) return;

    dispatch({ type: 'dateChange', payload: data });
    onChange(data.date);

    if (onAutosave) onAutosave(id, data.date, true);
  };

  return (
    <ThemeProvider
      theme={{
        breakpoints: ['32em', '48em', '64em'],
        reactDatepicker: {
          fontFamily: 'Roboto, system-ui, -apple-system',
          navButtonBorder: '1px solid #EAE8E4',
          inputColor: '#5B5F73',
          inputLabelBorder: '1px solid #EAE8E4',
          inputLabelBorderRadius: '10px',
          inputWidth: '198px',
          inputMinHeight: '51px',
          inputCalendarIconWidth: '18px',
          inputCalendarIconHeight: '18px',
          colors: {
            selectedDay: lighten(0.1, '#57B3FA'),
            selectedDayHover: lighten(0.04, '#57B3FA'),
            primaryColor: '#57B3FA',
          },
        },
      }}>
      <DateSingleInput
        onDateChange={onDateChange}
        onFocusChange={focusedInput =>
          dispatch({ type: 'focusChange', payload: focusedInput })
        }
        date={state.date} // Date or null
        firstDayOfWeek={0}
        focusedInput={state.focusedInput}
        showDatepicker={state.showDatepicker}
        showClose={false}
        showResetDate={false}
        placement={config && config.placement ? config.placement : 'bottom'}
        disabled={disabled}
        {...RANGE}
      />
    </ThemeProvider>
  );
};

export default DatePicker;
