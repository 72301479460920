import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import TableHeader from './TableHeader';
import TableItem from './TableItem';
import EmptyNotice from '../common/EmptyNotice';
import Loader from '../common/Loader';

const Table = ({
  loading,
  data,
  config,
  onClick,
  emptyNotice,
  widget,
  ehr,
}) => {
  const [sortedData, setSortedData] = useState(data);
  const [sortBy, setSortBy] = useState(config[0].id);
  const [sortDirection, setSortDirection] = useState('asc');

  useEffect(() => {
    const nestedSort = (prop1, prop2 = null, direction = 'asc') => (e1, e2) => {
      const a = prop2 ? e1[prop1][prop2] : e1[prop1],
        b = prop2 ? e2[prop1][prop2] : e2[prop1],
        sortOrder = direction === 'asc' ? 1 : -1;
      return a < b ? -sortOrder : a > b ? sortOrder : 0;
    };

    const sortArray = type => {
      const types = type.split('.');
      const sorted = [...data].sort(
        nestedSort(types[0], types[1], sortDirection),
      );
      setSortedData(sorted);
    };

    if (data) sortArray(sortBy);
  }, [data, sortBy, sortDirection]);

  const onSort = type => {
    if (type === sortBy)
      setSortDirection(sortDirection === 'asc' ? 'dsc' : 'asc');
    else {
      setSortBy(type);
      setSortDirection('asc');
    }
  };

  return (
    <Wrapper>
      <TableHeader
        config={config}
        sort={sortBy}
        sortDirection={sortDirection}
        onSort={onSort}
        widget={widget}
        ehr={ehr}
      />
      <TableBody widget={widget} ehr={ehr}>
        {loading ? (
          <Loader activity size="small" transparent />
        ) : sortedData?.length > 0 ? (
          sortedData.map((item, index) => (
            <TableItem
              key={item._id || `item-${index}`}
              item={item}
              config={config}
              onClick={onClick}
              sort={sortBy}
              widget={widget}
              ehr={ehr}
            />
          ))
        ) : (
          <EmptyWrapper>
            <EmptyNotice lightAlt={ehr}>{emptyNotice}</EmptyNotice>
          </EmptyWrapper>
        )}
      </TableBody>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const EmptyWrapper = styled.div`
  min-height: 400px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const TableBody = styled.div`
  position: relative;
  padding: ${props => (props.widget ? '5px 25px' : 0)};
  padding-bottom: 30px;
  height: 100%;
  width: 100%;
  overflow-y: ${props => (props.widget ? 'scroll' : 'auto')};

  ${({ widget }) =>
    widget &&
    `
    ${EmptyWrapper} {
      min-height: auto;
      padding-top: 50px;
    }
  `}
`;

export default Table;
