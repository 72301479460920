import React, { useCallback, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { FaRegFolderOpen } from 'react-icons/fa';
import { FiActivity, FiHome, FiUsers } from 'react-icons/fi';
import { GrUserManager } from 'react-icons/gr';
import AlertCenter from '../components/common/AlertCenter';
import EncounterManager from '../components/encounter/EncounterManager';
import ModalManager from '../components/modals/ModalManager';
import TimeoutModal from '../components/modals/TimeoutModal';
import Popover from '../components/popovers/Popover';
import useAutoLogout from '../hooks/useAutoLogout';
import { DOCUMENT_TITLE } from '../../constants';
import logo from '../../assets/images/logo-mini.png';

const AdminLayout = ({ children }) => {
  const history = useHistory();
  const location = useLocation();
  const [showTimeout, setShowTimeout] = useState(false);

  const showTimeoutModal = useCallback(() => {
    setShowTimeout(true);
  }, []);

  const hideTimeoutModal = useCallback(() => {
    document.title = DOCUMENT_TITLE;
    setShowTimeout(false);
  }, []);

  const logout = useCallback(() => {
    document.title = DOCUMENT_TITLE;
    history.replace('/logout');
  }, []);

  // -- configure auto logout --
  useAutoLogout(showTimeoutModal, logout, hideTimeoutModal);

  return (
    <Container>
      <MenuWrapper>
        <MenuLogo>
          <Link to="/">
            <Logo src={logo} />
          </Link>
        </MenuLogo>
        <MenuButtons>
          <MenuBtn active={location.pathname === '/'}>
            <Link to="/">
              <FiHome />
              <MenuBtnText>Dashboard</MenuBtnText>
            </Link>
          </MenuBtn>
          <MenuBtn active={location.pathname.indexOf('/patient') > -1}>
            <Link to="/patients">
              <FaRegFolderOpen />
              <MenuBtnText>Patients</MenuBtnText>
            </Link>
          </MenuBtn>

          <MenuBtn active={location.pathname === '/providers'}>
            <Link to="/providers">
              <GrUserManager />
              <MenuBtnText>Providers</MenuBtnText>
            </Link>
          </MenuBtn>
          <MenuBtn active={location.pathname === '/team'}>
            <Link to="/team">
              <FiUsers />
              <MenuBtnText>Team</MenuBtnText>
            </Link>
          </MenuBtn>
          <MenuBtn>
            <a
              href="https://analytics.amplitude.com/bettermedics"
              target="_blank">
              <FiActivity />
              <MenuBtnText>Analytics</MenuBtnText>
            </a>
          </MenuBtn>
        </MenuButtons>
      </MenuWrapper>
      <Content>{children}</Content>

      <EncounterManager />
      <ModalManager />
      <Popover />
      <AlertCenter admin />
      {showTimeout ? <TimeoutModal /> : null}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.backgroundColors.primary};
  flex-direction: row;
  flex: 1;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: white;
  padding: 10px 0 20px;
  border-right: 1px solid;
  border-color: ${props => props.theme.colors.border};
`;

const MenuLogo = styled.div`
  margin-bottom: auto;
  padding: 20px;
`;

const Logo = styled.img`
  display: block;
  width: 50px;
  border-radius: 16%;
`;

const MenuButtons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 100%;
`;

const MenuBtn = styled.div`
  position: relative;
  width: 100%;
  padding: 20px 0;
  margin: 5px 0;
  text-align: center;
  cursor: pointer;

  a {
    display: block;
    width: 100%;
  }

  p {
    color: ${props =>
      props.active
        ? props.theme.textColors.primary
        : props.theme.textColors.secondary};
  }

  svg {
    font-size: 30px;
    color: ${props => props.theme.textColors.primary};

    path {
      stroke: ${props => props.theme.textColors.primary};
    }
  }

  &:hover {
    svg {
      color: ${props =>
        props.active
          ? props.theme.textColors.primary
          : props.theme.colors.tertiary};

      path,
      line,
      polyline {
        color: ${props =>
          props.active
            ? props.theme.textColors.primary
            : props.theme.colors.tertiary};
        stroke: ${props =>
          props.active
            ? props.theme.textColors.primary
            : props.theme.colors.tertiary};
      }
    }
    p {
      color: ${props => props.theme.textColors.primary};
    }
  }

  :after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    height: 40px;
    width: 3px;
    border-radius: 5px 0 0 5px;
    background-color: ${props =>
      props.active ? props.theme.colors.tertiary : 'transparent'};
    transform: translateY(-50%);
  }

  &:hover:after {
    background-color: ${props =>
      props.active ? props.theme.colors.primary : props.theme.colors.tertiary};
  }
`;

const MenuBtnText = styled.p`
  font-size: 12px;
  font-family: ${props => props.theme.fonts.primary};
  text-transform: uppercase;
  letter-spacing: 0.4px;
  font-weight: ${props => props.theme.fontWeights.bold};
  margin: 7px 0 0;
  line-height: 1;
`;

const LogoutButton = styled(MenuBtn)`
  display: flex;
  margin-top: auto;
  text-align: center;
  cursor: pointer;
  background-color: ${props => props.theme.backgroundColors.lightAlt};
  width: 60px;
  height: 60px;
  border-radius: 40px;
  justify-content: center;
  align-items: center;

  &:hover {
    opacity: 0.8;

    svg {
      color: ${props => props.theme.colors.tertiary};

      path {
        stroke: ${props => props.theme.colors.tertiary};
      }
    }

    &:after {
      display: none;
    }
  }

  svg,
  path {
    font-size: 24px;
    color: #444;
    stroke: #444;
  }
`;

export default AdminLayout;
