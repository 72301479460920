import React from 'react';
import styled from 'styled-components';
import { FaPen, FaExclamationTriangle } from 'react-icons/fa';
import RequestIcon from './RequestIcon';

const getStandardDescription = config => {
  switch (config.type) {
    case 'Rx Refill':
      return `Patient has requested ${config.medication.length} medication${
        config.medication.length === 1 ? '' : 's'
      } be refilled.`;
    default:
      return 'No notes provided';
  }
};

const RequestFormItem = ({ config, onEdit }) => (
  <Item onClick={onEdit}>
    <Content>
      <RequestIcon type={config.type} />
      <Info>
        <Title>
          {config.type}
          {config.urgency === 'high' ? (
            <FaExclamationTriangle title="High Priority" />
          ) : null}
        </Title>
        <Description>
          {config.description || getStandardDescription(config)}
        </Description>
      </Info>
    </Content>

    <EditBtn>
      <FaPen />
    </EditBtn>
  </Item>
);

const EditBtn = styled.div``;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 30px;
`;

const Info = styled.div`
  flex: 1;
  padding-left: 30px;
`;

const Title = styled.p`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 18px;
  color: ${props => props.theme.textColors.primary};
  margin: 0 0 6px;

  svg {
    margin-left: 8px;
    font-size: 14px;
    color: ${props => props.theme.colors.primary};
  }
`;

const Description = styled.p`
  font-size: 15px;
  line-height: 1.5;
  color: ${props => props.theme.textColors.tertiary};
  margin: 0;
`;

const Item = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  border-radius: 25px;
  background-color: ${props => props.theme.backgroundColors.lightAlt};
  opacity: 0.8;
  cursor: pointer;
  overflow: hidden;

  ${EditBtn} {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: ${props => props.theme.textColors.tertiary};
    margin-left: auto;
    padding: 0 30px;
    height: 100%;
  }

  &:hover {
    opacity: 1;

    ${EditBtn} {
      background-color: rgba(0, 0, 0, 0.05);
      color: ${props => props.theme.colors.blue};
    }

    ${Title} {
      color: ${props => props.theme.colors.blue};
    }
  }
`;

export default RequestFormItem;
