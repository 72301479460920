import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import { FaDna } from 'react-icons/fa';
import { MdErrorOutline } from 'react-icons/md';
import { RiHome4Line } from 'react-icons/ri';
import { FormContext } from '../../../contexts/FormContext';
import { SystemContext } from '../../../contexts/SystemContext';
import Grid from '../../common/Grid';
import Loader from '../../common/Loader';
import { useEventListener } from '../../../hooks/useEventListener';
import { decodeAddress } from '../../../hooks/useFormHelper';
import {
  formatName,
  formatAddress,
  formatBirthDate,
  formatDiagnoses,
  capitalize,
  convertToYesNo,
} from '../../../hooks/useTextHelper';
import {
  GET_FAMILY_HISTORY,
  ADD_FAMILY_HISTORY,
  UPDATE_FAMILY_HISTORY,
  REMOVE_FAMILY_HISTORY,
} from '../../../api/familyHistory';
import {
  LIVING_ARRANGEMENT_FORM,
  LOCATION_FORM,
  PHONE_FORM,
  FAMILY_HISTORY_FORM,
  GENDER_FORM,
  DOB_FORM,
  NAME_FORM,
  RACES_FORM,
  LANGUAGE_FORM,
  SSN_FORM,
  CODE_STATUS_FORM,
  CLINICAL_STUDY_FORM,
} from '../../../../forms/personal';

const getFamilyHistorySummary = family => {
  if (!family || family.length === 0) return 'No family history reported.';

  return `${family.length} family members listed`;
};

const ChartingOnboardingPersonal = ({ patient, noTopBorder }) => {
  const isRefetching = useRef(false);
  const { state: activeForm, dispatch: dispatchForm } = useContext(FormContext);
  const {
    state: { realmUser },
  } = useContext(SystemContext);

  const { loading, error, data, refetch } = useQuery(GET_FAMILY_HISTORY, {
    variables: { patient: patient.userId },
  });

  const [config, setConfig] = useState();

  const address = useMemo(
    () =>
      patient.address && patient.address.hasOwnProperty('home')
        ? patient.address.home
        : null,
    [patient.address],
  );
  const phone = useMemo(
    () =>
      patient.telecom && patient.telecom.hasOwnProperty('home')
        ? patient.telecom.home
        : null,
    [patient.telecom],
  );

  const buildLayout = useCallback(() => {
    const { familyHistories } = data;

    const LAYOUT = {
      name: {
        targetId: patient.userId,
        label: 'Full Name',
        form: NAME_FORM,
        content: formatName(patient),
        data: patient.name,
        editable: true,
        inline: true,
        columnSize: '1 / span 3',
        viewOnly: realmUser?.customData?.role !== 'admin',
      },
      dob: {
        targetId: patient.userId,
        label: 'Date of Birth',
        form: DOB_FORM,
        content: formatBirthDate(patient.birthDate),
        data: { birthDate: patient.birthDate },
        editable: true,
        inline: true,
        columnSize: '4 / span 3',
        viewOnly: realmUser?.customData?.role !== 'admin',
      },
      gender: {
        targetId: patient.userId,
        label: 'Gender',
        form: GENDER_FORM,
        content: capitalize(patient.gender),
        data: { gender: patient.gender },
        editable: true,
        inline: true,
        columnSize: '1 / span 2',
        viewOnly: realmUser?.customData?.role !== 'admin',
      },
      ssn: {
        targetId: patient.userId,
        label: 'Social Security #',
        form: SSN_FORM,
        content: `XXX-XX-${patient.ssn?.last4}`,
        data: { ssn: patient.ssn },
        editable: true,
        inline: true,
        columnSize: '3 / span 2',
        viewOnly: realmUser?.customData?.role !== 'admin',
      },
      codeStatus: {
        targetId: patient.userId,
        label: 'Code Status',
        form: CODE_STATUS_FORM,
        content: patient.codeStatus,
        data: { codeStatus: patient.codeStatus },
        editable: true,
        inline: true,
        columnSize: '5 / span 2',
        viewOnly: realmUser?.customData?.role !== 'admin',
      },
      races: {
        targetId: patient.userId,
        label: 'Race / Ethnicity',
        form: RACES_FORM,
        content: patient.race,
        data: { race: patient.race },
        editable: true,
        inline: true,
        columnSize: '1 / span 2',
        viewOnly: realmUser?.customData?.role !== 'admin',
      },
      language: {
        targetId: patient.userId,
        label: 'Primary Language',
        form: LANGUAGE_FORM,
        content: patient.language,
        data: { language: patient.language },
        editable: true,
        inline: true,
        columnSize: '3 / span 2',
        viewOnly: realmUser?.customData?.role !== 'admin',
      },
      clinicalStudy: {
        targetId: patient.userId,
        label: 'Clinical Study',
        form: CLINICAL_STUDY_FORM,
        content: patient.clinicalStudy,
        data: { clinicalStudy: patient.clinicalStudy },
        editable: true,
        inline: true,
        columnSize: '5 / span 2',
        viewOnly: realmUser?.customData?.role !== 'admin',
      },
      phone: {
        targetId: patient.userId,
        label: 'Phone Number',
        form: PHONE_FORM,
        content: phone,
        data: { phone },
        editable: true,
        inline: true,
        columnSize: '1 / span 3',
      },
      address: {
        targetId: patient.userId,
        label: 'Address',
        form: LOCATION_FORM,
        formHeader: {
          title: 'Patient Address',
          icon: <RiHome4Line />,
          label: 'Personal Information',
        },
        content: formatAddress(address, true),
        data: address ? decodeAddress(address) : null,
        editable: true,
        columnSize: '4 / span 3',
      },
      livingArrangement: {
        targetId: patient.userId,
        label: 'Living Arrangement',
        form: LIVING_ARRANGEMENT_FORM,
        content: patient.livingArrangement,
        data: { livingArrangment: patient.livingArrangement },
        editable: true,
        inline: true,
        style: {
          fontSize: '16px',
          fontWeight: '400',
        },
        columnSize: '1 / span 3',
      },
      familyHistory: {
        targetId: patient.userId,
        list: {
          form: FAMILY_HISTORY_FORM,
          add: ADD_FAMILY_HISTORY,
          remove: REMOVE_FAMILY_HISTORY,
          update: UPDATE_FAMILY_HISTORY,
          formHeader: {
            addTitle: 'Add a Family Member',
            editTitle: 'Edit a Family Member',
            icon: <FaDna />,
            label: 'Family History',
          },
          map: {
            label: 'condition.display',
          },
          table: [
            {
              id: 'relationship',
              label: 'Relative',
              width: '180px',
              sortable: true,
              formatFn: capitalize,
            },
            {
              id: 'conditions',
              label: 'Types of Illness',
              flex: 1,
              formatFn: formatDiagnoses,
            },
            {
              id: 'deceased',
              label: 'Deceased',
              sortable: true,
              formatFn: convertToYesNo,
              width: '80px',
            },
          ],
          emptyLabel: 'No family history reported',
        },
        data: familyHistories,
        summary: {
          title: 'Family History',
          subtitle: getFamilyHistorySummary(familyHistories),
          icon: <FaDna />,
        },
        columnSize: '4 / span 3',
      },
    };

    setConfig({ cards: LAYOUT, template: '1fr 1fr 1fr 1fr 1fr 1fr' });

    if (isRefetching.current) {
      isRefetching.current = false;

      if (activeForm && LAYOUT[activeForm.config.key]) {
        dispatchForm({
          type: 'UPDATE_DATA',
          data: LAYOUT[activeForm.config.key].data,
        });
      }
    }
  }, [
    data,
    patient,
    patient.userId,
    patient.livingArrangement,
    phone,
    address,
  ]);

  useEffect(() => {
    if (data) buildLayout();
  }, [data, patient, buildLayout]);

  const refreshList = () => {
    isRefetching.current = true;
    refetch();
  };

  useEventListener('refreshListData', refreshList);

  return (
    <Container noTopBorder={noTopBorder}>
      {loading ? (
        <CenterWrapper>
          <Loader size="small" activity transparent />
        </CenterWrapper>
      ) : config ? (
        <Grid config={config} />
      ) : error ? (
        <CenterWrapper>
          <ErrorIcon>
            <MdErrorOutline />
          </ErrorIcon>
          <ErrorMessage>{error.message}</ErrorMessage>
          <TryAgain onClick={refreshList}>Try Again</TryAgain>
        </CenterWrapper>
      ) : null}
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  width: 100%;
  border-top: ${props =>
    props.noTopBorder ? 'none' : `1px solid ${props.theme.colors.border}`};
`;

const CenterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const ErrorIcon = styled.div`
  svg {
    font-size: 42px;
    color: ${props => props.theme.textColors.tertiary};
  }
`;

const ErrorMessage = styled.p`
  margin: 20px 0 50px;
  color: ${props => props.theme.textColors.tertiary};
  text-align: center;
`;

const TryAgain = styled.span`
  color: ${props => props.theme.colors.blue};
  font-size: 15px;
  margin-top: 20px;
  cursor: pointer;
`;

export default ChartingOnboardingPersonal;
