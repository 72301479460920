import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { triangle } from 'polished';
import {
  FaAllergies,
  FaCheckCircle,
  FaClipboard,
  FaPrescriptionBottle,
  FaStethoscope,
  FaTasks,
} from 'react-icons/fa';
import { FiMapPin } from 'react-icons/fi';
import ChartingCarelineAllergies from './ChartingCarelineAllergies';
import ChartingCarelineLocation from './ChartingCarelineLocation';
import ChartingCarelineIntake from './ChartingCarelineIntake';
import ChartingVitals from '../ChartingVitals';
import ChartingCarelineHistory from '../careline/ChartingCarelineHistory';
import ChartingCarelineMedications from './ChartingCarelineMedications';
import { CallContext } from '../../../contexts/CallContext';
import { LogContext } from '../../../contexts/LogContext';
import { SystemContext } from '../../../contexts/SystemContext';

const SECTIONS = [
  'location',
  'intake',
  'vitals',
  'medications',
  'allergies',
  'history',
];

const ChartingCareline = () => {
  const {
    state: { _id: callId, encounter, patient, verifiedStatus },
    dispatch: dispatchStatus,
  } = useContext(CallContext);
  const {
    state: { log },
    dispatch: dispatchLog,
  } = useContext(LogContext);
  const {
    state: { realmUser },
  } = useContext(SystemContext);

  const [activeSection, setActiveSection] = useState(SECTIONS[0]);

  const saveAuditLog = async (section, status) => {
    try {
      if (status === true) {
        await realmUser.functions.verifySection(
          patient.userId,
          section,
          log,
          callId,
          encounter,
        );
      } else {
        await realmUser.functions.unverifySection(section, callId);
      }
    } catch (e) {
      console.log(e);
    }

    dispatchLog({ type: 'CLEAR' });
  };

  const verifySection = async (section, status) => {
    const currentStatus = verifiedStatus[section] || false;
    if (currentStatus !== status) saveAuditLog(section, status);

    // update navigation bar status
    dispatchStatus({
      type: 'SET_STATUS',
      data: {
        [section]: status,
      },
    });
  };

  return (
    <Container>
      <SectionBar>
        {SECTIONS.map(section => (
          <SectionBtn
            key={section}
            active={activeSection === section}
            completed={verifiedStatus[section]}
            onClick={() => setActiveSection(section)}>
            <SectionBtnOrb active={activeSection === section}>
              {verifiedStatus[section] ? (
                <FaCheckCircle />
              ) : section === 'location' ? (
                <FiMapPin />
              ) : section === 'intake' ? (
                <FaTasks />
              ) : section === 'vitals' ? (
                <FaStethoscope />
              ) : section === 'medications' ? (
                <FaPrescriptionBottle />
              ) : section === 'allergies' ? (
                <FaAllergies />
              ) : section === 'history' ? (
                <FaClipboard />
              ) : null}
            </SectionBtnOrb>
            <SectionBtnLabel>{section}</SectionBtnLabel>
          </SectionBtn>
        ))}
      </SectionBar>
      <SectionWrapper>
        <SectionContent>
          {activeSection === 'location' ? (
            <ChartingCarelineLocation
              onVerify={status => verifySection('location', status)}
            />
          ) : activeSection === 'intake' ? (
            <ChartingCarelineIntake patient={patient} />
          ) : activeSection === 'vitals' ? (
            <ChartingVitals encounter={encounter} />
          ) : activeSection === 'medications' ? (
            <ChartingCarelineMedications
              patient={patient}
              isVerified={verifiedStatus.medications}
              onVerify={status => verifySection('medications', status)}
            />
          ) : activeSection === 'allergies' ? (
            <ChartingCarelineAllergies
              patient={patient}
              isVerified={verifiedStatus.allergies}
              onVerify={status => verifySection('allergies', status)}
            />
          ) : activeSection === 'history' ? (
            <ChartingCarelineHistory
              patient={patient}
              isVerified={verifiedStatus.history}
              onVerify={status => verifySection('history', status)}
            />
          ) : null}
        </SectionContent>
      </SectionWrapper>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const SectionWrapper = styled.div`
  position: relative;
  flex: 1;
`;

const SectionContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
`;

const SectionBar = styled.div`
  background-color: ${props => props.theme.backgroundColors.tertiary};
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid ${props => props.theme.colors.border};
`;

const SectionBtn = styled.div`
  position: relative;
  display: inline-flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 64px;
  padding: 0 10px;
  background-color: ${props =>
    props.active
      ? props.theme.colors.blue
      : props.theme.backgroundColors.tertiary};
  cursor: ${props => (props.active ? 'default' : 'pointer')};

  &:hover {
    label {
      color: ${props =>
        props.active ? 'white' : props.theme.textColors.primary};
    }
    svg {
      font-size: 18px;
      color: ${props =>
        props.active
          ? 'white'
          : props.completed
          ? props.theme.colors.green
          : props.theme.textColors.primary};
    }
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    z-index: 51;
    transform: translateY(100%) translateX(-50%);
    ${props =>
      triangle({
        pointingDirection: 'bottom',
        width: '40px',
        height: '8px',
        foregroundColor: props.active ? props.theme.colors.blue : 'transparent',
      })};
  }

  label {
    color: ${props =>
      props.active
        ? 'white'
        : `rgba(${props.theme.textColors.tertiaryRGB}, 0.7)`};
    padding-bottom: 4px;
    user-select: none;
    pointer-events: none;
  }

  svg {
    font-size: 18px;
    color: ${props =>
      props.active
        ? 'white'
        : props.completed
        ? props.theme.colors.green
        : `rgba(${props.theme.textColors.tertiaryRGB}, 0.7)`};
  }
`;

const SectionBtnOrb = styled.span`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
`;

const SectionBtnLabel = styled.label`
  margin: 0;
  font-size: 9px;
  font-family: ${props => props.theme.fonts.primary};
  font-weight: ${props => props.theme.fontWeights.bold};
  letter-spacing: 0.6px;
  text-align: center;
  text-transform: uppercase;

  @media only screen and (min-width: 1200px) {
    font-size: 12px;
  }
`;

export default ChartingCareline;
