import React from 'react';
import styled from 'styled-components';
import { BsChevronCompactLeft } from 'react-icons/bs';

const PageHeader = ({ title, onBack, sticky }) => (
  <Wrapper sticky={sticky}>
    <BackButton onClick={onBack}>
      <BsChevronCompactLeft />
    </BackButton>
    <PageTitleWrapper>
      <PageTitle>{title}</PageTitle>
    </PageTitleWrapper>
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  background-color: white;
  border-bottom: 1px solid ${props => props.theme.colors.border};

  ${({ sticky }) =>
    sticky &&
    `
    position: sticky;
    top: 0;
    left: 0;
    z-index: 51;
  `}
`;

const BackButton = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  border-right: 1px solid ${props => props.theme.colors.border};
  outline: none;
  width: 60px;
  height: 60px;
  cursor: pointer;

  svg {
    font-size: 28px;
    color: ${props => props.theme.textColors.secondary};
  }

  &:hover {
    background-color: ${props => props.theme.backgroundColors.lightAlt};
    svg {
      color: ${props => props.theme.colors.blue};
    }
  }
`;

const PageTitleWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 10px 60px 10px 0;
`;

const PageTitle = styled.h2`
  margin: 0;
  font-size: 18px;
  font-weight: ${props => props.theme.fontWeights.bold};
`;

export default PageHeader;
