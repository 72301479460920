import React from 'react';
import styled from 'styled-components';
import { FaCheckSquare, FaRegSquare } from 'react-icons/fa';

const Checkbox = ({ checked, onChange, disabled = false, children }) => {
  return (
    <Wrapper checked={checked} onClick={onChange} disabled={disabled}>
      {checked ? <FaCheckSquare /> : <FaRegSquare />}
      <Label>{children}</Label>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  svg {
    color: ${props =>
      props.checked
        ? props.theme.colors.blue
        : props.theme.textColors.secondary};
    font-size: 20px;
    margin-right: 10px;
  }

  p {
    color: ${props =>
      props.checked
        ? props.theme.textColors.primary
        : props.theme.textColors.secondary};
    line-height: 1.4;
  }

  &:hover {
    svg {
      color: ${props => props.theme.colors.blue};
    }
    p {
      color: ${props => props.theme.textColors.primary};
    }
  }

  ${({ disabled }) =>
    disabled &&
    `
    cursor: default;
    pointer-events: none;
    opacity: 0.8;
  `}
`;

const Label = styled.p`
  font-size: 14px;
  margin: 0;
`;

export default Checkbox;
