import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { FaCheckCircle } from 'react-icons/fa';
import {
  findObjectByProperty,
  findObjectInArray,
} from '../../hooks/useTextHelper';

const ChecklistInput = ({
  sctid,
  value,
  label,
  locationOptions,
  options,
  radio,
  onChange,
  disabled = false,
}) => {
  // used to track UI states
  const [selectedKeys, setSelectedKeys] = useState([]);

  // used to save data to send to server
  const [locations, setLocations] = useState([]);
  const [selected, setSelected] = useState([]);

  const onItemSelect = key => {
    let arr = radio ? [key] : [...selectedKeys];
    if (!radio) {
      const idx = selectedKeys.indexOf(key);

      if (idx === -1) {
        arr.push(key);
      } else {
        arr.splice(idx, 1);
      }
    }
    setSelectedKeys([...arr]);

    // compile selected objects from array of keys
    const dataArr = [];
    arr.forEach(selectedKey => {
      dataArr.push(options[selectedKey]);
    });
    setSelected(dataArr);

    const obj = { sctid, selected: dataArr, label };
    if (locations.length > 0) obj.locations = locations;

    onChange(obj);
  };

  const onLocationSelect = loc => {
    let arr = [...locations];
    const idx = locations.indexOf(loc);

    if (idx === -1) {
      arr.push(loc);
    } else {
      arr.splice(idx, 1);
    }
    setLocations([...arr]);

    const obj = { sctid, selected, label };
    if (arr.length > 0) obj.locations = arr;

    onChange(obj);
  };

  // setup initial values when editing form
  useEffect(() => {
    if (value) {
      if (value.selected) {
        // initially store selected values in event no changes are made
        setSelected(value.selected);

        // fetch keys of selected items from form's options object
        let selArr = [];
        for (let i = 0; i < value.selected.length; i++) {
          const key = findObjectByProperty(
            options,
            'sctid',
            value.selected[i].sctid,
            true,
          );
          selArr.push(key);
        }
        setSelectedKeys(selArr);
      }
      if (value.locations && locationOptions) {
        // initially store location values in event no changes are made
        setLocations(value.locations);

        // fetch keys of selected items from form's locationOptions object
        let locArr = [];
        for (let k = 0; k < value.locations.length; k++) {
          const locObj = findObjectInArray(
            locationOptions,
            'sctid',
            value.locations[k].sctid,
          );
          locArr.push(locObj);
        }
        setLocations(locArr);
      }
    }
  }, [value]);

  useEffect(() => {
    if (radio && !value) {
      const keys = Object.keys(options);
      onItemSelect(keys[0]);
    }
  }, [radio]);

  return (
    <Wrapper disabled={disabled}>
      <Row>
        <label>{label}</label>
        {locationOptions ? (
          <LocationsWrapper>
            {locationOptions.map(loc => (
              <LocationBtn
                key={loc.sctid}
                active={locations.includes(loc)}
                onClick={() => onLocationSelect(loc)}>
                {loc.label.toUpperCase().charAt(0)}
              </LocationBtn>
            ))}
          </LocationsWrapper>
        ) : null}
      </Row>
      <OptionsWrapper radio={radio}>
        {Object.keys(options).map(key => (
          <OptionBtn
            key={key}
            active={selectedKeys.includes(key)}
            onClick={() => onItemSelect(key)}>
            {options[key].label}
            {selectedKeys.includes(key) ? <FaCheckCircle /> : null}
          </OptionBtn>
        ))}
      </OptionsWrapper>
    </Wrapper>
  );
};

const OptionBtn = styled.button`
  position: relative;
  outline: none;
  border: 1px solid;
  border-color: ${props =>
    props.active ? props.theme.colors.blue : props.theme.colors.border};
  border-radius: 10px;
  background-color: ${props =>
    props.active ? props.theme.colors.blue : 'white'};

  color: ${props =>
    props.active
      ? 'white'
      : `rgba(${props.theme.textColors.tertiaryRGB}, 0.6)`};
  text-transform: uppercase;
  font-size: 12px;
  font-weight: ${props => props.theme.fontWeights.bold};
  letter-spacing: 0.2px;
  padding: 0 10px;
  height: 42px;
  transition: all 0.2s;
  cursor: pointer;

  &:hover {
    color: ${props => (props.active ? 'white' : props.theme.colors.tertiary)};
    border-color: ${props =>
      props.active ? props.theme.colors.blue : props.theme.colors.tertiary};
  }

  svg {
    position: absolute;
    top: 4px;
    right: 4px;
    font-size: 12px;
    color: white;
  }
`;

const OptionsWrapper = styled.div`
  display: ${props => (props.radio ? 'flex' : 'grid')};

  ${({ radio }) =>
    !radio &&
    `
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 10px;
  `};

  ${OptionBtn} {
    ${({ radio }) =>
      radio &&
      `
      flex: 1;
      border-radius: 0;

      &:first-child {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px; 
      }
      &:last-child {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    `}
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;

  label {
    margin-bottom: 0;
  }
`;

const LocationsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: auto;
`;

const LocationBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  margin-left: 4px;
  width: 28px;
  height: 28px;
  outline: none;
  border: 1px solid;
  border-color: ${props =>
    props.active ? props.theme.colors.blue : props.theme.colors.border};
  border-radius: 5px;
  background-color: ${props =>
    props.active ? props.theme.colors.blue : 'white'};
  color: ${props =>
    props.active
      ? 'white'
      : `rgba(${props.theme.textColors.tertiaryRGB}, 0.6)`};
  font-size: 13px;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    color: ${props => (props.active ? 'white' : props.theme.colors.tertiary)};
    border-color: ${props =>
      props.active ? props.theme.colors.blue : props.theme.colors.tertiary};
  }
`;

const Wrapper = styled.div`
  width: 100%;

  ${({ disabled }) =>
    disabled &&
    `
    ${LocationBtn}, ${OptionBtn} {
      pointer-events: none;
      cursor: default;
      opacity: 0.9;
    }
  `}
`;

export default ChecklistInput;
