import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import SearchSelector from './SearchSelector';
import { decodeAddress } from '../../hooks/useFormHelper';

// Wrapper for SearchSelector

const PhysicianLookupSelector = ({
  id,
  value,
  onSelect,
  placeholder,
  disabled = false,
}) => {
  const [selected, setSelected] = useState(value);

  const displayNumber = useMemo(
    () => (!selected ? '' : `NPI: #${selected.npi}`),
    [selected],
  );

  const onSelected = (id, record) => {
    setSelected(record);

    // send to parent (Form.js)
    // overwrite all initial form data with pulled record
    const newRecord = record
      ? {
          ...record,
          ...decodeAddress(record.address),
        }
      : {};

    onSelect(newRecord);
  };

  return (
    <Container>
      <SearchSelector
        id={id}
        value={selected}
        mode="physician"
        placeholder={placeholder}
        selectedDisplay={displayNumber}
        onSelect={onSelected}
        disabled={disabled}
      />
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  text-align: center;
  width: 100%;
`;

export default PhysicianLookupSelector;
