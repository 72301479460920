// @flow
import React from 'react';
import AlertContextProvider from './contexts/AlertContext';
import CallContextProvider from './contexts/CallContext';
import EncounterContextProvider from './contexts/EncounterContext';
import FormContextProvider from './contexts/FormContext';
import LogContextProvider from './contexts/LogContext';
import MediaContextProvider from './contexts/MediaContext';
import ModalContextProvider from './contexts/ModalContext';
import PatientContextProvider from './contexts/PatientContext';
import PopoverContextProvider from './contexts/PopoverContext';
import SettingsContextProvider from './contexts/SettingsContext';
import SystemContextProvider from './contexts/SystemContext';

const Root = ({ children }) => (
  <SystemContextProvider>
    <SettingsContextProvider>
      <CallContextProvider>
        <PatientContextProvider>
          <MediaContextProvider>
            <LogContextProvider>
              <ModalContextProvider>
                <PopoverContextProvider>
                  <EncounterContextProvider>
                    <FormContextProvider>
                      <AlertContextProvider>{children}</AlertContextProvider>
                    </FormContextProvider>
                  </EncounterContextProvider>
                </PopoverContextProvider>
              </ModalContextProvider>
            </LogContextProvider>
          </MediaContextProvider>
        </PatientContextProvider>
      </CallContextProvider>
    </SettingsContextProvider>
  </SystemContextProvider>
);

export default Root;
