import React, { useState } from 'react';
import styled from 'styled-components';
import { darken } from 'polished';
import parse from 'html-react-parser';
import { FaPen } from 'react-icons/fa';
import EditModal from '../modals/EditModal';

// info: a configuration for an info card, a specially designed descriptor card
// summary: a configuration for a summary card

const Card = ({
  targetId,
  info,
  form,
  data,
  label,
  onClick,
  content,
  editable,
  inline,
  columnSize,
  rowSize,
  style,
  summary,
  custom,
  viewOnly,
}) => {
  const [showForm, setShowForm] = useState(false);

  const boxClick = () => {
    if (inline) setShowForm(true);
    else onClick();
  };

  const onFormClose = saved => {
    if (saved) {
      window.dispatchEvent(new Event('refetchData'));
    }

    setShowForm(false);
  };

  return (
    <Box
      onClick={boxClick}
      columnSize={columnSize}
      rowSize={rowSize}
      editing={showForm}
      disabled={viewOnly}>
      {custom ? (
        custom
      ) : (
        <CardContent>
          {summary ? (
            <SummaryCard>
              <SummaryIcon>{summary.icon}</SummaryIcon>
              <SummaryTitle>{summary.title}</SummaryTitle>
              <SummarySubtitle>{parse(summary.subtitle)}</SummarySubtitle>
            </SummaryCard>
          ) : showForm ? (
            <EditModal
              data={data}
              config={{
                targetId,
                form,
                type: 'form',
              }}
              inline
              onClose={onFormClose}
            />
          ) : info ? (
            <Info>
              <InfoIcon>{info.icon}</InfoIcon>
              <InfoLabel>{label}</InfoLabel>
              <InfoContent></InfoContent>
            </Info>
          ) : (
            <>
              <Label>{label}</Label>
              <Content style={style}>
                {content ? parse(content) : <Empty />}
              </Content>
            </>
          )}
        </CardContent>
      )}
      {editable && !viewOnly ? (
        <EditBtn>
          <FaPen />
        </EditBtn>
      ) : null}
    </Box>
  );
};

const EditBtn = styled.div`
  width: 44px;
  height: 44px;
  border-radius: 50%;
  display: inline-flex;
  background-color: white;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  border: 2px solid ${props => props.theme.colors.border};
  cursor: pointer;

  svg {
    font-size: 16px;
    color: ${props => props.theme.textColors.secondary};
  }
`;

const Box = styled.div`
  position: relative;
  grid-column: ${props => props.columnSize || 'auto'};
  grid-row: ${props => props.rowSize || 'auto'};
  border: 1px solid ${props => props.theme.colors.border};
  border-radius: 10px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 30px;
  box-sizing: border-box;
  cursor: ${props => (props.onClick && !props.editing ? 'pointer' : 'default')};
  overflow: hidden;

  &:hover {
    border-color: ${props =>
      props.onClick && !props.editing
        ? props.theme.colors.blue
        : props.theme.colors.border};

    ${EditBtn} {
      border-color: ${props => props.theme.colors.blue};

      svg {
        color: ${props => props.theme.colors.blue};
      }
    }
  }

  ${({ disabled }) =>
    disabled &&
    `
    pointer-events: none;
    cursor: default;
  `}
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

const Content = styled.div`
  margin: 10px 0 15px;
  color: ${props => props.theme.textColors.primary};
  font-size: 24px;
  font-weight: ${props => props.theme.fontWeights.bold};
  line-height: 1.5;
`;

const Label = styled.label`
  display: block;
  font-family: ${props => props.theme.fonts.secondary};
  font-weight: ${props => props.theme.fontWeights.medium};
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
`;

const Empty = styled.p`
  display: block;
  color: ${props => props.theme.textColors.tertiary};
  font-style: italic;
  font-size: 14px;
  font-weight: ${props => props.theme.fontWeights.regular};
  margin: 0;
  opacity: 0.7;

  &:after {
    content: '[ missing information ]';
  }
`;

const Info = styled.div`
  display: block;
  width: 100%;
  height: 100%;
`;

const InfoIcon = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: ${props => props.theme.backgroundColors.lightAlt};
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    color: ${props => props.theme.colors.blue};
    font-size: 28px;
  }
`;

const InfoLabel = styled.p`
  font-weight: ${props => props.theme.fontWeights.bold};
`;

const InfoContent = styled.div``;

const SummaryCard = styled.div``;

const SummaryTitle = styled.h3`
  font-size: 18px;
  font-family: ${props => props.theme.fonts.primary};
  text-align: center;
  margin: 15px 0 10px;
`;

const SummarySubtitle = styled.div`
  font-family: ${props => props.theme.fonts.secondary};
  font-size: 13px;
  line-height: 1.5;
  text-align: center;
  color: ${props => props.theme.textColors.tertiary};
  margin: 0;

  strong {
    display: inline-block;
    font-weight: ${props => props.theme.fontWeights.regular};
    background-color: ${props => props.theme.backgroundColors.tertiary};
    border: 1px solid;
    border-color: ${props =>
      darken(0.02, props.theme.backgroundColors.tertiary)};
    padding: 3px 10px;
    border-radius: 15px;
    font-size: 11px;
    color: ${props => props.theme.textColors.secondary};
    letter-spacing: 1px;
    margin-top: 2px;
  }
`;

const SummaryIcon = styled.div`
  svg {
    font-size: 36px;
    color: ${props => props.theme.colors.blue};
  }
`;

export default Card;
