import React, { useMemo, useEffect, useState, useContext, useRef } from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import AdminHeader from '../components/admin/AdminHeader';
import AdminSchedule from '../components/admin/AdminSchedule';
import { SystemContext } from '../contexts/SystemContext';
import { GET_REQUESTS, GET_STARRED_REQUESTS } from '../api/requests';
import ActivityIndicator from '../components/common/ActivityIndicator';
import CollapsibleList from '../components/common/CollapsibleList';
import { useEventListener } from '../hooks/useEventListener';

const AdminHomePage = () => {
  const channel = useRef();
  const {
    state: { pusher },
  } = useContext(SystemContext);
  const [refetchCount, setRefetchCount] = useState(0);

  const {
    data: allRequests,
    loading,
    error: requestsError,
    refetch: refetchRequests,
  } = useQuery(GET_REQUESTS);
  const {
    data: starredRequests,
    error: starredError,
    refetch: refetchStarred,
  } = useQuery(GET_STARRED_REQUESTS);
  const requests = useMemo(() => (allRequests ? allRequests.requests : null), [
    allRequests,
  ]);
  const starred = useMemo(
    () => (starredRequests ? starredRequests.requests : null),
    [starredRequests],
  );

  useEffect(() => {
    // break out refetch() into own useEffect so old version is not stored within event listener below
    refetchRequests();
    refetchStarred();
  }, [refetchCount]);

  useEffect(() => {
    const tasksUpdated = () => {
      setRefetchCount(count => count + 1);
    };

    if (pusher && !channel.current) {
      channel.current = pusher.subscribe('admin-tasks');
      channel.current.bind('update-list', tasksUpdated);
    }

    return () => {
      if (pusher && channel.current) {
        channel.current.unbind('update-list', tasksUpdated);
        channel.current = null;
        pusher.unsubscribe('admin-tasks');
      }
    };
  }, [pusher]);

  useEventListener('refreshRequestData', () =>
    setRefetchCount(count => count + 1),
  );

  return (
    <Container>
      <LeftColumn>
        <AdminHeader title="Dashboard" />

        {loading ? (
          <LoadingWrapper>
            <ActivityIndicator color="secondary" />
          </LoadingWrapper>
        ) : (
          <Tasks>
            {starred && starred.length > 0 ? (
              <CollapsibleList
                label="Starred"
                data={starred}
                error={starredError ? starredError.message : null}
              />
            ) : null}
            <CollapsibleList
              label={`Patient Requests ${
                requests ? `(${requests.length})` : ''
              }`}
              data={requests}
              error={requestsError ? requestsError.message : null}
            />
          </Tasks>
        )}
      </LeftColumn>
      <RightColumn>
        <AdminSchedule />
      </RightColumn>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
`;

const LeftColumn = styled.div`
  display: block;
  flex: 1;
  padding: 30px 40px;
  height: 100%;
  background-color: ${props => props.theme.backgroundColors.lightAlt};
  overflow-y: auto;
`;

const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
  background-color: white;
`;

const Tasks = styled.div`
  padding-top: 30px;

  > div {
    margin-bottom: 40px;
  }
`;

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export default AdminHomePage;
