import React, { useEffect, useContext, useState, useRef } from 'react';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';
import { darken } from 'polished';
import EmptyNotice from '../components/common/EmptyNotice';
import RecentCall from '../components/calls/RecentCall';
import WaitingRoomCall from '../components/calls/WaitingRoomCall';
import MissedCallModal from '../components/modals/MissedCallModal';
import { ModalContext } from '../contexts/ModalContext';
import { SystemContext } from '../contexts/SystemContext';
import {
  CARELINE_WAITING_ROOM_LIST,
  PHYSICIAN_WAITING_ROOM_LIST,
  GET_PHYSICIAN_RECENT_CALLS,
} from '../api/rooms';
import ActivityIndicator from '../components/common/ActivityIndicator';

const CarlineHomePage = ({ role }) => {
  const {
    state: { pusher, userId },
  } = useContext(SystemContext);
  const { dispatch: dispatchModal } = useContext(ModalContext);
  const [activeTab, setActiveTab] = useState('calls');
  const [refetchCount, setRefetchCount] = useState(0);
  const channel = useRef();

  const {
    loading: waitingRoomLoading,
    error,
    data: waitingRoomCalls,
    refetch,
  } = useQuery(
    role === 'physician'
      ? PHYSICIAN_WAITING_ROOM_LIST
      : CARELINE_WAITING_ROOM_LIST,
    {
      variables: { userId },
    },
  );

  const {
    loading: recentsLoading,
    error: recentsError,
    data: recentCalls,
  } = useQuery(GET_PHYSICIAN_RECENT_CALLS, {
    variables: { userId },
    skip: role !== 'physician',
  });

  useEffect(() => {
    if (error) console.log('Error loading Waiting Room: ', error);
    if (recentsError) console.log('Error loading Recent Calls: ', recentsError);
  }, [error, recentsError]);

  const onMissedCall = call => {
    dispatchModal({
      type: 'ADD_MODAL',
      data: {
        component: MissedCallModal,
        props: {
          call: call,
          maxWidth: '520px',
        },
        onDismiss: refetch,
      },
    });
  };

  useEffect(() => {
    // break out refecth() into own useEffect so old version is not stored within event listener below
    refetch();
  }, [refetchCount]);

  useEffect(() => {
    const callsUpdated = () => {
      setRefetchCount(count => count + 1);
    };

    if (pusher && !channel.current) {
      channel.current = pusher.subscribe('calls');
      channel.current.bind('calls-updated', callsUpdated);
    }

    return () => {
      if (pusher && channel.current) {
        channel.current.unbind('calls-updated', callsUpdated);
        channel.current = null;
        pusher.unsubscribe('calls');
      }
    };
  }, [pusher]);

  const activeData = activeTab === 'calls' ? waitingRoomCalls : recentCalls;
  const activeLoading =
    activeTab === 'calls' ? waitingRoomLoading : recentsLoading;

  return (
    <Container>
      <Header>
        <TabsWrapper>
          {role === 'physician' ? (
            <Tabs>
              <TabBtn
                active={activeTab === 'calls'}
                onClick={() => setActiveTab('calls')}>
                Waiting Room
              </TabBtn>
              <TabBtn
                active={activeTab === 'recent'}
                onClick={() => setActiveTab('recent')}>
                Recent Calls
              </TabBtn>
            </Tabs>
          ) : (
            <SectionTitle>Waiting Room</SectionTitle>
          )}
        </TabsWrapper>
        <TableHeader>
          {activeTab === 'recent' ? (
            <CellHeader style={{ textAlign: 'left' }}>Call Date</CellHeader>
          ) : null}
          <CellHeader fill="true">Patient Name</CellHeader>
          {activeTab === 'calls' ? (
            <>
              <CellHeader>Status</CellHeader>
              <CellHeader>Call Started</CellHeader>
              <CellHeader>Call Type</CellHeader>
            </>
          ) : (
            <CellHeader>Call Duration</CellHeader>
          )}
        </TableHeader>
      </Header>
      <Content>
        {!activeData || activeData.calls.length === 0 ? (
          <EmptyWrapper>
            <EmptyNotice marginBottom={100}>{`No ${
              activeTab === 'calls' ? 'Active' : 'Recent'
            } Calls`}</EmptyNotice>
          </EmptyWrapper>
        ) : (
          <List>
            {activeLoading
              ? null
              : activeData.calls.map(call =>
                  activeTab === 'calls' ? (
                    <WaitingRoomCall
                      key={call.room}
                      call={call}
                      onMissedCall={onMissedCall}
                    />
                  ) : (
                    <RecentCall key={call._id} call={call} />
                  ),
                )}
          </List>
        )}
      </Content>
      {waitingRoomLoading ? (
        <Loader>
          <ActivityIndicator size={18} color="#999" />
          <LoadingText>Refreshing List</LoadingText>
        </Loader>
      ) : null}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  padding: 30px 30px 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const TabsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
`;

const Tabs = styled.div`
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  overflow: hidden;
  border: 1px solid ${props => props.theme.colors.border};
`;

const TabBtn = styled.button`
  background-color: ${props =>
    props.active ? 'white' : props.theme.backgroundColors.lightAlt};
  color: ${props =>
    props.active
      ? props.theme.colors.primary
      : props.theme.textColors.secondary};
  height: 48px;
  width: 220px;
  cursor: ${props => (props.active ? 'default' : 'pointer')};
  font-size: 13px;
  font-weight: ${props => props.theme.fontWeights.bold};
  text-transform: uppercase;
  letter-spacing: 0.2px;
  outline: none;
  border: none;

  &:first-child {
    border-right: 1px solid ${props => props.theme.colors.border};
  }

  &:hover {
    background-color: ${props =>
      props.active
        ? 'white'
        : darken(0.01, props.theme.backgroundColors.lightAlt)};
    color: ${props =>
      props.active
        ? props.theme.colors.primary
        : props.theme.textColors.primary};
  }
`;

const SectionTitle = styled.h1`
  display: block;
  font-size: 24px;
  letter-spacing: 0.25px;
  color: ${props =>
    props.secondary ? 'white' : props.theme.textColors.primary};
  margin: 0;
`;

const CellHeader = styled.div`
  position: relative;
  padding: 0 10px;
  width: 180px;
  font-family: ${props => props.theme.fonts.primary};
  color: ${props => props.theme.textColors.tertiary};
  text-transform: uppercase;
  text-align: ${props => (props.fill ? 'left' : 'center')};
  font-size: 13px;
  font-weight: ${props => props.theme.fontWeights.bold};
  letter-spacing: 0.2px;
  margin-right: ${props => (props.fill ? 'auto' : 0)};

  @media (max-width: 1199px) {
    width: 120px;
  }
`;

const Header = styled.div``;

const Content = styled.div`
  flex: 1;
  overflow-y: scroll;
  padding-bottom: 30px;
`;

const TableHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0 20px 10px;
`;

const List = styled.div`
  position: relative;
  flex: 1;
  flex-direction: column;
  padding-bottom: 80px;
`;

const EmptyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 100%;
`;

const Loader = styled.div`
  position: fixed;
  right: 30px;
  bottom: 30px;
  background-color: white;
  border: 2px solid ${props => props.theme.colors.border};
  border-radius: 40px;
  padding: 15px 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const LoadingText = styled.span`
  font-size: 14px;
  font-weight: ${props => props.theme.fontWeights.bold};
  color: ${props => props.theme.textColors.secondary};
  padding-left: 15px;
`;

export default CarlineHomePage;
