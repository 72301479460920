import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import { FaUserMd, FaStethoscope, FaPrescription } from 'react-icons/fa';
import { RiHome4Line } from 'react-icons/ri';
import { useEventListener } from '../../../hooks/useEventListener';
import { FormContext } from '../../../contexts/FormContext';
import { PatientContext } from '../../../contexts/PatientContext';
import ActivityIndicator from '../../common/ActivityIndicator';
import Grid from '../../common/Grid';
import PageHeader from '../../common/PageHeader';
import ChartingOnboardingPersonal from './ChartingOnboardingPersonal';
import ChartingOnboardingMedical from './ChartingOnboardingMedical';
import ChartingOnboardingCareTeam from './ChartingOnboardingCareTeam';
import ChartingVitals from '../ChartingVitals';
import EditModal from '../../modals/EditModal';
import { GET_PATIENT_INFO } from '../../../api/patients';

const SECTIONS = {
  personal: {
    summary: {
      title: 'Personal Information',
      subtitle: 'Verify Address, Contact and Household Information',
      icon: <RiHome4Line />,
    },
  },
  medical: {
    summary: {
      title: 'Medical Record',
      subtitle: 'Verify Diagnoses, Allergies, Medications and History',
      icon: <FaPrescription />,
    },
  },
  care: {
    summary: {
      title: 'Care Team',
      subtitle:
        'Primary Care Physician, Case Manager, <br> Preferred Pharmacy and Hospital',
      icon: <FaUserMd />,
    },
  },
};

const SECTIONS_CALL = {
  ...SECTIONS,
  vitals: {
    summary: {
      title: 'Vitals',
      subtitle: 'Introduce BetterMedics Kit and Collect Vitals',
      icon: <FaStethoscope />,
    },
  },
};

const ChartingOnboarding = ({
  userId,
  inModal,
  stickyFooter,
  hideVitals,
  ehr,
}) => {
  const { dispatch: dispatchPatient } = useContext(PatientContext);
  const { state: form, dispatch: dispatchForm } = useContext(FormContext);
  const [section, setSection] = useState();

  const SECTION_LIST = hideVitals ? SECTIONS : SECTIONS_CALL;

  const { data: patientData, loading, refetch } = useQuery(GET_PATIENT_INFO, {
    variables: { userId },
  });

  const closeForm = saved => {
    if (saved) {
      // data was saved on patient, update the record
      refetch();
    }

    dispatchForm({ type: 'CLEAR' });
    window.dispatchEvent(new Event('refreshListData'));
  };

  const updatePatient = () => {
    refetch();
  };

  useEffect(() => {
    if (patientData) {
      dispatchPatient({
        type: 'SET_PATIENT',
        data: {
          patient: patientData.patient,
        },
      });
    }
  }, [patientData]);

  useEventListener('refetchData', updatePatient);

  return (
    <>
      {form ? (
        <EditModal
          data={form.formData}
          config={form.config}
          stickyFooter={inModal || stickyFooter}
          onClose={closeForm}
          inModal={inModal}
        />
      ) : null}
      <Container disableScroll={form}>
        <PageWrapper on={section}>
          {section ? (
            <PageHeader
              title={SECTION_LIST[section].summary.title}
              onBack={() => setSection(null)}
              sticky
            />
          ) : null}
          {loading ? (
            <EmptyView>
              <ActivityIndicator color="secondary" />
            </EmptyView>
          ) : patientData ? (
            <PageContent>
              {section === 'personal' ? (
                <ChartingOnboardingPersonal patient={patientData.patient} />
              ) : section === 'medical' ? (
                <ChartingOnboardingMedical patient={patientData.patient} ehr />
              ) : section === 'care' ? (
                <ChartingOnboardingCareTeam patient={patientData.patient} />
              ) : section === 'vitals' ? (
                <ChartingVitals />
              ) : null}
            </PageContent>
          ) : (
            <EmptyView>
              <Error>
                <ErrorTitle>No Patient Data</ErrorTitle>
                <p>
                  Patient data was not returned from the server. To try to
                  refresh the data, click the button below. If the problem
                  persists, copy the message below for the technical team to
                  investigate.
                </p>
                <code>{`Error retrieving Patient data for ID# (${userId}) in ChartingOnboarding using call GET_PATIENT_INFO.`}</code>
                <ErrorBtn onClick={updatePatient}>
                  Refresh Patient Data
                </ErrorBtn>
              </Error>
            </EmptyView>
          )}
        </PageWrapper>
        <SectionsWrapper hide={section}>
          <Grid
            config={{
              cards: SECTION_LIST,
              template: hideVitals ? '1fr' : '1fr 1fr',
            }}
            onClick={setSection}
          />
        </SectionsWrapper>
      </Container>
    </>
  );
};

const Error = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 600px;
  padding: 20px;
  text-align: center;

  p {
    line-height: 1.4;
  }
`;

const ErrorTitle = styled.h2`
  font-size: 28px;
  margin: 0;
`;

const ErrorBtn = styled.button`
  outline: none;
  border: none;
  border-radius: 8px;
  background-color: ${props => props.theme.colors.blue};
  color: white;
  height: 44px;
  padding: 0 24px;
  margin-top: 30px;
`;

const Container = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: block;
  border-top: 1px solid ${props => props.theme.colors.border};
  background-color: ${props => props.theme.backgroundColors.lightAlt};
  overflow-y: ${props => (props.disableScroll ? 'hidden' : 'scroll')};
  overflow-x: hidden;
`;

const PageWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
  transform: ${props => (props.on ? 'translateX(0)' : 'translateX(200px)')};
  transition: all 0.3s;
  opacity: ${props => (props.on ? 1 : 0)};
  pointer-events: ${props => (props.on ? 'auto' : 'none')};
  display: flex;
  flex-direction: column;
`;

const PageContent = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
`;

const SectionsWrapper = styled.div`
  transform: ${props => (props.hide ? 'translateX(-200px)' : 'translateX(0)')};
  transition: all 0.3s;
  opacity: ${props => (props.hide ? 0 : 1)};
  pointer-events: ${props => (props.hide ? 'none' : 'auto')};
`;

const EmptyView = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

export default ChartingOnboarding;
