import React from 'react';
import styled from 'styled-components';
import { CAREPLAN_ACTIONS } from '../../../forms/careplan';

const CareplanIcon = ({ type }) => (
  <Icon>{type ? CAREPLAN_ACTIONS[type].icon : null}</Icon>
);

const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e3e7f5;
  width: 40px;
  height: 40px;
  border-radius: 15px;
  margin-right: 10px;

  svg {
    font-size: 16px;
    color: ${props => props.theme.textColors.primary};
  }
`;

export default CareplanIcon;
