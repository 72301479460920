import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import { FaTimes } from 'react-icons/fa';
import { FiSend } from 'react-icons/fi';
import ActivityIndicator from '../common/ActivityIndicator';
import { CallContext } from '../../contexts/CallContext';
import { SystemContext } from '../../contexts/SystemContext';
import WithTooltip from '../common/WithTooltip';

const AMAModal = ({ onDismiss }) => {
  const {
    state: { room, encounter },
    dispatch: dispatchCall,
  } = useContext(CallContext);
  const {
    state: { realmUser },
  } = useContext(SystemContext);

  const [formData, setFormData] = useState({
    care: '',
    chart: '',
  });
  const [saving, setSaving] = useState(false);
  const [focused, setFocused] = useState();
  const [valid, setValid] = useState(false);

  const onFieldChange = (field, value) => {
    setFormData((prevState, props) => {
      return {
        ...prevState,
        [field]: value,
      };
    });
  };

  const onSave = async () => {
    setSaving(true);

    await realmUser.functions.sendPusherEvent(`presence-${room}`, 'show-ama', {
      care: formData.care,
    });

    dispatchCall({
      type: 'SET_AMA',
      data: { status: 'sent', care: formData.care, chart: formData.chart },
    });

    await realmUser.functions.updateAMAStatus(
      encounter,
      'sent',
      formData.chart,
    );

    setSaving(false);

    onDismiss();
  };

  useEffect(() => {
    const isValid = formData.care !== '' && formData.chart !== '';
    setValid(isValid);
  }, [formData]);

  return (
    <Container>
      <FormWrapper>
        <Title>Against Medical Advice (AMA) Patient Form</Title>
        <Description>
          This form should be utilized if the Patient is refusing care or has
          stated that he/she will not follow medical advice.
        </Description>
        <Fieldset active={focused === 'care'}>
          <Label>Description for the Patient</Label>
          <Textarea
            placeholder="Patient has been advised to ..."
            defaultValue={formData.care}
            onChange={e => onFieldChange('care', e.target.value)}
            onBlur={() => setFocused()}
            onFocus={() => setFocused('care')}
          />
          <p>
            Please enter your recommendation in the field below. This
            description will be SHARED with the PATIENT on their tablet.
          </p>
        </Fieldset>
        <Fieldset active={focused === 'care'}>
          <Label>Description for the Chart</Label>
          <Textarea
            placeholder="Patient has been advised to ..."
            defaultValue={formData.chart}
            onChange={e => onFieldChange('chart', e.target.value)}
            onBlur={() => setFocused()}
            onFocus={() => setFocused('chart')}
          />
          <p>
            Please provide additional details about your recommendation,
            possible medical risks and the patient's response. This
            documentation is NOT SHARED with the patient and becomes part of the
            ENCOUNTER.
          </p>
        </Fieldset>
      </FormWrapper>
      <FormFooter valid={valid}>
        <WithTooltip title={valid ? null : 'Form is incomplete'}>
          <SaveBtn disabled={!valid || saving} onClick={onSave}>
            {saving ? <ActivityIndicator color="white" /> : <FiSend />}
          </SaveBtn>
        </WithTooltip>
      </FormFooter>
      <CloseBtn onClick={onDismiss}>
        <FaTimes />
      </CloseBtn>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  background-color: ${props => props.theme.backgroundColors.secondary};
  display: flex;
  flex-direction: column;
  max-height: 600px;
  overflow: hidden;
`;

const Title = styled.h2`
  margin: 0 0 20px;
  font-size: 22px;
  color: ${props => props.theme.textColors.primary};
  text-align: center;
`;

const Description = styled.p`
  font-size: 14px;
  margin: 10px auto 30px;
  line-height: 1.5;
  text-align: center;
  width: 400px;
`;

const FormWrapper = styled.div`
  flex: 1;
  overflow-y: scroll;
  padding: 30px 30px 0;
`;

const Fieldset = styled.fieldset`
  text-align: center;
  width: 100%;
  padding: 0;
  margin-bottom: 30px;
  opacity: ${props => (props.active ? 1 : 0.8)};

  p {
    font-size: 12px;
    line-height: 1.4;
    margin-top: 3px;
    text-align: left;
    color: ${props => props.theme.textColors.secondary};
    padding: 0 10px;
  }
`;

const Label = styled.label`
  color: ${props => props.theme.textColors.primary};
`;

const Textarea = styled.textarea`
  resize: none;
  width: 100%;
  font-size: 14px;
  padding: 12px 14px;
`;

const CloseBtn = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 10;
  border: 0;
  outline: none;
  background-color: transparent;
  opacity: 0.5;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }

  svg {
    font-size: 20px;
    color: ${props => props.theme.textColors.tertiary};
    opacity: 0.8;
  }
`;

const FormFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  margin-left: -25px;
  margin-right: -25px;
  padding: 15px;
  background-color: ${props =>
    props.valid ? 'rgba(255, 255, 255, 0.4)' : 'rgba(255, 255, 255, 0.2)'};
`;

const SaveBtn = styled.button`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.colors.warning};
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  border: none;
  outline: none;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};

  svg {
    color: white;
    font-size: 26px;
    margin-top: ${props => (props.disabled ? 0 : '2px')};
    margin-left: ${props => (props.disabled ? 0 : '-2px')};
  }
`;

export default AMAModal;
