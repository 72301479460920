import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { DOCUMENT_TITLE, TIMEOUT } from '../../../constants';

const TimeoutModal = () => {
  const [timer, setTimer] = useState(TIMEOUT.warnFor);

  useEffect(() => {
    const myInterval = setInterval(() => {
      if (timer > 0) {
        document.title = `${DOCUMENT_TITLE} (${timer - 1}s)`;
        setTimer(timer - 1);
      }
    }, 1000);
    return () => {
      document.title = DOCUMENT_TITLE;
      clearInterval(myInterval);
    };
  });

  return (
    <Container>
      <Shade />
      <Box>
        <Title>Are you still there?</Title>
        <Subtitle>
          You will be logged out in{' '}
          <b>{`${timer} second${timer !== 1 ? 's' : ''}`}</b>
          <br /> due to inactivity.
        </Subtitle>
      </Box>
    </Container>
  );
};

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 250;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const Shade = styled.div`
  background-color: ${props => props.theme.backgroundColors.primary};
  opacity: 0.5;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
`;

const Box = styled.div`
  padding: 30px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 320px;

  box-shadow: 0 0 10px #f5f7ff;
  border: 1px solid #f5f7ff;

  position: relative;
  z-index: 2;
`;

const Title = styled.h3`
  font-size: 16px;
  font-weight: bold;
  color: ${props => props.theme.textColors.primary};
  text-align: center;
`;

const Subtitle = styled.p`
  font-size: 14px;
  color: ${props => props.theme.textColors.secondary};
  text-align: center;
  line-height: 1.4;
`;

export default TimeoutModal;
