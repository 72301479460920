import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { format, addMonths, parseISO } from 'date-fns';
import Selector from './Selector';
import { padDigit } from '../../hooks/useTextHelper';
import { generateReverseYears } from '../../hooks/useFormHelper';
import { MONTHS } from '../../../constants';

const YEARS = generateReverseYears(100, true);

const convertToDate = data => {
  return new Date(data.year, parseInt(data.month) - 1, 1);
};

const MonthYear = ({
  id,
  value,
  onChange,
  onAutosave,
  convert,
  disabled = false,
}) => {
  const isInitialMount = useRef(true);

  const [data, setData] = useState();

  const onSelect = (id, val) => {
    const newData = {
      ...data,
      [id]: val,
    };
    setData(newData);

    const formatted = convert ? convertToDate(newData) : newData;
    onChange(formatted);

    // if autosave, force it
    if (onAutosave) onAutosave(id, newData, true);
  };

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      const parsedValue = parseISO(value);
      const initData = {
        month: !value
          ? MONTHS[0]
          : convert
          ? padDigit(format(parsedValue, 'M'))
          : parsedValue.month,
        year: !value
          ? YEARS[0]
          : convert
          ? format(parsedValue, 'yyyy')
          : parsedValue.year,
      };

      setData(initData);

      const formatted = convert ? convertToDate(initData) : initData;
      onChange(formatted);
    }
  }, [value, data, onChange]);

  return (
    <Container>
      {data ? (
        <>
          <Selector
            value={data.month}
            options={MONTHS}
            onChange={val => onSelect('month', val)}
            disabled={disabled}
          />
          <Selector
            value={data.year}
            options={YEARS}
            onChange={val => onSelect('year', val)}
            disabled={disabled}
          />
        </>
      ) : null}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;

  > select:first-child {
    margin-right: 5px;
  }
`;

export default MonthYear;
