import React from 'react';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';
import { GET_PATIENT_INFO } from '../../api/patients';
import ActivityIndicator from '../common/ActivityIndicator';
import ChartingOnboarding from '../charting/onboarding/ChartingOnboarding';
import PatientHeaderOnboarding from '../patient/PatientHeaderOnboarding';

const PatientModal = ({ patient, onDismiss }) => {
  const { data, loading, error } = useQuery(GET_PATIENT_INFO, {
    variables: { userId: patient.userId },
  });

  return (
    <Container>
      {loading ? (
        <CenterWrapper>
          <ActivityIndicator />
        </CenterWrapper>
      ) : data && data.patient ? (
        <>
          <PatientHeaderOnboarding patient={data.patient} onBack={onDismiss} />

          <ChartingOnboarding
            userId={data.patient.userId}
            stickyFooter
            hideVitals
          />
        </>
      ) : (
        <CenterWrapper>
          <Error>
            {error
              ? error.message
              : data && data.errors
              ? data.errors.message
              : 'An error occurred retrieving data'}
          </Error>
        </CenterWrapper>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const CenterWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const Error = styled.p`
  text-align: center;
  font-size: 15px;
  color: ${props => props.theme.textColors.secondary};
`;

export default PatientModal;
