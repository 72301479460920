import React from 'react';
import styled from 'styled-components';
import parse from 'html-react-parser';

import { capitalize } from '../../hooks/useTextHelper';

import thermometerAdult from '../../../assets/images/vitals/thermometer-mode-adult.svg';
import thermometerChild from '../../../assets/images/vitals/thermometer-mode-child.svg';
import thermometerEar from '../../../assets/images/vitals/thermometer-mode-ear.svg';
import thermometerHome from '../../../assets/images/vitals/thermometer-mode-home.svg';

const getTempModeIcon = id =>
  id === 'adult'
    ? thermometerAdult
    : id === 'ear'
    ? thermometerEar
    : id === 'child'
    ? thermometerChild
    : id === 'home'
    ? thermometerHome
    : '';

const Temperature = ({ temp, mode, widget, saved }) => (
  <Container widget={widget}>
    <Reading temp={temp}>{parse(`${temp}&deg;<b> F</b>`)}</Reading>
    {mode ? (
      <TemperatureMode
        title={`${capitalize(mode)} mode`}
        src={getTempModeIcon(mode)}
      />
    ) : null}
  </Container>
);

const Reading = styled.div`
  font-size: 48px;
  color: ${props =>
    props.temp > 101
      ? props.theme.colors.primary
      : props.temp > 99
      ? props.theme.colors.tertiary
      : props.temp > 97
      ? props.theme.colors.green
      : props.theme.colors.blue};
  font-weight: ${props => props.theme.fontWeights.bold};

  b {
    font-size: 32px;
  }
`;

const TemperatureMode = styled.img`
  display: block;
  width: 30px;
  height: 30px;
  position: absolute;
  top: 15px;
  left: 15px;
  opacity: 0.7;
`;

const Container = styled.div`
  text-align: center;

  ${({ widget }) =>
    widget &&
    `
    ${TemperatureMode} {
      position: relative;
      top: auto;
      left: auto;
      width: 24px;
      height: 24px;
      margin: 10px auto 0;
    }
  `}
`;

export default Temperature;
