import React, { useEffect, useContext } from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import { format, parseISO } from 'date-fns';
import { BiRightArrowCircle } from 'react-icons/bi';
import { GET_RECENT_CALLS } from '../../api/rooms';
import { formatName } from '../../hooks/useTextHelper';
import ActivityIndicator from '../common/ActivityIndicator';
import { EncounterContext } from '../../contexts/EncounterContext';
import UserIcon from '../common/UserIcon';

const CallPopover = ({ onDismiss }) => {
  const { dispatch: dispatchEncounter } = useContext(EncounterContext);
  const { loading, error, data } = useQuery(GET_RECENT_CALLS);

  const openEncounter = encounter => {
    // open modal for encounter
    dispatchEncounter({
      type: 'VIEW_ENCOUNTER',
      data: {
        encounterId: encounter,
      },
    });

    onDismiss();
  };

  return (
    <Container>
      <Header>
        <Title>Recent Calls</Title>
        <Subtitle>Last 30 Encounters</Subtitle>
      </Header>
      <List>
        {loading ? (
          <LoadingWrapper>
            <ActivityIndicator color="secondary" />
          </LoadingWrapper>
        ) : !data || data.calls?.length === 0 ? (
          <Empty>No active calls</Empty>
        ) : (
          data.calls.map(call => {
            const {
              _id,
              date,
              encounter,
              patient,
              room,
              careline,
              physician,
            } = call;
            return encounter ? (
              <ListItem key={_id} onClick={() => openEncounter(encounter)}>
                <Date>
                  {format(parseISO(date), 'M/d')}
                  <span>{format(parseISO(date), 'h:mm a')}</span>
                </Date>
                <PeopleWrapper>
                  <UserIcon user={patient} size="36px" patient />

                  <Name>{`${patient?.name?.given} ${patient?.name?.family}`}</Name>

                  <Caret>
                    <BiRightArrowCircle />
                  </Caret>
                  <Name secondary={!physician}>
                    {physician || careline
                      ? formatName(physician || careline, physician)
                      : '[ No answer ]'}
                  </Name>
                </PeopleWrapper>
              </ListItem>
            ) : null;
          })
        )}
      </List>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 8px;

  width: 500px;
  height: 400px;
  overflow: hidden;
  box-shadow: 0 5px 20px 0 rgb(21 27 38 / 8%);

  margin-top: 20px;
  transform: translateX(20px);
`;

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 24px 30px 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h3`
  flex: 1;
  margin: 0;
`;

const Subtitle = styled.p`
  margin: 0;
  font-size: 11px;
  color: ${props => props.theme.textColors.secondary};
`;

const List = styled.div`
  flex: 1;
  overflow-y: scroll;
  width: 100%;
  padding: 0 0 20px;
`;

const Date = styled.div`
  color: ${props => props.theme.textColors.primary};
  font-size: 17px;
  font-weight: ${props => props.theme.fontWeights.bold};
  margin-right: 20px;
  width: 46px;

  span {
    display: block;
    font-size: 10px;
    color: ${props => props.theme.textColors.secondary};
    margin-top: 4px;
  }
`;

const ListItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 14px 20px;
  border-bottom: 1px solid ${props => props.theme.backgroundColors.lightAlt};
  cursor: pointer;

  &:hover {
    background-color: ${props => props.theme.backgroundColors.lightAlt};
  }

  &:last-of-type {
    border-bottom: none;
  }
`;

const PeopleWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  width: 394px;
`;

const Caret = styled.div`
  margin: 0 12px;
  svg {
    color: ${props => props.theme.textColors.secondary};
    font-size: 16px;
  }
`;

const Name = styled.div`
  font-size: 15px;
  font-weight: ${props => props.theme.fontWeights.bold};
  color: ${props => props.theme.textColors.primary};
  margin-bottom: 2px;
  margin-left: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  ${({ secondary, theme }) =>
    secondary && `color: ${theme.textColors.secondary};`}
`;

const Empty = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  font-size: 14px;
  color: ${props => props.theme.textColors.secondary};
  text-align: center;
  height: 100%;
  width: 100%;
`;

export default CallPopover;
