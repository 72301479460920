import React, { useRef, useEffect, useContext } from 'react';
import styled from 'styled-components';
import parse from 'html-react-parser';
import { useApolloClient } from '@apollo/client';
import { lighten } from 'polished';
import ContactCard from './ContactCard';
import { SET_USER_AVAILABLE } from '../../api/team';
import { SystemContext } from '../../contexts/SystemContext';

const ErrorMessage = ({ title, description, contact }) => {
  const isInitialMount = useRef(true);
  const client = useApolloClient();

  const {
    state: { userId },
  } = useContext(SystemContext);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;

      const markAsAvailable = async () => {
        try {
          await client.mutate({
            mutation: SET_USER_AVAILABLE,
            variables: { id: userId },
          });
        } catch (err) {
          console.log('Error marking staff available: ', err);
        }
      };

      markAsAvailable();
    }
  }, []);

  return (
    <ErrorWrapper>
      <ErrorTitle>{title}</ErrorTitle>
      <ErrorDescription>{parse(description)}</ErrorDescription>
      {contact ? <ContactCard info={contact} /> : null}
      <ErrorButton onClick={() => (window.location.href = '/')}>
        Return to home screen
      </ErrorButton>
    </ErrorWrapper>
  );
};

const ErrorWrapper = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  margin: 0 auto;
  max-width: 840px;
  padding: 80px;
`;

const ErrorTitle = styled.h1`
  font-weight: ${props => props.theme.fontWeights.medium};
`;

const ErrorDescription = styled.p`
  color: ${props => props.theme.textColors.secondary};
  line-height: 1.8;
  margin-bottom: 40px;
`;

const ErrorButton = styled.button`
  display: block;
  border: none;
  border-radius: 8px;
  padding: 12px 24px;
  color: white;
  font-family: ${props => props.theme.fonts.secondary};
  font-weight: ${props => props.theme.fontWeights.medium};
  background-color: ${props => props.theme.colors.blue};
  outline: none;

  &:hover {
    background-color: ${props => lighten(0.03, props.theme.colors.blue)};
    cursor: pointer;
  }
`;

export default ErrorMessage;
