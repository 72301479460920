import React from 'react';
import styled from 'styled-components';
import ReviewOfSystemsForm from '../charting/physician/ReviewOfSystemsForm';
import { SYSTEMS } from '../../../forms/exams';
import { createInitialData } from '../../hooks/useFormHelper';

const ConstitutionalModal = ({ encounter, patient, data, onDismiss }) => {
  const initialData = data || createInitialData(SYSTEMS.constitutional.form);

  return (
    <Container>
      <Wrapper>
        <ReviewOfSystemsForm
          patient={patient}
          encounter={encounter}
          system={SYSTEMS.constitutional}
          formData={initialData}
          onBack={onDismiss}
        />
      </Wrapper>
    </Container>
  );
};

const Container = styled.div`
  flex: 1;
  height: 100%;
  width: 100%;
  background-color: ${props => props.theme.backgroundColors.tertiary};
`;

const Wrapper = styled.div`
  height: calc(100% - 74px);
`;

export default ConstitutionalModal;
