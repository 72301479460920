import React, { useState, useRef, forwardRef, useEffect } from 'react';
import styled from 'styled-components';

const SocialSecurityInput = forwardRef(
  ({ value, onChange, disabled = false }, ref) => {
    const isInitialMount = useRef(true);
    const [data, setData] = useState({ full: '', last4: '' });

    const onValChange = val => {
      setData(prev => {
        return { ...prev, full: val };
      });
    };

    const onValBlur = val => {
      // strip to only numbers, verify it's 9 digits long
      let reducedVal = val.replace(/[^$\d]+/g, '');

      const newData = {
        full: reducedVal,
        last4: reducedVal.substring(reducedVal.length - 4, reducedVal.length),
      };

      const isValid = reducedVal.length === 9;
      onChange(newData, isValid);

      // format to a pretty display on blur (XXX-XX-XXXX)
      const fullWithDashes =
        reducedVal.substring(0, 3) +
        '-' +
        reducedVal.substring(3, 5) +
        '-' +
        reducedVal.substring(5, 9);
      setData({
        ...newData,
        full: fullWithDashes,
      });
    };

    useEffect(() => {
      if (isInitialMount.current) {
        isInitialMount.current = false;

        if (!value) return;

        onValBlur(value?.full);
      }
    }, [value]);

    return (
      <TextInput
        ref={ref}
        onChange={e => onValChange(e.target.value)}
        onBlur={e => onValBlur(e.target.value)}
        placeholder="XXX-XX-XXXX"
        type="text"
        value={data?.full}
        maxLength={14}
        disabled={disabled}
      />
    );
  },
);

const TextInput = styled.input``;

export default SocialSecurityInput;
