import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const EncounterVitals = ({ vitals, exams }) => {
  const [data, setData] = useState();

  useEffect(() => {
    // combine objects on init to parse data into readable object
    const findConstitutional = () => {
      if (!exams) return null;

      const CONSTITUTIONAL_SCTID = '363791007';

      for (let i = 0; i < exams.length; i++) {
        if (exams[i].system?.sctid === CONSTITUTIONAL_SCTID) {
          return exams[i];
        }
      }

      return null;
    };

    let vitalsData = {};

    // 1. check if constituational exam is complete
    const constitutional = findConstitutional();

    // 2. grab values
    for (let j = 0; j < vitals.length; j++) {
      const vital = vitals[j].value;

      if (vitals[j].type === 'pulseox') {
        vitalsData['pulse'] = {
          pi: vital.pulse,
          pleth: vital.pleth,
          value: vital.pulse || '-',
        };

        vitalsData['O2'] = {
          value: vital.spo2 || '-',
        };
      }

      if (vitals[j].type === 'temperature') {
        vitalsData['temperature'] = {
          value: vital.temp || '-',
        };
      }

      if (vitals[j].type === 'bp') {
        vitalsData['bp'] = {
          value: vital.sys && vital.dia ? `${vital.sys} / ${vital.dia}` : '-',
        };
      }
    }

    // 3. use constitutional to see if values are abnormal (optional)
    if (constitutional?.inspections) {
      const { inspections } = constitutional;
      for (let k = 0; k < inspections.length; k++) {
        const { type, selected } = inspections[k];
        if (vitalsData[type] && selected) {
          vitalsData[type].abnormal = selected[0].label === 'Abnormal';
        }
      }
    }

    setData(vitalsData);
  }, [exams, vitals]);

  return data ? (
    <VitalsGrid>
      <Vital abnormal={data.temperature?.abnormal}>
        <label>Temperature</label>
        <span>{data.temperature?.value || '-'}</span>
      </Vital>
      <Vital abnormal={data.bp?.abnormal}>
        <label>Blood Pressure</label>
        <span>{data.bp?.value || '-'}</span>
      </Vital>
      <Vital abnormal={data.pulse?.abnormal}>
        <label>Pulse</label>
        <span>{data.pulse?.value || '-'}</span>
      </Vital>
      <Vital abnormal={data.O2?.abnormal}>
        <label>
          O<sup>2</sup> Saturation
        </label>
        <span>{data.O2?.value || '-'}</span>
      </Vital>
    </VitalsGrid>
  ) : null;
};

const VitalsGrid = styled.div`
  display: grid;
  gap: 15px 10px;
  grid-template-columns: 1fr 1fr;
  padding-bottom: 30px;
`;

const Vital = styled.div`
  min-height: 43px;
  label {
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 400;
    letter-spacing: 0.4px;
    margin-bottom: 4px;
  }
  span {
    font-size: 24px;
    font-weight: bold;
    color: ${props =>
      props.abnormal
        ? props.theme.colors.warning
        : props.theme.textColors.primary};
  }
`;

export default EncounterVitals;
