import React, { useMemo } from 'react';
import styled, { keyframes } from 'styled-components';

const LoadingDots = ({ radius = 3, spacing = 1, color = '#A3A3A3' }) => {
  const diameter = useMemo(() => radius * 2, [radius]);
  const canvasWidth = useMemo(() => radius * 7 + spacing * 2, [
    radius,
    spacing,
  ]);

  return (
    <svg
      id="dots"
      width={`${canvasWidth}px`}
      height={`${diameter}px`}
      viewBox={`0 0 ${canvasWidth} ${diameter}`}>
      <g
        id="canvas"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd">
        <g id="dotsWrapper" fill={color}>
          <Dot num="dot1" cx={radius} cy={radius} r={radius}></Dot>
          <Dot
            num="dot2"
            cx={radius * 4 - spacing}
            cy={radius}
            r={radius}></Dot>
          <Dot
            num="dot3"
            cx={radius * 7 - spacing * 2}
            cy={radius}
            r={radius}></Dot>
        </g>
      </g>
    </svg>
  );
};

const cycle = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const Dot = styled.circle`
  animation-name: ${cycle};
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-delay: ${props =>
    props.num === 'dot2' ? '0.3s' : props.num === 'dot3' ? '0.6s' : 0};
`;

export default LoadingDots;
