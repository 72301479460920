import React from 'react';
import styled from 'styled-components';
import { parseISO, format } from 'date-fns';

/**
 * Used for AMA and EMS details displayed within Encounter Modals
 */

const getDate = date => {
  // prevents crash if date data is ever not as expected
  try {
    return format(parseISO(date), 'M/d/yyyy h:mm aaa');
  } catch (err) {
    console.log('bad date provided: ', date);
    return '-';
  }
};

const EncounterDetailsPopover = ({ data }) => (
  <Container>
    <Content>
      {data.map(item => (
        <div key={item.label}>
          <label>{item.label}</label>
          <p>
            {typeof item.value === 'object' ? getDate(item.value) : item.value}
          </p>
        </div>
      ))}
    </Content>
  </Container>
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 8px;

  width: 300px;
  max-height: 220px;
  padding: 4px 0;
  overflow: hidden;
  border: 1px solid ${props => props.theme.colors.border};
  box-shadow: 0 5px 20px 0 rgb(21 27 38 / 8%);
`;

const Content = styled.div`
  flex: 1;
  overflow-y: scroll;
  padding: 20px;

  p {
    margin: 0 0 20px;
    font-size: 14px;
  }

  div:last-of-type {
    p {
      margin-bottom: 0;
    }
  }
`;

export default EncounterDetailsPopover;
