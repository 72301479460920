// @flow
import React, { useReducer, createContext } from 'react';

export const ModalContext = createContext();

/**
 * Modal Context.
 *
 * Creates a modal object that's received and displayed by <ModalManager>
 *
 * Modal object structure:
 * Expects component written as: CustomerModal  and not: <CustomerModal>  and not:  'CustomerModal'
 * @param {Node}      component               name of component to use in the modal, written as: CustomerModal
 * @param {Object}    props                   object of props for the component
 * @param {Boolean}   disableDismissOutside   optional: clicking outside to close the modal
 * @param {Boolean}   replaceOnDismiss        optional: replace the modal with another on dismiss
 * @param {Function}  onConfirm               function to run on confirm button
 * @param {Function}  onDismiss               optional: function to run on dismiss button
 *
 */

const Context = ({ children }) => {
  const generateRandomID = () =>
    Math.random()
      .toString(36)
      .substring(2, 9);

  const reducer = (state, { type, data }) => {
    switch (type) {
      case 'ADD_MODAL': {
        data.id = generateRandomID();
        return {
          ...state,
          ...data,
        };
      }
      case 'REPLACE_MODAL': {
        data.id = generateRandomID();
        return { ...data };
      }
      case 'CLEAR': {
        return null;
      }
      default: {
        return state;
      }
    }
  };

  const [state, dispatch] = useReducer(reducer, null);

  return (
    <ModalContext.Provider value={{ state, dispatch }}>
      {children}
    </ModalContext.Provider>
  );
};

export default Context;
