import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { lighten } from 'polished';
import { BsFillPersonFill } from 'react-icons/bs';
import { GoStop } from 'react-icons/go';
import {
  FaNotesMedical,
  FaTimes,
  FaCheck,
  FaConciergeBell,
  FaAmbulance,
} from 'react-icons/fa';
import AMADocumentModal from '../../modals/AMADocumentModal';
import ChartingPhysicianEncounter from './ChartingPhysicianEncounter';
import ChartingPhysicianIntake from './ChartingPhysicianIntake';
import LoadingDots from '../../common/LoadingDots';
import WithTooltip from '../../common/WithTooltip';
import { CallContext } from '../../../contexts/CallContext';
import ExamsContextProvider from '../../../contexts/ExamsContext';
import { ModalContext } from '../../../contexts/ModalContext';
import PatientEHR from '../../patient/PatientEHR';
import EMSModal from '../../modals/EMSModal';

const ChartingPhysician = ({ onChat, onRequestForm }) => {
  const {
    state: { patient, encounter, ama, status, requests },
  } = useContext(CallContext);

  const { dispatch: dispatchModal } = useContext(ModalContext);

  const [showEncounter, setShowEncounter] = useState(false);
  const [showEHR, setShowEHR] = useState(false);
  const [showPhysicianButtons, setShowPhysicianButtons] = useState(true);

  const openAMAModal = () => {
    // open AMA modal
    dispatchModal({
      type: 'ADD_MODAL',
      data: {
        component: AMADocumentModal,
        props: {
          maxWidth: '640px',
        },
      },
    });
  };

  const openEMSModal = () => {
    // open AMA modal
    dispatchModal({
      type: 'ADD_MODAL',
      data: {
        component: EMSModal,
        props: {
          maxWidth: '640px',
        },
      },
    });
  };

  const onOpenEHR = () => {
    setShowEHR(true);
  };

  const showHidePhysicianButtons = visible => {
    setShowPhysicianButtons(visible);
  };

  return (
    <ExamsContextProvider>
      <Container>
        <ChartingPhysicianIntake
          patient={patient}
          encounter={encounter}
          openEHR={onOpenEHR}
        />

        {showEHR ? (
          <PatientEHR
            patient={patient}
            encounter={encounter}
            show={showEHR}
            onClose={() => setShowEHR(false)}
          />
        ) : null}

        {showEncounter ? (
          <ChartingPhysicianEncounter
            patient={patient}
            encounter={encounter}
            show={showEncounter}
            onTogglePhysicianButtons={showHidePhysicianButtons}
            onClose={() => setShowEncounter(false)}
          />
        ) : null}

        {status === 'preview' || !showPhysicianButtons ? null : (
          <ButtonsGroup horizontal={showEncounter}>
            {['sent', 'completed'].includes(ama.status) ? (
              <Btn onClick={openAMAModal}>
                <GoStop />
                <BtnIndicator status={ama.status}>
                  {ama.status === 'completed' ? <FaCheck /> : <LoadingDots />}
                </BtnIndicator>
              </Btn>
            ) : null}

            <WithTooltip title="Call EMS" left={!showEncounter}>
              <Btn onClick={openEMSModal}>
                <FaAmbulance />
              </Btn>
            </WithTooltip>
            <WithTooltip title="Patient Requests" left={!showEncounter}>
              <Btn onClick={onRequestForm}>
                <FaConciergeBell />
                {requests.length > 0 ? <Count>{requests.length}</Count> : null}
              </Btn>
            </WithTooltip>
            <WithTooltip title="View EHR" left={!showEncounter}>
              <Btn onClick={() => setShowEHR(!showEHR)}>
                <BsFillPersonFill />
              </Btn>
            </WithTooltip>
            {/* <Btn onClick={onChat}>
              <FaRegCommentDots />
            </Btn> */}
            <WithTooltip
              title={showEncounter ? 'Close' : 'Encounter Notes'}
              left={!showEncounter}>
              <PrimaryBtn
                active={showEncounter}
                onClick={() => setShowEncounter(!showEncounter)}>
                {showEncounter ? <FaTimes /> : <FaNotesMedical />}
              </PrimaryBtn>
            </WithTooltip>
          </ButtonsGroup>
        )}
      </Container>
    </ExamsContextProvider>
  );
};

const Container = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const ButtonsGroup = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 50;
  display: flex;
  flex-direction: ${props => (props.horizontal ? 'row' : 'column')};
  align-items: center;
  padding: 10px;
  pointer-events: none;
`;

const Btn = styled.button`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  border: none;
  outline: none;
  cursor: pointer;
  margin: 5px;
  pointer-events: all;

  svg {
    font-size: 22px;
    color: ${props => props.theme.textColors.secondary};
  }

  &:hover {
    background-color: ${props =>
      lighten(0.01, props.theme.backgroundColors.tertiary)};
    svg {
      color: ${props => props.theme.colors.blue};
    }
  }
`;

const Count = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  font-weight: ${props => props.theme.fontWeights.medium};
  color: white;
  background-color: ${props => props.theme.colors.primary};
  transform: translateX(20%) translateY(-20%);
`;

const PrimaryBtn = styled(Btn)`
  background-color: ${props => props.theme.colors.primary};
  width: 72px;
  height: 72px;
  transition: all 0.2s ease-in;

  svg {
    color: white;
    font-size: 34px;
  }

  ${({ active, theme }) =>
    active &&
    `
    background-color: ${theme.textColors.secondary};
    width: 48px;
    height: 48px;

    svg {
      font-size: 22px;
    }
  `}

  &:hover {
    background-color: ${props =>
      props.active
        ? props.theme.textColors.primary
        : lighten(0.05, props.theme.colors.primary)};
    svg {
      color: white;
    }
  }
`;

const BtnIndicator = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  right: -2px;
  transform: translateY(-24%);
  border-radius: 12px;
  background-color: ${props =>
    props.status === 'completed' ? props.theme.colors.green : '#DDD'};
  padding: 4px 6px;
  box-shadow: 0 1px 2px #ccc;
  pointer-events: none;

  svg {
    color: white !important;
    font-size: 10px;
  }
`;

export default ChartingPhysician;
