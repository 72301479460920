import React, { useContext } from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import List from '../../forms/List';
import { useEventListener } from '../../../hooks/useEventListener';
import {
  MEDICATIONS_FORM,
  MEDICATIONS_FORM_HEADER,
  MEDICATIONS_TABLE,
} from '../../../../forms/medications';
import {
  GET_MEDICATIONS,
  ADD_MEDICATION,
  UPDATE_MEDICATION,
  REMOVE_MEDICATION,
} from '../../../api/medications';
import { CallContext } from '../../../contexts/CallContext';
import { SystemContext } from '../../../contexts/SystemContext';

const ChartingCarelineMedications = ({
  patient,
  isVerified,
  onVerify,
  ehr,
}) => {
  const {
    state: { physicianStatus, room },
  } = useContext(CallContext);
  const {
    state: { realmUser },
  } = useContext(SystemContext);

  const { data, refetch } = useQuery(GET_MEDICATIONS, {
    variables: { patient: patient.userId },
  });

  const refreshList = async () => {
    refetch();

    if (physicianStatus === 'waitingForPhysician') {
      await realmUser.functions.sendPusherEvent(
        `presence-${room}`,
        'update-medications',
      );
    }
  };

  useEventListener('refreshListData', refreshList);

  return (
    <Container>
      {data ? (
        <List
          targetId={patient.userId}
          data={data.medications}
          form={MEDICATIONS_FORM}
          add={ADD_MEDICATION}
          update={UPDATE_MEDICATION}
          remove={REMOVE_MEDICATION}
          formHeader={MEDICATIONS_FORM_HEADER}
          table={MEDICATIONS_TABLE}
          verified={isVerified}
          onVerify={onVerify}
          ehr={ehr}
        />
      ) : null}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  text-align: center;
`;

export default ChartingCarelineMedications;
