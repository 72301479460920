import gql from 'graphql-tag';

// TEAM PROFILES

/**
 * QUERIES
 */
export const GET_TEAM = gql`
  query GET_TEAM($role: String) {
    users(query: { role: $role }) {
      userId
      firstName
      lastName
      photo
      phoneNumber
      email
      role
      available
      primary
      secondary
      pool
    }
  }
`;

/**
 * MUTATIONS
 */

// PERSONAL INFORMATION
export const UPDATE_PROFILE = gql`
  mutation UPDATE_PROFILE(
    $id: String
    $firstName: String
    $lastName: String
    $photo: String
    $phoneNumber: String
    $role: String!
    $email: String
  ) {
    updateOneUser(
      query: { userId: $id }
      set: {
        firstName: $firstName
        lastName: $lastName
        photo: $photo
        phoneNumber: $phoneNumber
        role: $role
        email: $email
      }
    ) {
      userId
    }
  }
`;

export const SET_USER_AVAILABLE = gql`
  mutation SET_USER_AVAILABLE($id: String) {
    updateOneUser(query: { userId: $id }, set: { available: true }) {
      userId
      available
    }
  }
`;
