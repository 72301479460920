import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import ScheduleColumn from '../admin/ScheduleColumn';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import {
  startOfWeek,
  addDays,
  setHours,
  subWeeks,
  addWeeks,
  format,
  isToday,
} from 'date-fns';
import { SystemContext } from '../../contexts/SystemContext';
import ActivityIndicator from '../common/ActivityIndicator';

const ScheduleModal = ({ date = new Date(), onDismiss }) => {
  const {
    state: { realmUser },
  } = useContext(SystemContext);

  const [startDate, setStartDate] = useState(date);
  const [weeklyLabel, setWeeklyLabel] = useState();
  const [weeklyData, setWeeklyData] = useState();

  const changeWeek = backwards => {
    const newDate = backwards ? subWeeks(startDate, 1) : addWeeks(startDate, 1);
    setStartDate(newDate);
  };

  useEffect(() => {
    const sunday = startOfWeek(startDate, { weekStartsOn: 0 });
    console.log('sunday: ', sunday.toUTCString());
    const saturday = addDays(sunday, 6);

    setWeeklyLabel(`${format(sunday, 'PPP')} - ${format(saturday, 'PPP')}`);

    // this should be inside useEffect as written (see: https://devtrium.com/posts/async-functions-useeffect)
    const fetchWeeklyData = async (start, end) => {
      const data = await realmUser.functions.getSchedule(start, end);
      setWeeklyData(data);
    };

    // fetch data from server
    fetchWeeklyData(setHours(sunday, 5), setHours(saturday, 5));
  }, [startDate]);

  return (
    <Container>
      <Header>
        <ScheduleRow>
          <PageBtn
            disabled={isToday(startDate)}
            onClick={() => changeWeek(true)}>
            <FaChevronLeft />
          </PageBtn>
          <DateDisplay>{weeklyLabel}</DateDisplay>
          <PageBtn onClick={() => changeWeek()}>
            <FaChevronRight />
          </PageBtn>
        </ScheduleRow>
      </Header>
      <Content>
        {weeklyData ? (
          <Schedule>
            <ScheduleColumn header />
            {weeklyData.map(day => (
              <ScheduleColumn key={day.date} data={day} />
            ))}
          </Schedule>
        ) : (
          <ActivityIndicator />
        )}
      </Content>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  background-color: ${props => props.theme.backgroundColors.tertiary};
`;

const Content = styled.div`
  flex: 1;
  overflow-y: scroll;
  padding: 30px;
  min-height: 402px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ScheduleRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const DateDisplay = styled.h3`
  color: ${props => props.theme.textColors.primary};
  flex: 1;
  text-align: center;
  padding: 0 20px;
`;

const PageBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  outline: none;
  border: 2px solid ${props => props.theme.colors.border};
  border-radius: 10px;
  width: 32px;
  height: 32px;
  cursor: pointer;

  svg {
    color: ${props =>
      props.disabled ? '#DDD' : props.theme.textColors.secondary};
  }

  &:hover {
    svg {
      color: ${props => props.theme.textColors.primary};
    }
  }

  ${({ disabled }) =>
    disabled &&
    `
    pointer-events: none;
    cursor: default;
  `}
`;

const Schedule = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  > div {
    border-right: 1px solid ${props => props.theme.colors.border};

    &:last-child {
      border-right: none;
    }
  }
`;

export default ScheduleModal;
