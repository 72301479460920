import gql from 'graphql-tag';
import { VITAL_FRAGMENT } from './vitals';

// TRIAGE INTAKE

/**
 * FRAGMENTS
 */

const intakeFields = gql`
  fragment intakeFields on Encounter {
    _id
    reason
    contextualFactors
    exacerbatingFactors
    homeStatus
    relievingFactors
    medications {
      clinicalCode
      display
    }
    notes
    address {
      line
      city
      state
      postalCode
    }
    phoneNumber
  }
`;

export const INTAKE_FRAGMENT = intakeFields;

/**
 * QUERIES
 */
export const GET_INTAKE = gql`
  query GET_INTAKE($encounter: ObjectId!) {
    encounter(query: { _id: $encounter }) {
      ...intakeFields
    }
    vitals(query: { _id: $encounter }) {
      ...vitalFields
    }
  }
  ${intakeFields}
  ${VITAL_FRAGMENT}
`;

/**
 * MUTATIONS
 */

export const UPDATE_INTAKE = gql`
  mutation UPDATE_INTAKE(
    $id: ObjectId!
    $reason: String
    $contextualFactors: String
    $exacerbatingFactors: String
    $relievingFactors: String
    $medications: [EncounterMedicationUpdateInput]
    $notes: String
  ) {
    updateOneEncounter(
      query: { _id: $id }
      set: {
        reason: $reason
        contextualFactors: $contextualFactors
        exacerbatingFactors: $exacerbatingFactors
        relievingFactors: $relievingFactors
        medications: $medications
        notes: $notes
      }
    ) {
      ...intakeFields
    }
  }
  ${intakeFields}
`;

export const UPDATE_HOME_STATUS = gql`
  mutation UPDATE_HOME_STATUS($id: ObjectId!, $homeStatus: String) {
    updateOneEncounter(query: { _id: $id }, set: { homeStatus: $homeStatus }) {
      homeStatus
    }
  }
`;

export const UPDATE_ADDRESS = gql`
  mutation UPDATE_ADDRESS(
    $id: ObjectId!
    $line: [String!]
    $city: String!
    $state: String!
    $postalCode: String!
  ) {
    updateOneEncounter(
      query: { _id: $id }
      set: {
        address: {
          line: $line
          city: $city
          state: $state
          postalCode: $postalCode
        }
      }
    ) {
      address {
        line
        city
        state
        postalCode
      }
    }
  }
`;

export const UPDATE_PHONE_NUMBER = gql`
  mutation UPDATE_PHONE_NUMBER($id: ObjectId!, $phone: String!) {
    updateOneEncounter(query: { _id: $id }, set: { phoneNumber: $phone }) {
      phoneNumber
    }
  }
`;
