import React, { useEffect, useCallback, useState, useContext } from 'react';
import { format, parseISO } from 'date-fns';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import {
  FaNotesMedical,
  FaAllergies,
  FaPhone,
  FaPills,
  FaPrescriptionBottle,
  FaClipboard,
  FaSyringe,
} from 'react-icons/fa';
import { MdErrorOutline } from 'react-icons/md';
import { EncounterContext } from '../../../contexts/EncounterContext';
import { FormContext } from '../../../contexts/FormContext';
import Grid from '../../common/Grid';
import Loader from '../../common/Loader';
import { useEventListener } from '../../../hooks/useEventListener';
import {
  formatCareTeam,
  formatDateRange,
  formatDiagnoses,
  formatMedicationTiming,
} from '../../../hooks/useTextHelper';

import { ALLERGIES_FORM } from '../../../../forms/allergies';
import { DAT_FORM } from '../../../../forms/dat';
import { DIAGNOSES_FORM } from '../../../../forms/diagnoses';
import { PAST_ENCOUNTER_FORM } from '../../../../forms/encounters';
import { MEDICATIONS_FORM } from '../../../../forms/medications';
import { VACCINE_FORM } from '../../../../forms/vaccine';
import { MEDICAL_RECORD } from '../../../api/medical';
import {
  ADD_ALLERGY,
  REMOVE_ALLERGY,
  UPDATE_ALLERGY,
} from '../../../api/allergies';
import {
  ADD_MEDICATION,
  UPDATE_MEDICATION,
  REMOVE_MEDICATION,
} from '../../../api/medications';
import {
  ADD_TO_MEDICAL_HISTORY,
  UPDATE_MEDICAL_HISTORY,
  REMOVE_FROM_MEDICAL_HISTORY,
} from '../../../api/encounters';

const getAllergySummary = (firstName, allergies) => {
  let msg = `${firstName} `;
  if (!allergies || allergies.length === 0) {
    msg += 'has no reported allergies.';
  } else if (allergies.length === 1) {
    msg += `is allergic to <strong>${allergies[0].allergy.display}</strong>`;
  } else {
    msg += `has <b>${allergies.length} allergies</b> including <strong>${
      allergies[0].allergy.display
    }</strong> and ${allergies.length - 1} other${
      allergies.length - 1 > 1 ? 's' : ''
    }.`;
  }
  return msg;
};

const getDiagnosesSummary = (firstName, diagnoses) => {
  let msg = `${firstName} `;
  if (!diagnoses || diagnoses.length === 0) {
    msg += 'has no medical diagnoses.';
  } else if (diagnoses.length === 1) {
    msg += `has been diagnosed with <strong>${diagnoses[0].display}</strong>`;
  } else {
    msg += `has <b>${diagnoses.length} diagnoses</b> including <strong>${
      diagnoses[0].display
    }</strong> and ${diagnoses.length - 1} other${
      diagnoses.length - 1 > 1 ? 's' : ''
    }.`;
  }
  return msg;
};

const getMedicationSummary = meds => {
  let msg = 'Currently taking ';
  if (!meds || meds.length === 0) {
    msg += '<b>no medications</b>.';
  } else if (meds.length === 1) {
    msg += `<strong>${meds[0].medication.display}</strong>`;
  } else {
    msg += `<b>${meds.length} medications</b> including <strong>${
      meds[0].medication.display
    }</strong> and ${meds.length - 1} other${meds.length - 1 > 1 ? 's' : ''}.`;
  }
  return msg;
};

const getDATSummary = dat => {
  if (!dat?.drugs) return 'Not reported';
  return '';
};

const getVaccineSummary = vaccine => {
  if (!vaccine?.covidVaccine) return 'Not reported';
  return '';
};

const getVisitSummary = (encounters, type) => {
  if (encounters?.length > 0) {
    return `Most recent ${type} was on <b>${format(
      parseISO(encounters[0].period.start),
      'M/d/yyyy',
    )}</b>`;
  }
  return `No recent ${
    type === 'call' ? 'BetterMedics calls' : 'medical visits'
  }`;
};

const ChartingOnboardingMedical = ({ patient, ehr }) => {
  const { state: activeForm, dispatch: dispatchForm } = useContext(FormContext);
  const { dispatch: dispatchEncounter } = useContext(EncounterContext);

  const { loading, error, data, refetch } = useQuery(MEDICAL_RECORD, {
    variables: { patient: patient.userId },
  });

  const [config, setConfig] = useState();

  const buildLayout = useCallback(() => {
    // data is returned from useQuery, setup cards for grid layout with personal patient info filled in
    const {
      allergies,
      medications,
      medicalVisits,
      callHistory,
      dat,
      vaccine,
    } = data;

    const LAYOUT = {
      diagnoses: {
        targetId: patient.userId,
        label: 'Medical Diagnoses',
        form: DIAGNOSES_FORM,
        formHeader: {
          title: 'Edit Patient Diagnoses',
          icon: <FaNotesMedical />,
          label: 'Diagnoses',
        },
        data: { conditions: patient.conditions },
        summary: {
          title: 'Medical Diagnoses',
          subtitle: getDiagnosesSummary(patient.name.given, patient.conditions),
          icon: <FaNotesMedical />,
        },
      },
      allergies: {
        targetId: patient.userId,
        list: {
          form: ALLERGIES_FORM,
          add: ADD_ALLERGY,
          remove: REMOVE_ALLERGY,
          update: UPDATE_ALLERGY,
          formHeader: {
            addTitle: 'Add a new allergy',
            editTitle: 'Edit an existing allergy',
            icon: <FaAllergies />,
            label: 'Allergies',
          },
          map: {
            label: 'allergy.display',
          },
        },
        data: allergies,
        summary: {
          title: 'Allergies',
          subtitle: getAllergySummary(patient.name.given, allergies),
          icon: <FaAllergies />,
        },
      },
      medical: {
        targetId: patient.userId,
        list: {
          form: MEDICATIONS_FORM,
          add: ADD_MEDICATION,
          remove: REMOVE_MEDICATION,
          update: UPDATE_MEDICATION,
          formHeader: {
            addTitle: 'Add a new medication',
            editTitle: 'Edit an existing medication',
            icon: <FaPrescriptionBottle />,
            label: 'Medications',
          },
          map: {
            label: 'medication.display',
          },
          subtext: {
            key: 'timing',
            resolveWith: formatMedicationTiming,
          },
        },
        data: medications,
        summary: {
          title: 'Medications',
          subtitle: getMedicationSummary(medications),
          icon: <FaPrescriptionBottle />,
        },
      },
      dat: {
        targetId: patient.userId,
        label: 'Drug, Alcohol & Tobacco',
        form: DAT_FORM,
        formHeader: {
          title: 'Drug, Alcohol & Tobacco Use',
          icon: <FaPills />,
          label: 'Medical Record',
        },
        data: dat,
        summary: {
          title: 'Drug, Alcohol & Tobacco',
          subtitle: getDATSummary(dat),
          icon: <FaPills />,
        },
      },
      callHistory: {
        targetId: patient.userId,
        data: callHistory,
        list: {
          onItemClick: item => {
            dispatchEncounter({
              type: 'VIEW_ENCOUNTER',
              data: {
                encounterId: item._id,
              },
            });
          },
          table: [
            {
              id: 'period',
              label: 'Visit Date',
              width: '200px',
              sortable: true,
              formatFn: formatDateRange,
            },
            {
              id: 'diagnosis',
              label: 'Diagnosis',
              flex: 1,
              formatFn: formatDiagnoses,
            },
            {
              id: 'careteam',
              label: 'Spoke with',
              formatFn: formatCareTeam,
            },
          ],
        },
        summary: {
          title: 'BetterMedics Call History',
          subtitle: getVisitSummary(callHistory, 'call'),
          icon: <FaPhone />,
        },
        restrictForRoles: ['careline'],
      },
      history: {
        targetId: patient.userId,
        data: medicalVisits,
        list: {
          form: PAST_ENCOUNTER_FORM,
          add: ADD_TO_MEDICAL_HISTORY,
          remove: REMOVE_FROM_MEDICAL_HISTORY,
          update: UPDATE_MEDICAL_HISTORY,
          formHeader: {
            addTitle: 'Add a Medical Visit',
            editTitle: 'Edit an existing Medical Visit',
            icon: <FaClipboard />,
            label: 'History',
          },
          map: {
            label: 'display',
          },
          table: [
            {
              id: 'period',
              label: 'Visit Date',
              width: '200px',
              sortable: true,
              formatFn: formatDateRange,
            },
            {
              id: 'diagnosis',
              label: 'Diagnosis',
              flex: 1,
              formatFn: formatDiagnoses,
            },
            {
              id: 'location',
              label: 'Location',
              sortable: true,
              width: '130px',
            },
          ],
        },
        summary: {
          title: 'Medical Visit History',
          subtitle: getVisitSummary(medicalVisits, 'visit'),
          icon: <FaClipboard />,
        },
      },
      vaccine: {
        targetId: patient.userId,
        label: 'COVID-19',
        form: VACCINE_FORM,
        formHeader: {
          title: 'COVID-19',
          icon: <FaSyringe />,
          label: 'Medical Record',
        },
        data: vaccine,
        summary: {
          title: 'COVID-19',
          subtitle: getVaccineSummary(vaccine),
          icon: <FaSyringe />,
        },
      },
    };

    setConfig({ cards: LAYOUT });

    if (activeForm && LAYOUT[activeForm.config.key]) {
      dispatchForm({
        type: 'UPDATE_DATA',
        data: LAYOUT[activeForm.config.key].data,
      });
    }
  }, [data, patient.conditions, patient.userId, patient.name.given]);

  // when useQuery comes back, data is available and can be processed
  useEffect(() => {
    if (data) buildLayout();
  }, [data, patient, buildLayout]);

  useEventListener('refreshListData', refetch);

  return (
    <Container>
      {loading ? (
        <CenterWrapper>
          <Loader size="small" activity transparent />
        </CenterWrapper>
      ) : config ? (
        <Grid config={config} />
      ) : error ? (
        <CenterWrapper>
          <ErrorIcon>
            <MdErrorOutline />
          </ErrorIcon>
          <ErrorMessage>{error.message}</ErrorMessage>
        </CenterWrapper>
      ) : null}
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  width: 100%;
  border-top: 1px solid ${props => props.theme.colors.border};
`;

const CenterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const ErrorIcon = styled.div`
  svg {
    font-size: 42px;
    color: ${props => props.theme.textColors.tertiary};
  }
`;

const ErrorMessage = styled.p`
  margin: 20px 0 50px;
  color: ${props => props.theme.textColors.tertiary};
  text-align: center;
`;

export default ChartingOnboardingMedical;
