import React, { useContext } from 'react';
import styled from 'styled-components';
import { FaPlus } from 'react-icons/fa';
import { format } from 'date-fns';
import { formatInitialData } from '../../hooks/useFormHelper';
import TeamPopover from '../popovers/TeamPopover';
import UserIcon from '../common/UserIcon';
import { PopoverContext } from '../../contexts/PopoverContext';
import { SystemContext } from '../../contexts/SystemContext';

const HEADER = ['Shift #1', 'Shift #2'];

const PersonBtn = ({ user, onClick, disabled }) => (
  <Button
    onClick={onClick}
    disabled={disabled}
    title={user ? `${user.firstName} ${user.lastName}` : ''}>
    {user ? (
      <UserInfo>
        <UserIcon user={user} size="30px" inline />
        <Name>{`${user.firstName} ${user.lastName}`}</Name>
      </UserInfo>
    ) : (
      <FaPlus />
    )}
  </Button>
);

const ScheduleColumn = ({ header, data }) => {
  const {
    state: { realmUser },
  } = useContext(SystemContext);
  const { dispatch: dispatchPopover } = useContext(PopoverContext);

  const SHIFTS = header ? HEADER : data.shifts;

  const onUpdateShift = async (user, role, shiftNum) => {
    // copy date's original object
    const newData = { ...data };
    // remove [__typename] from selected user object
    const cleanUserData = formatInitialData(user);
    // update copied date object with selected user
    newData.shifts[shiftNum] = {
      ...data.shifts[shiftNum],
      [role]: cleanUserData,
    };

    // save data to server
    await realmUser.functions.saveSchedule(newData);

    console.log('saveData: ', JSON.stringify(newData));
  };

  const openPersonSelector = (evt, role, user, shift) => {
    dispatchPopover({
      type: 'ADD_POPOVER',
      data: {
        component: TeamPopover,
        props: {
          bounds: evt.target.getBoundingClientRect(),
          role: role === 'physician' ? 'physician' : 'careline',
          selected: user,
          onSelect: member => onUpdateShift(member, role, shift),
        },
      },
    });
  };

  return (
    <Container>
      {header ? (
        <DayHeader />
      ) : (
        <DayHeader>
          <Date>{format(data.date, 'd')}</Date>
          <Day>{format(data.date, 'EEE')}</Day>
        </DayHeader>
      )}
      {SHIFTS.map((shift, index) => (
        <Shift key={header ? shift : shift.startTime}>
          <Divider header={header}>{header ? shift : shift.startTime}</Divider>
          <Cell>
            {header ? (
              <Label>
                Primary
                <br />
                CareLine
              </Label>
            ) : (
              <PersonBtn
                user={shift.primaryCareline}
                onClick={e =>
                  openPersonSelector(
                    e,
                    'primaryCareline',
                    shift.primaryCareline,
                    index,
                  )
                }
              />
            )}
          </Cell>
          <Cell>
            {header ? (
              <Label>
                Secondary
                <br />
                CareLine
              </Label>
            ) : (
              <PersonBtn
                user={shift.secondaryCareline}
                onClick={e =>
                  openPersonSelector(
                    e,
                    'secondaryCareline',
                    shift.secondaryCareline,
                    index,
                  )
                }
              />
            )}
          </Cell>
          <Cell>
            {header ? (
              <Label>Physician</Label>
            ) : (
              <PersonBtn
                user={shift.physician}
                onClick={e =>
                  openPersonSelector(e, 'physician', shift.physician, index)
                }
                disabled={realmUser?.customData?.role !== 'admin'}
              />
            )}
          </Cell>
        </Shift>
      ))}
    </Container>
  );
};

const DayHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 60px;
`;

const Date = styled.div`
  font-size: 22px;
  font-weight: ${props => props.theme.fontWeights.bold};
  font-family: ${props => props.theme.fonts.secondary};
  color: ${props => props.theme.colors.blue};
`;

const Day = styled.div`
  font-size: 11px;
  color: ${props => props.theme.textColors.secondary};
  letter-spacing: 0.4px;
  text-transform: uppercase;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Divider = styled.div`
  background-color: ${props => props.theme.backgroundColors.secondary};
  padding: 6px 8px;
  font-size: 11px;
  font-weight: ${props => props.theme.fontWeights.bold};
  color: ${props => props.theme.textColors.secondary};
  text-transform: uppercase;
  letter-spacing: 0.2px;
  text-align: ${props => (props.header ? 'left' : 'center')};
`;

const Cell = styled.div`
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 66px;
`;

const Label = styled.span`
  display: block;
  color: ${props => props.theme.textColors.tertiary};
  font-size: 11px;
  font-weight: ${props => props.theme.fontWeights.bold};
  text-transform: uppercase;
  letter-spacing: 0.2px;
  text-align: center;
`;

const Shift = styled.div`
  ${Cell} {
    border-bottom: 1px solid ${props => props.theme.colors.border};
    &:last-of-type {
      border-bottom: none;
      padding-bottom: 6px;
    }
  }
`;

const UserInfo = styled.div`
  text-align: center;
  max-width: 100%;
`;

const Name = styled.div`
  font-size: 12px;
  color: ${props => props.theme.textColors.primary};
  text-align: center;
  margin-top: 3px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  padding: 0 3px;
`;

const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 72px;
  height: 54px;
  border-radius: 10px;

  svg {
    color: ${props => props.theme.colors.border};
  }

  &:hover {
    background-color: ${props => props.theme.backgroundColors.tertiary};

    svg {
      color: ${props => props.theme.colors.blue};
    }

    ${Name} {
      color: ${props => props.theme.colors.blue};
    }
  }

  ${({ disabled, theme }) =>
    disabled &&
    `
    user-select: none;
    cursor: default;
    pointer-events: none;
    background-color: ${`rgba(${theme.backgroundColors.secondaryRGB}, 0.2)`};

    svg {
      display: none;
    }
  `}
`;

export default ScheduleColumn;
