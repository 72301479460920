import React from 'react';
import styled from 'styled-components';
import { format, parseISO } from 'date-fns';

const Symptom = ({ config, onClick, disabled }) => (
  <Wrapper disabled={disabled} onClick={onClick}>
    <Title>{config.symptom.display}</Title>
    {config.location ? <Location>{config.location.display}</Location> : null}
    {config.onset ? (
      <Date>{format(parseISO(config.onset), 'eee, MMM do @ ha')}</Date>
    ) : null}
    {config.severity ? <SeverityOrb>{config.severity}</SeverityOrb> : null}
  </Wrapper>
);

const Wrapper = styled.div`
  position: relative;
  border-radius: 10px;
  padding: 20px;
  background-color: white;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.05);
  border: 2px solid transparent;
  margin-bottom: 20px;
  cursor: pointer;

  ${({ disabled }) =>
    disabled &&
    `
    cursor: default;
    pointer-events: none;
    opacity: 0.9;
  `}

  &:hover {
    border-color: ${props => props.theme.colors.border};
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const Title = styled.div`
  color: ${props => props.theme.textColors.primary};
  font-size: 15px;
  font-weight: ${props => props.theme.fontWeights.bold};
`;

const Location = styled.div`
  color: ${props => props.theme.textColors.tertiary};
  font-size: 13px;
  margin-top: 5px;
`;

const Date = styled.div`
  font-size: 11px;
  color: ${props => props.theme.textColors.tertiary};
  margin-top: 10px;
`;

const SeverityOrb = styled.span`
  position: absolute;
  top: 15px;
  right: 15px;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: ${props => props.theme.fontWeights.bold};
  color: ${props => props.theme.textColors.tertiary};
  background-color: rgba(0, 0, 0, 0.06);
`;

export default Symptom;
