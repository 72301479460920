import React, { useContext } from 'react';
import styled from 'styled-components';
import { format, formatDistance, parseISO } from 'date-fns';
import UserIcon from '../common/UserIcon';
import { formatName } from '../../hooks/useTextHelper';
import { EncounterContext } from '../../contexts/EncounterContext';

const RecentCall = ({ call }) => {
  const { dispatch: dispatchEncounter } = useContext(EncounterContext);
  const { patient, encounter, date, callEnded } = call;

  const viewEncounter = () => {
    dispatchEncounter({
      type: 'VIEW_ENCOUNTER',
      data: {
        encounterId: encounter,
      },
    });
  };

  return (
    <Wrapper onClick={viewEncounter}>
      <Cell>
        <Day>{format(parseISO(date), 'M/d')}</Day>
        <Time>{format(parseISO(date), 'h:mm aa')}</Time>
      </Cell>
      <PhotoWrapper>
        <UserIcon user={patient} size="50px" patient />
      </PhotoWrapper>
      <PatientName>{formatName(patient)}</PatientName>
      <Timer>
        <TimeText>
          {callEnded && date
            ? formatDistance(parseISO(callEnded), parseISO(date))
            : '-'}
        </TimeText>
      </Timer>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  cursor: pointer;
  padding: 15px 20px;
  margin: 10px 0;
  border-radius: 20px;
  border: 1px solid transparent;

  &:hover {
    box-shadow: 0 0 10px #f5f7ff;
    border-color: #f5f7ff;
    background-color: white;
  }
`;

const PhotoWrapper = styled.div`
  position: relative;
`;

const PatientName = styled.p`
  flex: 1;
  padding: 0 20px;
  font-size: 18px;
  font-weight: ${props => props.theme.fontWeights.bold};
  color: ${props => props.theme.textColors.primary};
`;

const Cell = styled.div`
  position: relative;
  text-align: center;
  padding: 10px 20px;
  width: 180px;

  &:first-of-type {
    padding-left: 10px;
    text-align: left;
    color: ${props => props.theme.textColors.primary};
    font-weight: 500;
  }

  @media (max-width: 1199px) {
    width: 120px;
  }
`;

const Day = styled.span`
  display: block;
  tex-align: center;
  font-size: 20px;
  font-weight: bold;
  color: ${props => props.theme.textColors.primary};
`;

const Time = styled.span`
  text-align: center;
  font-size: 14px;
  color: ${props => props.theme.textColors.primary};
`;

const Timer = styled(Cell)``;

const TimeText = styled.p`
  margin: 0;
  color: ${props => props.theme.textColors.primary};
  font-size: 18px;
  font-weight: ${props => props.theme.fontWeights.medium};
`;

export default RecentCall;
