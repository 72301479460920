import React, { useState } from 'react';
import styled from 'styled-components';
import { FaClipboard } from 'react-icons/fa';
import { useQuery } from '@apollo/client';
import {
  GET_MEDICAL_HISTORY,
  ADD_TO_MEDICAL_HISTORY,
  REMOVE_FROM_MEDICAL_HISTORY,
  UPDATE_MEDICAL_HISTORY,
} from '../../../api/encounters';
import { PAST_ENCOUNTER_FORM } from '../../../../forms/encounters';
import { useEventListener } from '../../../hooks/useEventListener';
import { formatDateRange, formatDiagnoses } from '../../../hooks/useTextHelper';
import List from '../../forms/List';

const TABLE = [
  {
    id: 'diagnosis',
    label: 'Diagnosis',
    flex: 1,
    formatFn: formatDiagnoses,
  },
  {
    id: 'location',
    label: 'Location',
    sortable: true,
    width: '170px',
  },
  {
    id: 'period',
    label: 'Visit Date',
    width: '160px',
    sortable: true,
    formatFn: formatDateRange,
  },
];

const HEADER = {
  addTitle: 'Add a Medical Visit',
  editTitle: 'Edit an existing Medical Visit',
  icon: <FaClipboard />,
  label: 'History',
};

const ChartingCarelineHistory = ({ patient, isVerified, onVerify, ehr }) => {
  const { loading, error, data, refetch } = useQuery(GET_MEDICAL_HISTORY, {
    variables: { patient: patient.userId },
  });

  const refreshList = () => {
    refetch();
  };

  useEventListener('refreshListData', refreshList);

  return (
    <Container>
      {data ? (
        <List
          targetId={patient.userId}
          form={PAST_ENCOUNTER_FORM}
          add={ADD_TO_MEDICAL_HISTORY}
          update={UPDATE_MEDICAL_HISTORY}
          remove={REMOVE_FROM_MEDICAL_HISTORY}
          data={data.encounters}
          formHeader={HEADER}
          table={TABLE}
          verified={isVerified}
          onVerify={onVerify}
          ehr={ehr}
        />
      ) : null}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export default ChartingCarelineHistory;
