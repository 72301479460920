// @flow
import React, { createContext, useReducer } from 'react';

const INITIAL_STATE = {
  patient: null,
};

export const PatientContext = createContext(INITIAL_STATE);

export default ({ children }) => {
  const reducer = (state, { type, data }) => {
    switch (type) {
      case 'SET_PATIENT': {
        return {
          ...state,
          ...data,
        };
      }
      case 'CLEAR': {
        return INITIAL_STATE;
      }
      default: {
        return state;
      }
    }
  };

  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <PatientContext.Provider value={{ state, dispatch }}>
      {children}
    </PatientContext.Provider>
  );
};
