import { useState, useEffect, useRef } from 'react';

// isMobile just checks if responsive width is in mobile size (< 992px)
// this hook will return the width & height of the browser window

const useWindowSize = () => {
  const isInitialMount = useRef(true);

  const getSize = () => {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
      isMobile: window.innerWidth < 992,
    };
  };

  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
    isMobile: undefined,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(getSize());
    };

    if (isInitialMount.current) {
      isInitialMount.current = false;
      handleResize();
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
};

export default useWindowSize;
