import React from 'react';
import styled from 'styled-components';

const PageTabs = ({
  config,
  activeTab,
  ehr,
  profileMode,
  rowPad,
  center,
  onTabClick,
}) => (
  <Wrapper profileMode={profileMode} ehr={ehr} rowPad={rowPad}>
    <TabBar center={center}>
      {config.map(tab => (
        <Tab
          key={tab.id}
          active={activeTab === tab.id}
          onClick={() => onTabClick(tab.id)}>
          {tab.label}
        </Tab>
      ))}
    </TabBar>
  </Wrapper>
);

const Tab = styled.button`
  position: relative;
  outline: 0;
  border: 0;
  font-size: 16px;
  font-weight: ${props =>
    props.active
      ? props.theme.fontWeights.bold
      : props.theme.fontWeights.regular};
  color: ${props =>
    props.active
      ? props.theme.textColors.primary
      : props.theme.textColors.tertiary};
  cursor: pointer;
  background-color: transparent;
  padding: 0 20px 15px;
  margin-right: 20px;

  &:last-child {
    margin-right: 0;
  }

  &:after {
    position: absolute;
    display: ${props => (props.active ? 'block' : 'none')};
    content: '';
    left: 0;
    right: 0;
    bottom: -1px;
    width: 100%;
    height: 2px;
    border-radius: 5px;
    background-color: ${props => props.theme.colors.primary};
  }
`;

const TabBar = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${props => (props.center ? 'center' : 'flex-start')};
  width: 100%;
  border-bottom: 1px solid ${props => props.theme.colors.border};
`;

const Wrapper = styled.div`
  padding: ${props =>
    props.profileMode ? '25px 0 0' : props.ehr ? '0' : '0 10px'};
  margin-bottom: ${props =>
    props.profileMode ? '0' : props.ehr ? '0' : '40px'};

  ${Tab} {
    padding-bottom: ${props => (props.profileMode ? '24px' : '15px')};

    &:after {
      height: ${props => (props.profileMode ? '4px' : '2px')};
    }
  }

  ${TabBar} {
    border-bottom: none;
  }
`;

export default PageTabs;
