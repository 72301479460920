export const SYMPTOM_FORM = {
  fields: [
    {
      id: 'medical-row',
      type: 'row',
      fill: true,
      fields: [
        {
          id: 'symptom',
          type: 'search',
          label: 'Symptom',
          config: {
            placeholder: 'Lookup Symptom',
            mode: 'symptom',
            theme: 'white',
          },
        },
        {
          id: 'location',
          type: 'search',
          label: 'Body Location',
          config: {
            placeholder: 'Lookup Body Locations',
            mode: 'body',
            theme: 'white',
          },
          conditional: {
            property: 'symptom',
            condition: 'exists',
          },
        },
      ],
    },
    {
      id: 'severity',
      type: 'scale',
      label: 'Severity',
      conditional: {
        property: 'symptom',
        condition: 'exists',
      },
      config: {
        min: 1,
        max: 10,
      },
      defaultValue: 1,
    },
    {
      id: 'onset',
      type: 'datetime',
      conditional: {
        property: 'symptom',
        condition: 'exists',
      },
      label: 'Onset',
      config: {
        datepicker: {
          placement: 'top',
          width: '120px',
        },
        time: {
          hoursOnly: true,
        },
      },
    },
  ],
  validation: [
    {
      property: 'symptom',
      condition: 'exists',
      message: 'Symptom is required',
    },
    {
      property: 'location',
      condition: 'exists',
      message: 'Body Location is required',
    },
    {
      property: 'severity',
      condition: 'exists',
      message: 'Severity is required',
    },
  ],
  minHeight: '240px',
};
