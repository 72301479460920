// @flow
import React, { createContext, useReducer } from 'react';

const INITIAL_STATE = {
  _id: null,
  callType: null,
  date: null,
  encounter: null,
  homeStatus: null,
  onboarding: null,
  patient: null,
  room: null,
  channel: null,
  startTime: null,
  status: null, // new, patientCancelled, active, disconnected, ended (server but not used in webapp:  patientEnded, resolved)
  physicianStatus: null, // waitingForPhysician, physicianConnected
  verifiedStatus: {},
  requests: [],
  careplan: [],
  careline: null,
  physician: null,
  ama: {
    status: null,
    care: null,
    chart: null,
    timestamp: null,
  },
  patientBattery: null,
};

export const CallContext = createContext(INITIAL_STATE);

export default ({ children }) => {
  const reducer = (state, { type, data }) => {
    switch (type) {
      case 'SET_CALL': {
        return {
          ...state,
          ...data,
        };
      }
      case 'SET_PATIENT': {
        return {
          ...state,
          patient: {
            ...state.patient,
            ...data,
          },
        };
      }
      case 'SET_ROLE_ENDTIME': {
        return {
          ...state,
          [data.role]: {
            ...state[data.role],
            endTime: data.endTime,
          },
        };
      }
      case 'SET_STATUS': {
        return {
          ...state,
          verifiedStatus: {
            ...state.verifiedStatus,
            ...data,
          },
        };
      }
      case 'SET_AMA': {
        return {
          ...state,
          ama: {
            ...state.ama,
            ...data,
          },
        };
      }
      case 'SET_PATIENT_BATTERY': {
        const value = Math.round(data.battery * 4) - 1;
        return {
          ...state,
          patientBattery: {
            pct: data ? `${data.battery * 100}%` : null,
            value,
          },
        };
      }
      case 'CLEAR': {
        return INITIAL_STATE;
      }
      default: {
        return state;
      }
    }
  };

  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <CallContext.Provider value={{ state, dispatch }}>
      {children}
    </CallContext.Provider>
  );
};
