import React, { useContext } from 'react';
import styled from 'styled-components';
import EndScreenConfirmBtn from './EndScreenConfirmBtn';
import { EncounterContext } from '../../../contexts/EncounterContext';

const EndScreenPhysicianComplete = ({ encounter }) => {
  const { dispatch: dispatchEncounter } = useContext(EncounterContext);

  const showSummary = () => {
    dispatchEncounter({
      type: 'VIEW_ENCOUNTER',
      data: {
        encounterId: encounter,
        activeCall: true,
      },
    });
  };

  return (
    <Wrapper>
      <Content>
        <p>You may continue adding notes.</p>
        <p>
          When finished, use the button below to review the Encounter Summary
          and finalize the record.
        </p>
      </Content>
      <EndScreenConfirmBtn onClick={showSummary}>
        Review &amp; Submit
      </EndScreenConfirmBtn>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 30px 0;

  p {
    line-height: 1.5;
    text-align: center;
  }
`;

const FullScreenWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 5000;
`;

export default EndScreenPhysicianComplete;
