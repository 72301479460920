export const PAST_ENCOUNTER_FORM = {
  fields: [
    {
      id: 'period',
      type: 'dateRange',
      label: 'Visit Date(s)',
    },
    {
      id: 'location',
      type: 'select',
      config: {
        options: [
          'Home',
          'Outpatient Hospital',
          'Inpatient Hospital',
          'Emergency Room',
          'Providers Office',
          'Skilled Nursing Facility',
          'Independent Laboratory',
        ],
      },
      label: 'Visit Type',
    },
    {
      id: 'diagnosis',
      type: 'search',
      label: 'Diagnoses',
      config: {
        multiple: true,
      },
    },
    {
      id: 'notes',
      type: 'textarea',
      label: 'Notes',
    },
  ],
  maxWidth: '640px',
};
