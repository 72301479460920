import React, { useState, useEffect, useContext } from 'react';
import { useQuery } from '@apollo/client';
import { groupBy } from '../../../../hooks/useTextHelper';
import { GET_ALLERGIES } from '../../../../api/allergies';
import TableWidget from '../../../tables/TableWidget';
import { CallContext } from '../../../../contexts/CallContext';

const severityColor = value => {
  return value === 'mild'
    ? '#75C282'
    : value === 'moderate'
    ? '#FFAA76'
    : '#F9566E';
};

const TABLE = [
  {
    id: 'allergy.display',
    label: 'Name',
    flex: 1,
  },
  {
    id: 'severity',
    label: 'Severity',
    width: '80px',
    align: 'right',
    fontSize: '14px',
    colorFn: severityColor,
  },
];

const AllergiesWidget = ({ patient }) => {
  const {
    state: { channel },
  } = useContext(CallContext);

  const [allergies, setAllergies] = useState();

  const { loading, error, data, refetch } = useQuery(GET_ALLERGIES, {
    variables: { patient },
  });

  useEffect(() => {
    if (channel) channel.bind('update-allergies', refetch);

    return () => {
      if (channel) channel.unbind('update-allergies', refetch);
    };
  }, [channel, refetch]);

  const onEdit = () => {};

  useEffect(() => {
    if (data) {
      const groupBySeverity = groupBy('severity');
      const severities = groupBySeverity(data.allergies);

      let arr = [];
      if (severities.severe) arr = [...arr, ...severities.severe];
      if (severities.moderate) arr = [...arr, ...severities.moderate];
      if (severities.mild) arr = [...arr, ...severities.mild];

      setAllergies(arr);
    }
  }, [data]);

  return (
    <TableWidget
      title="Allergies"
      onEdit={onEdit}
      loading={loading}
      data={allergies}
      config={TABLE}
      emptyNotice="No allergies listed"
    />
  );
};

export default AllergiesWidget;
