import React from 'react';
import styled, { keyframes, css } from 'styled-components';
import { FaCheckCircle, FaUnlock } from 'react-icons/fa';

const VerifyButton = ({ isVerified, onClick }) => (
  <VerifyBtn active={isVerified} onClick={onClick}>
    <VerifyBtnWrapper active={isVerified}>
      <VerifyBtnPanel active animate={isVerified}>
        <FaCheckCircle />
        <label>Verified</label>
      </VerifyBtnPanel>
      <VerifyBtnPanel>
        <FaUnlock />
        <label>Verify Me</label>
      </VerifyBtnPanel>
    </VerifyBtnWrapper>
  </VerifyBtn>
);

const iconDropIn = keyframes`
  0% {
    transform: scale(2.5);
  }
  100% {
    transform: scale(1);
  }
`;

const VerifyBtn = styled.button`
  position: absolute;
  right: 30px;
  bottom: 40px;
  z-index: 6;

  display: block;
  border-radius: 12px;
  border: 2px solid;
  border-color: ${props =>
    props.active ? props.theme.colors.green : props.theme.colors.border};
  outline: none;
  cursor: pointer;
  overflow: hidden;
  padding: 0;

  height: 44px;
  min-width: 120px;
`;

const VerifyBtnWrapper = styled.div`
  width: 100%;
  transition: all 0.2s ease-in;
  transform: ${props => (props.active ? 'translateY(0)' : 'translateY(-50%)')};
`;

const VerifyBtnPanel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 42px;
  background-color: ${props =>
    props.active ? props.theme.colors.green : 'white'};

  &:hover {
    svg,
    label {
      color: ${props =>
        props.active ? 'white' : props.theme.textColors.primary};
    }
  }

  svg {
    font-size: 16px;
    color: ${props =>
      props.active ? 'white' : props.theme.textColors.secondary};
    margin-right: 10px;
    pointer-events: none;
    animation-duration: 0.5s;
    animation-direction: forwards;
    animation-delay: 0.25s;
    animation-timing-function: ease-in;

    ${({ animate }) =>
      animate &&
      css`
        animation-name: ${iconDropIn};
      `}
  }

  label {
    margin: 0;
    font-size: 15px;
    color: ${props =>
      props.active ? 'white' : props.theme.textColors.secondary};
    text-transform: initial;
    letter-spacing: 0.3px;
    pointer-events: none;
  }
`;
export default VerifyButton;
