import gql from 'graphql-tag';

/**
 * QUERIES
 */

export const GET_RECENT_CALLS = gql`
  query GET_RECENT_CALLS {
    calls(
      query: { status_in: ["ended", "resolved"] }
      limit: 30
      sortBy: _ID_DESC
    ) {
      _id
      encounter
      date
      status
      patient {
        name {
          family
          given
        }
        photo
      }
      careline {
        firstName
        lastName
      }
      physician {
        firstName
        lastName
      }
    }
  }
`;

export const GET_PHYSICIAN_RECENT_CALLS = gql`
  query GET_PHYSICIAN_RECENT_CALLS($userId: String!) {
    calls(
      query: {
        physician: { userId: $userId }
        status_in: ["ended", "resolved"]
      }
      limit: 10
      sortBy: _ID_DESC
    ) {
      _id
      callEnded
      date
      encounter
      patient {
        name {
          family
          given
        }
      }
      status
    }
  }
`;

export const GET_CALL_NOTIFICATIONS = gql`
  query GET_CALL_NOTIFICATIONS($encounter: ObjectId!) {
    calls(query: { encounter: $encounter }) {
      careline {
        firstName
        lastName
        photo
      }
      physician {
        firstName
        lastName
        photo
      }
      carelineJoined
      physicianJoined
      notifications {
        primary
        pool
        secondary
        status
        role
        date
        user {
          firstName
          lastName
          photo
        }
        responseDate
      }
    }
  }
`;

// WAITING ROOM
// This one is deprecated
export const WAITING_ROOM_LIST = gql`
  query GET_WAITING_ROOM_LIST {
    calls(
      query: { status_in: ["new", "active", "disconnected"] }
      sortBy: DATE_DESC
    ) {
      date
      room
      status
      onboarding
      callType
      careteam {
        firstName
        lastName
        role
      }
      patient {
        userId
        name {
          given
          family
        }
        birthDate
        photo
        telecom {
          home
        }
      }
    }
  }
`;

export const CARELINE_WAITING_ROOM_LIST = gql`
  query GET_CARELINE_WAITING_ROOM_LIST {
    calls(
      query: {
        status_in: [
          "new"
          "active"
          "disconnected"
          "patientCancelled"
          "patientEnded"
        ]
      }
      sortBy: DATE_DESC
    ) {
      date
      room
      status
      onboarding
      callType
      careline {
        firstName
        lastName
        role
      }
      patient {
        userId
        name {
          given
          family
        }
        birthDate
        photo
        telecom {
          home
        }
      }
    }
  }
`;

export const PHYSICIAN_WAITING_ROOM_LIST = gql`
  query GET_PHYSICIAN_WAITING_ROOM_LIST {
    calls(
      query: {
        status_in: [
          "new"
          "active"
          "disconnected"
          "patientCancelled"
          "patientEnded"
        ]
        callType_ne: "onboarding"
      }
      sortBy: DATE_DESC
    ) {
      date
      room
      status
      onboarding
      callType
      physician {
        firstName
        lastName
        role
      }
      patient {
        userId
        name {
          given
          family
        }
        birthDate
        photo
        telecom {
          home
        }
      }
    }
  }
`;

// CALL AND PATIENT INFO
export const ROOM_PATIENT_INFO = gql`
  query GET_ROOM_PATIENT_INFO($room: String!) {
    call(query: { room: $room }) {
      patient {
        userId
        name {
          given
          family
        }
        mrn
        birthDate
        gender
        codeStatus
        conditions {
          billingCode
          display
        }
        address {
          home {
            line
            city
            state
            postalCode
            notes
          }
        }
        telecom {
          home
        }
        livingArrangement
        photo
        hospital {
          address {
            line
            city
            postalCode
            state
          }
          name
          phone
        }
        ems {
          agency
          primaryDispatch
          dispatchNumber
          secondaryDispatch
          secondaryDispatchNumber
          otherNumber
        }
        poa
        location {
          latitude
          longitude
        }
      }
      callType
      date
      room
      status
      onboarding
      _id
      encounter
    }
  }
`;

export const COMPLETE_ONBOARDING = gql`
  mutation COMPLETE_ONBOARDING($id: String!) {
    updateOnePatient(query: { userId: $id }, set: { onboarded: true }) {
      onboarded
    }
  }
`;

export const DISCONNECT_REASON = gql`
  mutation DISCONNECT_REASON($id: String!, $reason: String, $notes: String) {
    updateOneCall(
      query: { room: $id }
      set: {
        reasonForDisconnect: $reason
        disconnectNotes: $notes
        status: "resolved"
      }
    ) {
      status
    }
  }
`;

export const GET_ROOM_STATUS = gql`
  query GET_ROOM_STATUS($id: String!) {
    call(query: { room: $id }) {
      status
    }
  }
`;
