import React, { useMemo } from 'react';
import styled from 'styled-components';
import BatteryMeter from './BatteryMeter';

const BloodPressure = ({ battery, sys, dia, hr, widget, saved }) => {
  const batteryValue = useMemo(
    () => (battery ? Math.ceil((battery / 100) * 4 - 1) : null),
    [battery],
  );

  return (
    <Container>
      <ReadingRow>
        <Reading>
          <Label>DIA</Label>
          <Value>{sys || '-'}</Value>
          <Metric>mmHg</Metric>
        </Reading>

        <Reading>
          <Label>SYS</Label>
          <Value>{dia || '-'}</Value>
          <Metric>mmHg</Metric>
        </Reading>
      </ReadingRow>

      {hr ? (
        <HeartRate title="Heart Rate" widget={widget}>
          <Value>{hr}</Value>
          <Label>HR</Label>
        </HeartRate>
      ) : null}

      {battery ? (
        <BatteryMeter title={`${battery}%`} value={batteryValue} />
      ) : null}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ReadingRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const Reading = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 0 25px;
`;

const Label = styled.label``;

const Value = styled.div`
  font-size: 36px;
  color: ${props => props.theme.textColors.primary};
  font-weight: ${props => props.theme.fontWeights.bold};
  margin-bottom: 3px;
`;

const Metric = styled.div`
  font-size: 12px;
  font-weight: ${props => props.theme.fontWeights.medium};
  color: ${props => props.theme.textColors.tertiary};
  opacity: 0.6;
`;

const HeartRate = styled(Reading)`
  position: absolute;
  top: 17px;
  left: 17px;
  padding: 0;
  flex-direction: ${props => (props.widget ? 'column' : 'row')};

  ${Label} {
    margin: ${props => (props.widget ? 0 : '0 0 0 4px')};
  }

  ${Value} {
    font-size: 20px;
    margin: 0;
  }
`;

export default BloodPressure;
