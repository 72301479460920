import React, {
  useRef,
  useState,
  useEffect,
  useContext,
  useCallback,
} from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';
import { SET_USER_AVAILABLE } from '../../api/team';
import { CallContext } from '../../contexts/CallContext';
import { MediaContext } from '../../contexts/MediaContext';
import { SystemContext } from '../../contexts/SystemContext';
import useAmplitude from '../../hooks/useAmplitude';
import EndScreenDisconnected from './endScreen/EndScreenDisconnected';
import EndScreenOnboarding from './endScreen/EndScreenOnboarding';
import EndScreenQuestion from './endScreen/EndScreenQuestion';
import EndScreenCarelineComplete from './endScreen/EndScreenCarelineComplete';
import EndScreenPhysicianComplete from './endScreen/EndScreenPhysicianComplete';
import EndScreenHeader from './endScreen/EndScreenHeader';

const CallEndScreen = ({ forceEnd }) => {
  const isInitialMount = useRef(true);
  const staffMarkedAvailable = useRef(false);

  const {
    state: { startTime, callType, encounter, patient, room, status },
    dispatch: dispatchCall,
  } = useContext(CallContext);
  const { dispatch: dispatchMedia } = useContext(MediaContext);
  const {
    state: { userId, realmUser },
  } = useContext(SystemContext);

  const { trackCallDetails } = useAmplitude();

  // routing vars
  const [callFailed, setCallFailed] = useState(false);
  const [hideOnboarding, setHideOnboarding] = useState(false);

  const [markStaffAsAvailable] = useMutation(SET_USER_AVAILABLE, {
    onCompleted() {
      staffMarkedAvailable.current = true;
      if (
        status === 'ended' &&
        realmUser.customData.role === 'careline' &&
        callType !== 'onboarding'
      ) {
        // call was hung up by staff, take careline back to dashboard immediately
        window.location.href = '/';
      }
    },
    onError(err) {
      console.log('Error marking staff available: ', err);
    },
  });

  const markCallAsEnded = useCallback(async () => {
    // 1. careline does not have summary screen, record analytics now
    if (realmUser.customData.role === 'careline') trackCallDetails();

    // 2. tell server call has ended (mongo)
    try {
      await realmUser.functions.endCall(room);
    } catch (err) {
      console.log('Error ending call: ', err);
    }

    // 3. update call list (pusher)
    try {
      await realmUser.functions.sendPusherEvent('calls', 'calls-updated');
    } catch (err) {
      console.log('Error updating call list via Pusher: ', err);
    }

    // 4. update status locally (route this UI to new view)
    dispatchCall({
      type: 'SET_CALL',
      data: {
        status: 'ended',
      },
    });
  }, [realmUser, room, trackCallDetails]);

  // onInit
  useEffect(() => {
    const forceEndCall = async () => {
      await markCallAsEnded();
      if (!staffMarkedAvailable.current) {
        markStaffAsAvailable({ variables: { id: userId } });
      }
    };

    if (isInitialMount.current) {
      isInitialMount.current = false;

      // 1. clear Twilio media state
      dispatchMedia({ type: 'CLEAR' });

      // 2. when careline refreshes page, they cannot rejoin call
      if (forceEnd) {
        forceEndCall();
        return;
      }

      // 3. Amplitude: final stat tracking for a completed call
      if (status === 'ended' && realmUser.customData.role === 'careline')
        trackCallDetails();

      // 3. free up staff member for another call to come in
      if (!staffMarkedAvailable.current) {
        markStaffAsAvailable({ variables: { id: userId } });
      }
    }
  }, [forceEnd, markCallAsEnded, userId, trackCallDetails]);

  return (
    <Container>
      <EndScreenHeader status={status} startTime={startTime} />
      <Router>
        {callFailed || status === 'patientCancelled' ? (
          <EndScreenDisconnected
            onComplete={() => {
              setCallFailed(false);
              setHideOnboarding(true);
            }}
          />
        ) : status === 'ended' ? (
          callType === 'onboarding' && !hideOnboarding ? (
            <EndScreenOnboarding
              patient={patient}
              onComplete={() => setHideOnboarding(true)}
            />
          ) : realmUser?.customData?.role === 'physician' ? (
            <EndScreenPhysicianComplete encounter={encounter} />
          ) : (
            <EndScreenCarelineComplete />
          )
        ) : (
          <EndScreenQuestion
            message="Was the Patient's call completed?"
            yesTxt="Yes, the visit had concluded"
            noTxt="No, I don't know what happened"
            onYes={markCallAsEnded}
            onNo={() => setCallFailed(true)}
          />
        )}
      </Router>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  height: 100%;
  max-width: 460px;
  display: flex;
  flex-direction: column;
  padding: 30px;
  background-color: white;
  border-right: 2px solid ${props => props.theme.colors.border};
`;

const Router = styled.div`
  flex: 1;
  padding: 30px 0;
  display: flex;
  justify-content: center;
`;

export default CallEndScreen;
