import React, { useCallback, useRef } from 'react';
import { TIMEOUT } from '../../constants';

const useAutoLogout = (onWarn, onTimeOut, onReset) => {
  const isInitialMount = useRef(true);

  const warn = useCallback(() => {
    onWarn && onWarn();
  }, [onWarn]);

  const logout = useCallback(() => {
    onTimeOut && onTimeOut();
  }, [onTimeOut]);

  React.useEffect(() => {
    let warnTimeout;
    let logoutTimeout;

    const setTimeouts = () => {
      if (TIMEOUT.time > TIMEOUT.warnFor)
        warnTimeout = setTimeout(warn, (TIMEOUT.time - TIMEOUT.warnFor) * 1000);
      logoutTimeout = setTimeout(logout, TIMEOUT.time * 1000);
    };

    const clearTimeouts = () => {
      if (warnTimeout) clearTimeout(warnTimeout);
      if (logoutTimeout) clearTimeout(logoutTimeout);
    };

    const events = [
      'load',
      'mousemove',
      'mousedown',
      'click',
      'scroll',
      'keypress',
    ];

    const resetTimeout = e => {
      // console.log('event: ', e);
      onReset();
      clearTimeouts();
      setTimeouts();
    };

    for (let i in events) {
      window.addEventListener(events[i], resetTimeout);
    }

    if (isInitialMount.current) {
      setTimeouts();
      isInitialMount.current = false;
    }

    return () => {
      clearTimeouts();
      for (let i in events) {
        window.removeEventListener(events[i], resetTimeout);
      }
    };
  }, [logout, warn]);
};

export default useAutoLogout;
