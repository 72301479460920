import gql from 'graphql-tag';

// ALLERGY INFORMATION

/**
 * FRAGMENTS
 */

const allergyFragments = {
  standard: gql`
    fragment AllergyStandard on Allergy {
      _id
      allergy {
        display
        clinicalCode
      }
      onsetAge
      severity
    }
  `,
};

export const ALLERGY_FRAGMENTS = allergyFragments;

/**
 * QUERIES
 */

export const GET_ALLERGIES = gql`
  query GET_ALLERGIES($patient: String!) {
    allergies(query: { patient: $patient }) {
      ...AllergyStandard
    }
  }
  ${allergyFragments.standard}
`;

/**
 * MUTATIONS
 */

export const ADD_ALLERGY = gql`
  mutation ADD_ALLERGY(
    $id: String!
    $allergy: AllergyAllergyInsertInput!
    $onsetAge: String!
    $severity: String!
  ) {
    insertOneAllergy(
      data: {
        patient: $id
        allergy: $allergy
        onsetAge: $onsetAge
        severity: $severity
      }
    ) {
      ...AllergyStandard
    }
  }
  ${allergyFragments.standard}
`;

export const REMOVE_ALLERGY = gql`
  mutation DELETE_ALLERGY($id: ObjectId!) {
    deleteOneAllergy(query: { _id: $id }) {
      _id
    }
  }
`;

export const UPDATE_ALLERGY = gql`
  mutation UPDATE_ALLERGY(
    $id: ObjectId!
    $allergy: AllergyAllergyUpdateInput!
    $onsetAge: String!
    $severity: String!
  ) {
    updateOneAllergy(
      query: { _id: $id }
      set: { allergy: $allergy, onsetAge: $onsetAge, severity: $severity }
    ) {
      ...AllergyStandard
    }
  }
  ${allergyFragments.standard}
`;
