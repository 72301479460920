import React from 'react';
import ReactDOM from 'react-dom';
import Shell from './App/Shell';
import Root from './App/Root';
import Theme from './App/theme';
import GlobalStyle from './globalStyles';

ReactDOM.render(
  <React.StrictMode>
    <Theme>
      <GlobalStyle />
      <Root>
        <Shell />
      </Root>
    </Theme>
  </React.StrictMode>,
  document.getElementById('root'),
);
