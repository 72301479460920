import React, { useContext } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { FaPhone, FaRegBell, FaSearch } from 'react-icons/fa';
import UserIcon from '../common/UserIcon';
import CallPopover from '../popovers/CallPopover';
import ContextPopover from '../popovers/ContextPopover';
import SearchPopover from '../popovers/SearchPopover';
import { PopoverContext } from '../../contexts/PopoverContext';
import { SystemContext } from '../../contexts/SystemContext';
import WithTooltip from '../common/WithTooltip';

const ACTIONS = [
  { id: 'edit', label: 'Edit Profile' },
  { id: 'logout', label: 'Logout' },
];

const AdminHeader = ({ title }) => {
  const { dispatch: dispatchPopover } = useContext(PopoverContext);
  const {
    state: { realmUser },
  } = useContext(SystemContext);
  const history = useHistory();

  const openPopoverContext = e => {
    e.preventDefault();

    dispatchPopover({
      type: 'ADD_POPOVER',
      data: {
        component: ContextPopover,
        props: {
          actions: ACTIONS,
          bounds: e.target.getBoundingClientRect(),
          force: { right: true },
          onAction: onContextAction,
        },
      },
    });
  };

  const onContextAction = id => {
    if (id === 'logout') {
      history.replace('/logout');
    }
  };

  const openCallPopover = e => {
    e.preventDefault();

    dispatchPopover({
      type: 'ADD_POPOVER',
      data: {
        component: CallPopover,
        props: {
          force: { right: true },
          bounds: e.target.getBoundingClientRect(),
        },
      },
    });
  };

  const openSearch = e => {
    e.preventDefault();

    dispatchPopover({
      type: 'ADD_POPOVER',
      data: {
        component: SearchPopover,
        props: {
          force: { right: true },
          bounds: e.target.getBoundingClientRect(),
        },
      },
    });
  };

  return (
    <Wrapper>
      <Title>{title}</Title>
      {/* <SearchBar white /> */}
      <Buttons>
        <AuxButtonWrapper>
          <WithTooltip bottom title="Patient Lookup">
            <AuxButton onClick={openSearch}>
              <FaSearch />
            </AuxButton>
          </WithTooltip>
        </AuxButtonWrapper>
        <AuxButtonWrapper>
          <WithTooltip bottom title="Recent Calls">
            <AuxButton onClick={openCallPopover}>
              <FaPhone />
            </AuxButton>
          </WithTooltip>
        </AuxButtonWrapper>
        <AuxButtonWrapper>
          <WithTooltip bottom title="Notifications">
            <AuxButton>
              <FaRegBell />
            </AuxButton>
          </WithTooltip>
        </AuxButtonWrapper>
        <Profile onClick={openPopoverContext}>
          <UserIcon user={realmUser?.customData} />
        </Profile>
      </Buttons>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

const Title = styled.h1`
  position: relative;
  display: block;
  color: ${props => props.theme.textColors.primary};
  margin: 0;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  padding-left: 16px;
`;

const AuxButtonWrapper = styled.div`
  margin-right: 16px;
`;

const AuxButton = styled.div`
  position: relative;
  cursor: pointer;
  height: 48px;
  width: 48px;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${props => props.theme.colors.border};

  svg {
    font-size: 18px;
    color: ${props => props.theme.textColors.primary};
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.75);
  }

  &:after {
    position: absolute;
    display: block;
    top: 0px;
    right: -2px;
    content: '';
    width: 12px;
    height: 12px;
    background-color: ${props =>
      props.unread ? props.theme.colors.tertiary : 'transparent'};
    border-radius: 20px;
    border: 1px solid;
    border-color: ${props => (props.unread ? 'white' : 'transparent')};
  }
`;

const Profile = styled.div`
  cursor: pointer;
`;

export default AdminHeader;
