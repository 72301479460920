import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import { FaMapPin, FaPhone, FaTimes } from 'react-icons/fa';
import parse from 'html-react-parser';
import SearchSelector from './SearchSelector';
import { formatAddress, formatToPhone } from '../../hooks/useTextHelper';

// Wrapper for SearchSelector

const LookupSelector = ({
  id,
  value,
  mode,
  hideCard,
  onSelect,
  onAutosave,
  placeholder,
  disabled = false,
}) => {
  const [selected, setSelected] = useState(value);

  const displayNumber = useMemo(
    () =>
      !selected
        ? ''
        : id === 'pharmacy'
        ? `NCPDP: #${selected.ncpdp}`
        : `NPI: #${selected.npi}`,
    [selected],
  );

  const onSelected = (id, record) => {
    setSelected(record);

    // send to parent (Form.js)
    onSelect(id, record);

    // if autosave, force it
    if (onAutosave) onAutosave(id, record, true);
  };

  return (
    <Container>
      {selected && !hideCard ? (
        <OverflowVisible>
          <SelectedWrapper>
            <Name>{selected.displayName || selected.name}</Name>
            <Title>{selected.type}</Title>
            <Number>{displayNumber}</Number>
            <Contact>
              <ContactItem>
                <FaMapPin />
                <ContactItemText>
                  {parse(formatAddress(selected.address, true))}
                </ContactItemText>
              </ContactItem>
              <ContactItem>
                <FaPhone />
                <ContactItemText>
                  {formatToPhone(selected.phone)}
                </ContactItemText>
              </ContactItem>
            </Contact>
          </SelectedWrapper>
        </OverflowVisible>
      ) : (
        <SearchSelector
          id={id}
          value={selected}
          mode={mode}
          placeholder={placeholder}
          selectedDisplay={displayNumber}
          onSelect={onSelected}
          disabled={disabled}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  text-align: center;
  width: 100%;
`;

const OverflowVisible = styled.div`
  position: relative;
  display: inline-flex;
`;

const SelectedWrapper = styled.div`
  position: relative;
  background-color: white;
  border: 1px solid ${props => props.theme.colors.border};
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${props => (props.withEdit ? '30px 30px 65px 30px' : '30px')};
  overflow: hidden;
`;

const Name = styled.h3`
  margin: 0;
`;

const Title = styled.h5`
  font-size: 14px;
  font-weight: ${props => props.theme.fontWeights.medium};
  color: ${props => props.theme.textColors.tertiary};
  margin: 6px 0;
`;

const Number = styled.label`
  color: ${props => props.theme.colors.blue};
  font-size: 12px;
  margin: 10px 0 15px;
`;

const Contact = styled.div`
  padding: 0 20px;
  border-top: 1px solid ${props => props.theme.colors.border};
  text-align: left;
`;

const ContactItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
  line-height: 1.4;

  svg {
    color: ${props => props.theme.textColors.tertiary};
    font-size: 16px;
    margin-right: 15px;
  }
`;

const ContactItemText = styled.div`
  flex: 1;
  font-size: 14px;
  color: ${props => props.theme.textColors.secondary};
`;

const ClearBtn = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 5;
  border: 0;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: ${props => props.theme.colors.blue};
  cursor: pointer;
  transform: translateX(40%) translateY(-40%);

  svg {
    font-size: 20px;
    color: white;
  }
`;

export default LookupSelector;
