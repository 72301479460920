import gql from 'graphql-tag';

// VITALS INFORMATION

/**
 * FRAGMENTS
 */

const vitalFields = gql`
  fragment vitalFields on Vital {
    _id
    datetime
    device
    encounter
    type
    value {
      dia
      pi
      pulse
      pleth {
        value
      }
      spo2
      sys
      temp
    }
  }
`;

export const VITAL_FRAGMENT = vitalFields;

/**
 * QUERIES
 */

export const GET_VITALS = gql`
  query GET_VITALS($encounter: ObjectId!) {
    vitals(query: { encounter: $encounter }) {
      ...vitalFields
    }
  }
  ${vitalFields}
`;

/**
 * MUTATIONS
 */

export const ADD_VITAL = gql`
  mutation ADD_VITAL(
    $id: String!
    $datetime: DateTime
    $device: String
    $encounter: ObjectId!
    $type: String
    $value: VitalValueInsertInput
  ) {
    insertOneVital(
      data: {
        patient: $id
        datetime: $datetime
        device: $device
        encounter: $encounter
        type: $type
        value: $value
      }
    ) {
      ...vitalFields
    }
  }
  ${vitalFields}
`;

export const UPDATE_VITAL = gql`
  mutation UPDATE_VITAL(
    $_id: ObjectId!
    $datetime: DateTime
    $device: String
    $type: String
    $value: VitalValueUpdateInput
  ) {
    updateOneVital(
      query: { _id: $_id }
      set: { datetime: $datetime, device: $device, type: $type, value: $value }
    ) {
      ...vitalFields
    }
  }
  ${vitalFields}
`;
