import React, { useState, useContext } from 'react';
import { FaStickyNote } from 'react-icons/fa';
import styled, { keyframes } from 'styled-components';
import { useEventListener } from '../../hooks/useEventListener';
import List from '../forms/List';
import { REQUEST_FORM } from '../../../forms/requests';
import {
  ADD_REQUEST_ITEM,
  UPDATE_REQUEST_ITEM,
  REMOVE_REQUEST_ITEM,
} from '../../api/requests';
import RequestFormItem from './RequestFormItem';
import { CallContext } from '../../contexts/CallContext';
import { SystemContext } from '../../contexts/SystemContext';

const HEADER = {
  addTitle: 'Add New Patient Request',
  editTitle: 'Edit Patient Request',
  icon: <FaStickyNote />,
  label: 'Patient Requests',
};

const SUMMARY = {
  icon: <FaStickyNote />,
  title: 'Patient Requests',
};

const RequestForm = ({ patient, requests, onRefresh, onClose }) => {
  const {
    state: { _id: callId },
  } = useContext(CallContext);
  const {
    state: { userId },
  } = useContext(SystemContext);

  const [animOut, setAnimOut] = useState();

  const onAnimateClose = () => {
    setAnimOut(true);

    setTimeout(onClose, 250);
  };

  useEventListener('refreshListData', onRefresh);

  return (
    <Container out={animOut}>
      {requests ? (
        <List
          targetId={patient.userId}
          mode="request"
          data={requests}
          listItem={RequestFormItem}
          form={{
            ...REQUEST_FORM,
            extraData: {
              callId,
              datetime: '',
              createdBy: userId,
              patient: patient.userId,
            },
          }}
          add={ADD_REQUEST_ITEM}
          update={UPDATE_REQUEST_ITEM}
          remove={REMOVE_REQUEST_ITEM}
          formHeader={HEADER}
          summary={SUMMARY}
          columns={1}
          onClose={onAnimateClose}
        />
      ) : null}
    </Container>
  );
};

const slideUpIn = keyframes`
  0% {
    transform: translateY(30%);
    opacity: 0.4;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

const slideDownOut = keyframes`
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(30%);
    opacity: 0;
  }
`;

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 51;
  background-color: white;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  animation-duration: 0.3s;
  animation-direction: forwards;
  animation-timing-function: ${props => (props.out ? 'ease-in' : 'ease-out')};
  animation-name: ${props => (props.out ? slideDownOut : slideUpIn)};
`;

export default RequestForm;
