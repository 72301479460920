import React from 'react';
import styled from 'styled-components';
import { FaPen } from 'react-icons/fa';
import SeverityMeter from './SeverityMeter';
import { resolvePath } from '../../hooks/useTextHelper';
import { lighten } from 'polished';

const ListItem = ({ config, label, subtext, map, onEdit, ehr }) => (
  <Item onClick={onEdit} ehr={ehr}>
    <TextWrapper>
      <Label>{resolvePath(config, map.label, '')}</Label>
      {subtext ? (
        <Subtext>
          {subtext.resolveWith
            ? subtext.resolveWith(config[subtext.key])
            : config[subtext.key]}
        </Subtext>
      ) : null}
    </TextWrapper>
    {config.severity && config.severity !== 'unknown' ? (
      <MeterWrapper>
        <SeverityMeter level={config.severity} />
      </MeterWrapper>
    ) : null}
    <FaPen />
  </Item>
);

const MeterWrapper = styled.div``;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Label = styled.p`
  font-size: 16px;
  margin: 0;
  color: ${props => props.theme.textColors.primary};
  flex: 1;
  user-select: none;
`;

const Subtext = styled.p`
  font-size: 12px;
  font-weight: ${props => props.theme.fontWeights.medium};
  margin: 6px 0 0;
  color: ${props => props.theme.colors.blue};
  text-transform: uppercase;
`;

const Item = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 20px;
  border-radius: 10px;
  background-color: ${props =>
    props.ehr ? 'white' : props.theme.backgroundColors.lightAlt};
  opacity: 0.8;
  cursor: pointer;

  svg {
    display: none;
    font-size: 16px;
    color: ${props => props.theme.colors.blue};
  }

  &:hover {
    opacity: 1;
    background-color: ${props => props.theme.colors.lightBlue};

    svg {
      display: block;
    }

    ${Label} {
      color: ${props => props.theme.colors.blue};
    }

    ${Subtext} {
      color: ${props => lighten(0.15, props.theme.colors.blue)};
    }

    ${MeterWrapper} {
      display: none;
    }
  }
`;

export default ListItem;
