import React from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { GET_PATIENT_INFO } from '../api/patients';
import ActivityIndicator from '../components/common/ActivityIndicator';
import ChartingOnboarding from '../components/charting/onboarding/ChartingOnboarding';
import PatientHeaderOnboarding from '../components/patient/PatientHeaderOnboarding';

const PatientProfilePage = () => {
  // eslint-disable-next-line
  const { id } = useParams();

  const { data, loading, error } = useQuery(GET_PATIENT_INFO, {
    variables: { userId: id },
  });

  return (
    <Container>
      {loading ? (
        <CenterWrapper>
          <ActivityIndicator />
        </CenterWrapper>
      ) : data && data.patient ? (
        <>
          <PatientHeaderOnboarding
            patient={data.patient}
            onBack={() => window.history.back()}
          />

          <ChartingOnboarding
            userId={data.patient.userId}
            stickyFooter
            hideVitals
          />
        </>
      ) : (
        <CenterWrapper>
          <Error>
            {error
              ? error.message
              : data && data.errors
              ? data.errors.message
              : 'An error occurred retrieving data'}
          </Error>
        </CenterWrapper>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const CenterWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const Error = styled.p`
  text-align: center;
  font-size: 15px;
  color: ${props => props.theme.textColors.secondary};
`;

export default PatientProfilePage;
