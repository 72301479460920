import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import Checkbox from './Checkbox';
import { GET_MEDICATIONS } from '../../api/medications';
import { CallContext } from '../../contexts/CallContext';

const isChecked = (item, selected) => {
  return selected.indexOf(item._id) > -1;
};

const MedicationSelector = ({
  id,
  value,
  onChange,
  onAutosave,
  disabled = false,
}) => {
  const {
    state: { patient },
  } = useContext(CallContext);

  const [selected, setSelected] = useState([]);

  const { data } = useQuery(GET_MEDICATIONS, {
    variables: { patient: patient.userId },
  });

  const editList = item => {
    const idx = selected.indexOf(item._id);

    let arr = [...selected];
    if (idx > -1) arr.splice(idx, 1);
    else arr = [...selected, item._id];

    setSelected([...arr]);

    onChange(arr);

    if (onAutosave) onAutosave(id, arr, true);
  };

  useEffect(() => {
    // in edit mode, map medications to array of _id
    if (data && value) {
      // create array of medication clincialCodes
      const sel = value.reduce(
        (arr, { medication: { clinicalCode } }) => [...arr, clinicalCode],
        [],
      );
      // match clincicalCodes and add _id to array
      let selectedCopy = [];
      for (let i = 0; i < data.medications.length; i++) {
        const code = data.medications[i].medication.clinicalCode;
        if (sel.indexOf(code) > -1) selectedCopy.push(data.medications[i]._id);
      }
      setSelected(selectedCopy);
    }
  }, [data]);

  return (
    <Container>
      {data
        ? data.medications.map((item, index) => (
            <Item key={item._id}>
              <Checkbox
                checked={isChecked(item, selected)}
                onChange={() => editList(item)}
                disabled={disabled}>
                {item.medication.display}
              </Checkbox>
            </Item>
          ))
        : null}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  padding: 20px;
  background-color: ${props => props.theme.backgroundColors.lightAlt};
  width: 100%;
`;

const Item = styled.div`
  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;

export default MedicationSelector;
