import React from 'react';
import styled from 'styled-components';
import ModalDialog from './ModalDialog';

const ConfirmModal = ({
  message,
  cancelIcon,
  confirmIcon,
  cancelLabel,
  confirmLabel,
  onConfirm,
  onDismiss,
  confirmColor = 'red',
  onBack,
  dark,
  shade,
}) => (
  <Wrapper>
    {shade ? <Shade dark={dark} /> : null}
    <ModalDialog
      dark={dark}
      message={message}
      cancelIcon={cancelIcon}
      confirmIcon={confirmIcon}
      cancelLabel={cancelLabel}
      confirmLabel={confirmLabel}
      onConfirm={onConfirm}
      onDismiss={onDismiss}
      confirmColor={confirmColor}
      onBack={onBack}
    />
  </Wrapper>
);

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 50;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: none !important;
`;

const Shade = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: ${props =>
    props.dark ? 'black' : props.theme.backgroundColors.lightAlt};
  opacity: 0.7;
`;

export default ConfirmModal;
