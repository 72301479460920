import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import { useApolloClient, useQuery } from '@apollo/client';
import { format, parseISO } from 'date-fns';
import { FaCheck, FaPenAlt, FaTimes } from 'react-icons/fa';
import { FiSend } from 'react-icons/fi';
import ActivityIndicator from '../common/ActivityIndicator';
import { CallContext } from '../../contexts/CallContext';
import { SystemContext } from '../../contexts/SystemContext';
import { formatAMAText } from '../../hooks/useTextHelper';

const AMADocumentModal = ({ onDismiss }) => {
  const client = useApolloClient();

  const {
    state: { room, patient, encounter, ama },
    dispatch: dispatchCall,
  } = useContext(CallContext);
  const {
    state: { realmUser },
  } = useContext(SystemContext);

  const [saving, setSaving] = useState(false);

  const onRevoke = async () => {
    setSaving(true);

    // dispatch event to iPad to remove the form
    await realmUser.functions.sendPusherEvent(`presence-${room}`, 'hide-ama');

    dispatchCall({ type: 'SET_AMA', data: { status: 'revoked' } });

    await realmUser.functions.updateAMAStatus(encounter, 'revoked');

    setSaving(false);

    onDismiss();
  };

  return (
    <Container>
      <Header>
        <Subtitle>Agreement</Subtitle>
        <Title>Against Medical Advice (AMA)</Title>
        <CloseBtn onClick={onDismiss}>
          <FaTimes />
        </CloseBtn>
      </Header>
      <ScrollView>
        <p>
          This is to certify that I,{' '}
          <b>
            {patient.name
              ? `${patient.name.given} ${patient.name.family}`
              : '_________________'}
          </b>
          , insist on refusing, discontinuing, or withdrawing from the following
          medical treatment(s) or procedure(s):{' '}
          <b>{ama.care || '_________________'}</b>, and being discharged from
          the care of BetterMedics, which is against the advice of my treating
          provider/practitioner at this time. I understand that I may resume
          treatment and care from BetterMedics at any time, should I change my
          mind.
        </p>
        <p>
          I have been given the opportunity to ask any questions regarding the
          refusal, withdrawal, or discontinuance of the above medical
          treatment(s) or procedure(s) and I acknowledge the risks to my health
          and financial obligations for not following the advice of my
          provider/practitioner, which have been explained to me.
        </p>
        <p>
          By signing this form, I hereby accept all medical and financial risks
          and related consequences of my decision to not follow the medical
          advice of my provider/practitioner and hereby release BetterMedics and
          its providers/practitioners from any and all liability that may result
          from not following the medical advice which has been given to me.
        </p>
      </ScrollView>
      <Footer>
        <SignatureLine>
          <Signature>
            <FaPenAlt />
            <Name>
              {patient.name
                ? `${patient.name.given} ${patient.name.family}`
                : ''}
            </Name>
          </Signature>

          <Timestamp>
            <Icon status={ama.status}>
              {ama.status === 'completed' ? <FaCheck /> : <FiSend />}
            </Icon>
            <TimeText>
              {ama.timestamp
                ? format(parseISO(ama.timestamp), "'Accepted at 'h:mm a")
                : 'Waiting for acceptance'}
            </TimeText>
          </Timestamp>
        </SignatureLine>
        {ama.status === 'sent' ? (
          <RevokeBtn onClick={onRevoke} disabled={saving}>
            {saving ? (
              <ActivityIndicator size={15} color="white" />
            ) : (
              'Revoke Agreement'
            )}
          </RevokeBtn>
        ) : null}
      </Footer>
    </Container>
  );
};

const Icon = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${props =>
    props.status === 'completed'
      ? props.theme.colors.green
      : props.theme.colors.tertiary};
  margin-right: 8px;

  svg {
    color: white;
    font-size: 13px;
    margin-left: ${props => (props.status === 'completed' ? 0 : -1)};
  }
`;

const SignatureLine = styled.div`
  padding: 20px;
  width: 350px;
`;

const Signature = styled.div`
  display: flex;
  flex-direction: row;

  svg {
    color: ${props => props.theme.textColors.primary};
    font-size: 20px;
    margin-right: 12px;
    margin-top: 1px;
  }
`;

const Name = styled.div`
  font-size: 18px;
  color: ${props => props.theme.textColors.primary};
  padding-bottom: 10px;
  border-bottom: 1px dashed;
  border-bottom-color: ${props => props.theme.textColors.secondary};
  margin-bottom: 20px;
  flex: 1;
  font-family: Courier, monospace;
`;

const Timestamp = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TimeText = styled.span`
  font-size: 12px;
  color: ${props => props.theme.textColors.secondary};
  flex: 1;
`;

const Container = styled.div`
  position: relative;
  background-color: white;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 30px;
  overflow: hidden;
`;

const Header = styled.div`
  position: relative;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const CloseBtn = styled.span`
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;

  svg {
    font-size: 18px;
    color: ${props => props.theme.textColors.secondary};
  }

  &:hover {
    svg {
      color: ${props => props.theme.textColors.primary};
    }
  }
`;

const Title = styled.h1`
  margin: 10px 0 0;
  font-size: 36px;
  font-weight: bold;
  color: ${props => props.theme.textColors.primary};
`;

const Subtitle = styled.h3`
  color: ${props => props.theme.textColors.secondary};
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 0;
  padding-top: 10px;
`;

const ScrollView = styled.div`
  flex: 1;
  padding: 0 20px;
  overflow-y: scroll;

  p {
    margin-top: 0;
    font-size: 14px;
    line-height: 1.5;
  }
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 10px;
`;

const RevokeBtn = styled.button`
  border: 0;
  outline: none;
  background-color: ${props => props.theme.colors.blue};
  color: white;
  font-size: 14px;
  font-weight: ${props => props.theme.fontWeights.medium};
  opacity: 0.9;
  cursor: pointer;
  padding: 18px 20px;
  border-radius: 8px;
  margin-left: auto;

  &:hover {
    opacity: 1;
  }
`;

export default AMADocumentModal;
