import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { lighten } from 'polished';
import { useMutation, useApolloClient } from '@apollo/client';
import { FaPlus } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';
import Form from './Form';
import ListItem from './ListItem';
import Table from '../tables/Table';

import VerifyButton from '../common/VerifyButton';
import useScrollSpy from '../../hooks/useScrollSpy';

const List = ({
  targetId, // id of target mutations are made on (e.g. user id)
  mode, // optional: changes style of <ListItem />
  data, // list data returned from GET call (called by parent)
  listItem, // optional: override ListItem with another component
  summary, // { icon, title, subtitle} of card that was clicked on
  onFormSave, // optional: function to be fired when data is saved
  onClose, // parent function to close out of list
  form, // configuration object for form (see: forms.js)
  add, // graph mutation for adding a new item
  remove, // graph mutation for deleting an item
  update, // graph mutation for updating data in edit mode
  formHeader, // { addTitle, editTitle, icon, label } used to set title block of forms
  map, // used to database variable names to standard names
  subtext, // optional: used to display a secondary label
  emptyLabel, // optional: label to be used when list is empty
  table, // used for a table based layout
  columns = 2, // number of columns in grid
  verified, // passed in from context, sets section in verified/unverified mode
  onVerify, // careline calls need to verify information in list
  ehr, // optional:  conditional styling for <Table /> & <ListItem />
  onItemClick, // optional: override click that usually opens form
  inModal,
}) => {
  const client = useApolloClient();
  const scrollPane = useRef(null);
  const [isScrolledToBottom, setScrolledToBottom] = useState(false);

  useScrollSpy({
    target: scrollPane,
    onChange: setScrolledToBottom,
    toBottom: true,
    throttle: 100,
  });

  const [showForm, setShowForm] = useState();
  const [formData, setFormData] = useState();

  const [isVerified, setVerified] = useState(verified);

  const Item = listItem || ListItem;

  const onEdit = item => {
    if (onItemClick) {
      onItemClick(item);
      return;
    }

    setFormData(item);
    setShowForm('edit');
  };

  const onDelete = async itemID => {
    if (remove) {
      await client.mutate({
        mutation: remove,
        variables: {
          id: itemID,
        },
      });
      onFormClosed(true);
    }
  };

  const onFormClosed = saved => {
    setShowForm(false);
    setFormData();
    if (saved) {
      window.dispatchEvent(new Event('refreshListData'));
    }
  };

  const onCloseClick = e => {
    e.stopPropagation();
    onClose();
  };

  const onVerifyClick = e => {
    e.stopPropagation();
    // tell navigation bar to update status of section
    onVerify(!isVerified);

    // show verified state in this UI
    setVerified(!isVerified);
  };

  const headerConfig = formHeader
    ? {
        icon: formHeader.icon,
        title: showForm === 'add' ? formHeader.addTitle : formHeader.editTitle,
        label: formHeader.label,
      }
    : null;

  return (
    <Container ref={scrollPane} ehr={showForm && ehr}>
      {showForm ? (
        <FormWrapper>
          <Form
            targetId={showForm === 'add' ? targetId : formData._id}
            actionType={showForm}
            form={{ mutation: showForm === 'add' ? add : update, ...form }}
            data={formData}
            headerConfig={headerConfig}
            onFormSave={onFormSave}
            onClose={onFormClosed}
            onDelete={formData ? () => onDelete(formData._id) : null}
            stickyFooter={!isScrolledToBottom}
            inModal={inModal}
          />
        </FormWrapper>
      ) : (
        <>
          <ListWrapper locked={isVerified}>
            {summary ? (
              <ListHeader>
                <CloseButton onClick={onCloseClick}>
                  <MdClose />
                </CloseButton>
                <ListHeaderIcon>{summary.icon}</ListHeaderIcon>
                <ListHeaderTitle>{summary.title}</ListHeaderTitle>
              </ListHeader>
            ) : null}

            <ListContent table={table}>
              {!data || data.length === 0 ? (
                <EmptyWrapper>
                  <EmptyMessage>{emptyLabel || 'No items added'}</EmptyMessage>
                </EmptyWrapper>
              ) : table ? (
                <Table data={data} config={table} onClick={onEdit} ehr={ehr} />
              ) : (
                <ListGrid columns={columns}>
                  {data.map(item => (
                    <Item
                      key={item._id}
                      mode={mode}
                      config={item}
                      subtext={subtext}
                      map={map}
                      onEdit={() => onEdit(item)}
                      ehr={ehr}
                    />
                  ))}
                </ListGrid>
              )}
            </ListContent>
          </ListWrapper>
          <ListFooter bottom={isScrolledToBottom}>
            <AddButton onClick={() => setShowForm('add')} disabled={isVerified}>
              <FaPlus />
            </AddButton>
            {onVerify ? (
              <VerifyButton isVerified={isVerified} onClick={onVerifyClick} />
            ) : null}
          </ListFooter>
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  background-color: ${props => (props.ehr ? 'white' : 'transparent')};
`;

const FormWrapper = styled.div`
  position: relative;
  text-align: left;
  height: 100%;
`;

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  &::after {
    display: none;
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 5;
    background-color: rgba(255, 255, 255, 0.7);

    ${props =>
      props.locked &&
      `
        display: block;
        pointer-events: 'none';
      `}
  }
`;

const ListHeader = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
`;

const ListHeaderIcon = styled.div`
  margin-bottom: 10px;
  svg {
    font-size: 36px;
    color: ${props => props.theme.colors.blue};
  }
`;

const ListHeaderTitle = styled.h2`
  font-size: 20px;
  margin: 0;
`;

const CloseButton = styled.div`
  position: absolute;
  top: 30px;
  right: 30px;
  z-index: 5;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.backgroundColors.lightAlt};
  cursor: pointer;

  svg {
    color: ${props => props.theme.textColors.tertiary};
    font-size: 32px;
  }

  &:hover {
    svg {
      color: ${props => props.theme.colors.blue};
    }
  }
`;

const ListContent = styled.div`
  padding: ${props => (props.table ? '30px 40px' : 0)};
  flex: 1;
`;

const ListFooter = styled.div`
  position: sticky;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 24px 30px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.9);

  border-top: 1px solid;
  border-top-color: ${props =>
    props.bottom ? 'transparent' : props.theme.colors.border};
  transition: border-top-color 0.2s ease-out;
`;

const ListGrid = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: ${props => (props.columns == 2 ? '1fr 1fr' : '1fr')};
  grid-gap: 20px;
  padding: 20px 40px;
`;

const EmptyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const EmptyMessage = styled.p`
  font-size: 17px;
  color: ${props => props.theme.textColors.tertiary};
`;

const AddButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: ${props => props.theme.colors.blue};

  outline: none;
  border: 0;
  cursor: pointer;

  svg {
    color: white;
    font-size: 34px;
  }

  &:hover {
    background-color: ${props => lighten(0.05, props.theme.colors.blue)};
  }

  ${({ disabled }) =>
    disabled &&
    `
    opacity: 0.5;
    pointer-events: none;
  `}
`;

export default List;
