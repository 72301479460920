import React from 'react';
import styled, { keyframes } from 'styled-components';
import { ImSpinner3 } from 'react-icons/im';

const Loader = ({ dots, activity, size, gray, white, call, transparent }) => (
  <Wrapper call={call} transparent={transparent}>
    {dots ? (
      <Dots size={size} gray={gray} call={call} />
    ) : activity ? (
      <ActivityIndicator size={size} white={white}>
        <ImSpinner3 />
      </ActivityIndicator>
    ) : (
      <Spinner size={size} gray={gray} />
    )}
  </Wrapper>
);

const rotate360 = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const dots = keyframes`
  0% {
    box-shadow: 0 28px 0 -28px #FCAAB6;
  }
  100% {
    box-shadow: 0 28px 0 #FCAAB6;
  }
`;

const dotsCall = keyframes`
  0% {
    box-shadow: 0 14px 0 -14px white;
  }
  100% {
    box-shadow: 0 14px 0 white;
  }
`;

const Wrapper = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 200;
  background-color: ${props =>
    props.gray
      ? props.theme.colors.border
      : props.call || props.transparent
      ? 'transparent'
      : props.theme.backgroundColors.primary};

  justify-content: center;
  align-items: center;

  svg {
    color: ${props => (props.call ? 'white' : 'inherit')};
  }
`;

const Spinner = styled.div`
  font-size: 10px;
  margin: 50px auto 100px;
  text-indent: -9999em;
  width: ${props => (props.size === 'small' ? '5em' : '10em')};
  height: ${props => (props.size === 'small' ? '5em' : '10em')};
  border-radius: 50%;

  background: ${props =>
    `linear-gradient(to right, ${
      props.gray ? props.theme.colors.border : props.theme.colors.primaryLight
    } 10%, rgba(247,248,247, 0) 42%)`};
  position: relative;
  animation: ${rotate360} 1.4s infinite linear;
  transform: translateZ(0);

  &:before {
    width: 50%;
    height: 50%;
    background: ${props =>
      props.gray ? props.theme.colors.border : props.theme.colors.primaryLight};
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
  }
  &:after {
    background: ${props => props.theme.backgroundColors.primary};
    width: 65%;
    height: 65%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
`;

const Dots = styled.div`
  width: ${props => (props.size === 'small' ? '14px' : '28px')};
  height: ${props => (props.size === 'small' ? '14px' : '28px')};
  border-radius: 50%;
  position: relative;
  animation: ${props => (props.call ? dotsCall : dots)} 0.9s ease alternate
    infinite;
  animation-delay: 0.36s;
  margin: -42px auto 0;

  &:before,
  &:after {
    content: '';
    position: absolute;
    width: ${props => (props.size === 'small' ? '14px' : '28px')};
    height: ${props => (props.size === 'small' ? '14px' : '28px')};
    border-radius: 50%;
    animation: ${props => (props.call ? dotsCall : dots)} 0.9s ease alternate
      infinite;
  }

  &:before {
    left: ${props => (props.size === 'small' ? '-20px' : '-40px')};
    animation-delay: 0.18s;
  }

  &:after {
    right: ${props => (props.size === 'small' ? '-20px' : '-40px')};
    animation-delay: 0.54s;
  }
`;

const ActivityIndicator = styled.div`
  display: flex;
  animation: ${rotate360} 1.4s infinite linear;
  opacity: 0.7;

  svg {
    font-size: ${props => (props.size === 'small' ? '20px' : '40px')};
    margin-right: 0 !important;
    color: ${props => (props.white ? 'white' : props.theme.textColors.primary)};
  }
`;

export default Loader;
