import React, { useState, useContext, useEffect } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { addDays, format, isToday, subDays } from 'date-fns';
import {
  FaChevronLeft,
  FaChevronRight,
  FaRegCalendarAlt,
} from 'react-icons/fa';
import { FiSun, FiMoon } from 'react-icons/fi';
import { DateSingleInput } from '@datepicker-react/styled';
import { lighten } from 'polished';
import ActivityIndicator from '../common/ActivityIndicator';
import UserIcon from '../common/UserIcon';
import { ModalContext } from '../../contexts/ModalContext';
import { SystemContext } from '../../contexts/SystemContext';
import ScheduleModal from '../modals/ScheduleModal';
import WithTooltip from '../common/WithTooltip';

const TODAY = new Date();

const TeamMember = ({ config, role }) => (
  <TeamMemberItem>
    {config ? (
      <>
        <UserIcon user={config} size="44px" />
        <TeamMemberInfo>
          <TeamMemberName>{`${config.firstName} ${config.lastName}`}</TeamMemberName>
          <TeamMemberRole>{role}</TeamMemberRole>
        </TeamMemberInfo>
      </>
    ) : (
      <TeamMemberEmpty>{`${role} not scheduled`}</TeamMemberEmpty>
    )}
  </TeamMemberItem>
);

const AdminSchedule = () => {
  const {
    state: { realmUser },
  } = useContext(SystemContext);
  const { dispatch: dispatchModal } = useContext(ModalContext);
  const [date, setDate] = useState(TODAY);
  const [scheduleData, setScheduleData] = useState();
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [refreshCount, setRefreshCount] = useState(0);

  const openSchedule = () => {
    dispatchModal({
      type: 'ADD_MODAL',
      data: {
        component: ScheduleModal,
        props: {
          maxWidth: '840px',
        },
        onDismiss: () => setRefreshCount(prev => prev + 1),
      },
    });
  };

  useEffect(() => {
    const fetchDayData = async newDate => {
      const data = await realmUser.functions.getSchedule(newDate, newDate);
      setScheduleData(data[0]);
    };

    try {
      fetchDayData(date.setHours(0, 0, 0, 0));
    } catch (err) {
      console.log('AdminSchedule Error: ', err);
    }
  }, [refreshCount, date]);

  const toggleDay = backwards => {
    const newDate = backwards ? subDays(date, 1) : addDays(date, 1);
    setDate(newDate);
  };

  const handleDatePickerChange = d => {
    setDate(d.date);
    setShowDatePicker(false);
  };

  const handleDatePickerFocus = d => {
    if (showDatePicker) setShowDatePicker(false);
  };

  return (
    <Container>
      <Header>
        <SectionTitle secondary>Schedule</SectionTitle>
        <AddBtnWrapper>
          <WithTooltip title="Edit Schedule" left>
            <AddBtn onClick={openSchedule}>
              <FaRegCalendarAlt />
            </AddBtn>
          </WithTooltip>
        </AddBtnWrapper>
      </Header>
      <DayPicker>
        <PageBtn disabled={isToday(date)} onClick={() => toggleDay(true)}>
          <FaChevronLeft />
        </PageBtn>
        <ThemeProvider
          theme={{
            breakpoints: ['32em', '48em', '64em'],
            reactDatepicker: {
              daySize: [36, 40],
              fontFamily: 'Roboto, system-ui, -apple-system',
              colors: {
                accessibility: '#D80249',
                selectedDay: lighten(0.1, '#57B3FA'),
                selectedDayHover: lighten(0.04, '#57B3FA'),
                primaryColor: '#57B3FA',
              },
              inputLabelDisplay: 'none',
              datepickerZIndex: '999',
            },
          }}>
          <DateSingleInput
            onDateChange={handleDatePickerChange}
            onFocusChange={handleDatePickerFocus}
            date={date}
            showDatepicker={showDatePicker}
            showClose={false}
            showResetDate={false}
            onClose={() => setShowDatePicker(false)}
          />
        </ThemeProvider>
        <DateBtn onClick={() => setShowDatePicker(true)}>
          {format(date, 'eeee, MMMM do')}
        </DateBtn>
        <PageBtn onClick={() => toggleDay()}>
          <FaChevronRight />
        </PageBtn>
      </DayPicker>
      <Body>
        {scheduleData ? (
          scheduleData.shifts.map((shift, index) => (
            <Roster key={shift.startTime}>
              <Label>
                <span>{`Shift #${index + 1}:`}</span>
                <span>{`7:00 ${index === 0 ? 'AM' : 'PM'} - 7:00 ${
                  index === 0 ? 'PM' : 'AM'
                }`}</span>
                {index === 0 ? <FiSun /> : <FiMoon />}
              </Label>
              <TeamMember
                config={shift.primaryCareline}
                role="Primary Care Line"
              />
              <TeamMember
                config={shift.secondaryCareline}
                role="Secondary Care Line"
              />
              <TeamMember config={shift.physician} role="Physician" />
            </Roster>
          ))
        ) : (
          <EmptyView>
            <ActivityIndicator color="secondary" />
          </EmptyView>
        )}
      </Body>
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const DayPicker = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 40px 10px;
`;

const DateBtn = styled.button`
  font-size: 14px;
  color: ${props => props.theme.textColors.secondary};
  cursor: pointer;
  flex: 1;
  background: transparent;
  outline: none;
  border: none;
  border-radius: 10px;
  margin: 0 5px;
  height: 32px;
  font-size: 13px;
  font-weight: ${props => props.theme.fontWeights.medium};

  &:hover {
    background: #f0f0f0;
    color: ${props => props.theme.textColors.primary};
  }
`;

const PageBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  outline: none;
  border: 2px solid ${props => props.theme.colors.border};
  border-radius: 10px;
  width: 32px;
  height: 32px;
  cursor: ${props => (props.disabled ? 'cursor' : 'pointer')};
  pointer-events: ${props => (props.disabled ? 'none' : 'all')};

  svg {
    color: ${props =>
      props.disabled ? '#DDD' : props.theme.textColors.secondary};
  }

  &:hover {
    svg {
      color: ${props => props.theme.textColors.primary};
    }
  }
`;

const AddBtnWrapper = styled.div`
  margin-left: auto;
`;

const AddBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.colors.tertiary};
  outline: none;
  border: none;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  cursor: pointer;

  svg {
    color: white;
    font-size: 18px;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 35px 40px;
`;

const SectionTitle = styled.h1`
  flex: ${props => (props.table ? 1 : 0)};
  font-size: 24px;
  letter-spacing: 0.25px;
  color: ${props => props.theme.textColors.primary};
  margin: 0;
`;

const Body = styled.div`
  flex: 1;
  overflow-y: scroll;
  padding: 30px 40px;
`;

const Label = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;

  svg {
    margin-left: auto;
    font-size: 1.2em;
  }

  span {
    margin-right: 15px;
  }
`;

const Roster = styled.div`
  display: block;
  margin-bottom: 35px;
`;

const TeamMemberItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px 20px;
  border-radius: 10px;
  /* background-color: ${props => props.theme.backgroundColors.tertiary}; */
  border: 1px solid ${props => props.theme.backgroundColors.secondary};
  margin: 10px 0;
  min-height: 76px;
`;

const TeamMemberInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 20px;
`;

const TeamMemberName = styled.div`
  display: block;
  font-size: 14px;
  color: ${props => props.theme.textColors.primary};
  margin-bottom: 3px;
`;

const TeamMemberRole = styled.div`
  display: block;
  font-size: 11px;
  font-weight: ${props => props.theme.fontWeights.medium};
  letter-spacing: 0.4px;
  text-transform: uppercase;
  color: ${props => props.theme.colors.blue};
`;

const EmptyView = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;

const TeamMemberEmpty = styled.div`
  font-size: 13px;
  color: ${props => props.theme.textColors.secondary};
`;

export default AdminSchedule;
