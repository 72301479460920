import { format, differenceInYears, parseISO, isSameDay } from 'date-fns';
import { MEDICATION_FREQUENCIES } from '../../constants';

export const formatName = (person, isPhysician) => {
  if (person) {
    let name = person.name
      ? `${person.name.given} ${person.name.family}`
      : person.firstName
      ? `${person.firstName} ${person.lastName}`
      : '';
    return isPhysician ? `Dr. ${name}` : name;
  }
  return '';
};

export const formatCareTeam = careteam => {
  // find and return LAST person on phone with patient
  const featured = careteam?.physician?.lastName
    ? careteam.physician
    : careteam?.careline;
  return formatName(featured, careteam?.physician?.lastName);
};

export const formatAddress = (address, multiline) => {
  if (!address) return '';

  const { line, city, state, postalCode } = address;
  if (!line[0]) return '';

  return `
    ${line[0]}
    ${line[1] ? `${multiline ? '<br>' : ' '}${line[1]}` : ''}
    ${multiline ? '<br>' : ''}
    ${city}, ${state} ${postalCode}
  `;
};

export const formatAddressInline = address => {
  if (!address) return '';

  const { line, street1, street2, city, state, zip, postalCode } = address;

  return `${line ? line[0] : street1}${
    line && line[1] ? line[1] : street2 ? ` ${street2}` : ''
  }, ${city}, ${state} ${postalCode || zip}`;
};

export const formatHospital = hospital => {
  if (!hospital) return 'No preferred Hospital listed.';

  const { name: hospitalName, address } = hospital;

  return `${hospitalName}<br>${formatAddress(address, true)}`;
};

export const formatPharmacy = pharmacy => {
  if (!pharmacy) return 'No preferred Pharmacy listed.';

  const { name: pharmacyName, address } = pharmacy;

  return `${pharmacyName}<br>${formatAddress(address, true)}`;
};

export const formatEMS = ems => {
  if (!ems) return 'No local EMS listed.';

  const { agency, primaryDispatch, dispatchNumber } = ems;

  return `${agency}<br>${primaryDispatch}<br>${formatToPhone(dispatchNumber)}`;
};

export const formatPayer = payer => {
  if (!payer) return 'No insurance listed.';

  return `${payer.primaryInsurance}<br>${`ID# ${payer.memberId}`}`;
};

export const formatDateRange = dates => {
  if (!dates) return '';

  const formatted = date => format(parseISO(date), 'M/d/yy');
  if (typeof dates === 'string') return formatted(dates);

  const sameDay =
    !dates.end || isSameDay(parseISO(dates.start), parseISO(dates.end));

  const appended = sameDay ? '' : ` - ${formatted(dates.end)}`;
  return `${formatted(dates.start)} ${appended}`;
};

export const formatToPhone = phone => {
  if (!phone) return '';

  const value = phone.replace(/[^0-9]/g, '');
  const len = value.length;
  if (len === 0) return value;
  else if (len === 3) return '(' + value.substring(0, 3) + ') ';
  else if (len < 3) return '(' + value;
  else if (len < 6)
    return '(' + value.substring(0, 3) + ') ' + value.substring(3);
  else if (len === 6)
    return '(' + value.substring(0, 3) + ') ' + value.substring(3, 6) + '-';
  else if (len > 6) {
    // trimmed value chops off +1 in some phone numbers
    const trimmedVal =
      len > 10 ? value.substring(value.length - 10, value.length) : value;
    return (
      '(' +
      trimmedVal.substring(0, 3) +
      ') ' +
      trimmedVal.substring(3, 6) +
      '-' +
      trimmedVal.substring(6, 10)
    );
  }
  return value;
};

export const formatCommaSeparatedList = items => items.join(', ');

export const formatDiagnoses = diagnoses => {
  if (!diagnoses) return '';

  return diagnoses
    .map(diagnosis => {
      return diagnosis.display;
    })
    .join(', ');
};

export const findObjectByProperty = (obj, key, value, returnKey) => {
  // find in master object where property === value (optionally return only key)
  if (!obj) return null;

  for (let prop in obj) {
    if (obj[prop][key] === value) return returnKey ? prop : obj[prop];
  }
};

export const findObjectInArray = (array, key, value) => {
  // 1. takes an array of objects
  // 2. finds matching object for key/value pair
  // 3. returns match
  if (!array) return null;

  let obj = array.find(o => o[key] === value);

  return obj;
};

export const findObjectIndexInArray = (array, key, value) => {
  // 1. takes an array of objects
  // 2. finds matching object for key/value pair
  // 3. returns index
  for (let i = 0; i < array.length; i += 1) {
    if (array[i][key] === value) {
      return i;
    }
  }
  return -1;
};

export const groupBy = key => {
  // returns a function that should be called using the data to group
  // example:
  //    const groupByCity = groupBy('city');
  //    const groupedData = groupByCity(data);
  //    console.log(groupedData)
  //    // expected return
  //    // [ 'akron': [...], 'canton': [...], 'cleveland': [...] ]
  return array => {
    return array.reduce((acc, obj) => {
      const property = obj[key];
      acc[property] = acc[property] || [];
      acc[property].push(obj);
      return acc;
    }, []);
  };
};

export const getAge = dob => {
  // calculate age from DOB
  const birthdate = typeof dob === 'object' ? dob : parseISO(dob);
  return dob ? differenceInYears(new Date(), birthdate) : 'n/a';
};

export const capitalize = str => {
  return str ? str.charAt(0).toUpperCase() + str.slice(1).toLowerCase() : str;
};

export const resolvePath = (object, path, defaultValue) =>
  path.split('.').reduce((o, p) => (o ? o[p] : defaultValue), object);

export const padDigit = num => ('0' + num).slice(-2);

const getTimeCounts = time => {
  const value = time ? Number(time).toFixed(1) : 0;
  const decimal = (value % 1).toFixed(1);
  const numSeconds = parseInt(value, 10);

  const hours = Math.floor(numSeconds / 3600);
  const minutes = Math.floor((numSeconds - hours * 3600) / 60);
  const seconds = numSeconds - hours * 3600 - minutes * 60;

  return { hours, minutes, seconds, decimal };
};

export const formatTimestamp = (time, omitDecimal) => {
  if (time === undefined) return null;

  const t = getTimeCounts(time);

  let timestamp = padDigit(t.minutes) + ':' + padDigit(t.seconds);
  if (t.hours > 0) timestamp = padDigit(t.hours) + ':' + timestamp;
  if (!omitDecimal) timestamp = timestamp + String(t.decimal).substring(1, 3);

  return timestamp;
};

export const convertToSeverity = value => {
  if (value > 7) return 'severe';
  else if (value > 3) return 'moderate';
  return 'mild';
};

export const convertToYesNo = bool => (bool ? 'Yes' : 'No');

export const formatBirthDate = birthDate => {
  if (!birthDate) return birthDate;
  const arr = birthDate.split('-');
  if (arr.length < 3) return birthDate;
  return `${arr[1]}/${arr[2]}/${arr[0]}`;
};

export const formatMedicationTiming = id =>
  MEDICATION_FREQUENCIES[id] ? MEDICATION_FREQUENCIES[id].label : id;

export const convertLineBreaks = str => str.replaceAll('\n', '<br />');

export const camelToReadable = camel => {
  // ex. firstName ==> First Name
  // insert a space before all caps
  return camel.replace(/([A-Z])/g, ' $1').replace(/^./, str => {
    // uppercase the first character
    return str.toUpperCase();
  });
};
