import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';
import Form from '../forms/Form';
import {
  ADD_CAREPLAN,
  UPDATE_CAREPLAN,
  DELETE_CAREPLAN,
} from '../../api/careplan';
import {
  CAREPLAN_ACTIONS,
  COMMS_FORM,
  MEDICATION_FORM,
  TASK_FORM,
  BASIC_FORM,
} from '../../../forms/careplan';
import Field from '../forms/Field';
import { FaRegCheckCircle, FaLock } from 'react-icons/fa';
import { darken, lighten } from 'polished';
import { SystemContext } from '../../contexts/SystemContext';
import { BRAVADO_AUTH_URL, BRAVADO_KEY } from '../../../constants';

const FORMS = {
  basic: {
    form: BASIC_FORM,
    initialData: {
      request: null,
    },
  },
  comms: {
    form: COMMS_FORM,
    initialData: {
      request: null,
    },
  },
  medication: {
    form: MEDICATION_FORM,
    initialData: {},
  },
  task: {
    form: TASK_FORM,
    initialData: {
      title: '',
      description: '',
      assignedTo: 'patient',
      date: null,
    },
  },
};

const CareplanModal = ({
  encounter,
  patient,
  condition,
  bravadoToken,
  data,
  mode,
  onDismiss,
}) => {
  const [authenticated, setAuthenticated] = useState(bravadoToken);
  const {
    state: { userId },
  } = useContext(SystemContext);

  const [type, setType] = useState(data && data.type ? data.type : 'task');

  const [deleteItem] = useMutation(DELETE_CAREPLAN, {
    onCompleted() {
      onDismiss();
    },
  });

  const onDelete = id => {
    deleteItem({ variables: { id } });
  };

  const openBravado = () => {
    if (authenticated) {
      onDismiss(true);
    } else {
      window.open(
        `${BRAVADO_AUTH_URL[process.env.REACT_APP_ENVIRONMENT]}/oauth/authorize?clientId=${BRAVADO_KEY[process.env.REACT_APP_ENVIRONMENT]}&responseType=authorizationCode&state=${userId}&scope=full`,
        '_blank',
      );
      setAuthenticated(true);
    }
  };

  return (
    <Container>
      <Header>
        <Title>{`${mode === 'new' ? 'Add' : 'Edit'} Care Plan Action`}</Title>
      </Header>
      <FormWrapper>
        <TypeSelector>
          <Field
            id="type"
            type="select"
            config={{ options: CAREPLAN_ACTIONS }}
            value={type}
            onChange={(id, val) => setType(val)}
          />
        </TypeSelector>

        {type !== 'medication' ? (
          <Form
            targetId={encounter}
            form={{
              mutation: mode === 'new' ? ADD_CAREPLAN : UPDATE_CAREPLAN,
              extraData: { patient, condition, type },
              ...FORMS[type].form,
            }}
            data={data || FORMS[type].initialData}
            onClose={() => onDismiss(false)}
            onDelete={mode !== 'new' ? () => onDelete(data._id) : null}
            stickyFooter={true}
          />
        ) : (
          <AuthWrapper>
            <PrescriberAuth authenticated={authenticated} onClick={openBravado}>
              {authenticated ? (
                <>
                  <FaRegCheckCircle />
                  <AuthNotice>
                    To begin prescribing medications, use the button below to
                    open Bravado Health's e-prescribing software.
                  </AuthNotice>
                  <AuthBtn>Open e-Prescribe Software</AuthBtn>
                </>
              ) : (
                <>
                  <FaLock />
                  <AuthNotice>
                    You must verify authentication before you can begin
                    prescribing medications.
                  </AuthNotice>
                  <AuthBtn>Verify Authentication</AuthBtn>
                </>
              )}
            </PrescriberAuth>
          </AuthWrapper>
        )}
      </FormWrapper>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  background-color: ${props => props.theme.backgroundColors.tertiary};
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
`;

const Header = styled(Row)`
  padding: 2rem 3rem 0;
`;

const Title = styled.h2`
  flex: 1;
  font-size: 22px;
  margin: 5px 0;
  color: ${props => props.theme.textColors.primary};
`;

const FormWrapper = styled.div`
  fieldset {
    margin-top: 8px;
    margin-bottom: 0;
    margin-left: 0;
  }
`;

const TypeSelector = styled.div`
  position: relative;
  padding: 20px 40px 0;
  margin-bottom: -20px;
`;

const AuthNotice = styled.p`
  color: ${props => props.theme.textColors.secondary};
  font-size: 14px;
  font-weight: ${props => props.theme.fontWeights.regular};
  line-height: 1.4;
  text-align: center;
  text-transform: none;
`;

const AuthBtn = styled.span`
  outline: none;
  background-color: ${props => props.theme.colors.blue};
  border-radius: 20px;
  font-size: 11px;
  font-weight: ${props => props.theme.fontWeights.bold};
  text-transform: uppercase;
  padding: 10px 16px;
  color: white;
  line-height: 1;
`;

const PrescriberAuth = styled.div`
  margin: 0.75em;
  background-color: white;
  border-radius: 10px;
  border: 1px solid ${props => props.theme.colors.border};
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;

  svg {
    color: ${props =>
      props.authenticated ? props.theme.colors.green : props.theme.colors.blue};
    font-size: ${props => (props.authenticated ? '36px' : '28px')};
  }

  &:hover {
    background-color: ${props =>
      lighten(0.1, props.theme.backgroundColors.secondary)};
    border-color: ${props => props.theme.colors.tertiary};

    ${AuthBtn} {
      background-color: ${props => darken(0.03, props.theme.colors.blue)};
    }
  }
`;

const AuthWrapper = styled.div`
  padding: 30px 40px 50px;
`;

export default CareplanModal;
