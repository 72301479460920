import {
  ADD_EMS,
  LINK_EMS,
  UNSET_EMS,
  ADD_HOSPITAL,
  LINK_HOSPITAL,
  UNSET_HOSPITAL,
  UPDATE_PAYER,
  ADD_PRACTITIONER,
  LINK_PRACTITIONER,
  UNSET_PRACTITIONER,
  ADD_PHARMACY,
  LINK_PHARMACY,
  UNSET_PHARMACY,
  UPDATE_CASE_MANAGER,
  ADD_SUPPLIER,
  LINK_SUPPLIER,
  UNSET_SUPPLIER,
  UPDATE_EMERGENCY_CONTACTS,
} from '../App/api/careteam';
import {
  encodeEmergencyContacts,
  encodePhysician,
  moveToRoot,
} from '../App/hooks/useFormHelper';
import { STATES } from '../constants';

export const HOSPITAL_FORM = {
  fields: [
    {
      id: 'hospital',
      type: 'lookup',
      config: {
        placeholder: 'Hospital Name, City',
      },
    },
  ],
  precursor: {
    // indicates fn should be run before final save to get _id
    mutation: ADD_HOSPITAL, // graphql fn
    returnVariable: 'upsertOneHospital', // returned object containing _id  [returnVariable]: { _id }
    saveVariable: 'hospital', // map _id to $[saveVariable] for graphQL final save
  },
  mutation: LINK_HOSPITAL, // final save fn
  unset: UNSET_HOSPITAL, // clear saved data
  encodeData: moveToRoot, // fn to format form data for graphql
  maxWidth: '640px',
};

export const DME_FORM = {
  fields: [
    {
      id: 'dme',
      type: 'lookup',
      config: {
        placeholder: 'Company Name, City',
      },
    },
  ],
  precursor: {
    // indicates fn should be run before final save to get _id
    mutation: ADD_SUPPLIER, // graphql fn
    returnVariable: 'upsertOneMedicalSupplier', // returned object containing _id  [returnVariable]: { _id }
    saveVariable: 'dme', // map _id to $[saveVariable] for graphQL final save
  },
  mutation: LINK_SUPPLIER, // final save fn
  unset: UNSET_SUPPLIER, // clear saved data
  encodeData: moveToRoot, // fn to format form data for graphql
  maxWidth: '640px',
};

export const PHARMACY_FORM = {
  fields: [
    {
      id: 'pharmacy',
      type: 'lookup',
      config: {
        placeholder: 'Pharmacy, City',
      },
    },
  ],
  precursor: {
    // indicates fn should be run before final save to get _id
    mutation: ADD_PHARMACY, // graphql fn
    returnVariable: 'upsertOnePharmacy', // returned object containing _id  [returnVariable]: { _id }
    saveVariable: 'pharmacy', // map _id to $[saveVariable] for graphQL final save
  },
  mutation: LINK_PHARMACY, // final save fn
  unset: UNSET_PHARMACY, // clear saved data
  encodeData: moveToRoot, // fn to format form data for graphql
};

export const PHYSICIAN_FORM = {
  fields: [
    {
      id: 'physician',
      type: 'physicianLookup',
      label: 'Look Up Physician Record',
      config: {
        placeholder: 'Last Name, First Name',
        fill: true,
        hideCard: true,
      },
    },
    {
      id: 'divider',
      type: 'divider',
      conditional: {
        property: 'npi',
        condition: 'exists',
      },
    },
    {
      id: 'name-row',
      type: 'row',
      fields: [
        {
          id: 'firstName',
          type: 'text-input',
          label: 'First Name',
          config: {
            fill: true,
          },
          conditional: {
            property: 'npi',
            condition: 'exists',
          },
        },
        {
          id: 'lastName',
          type: 'text-input',
          label: 'Last Name',
          config: {
            fill: true,
          },
          conditional: {
            property: 'npi',
            condition: 'exists',
          },
        },
        {
          id: 'displayName',
          type: 'text-input',
          label: 'Display Name',
          config: {
            fill: true,
          },
          conditional: {
            property: 'npi',
            condition: 'exists',
          },
        },
      ],
    },
    {
      id: 'contact-row',
      type: 'row',
      fields: [
        {
          id: 'phone',
          type: 'phone-input',
          label: 'Phone Number',
          conditional: {
            property: 'npi',
            condition: 'exists',
          },
        },
        {
          id: 'fax',
          type: 'phone-input',
          label: 'Fax Number',
          conditional: {
            property: 'npi',
            condition: 'exists',
          },
        },
      ],
    },
    {
      id: 'street1',
      type: 'text-input',
      label: 'Street',
      map: {
        type: 'array',
        id: 'line',
        num: 0,
      },
      conditional: {
        property: 'npi',
        condition: 'exists',
      },
    },
    {
      id: 'street2',
      type: 'text-input',
      label: 'Street 2',
      map: {
        type: 'array',
        id: 'line',
        num: 1,
      },
      conditional: {
        property: 'npi',
        condition: 'exists',
      },
    },
    {
      id: 'address-row',
      type: 'row',
      fields: [
        {
          id: 'city',
          type: 'text-input',
          label: 'City',
          config: {
            fill: true,
          },
          conditional: {
            property: 'npi',
            condition: 'exists',
          },
        },
        {
          id: 'state',
          type: 'select',
          config: {
            options: STATES,
            minWidth: 'auto',
          },
          label: 'State',
          conditional: {
            property: 'npi',
            condition: 'exists',
          },
        },
        {
          id: 'postalCode',
          type: 'text-input',
          label: 'Zip',
          config: {
            width: '100px',
          },
          conditional: {
            property: 'npi',
            condition: 'exists',
          },
        },
      ],
    },
  ],
  validation: {
    property: 'npi',
    condition: 'exists',
    message: 'A valid NPI # is required',
  },
  precursor: {
    // indicates fn should be run before final save to get _id
    mutation: ADD_PRACTITIONER, // graphql fn
    returnVariable: 'upsertOnePractitioner', // returned object containing _id  [returnVariable]: { _id }
    saveVariable: 'practitioner', // map _id to $[saveVariable] for graphQL final save
  },
  mutation: LINK_PRACTITIONER, // final save fn
  unset: UNSET_PRACTITIONER, // clear saved data
  encodeData: encodePhysician, // fn to format form data for graphql
};

export const CASE_MANAGER_FORM = {
  fields: [
    {
      id: 'name-row',
      type: 'row',
      fields: [
        {
          id: 'firstName',
          type: 'text-input',
          label: 'First Name',
          config: {
            fill: true,
          },
        },
        {
          id: 'lastName',
          type: 'text-input',
          label: 'Last Name',
          config: {
            fill: true,
          },
        },
      ],
    },
    {
      id: 'organization',
      type: 'text-input',
      label: 'Organization (optional)',
    },
    {
      id: 'contact-row',
      type: 'row',
      fields: [
        {
          id: 'phone',
          type: 'phone-input',
          label: 'Phone Number',
        },
        {
          id: 'fax',
          type: 'phone-input',
          label: 'Fax Number',
        },
        {
          id: 'email',
          type: 'email',
          label: 'Email',
          config: {
            fill: true,
          },
        },
      ],
    },
  ],
  mutation: UPDATE_CASE_MANAGER,
};

export const EMERGENCY_CONTACT_FORM = {
  fields: [
    {
      type: 'header',
      label: 'Primary Contact',
    },
    {
      id: 'primary_firstName',
      type: 'text-input',
      label: 'First Name',
    },
    {
      id: 'primary_lastName',
      type: 'text-input',
      label: 'Last Name',
    },
    {
      id: 'primary-contact-row',
      type: 'row',
      fields: [
        {
          id: 'primary_relationship',
          type: 'text-input',
          label: 'Relation to Patient',
          config: {
            fill: true,
          },
        },
        {
          id: 'primary_phoneNumber',
          type: 'phone-input',
          label: 'Phone Number',
        },
      ],
    },
    {
      type: 'header',
      label: 'Secondary Contact',
    },
    {
      id: 'secondary_firstName',
      type: 'text-input',
      label: 'First Name',
    },
    {
      id: 'secondary_lastName',
      type: 'text-input',
      label: 'Last Name',
    },
    {
      id: 'secondary-contact-row',
      type: 'row',
      fields: [
        {
          id: 'secondary_relationship',
          type: 'text-input',
          label: 'Relation to Patient',
          config: {
            fill: true,
          },
        },
        {
          id: 'secondary_phoneNumber',
          type: 'phone-input',
          label: 'Phone Number',
        },
      ],
    },
  ],
  encodeData: encodeEmergencyContacts,
  mutation: UPDATE_EMERGENCY_CONTACTS,
  maxWidth: '640px',
};

export const PAYER_FORM = {
  fields: [
    {
      id: 'primary-insurance-row',
      type: 'row',
      fields: [
        {
          id: 'primaryInsurance',
          type: 'select',
          config: {
            options: [
              'Buckeye',
              'CareSource',
              'Medicaid',
              'Medical Mutual',
              'MRD IO Waiver',
              'MRDD Level 1 Waiver',
              'Oscar',
              'Passport',
              'United Health Care',
              'VA',
              'Waiver (MITS)',
            ],
            fill: true,
          },
          label: 'Primary Insurance',
        },
        {
          id: 'memberId',
          type: 'text-input',
          label: 'Member ID',
          config: {
            fill: true,
          },
        },
      ],
    },
    {
      id: 'secondary-insurance-row',
      type: 'row',
      fields: [
        {
          id: 'groupName',
          type: 'text-input',
          label: 'Group Name',
          config: {
            fill: true,
          },
        },
        {
          id: 'groupId',
          type: 'text-input',
          label: 'Group ID',
          config: {
            fill: true,
          },
        },
        {
          id: 'relationship',
          type: 'select',
          config: {
            options: ['Self', 'Spouse', 'Unknown', 'Other'],
            fill: true,
          },
          label: 'Relationship to Self',
        },
      ],
    },
  ],
  mutation: UPDATE_PAYER,
  maxWidth: '640px',
};

export const EMS_FORM = {
  fields: [
    {
      id: 'agency',
      type: 'text-input',
      label: 'Agency',
    },
    {
      id: 'primary-row',
      type: 'row',
      fields: [
        {
          id: 'primaryDispatch',
          type: 'text-input',
          label: 'Primary Dispatch Name',
          config: {
            fill: true,
          },
        },
        {
          id: 'dispatchNumber',
          type: 'phone-input',
          label: 'Primary Phone Number',
        },
        {
          id: 'otherNumber',
          type: 'phone-input',
          label: 'Other Phone Number',
        },
      ],
    },
    {
      id: 'secondary-row',
      type: 'row',
      fields: [
        {
          id: 'secondaryDispatch',
          type: 'text-input',
          label: 'Secondary Dispatch Name',
          config: {
            fill: true,
          },
        },
        {
          id: 'secondaryDispatchNumber',
          type: 'phone-input',
          label: 'Secondary Phone Number',
        },
      ],
    },
    {
      id: 'notes',
      type: 'textarea',
      label: 'Notes',
    },
  ],
  mutation: ADD_EMS,
};

export const EMS_PATIENT_FORM = {
  fields: [
    {
      id: 'ems',
      type: 'emsLookup',
      label: 'Local EMS Provider',
      config: {
        placeholder: 'Find PSAP by Agency (i.e. Region)',
        fill: true,
        center: true,
      },
    },
  ],
  mutation: LINK_EMS,
  unset: UNSET_EMS,
  maxWidth: '640px',
};
