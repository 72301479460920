import React from 'react';
import styled, { keyframes } from 'styled-components';

const Modal = ({ show, maxWidth, fullHeight, borderRadius, children }) => (
  <Container visible={show}>
    <Dialog
      maxWidth={maxWidth}
      fullHeight={fullHeight}
      borderRadius={borderRadius}>
      {children}
    </Dialog>
  </Container>
);

const animIn = keyframes`
  0% { 
    transform: translateY(100px);
    opacity: 0;
  }
  100% { 
    transform: translateY(0);
    opacity: 1;
  }
`;

const Dialog = styled.div`
  display: block;
  position: relative;
  pointer-events: all;
  opacity: 0;
  animation-duration: 0.3s;
  animation-timing-function: ease-out;
  animation-delay: 0.2s;
  animation-fill-mode: forwards;
  box-shadow: 0 20px 50px 10px
    ${props => `rgba(${props.theme.textColors.primaryRGB},0.3)`};

  width: ${props => props.maxWidth || '100%'};
  border-radius: ${props => props.borderRadius || 0};
  max-height: 100%;
  /* overflow: hidden; */

  ${({ fullHeight }) =>
    fullHeight &&
    `
    height: 100%;
  `}
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  ${Dialog} {
    animation-name: ${props => (props.visible ? animIn : 'none')};
    transform: ${props =>
      props.visible ? 'translateY(100px)' : 'translateY(100%)'};
  }
`;

export default Modal;
