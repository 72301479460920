import React, {
  useEffect,
  useCallback,
  useContext,
  useState,
  useRef,
} from 'react';
import styled from 'styled-components';
import { PopoverContext } from '../../contexts/PopoverContext';

// hide it wayyyy off screen until set up
const DEFAULT_BOUNDS = { left: -9999, top: -9999, down: true };

const Popover = () => {
  const { state: popover, dispatch: dispatchPopover } = useContext(
    PopoverContext,
  );

  const target = useRef(null);
  const boxRef = useRef(null);
  const [bounds, setBounds] = useState(DEFAULT_BOUNDS);

  const onDismiss = useCallback(
    data => {
      if (target.current.onDismiss) target.current.onDismiss(data);

      dispatchPopover({ type: 'CLEAR' });
      boxRef.current = null;
      target.current = null;
      setBounds(DEFAULT_BOUNDS);
    },
    [dispatchPopover],
  );

  const calculateBounds = useCallback((pBounds, force) => {
    if (!boxRef.current) return;

    const popBounds = boxRef.current.getBoundingClientRect();

    const isLeftAligned = popBounds.width + pBounds.left < window.innerWidth;
    const isTopAligned = popBounds.height + pBounds.top < window.innerHeight;

    const forceRight = force?.right;

    setBounds({
      top: isTopAligned ? pBounds.bottom : pBounds.top - popBounds.height,
      left:
        isLeftAligned && !forceRight
          ? pBounds.left
          : pBounds.right - popBounds.width,
      down: isTopAligned,
    });
  }, []);

  useEffect(() => {
    if (popover) {
      target.current = popover;

      calculateBounds(popover.props.bounds, popover.props.force);

      // kill popover if browser is resized
      window.addEventListener('resize', () => onDismiss);
    } else {
      window.removeEventListener('resize', () => onDismiss);
    }

    return () => {
      window.removeEventListener('resize', () => onDismiss);
    };
  }, [popover, calculateBounds, onDismiss]);

  const Component = popover ? popover.component : null;

  return popover ? (
    <Container>
      <Cover onClick={() => onDismiss()} />

      <Box
        ref={el => {
          if (!boxRef.current) {
            boxRef.current = el;
            if (popover)
              calculateBounds(popover.props.bounds, popover.props.force);
          }
        }}
        {...bounds}>
        <Component
          onDismiss={onDismiss}
          isDown={bounds.down}
          {...popover.props}
        />
      </Box>
    </Container>
  ) : null;
};

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
`;

const Cover = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const Box = styled.div`
  position: absolute;
  top: ${props => `${Math.round(props.top)}px`};
  left: ${props => `${Math.round(props.left)}px`};
  z-index: 3;
`;

export default Popover;
