import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import parse from 'html-react-parser';
import SearchSelector from './SearchSelector';
import { formatAddress, formatToPhone } from '../../hooks/useTextHelper';
import { PatientContext } from '../../contexts/PatientContext';

// Wrapper for SearchSelector

const EMSLookupSelector = ({
  id,
  value,
  hideCard,
  onSelect,
  onAutosave,
  placeholder,
  disabled = false,
}) => {
  const {
    state: { patient },
  } = useContext(PatientContext);
  const [selected, setSelected] = useState(value);

  const onSelected = (id, record) => {
    setSelected(record);

    // send to parent (Form.js)
    onSelect(id, record._id);

    // if autosave, force it
    if (onAutosave) onAutosave(id, record._id, true);
  };

  return (
    <Container>
      {selected && !hideCard ? (
        <SelectedWrapper>
          <Name>{selected.agency}</Name>
          <Title>{selected.primaryDispatch}</Title>
          <Number>{formatToPhone(selected.dispatchNumber)}</Number>
        </SelectedWrapper>
      ) : (
        <SearchSelector
          id={id}
          value={selected}
          mode="ems"
          placeholder={placeholder}
          selectedDisplay={selected ? selected.agency : ''}
          onSelect={onSelected}
          disabled={disabled}
        />
      )}
      {patient && patient.address ? (
        <PatientAddress>
          <label>Patient Address</label>
          <Card>
            <p>{parse(formatAddress(patient.address.home, true))}</p>
          </Card>
        </PatientAddress>
      ) : null}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  text-align: center;
  width: 100%;
`;

const PatientAddress = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
  text-align: center;
`;

const Card = styled.div`
  border: 1px solid ${props => props.theme.colors.border};
  border-radius: 12px;
  padding: 20px 40px;
  margin-top: 4px;
  width: 100%;

  p {
    line-height: 1.6;
  }
`;

const SelectedWrapper = styled.div`
  position: relative;
  background-color: white;
  border: 1px solid ${props => props.theme.colors.border};
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${props => (props.withEdit ? '30px 30px 65px 30px' : '30px')};
  width: 100%;
`;

const Name = styled.h3`
  margin: 0;
`;

const Title = styled.h5`
  font-size: 14px;
  font-weight: ${props => props.theme.fontWeights.medium};
  color: ${props => props.theme.textColors.tertiary};
  margin: 6px 0;
`;

const Number = styled.label`
  color: ${props => props.theme.colors.blue};
  font-size: 12px;
  margin: 10px 0 15px;
`;

export default EMSLookupSelector;
