import gql from 'graphql-tag';

/**
 * QUERIES
 */

export const GET_CARE_TEAM = gql`
  query GET_CARE_TEAM($patient: String!) {
    patient(query: { userId: $patient }) {
      generalPractitioner {
        _id
        address {
          city
          line
          postalCode
          state
        }
        displayName
        firstName
        lastName
        npi
        fax
        phone
        type
      }
      pharmacy {
        _id
        address {
          city
          line
          postalCode
          state
        }
        name
        ncpdp
        phone
      }
      caseManager {
        firstName
        lastName
        phone
        email
        fax
        organization
      }
      hospital {
        _id
        address {
          city
          line
          postalCode
          state
        }
        name
        npi
        phone
      }
      medicalSupplier {
        _id
        address {
          city
          line
          postalCode
          state
        }
        name
        npi
        phone
      }
      emergencyContacts {
        firstName
        lastName
        phoneNumber
        relationship
      }
      payer {
        primaryInsurance
        memberId
        groupName
        groupId
        relationship
      }
      ems {
        _id
        agency
        primaryDispatch
        dispatchNumber
        secondaryDispatch
        secondaryDispatchNumber
        otherNumber
        notes
      }
    }
  }
`;

export const GET_PREFERRED_PROVIDERS = gql`
  query GET_PREFERRED_PROVIDERS($patient: String!) {
    patient(query: { userId: $patient }) {
      pharmacy {
        _id
        address {
          city
          line
          postalCode
          state
        }
        name
        ncpdp
        phone
      }
      medicalSupplier {
        _id
        address {
          city
          line
          postalCode
          state
        }
        name
        npi
        phone
      }
    }
  }
`;

export const GET_EMS_LIST = gql`
  query GET_EMS_LIST {
    emergencyServices {
      _id
      agency
      primaryDispatch
      dispatchNumber
      secondaryDispatch
      secondaryDispatchNumber
      otherNumber
      notes
    }
  }
`;

/**
 * MUTATIONS
 */

export const ADD_PRACTITIONER = gql`
  mutation ADD_PRACTITIONER(
    $address: PractitionerAddressInsertInput!
    $displayName: String!
    $firstName: String!
    $lastName: String!
    $npi: String!
    $phone: String
    $fax: String
    $type: String
  ) {
    upsertOnePractitioner(
      query: { npi: $npi }
      data: {
        address: $address
        displayName: $displayName
        firstName: $firstName
        lastName: $lastName
        npi: $npi
        phone: $phone
        fax: $fax
        type: $type
      }
    ) {
      _id
    }
  }
`;

export const LINK_PRACTITIONER = gql`
  mutation LINK_PRACTITIONER($practitioner: ObjectId!, $id: String!) {
    updateOnePatient(
      query: { userId: $id }
      set: { generalPractitioner: { link: $practitioner } }
    ) {
      _id
      generalPractitioner {
        npi
        firstName
        lastName
      }
    }
  }
`;

export const UNSET_PRACTITIONER = gql`
  mutation UNSET_PRACTITIONER($id: String!) {
    updateOnePatient(
      query: { userId: $id }
      set: { generalPractitioner_unset: true }
    ) {
      _id
    }
  }
`;

export const ADD_PHARMACY = gql`
  mutation ADD_PHARMACY(
    $address: PharmacyAddressInsertInput!
    $name: String!
    $ncpdp: String!
    $phone: String
  ) {
    upsertOnePharmacy(
      query: { ncpdp: $ncpdp }
      data: { address: $address, name: $name, ncpdp: $ncpdp, phone: $phone }
    ) {
      _id
    }
  }
`;

export const LINK_PHARMACY = gql`
  mutation LINK_PHARMACY($pharmacy: ObjectId!, $id: String!) {
    updateOnePatient(
      query: { userId: $id }
      set: { pharmacy: { link: $pharmacy } }
    ) {
      _id
      pharmacy {
        ncpdp
        name
      }
    }
  }
`;

export const UNSET_PHARMACY = gql`
  mutation UNSET_PHARMACY($id: String!) {
    updateOnePatient(query: { userId: $id }, set: { pharmacy_unset: true }) {
      _id
    }
  }
`;

export const UPDATE_CASE_MANAGER = gql`
  mutation UPDATE_CASE_MANAGER(
    $id: String!
    $firstName: String
    $lastName: String
    $phone: String
    $email: String
    $fax: String
    $organization: String
  ) {
    updateOnePatient(
      query: { userId: $id }
      set: {
        caseManager: {
          firstName: $firstName
          lastName: $lastName
          phone: $phone
          email: $email
          fax: $fax
          organization: $organization
        }
      }
    ) {
      _id
      caseManager {
        firstName
        lastName
        phone
        email
        fax
        organization
      }
    }
  }
`;

export const REMOVE_CASE_MANAGER = gql`
  mutation REMOVE_CASE_MANAGER($id: String!) {
    updateOnePatient(query: { userId: $id }, set: { caseManager_unset: true }) {
      _id
      caseManager {
        firstName
        lastName
        phone
        email
      }
    }
  }
`;

export const ADD_HOSPITAL = gql`
  mutation ADD_HOSPITAL(
    $address: HospitalAddressInsertInput!
    $name: String!
    $npi: String!
    $phone: String
  ) {
    upsertOneHospital(
      query: { npi: $npi }
      data: { address: $address, name: $name, npi: $npi, phone: $phone }
    ) {
      _id
    }
  }
`;

export const LINK_HOSPITAL = gql`
  mutation LINK_HOSPITAL($hospital: ObjectId!, $id: String!) {
    updateOnePatient(
      query: { userId: $id }
      set: { hospital: { link: $hospital } }
    ) {
      _id
      hospital {
        npi
        name
      }
    }
  }
`;

export const UNSET_HOSPITAL = gql`
  mutation UNSET_HOSPITAL($id: String!) {
    updateOnePatient(query: { userId: $id }, set: { hospital_unset: true }) {
      _id
    }
  }
`;

export const ADD_SUPPLIER = gql`
  mutation ADD_SUPPLIER(
    $address: MedicalSupplierAddressInsertInput!
    $name: String!
    $npi: String!
    $phone: String
  ) {
    upsertOneMedicalSupplier(
      query: { npi: $npi }
      data: { address: $address, name: $name, npi: $npi, phone: $phone }
    ) {
      _id
    }
  }
`;

export const LINK_SUPPLIER = gql`
  mutation LINK_SUPPLIER($dme: ObjectId!, $id: String!) {
    updateOnePatient(
      query: { userId: $id }
      set: { medicalSupplier: { link: $dme } }
    ) {
      userId
      medicalSupplier {
        npi
        name
      }
    }
  }
`;

export const UNSET_SUPPLIER = gql`
  mutation UNSET_SUPPLIER($id: String!) {
    updateOnePatient(
      query: { userId: $id }
      set: { medicalSupplier_unset: true }
    ) {
      _id
    }
  }
`;

export const UPDATE_EMERGENCY_CONTACTS = gql`
  mutation UPDATE_EMERGENCY_CONTACTS(
    $id: String!
    $emergencyContacts: [PatientEmergencyContactUpdateInput]
  ) {
    updateOnePatient(
      query: { userId: $id }
      set: { emergencyContacts: $emergencyContacts }
    ) {
      userId
      emergencyContacts {
        firstName
        lastName
        relationship
        phoneNumber
      }
    }
  }
`;

export const UPDATE_PAYER = gql`
  mutation UPDATE_PAYER(
    $id: String!
    $groupId: String
    $groupName: String
    $memberId: String
    $primaryInsurance: String
    $relationship: String
  ) {
    updateOnePatient(
      query: { userId: $id }
      set: {
        payer: {
          primaryInsurance: $primaryInsurance
          groupId: $groupId
          groupName: $groupName
          memberId: $memberId
          relationship: $relationship
        }
      }
    ) {
      userId
      payer {
        primaryInsurance
        memberId
        groupName
        groupId
        relationship
      }
    }
  }
`;

export const ADD_EMS = gql`
  mutation ADD_EMS(
    $agency: String!
    $primaryDispatch: String
    $dispatchNumber: String
    $secondaryDispatch: String
    $secondaryDispatchNumber: String
    $otherNumber: String
    $notes: String
    $id: ObjectId
  ) {
    upsertOneEmergencyService(
      query: { _id: $id }
      data: {
        agency: $agency
        primaryDispatch: $primaryDispatch
        dispatchNumber: $dispatchNumber
        secondaryDispatch: $secondaryDispatch
        secondaryDispatchNumber: $secondaryDispatchNumber
        otherNumber: $otherNumber
        notes: $notes
      }
    ) {
      _id
      agency
      primaryDispatch
      dispatchNumber
      otherNumber
    }
  }
`;

export const LINK_EMS = gql`
  mutation LINK_EMS($ems: ObjectId!, $id: String!) {
    updateOnePatient(query: { userId: $id }, set: { ems: { link: $ems } }) {
      userId
      ems {
        _id
        agency
        primaryDispatch
        dispatchNumber
      }
    }
  }
`;

export const UNSET_EMS = gql`
  mutation UNSET_EMS($id: String!) {
    updateOnePatient(query: { userId: $id }, set: { ems_unset: true }) {
      _id
    }
  }
`;
