import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { useApolloClient } from '@apollo/client';
import { MdAltRoute } from 'react-icons/md';
import CareplanIcon from '../common/CareplanIcon';
import { ADD_CAREPLAN_DIAGNOSIS } from '../../api/careplan';
import arrow from '../../../assets/images/down-arrow.png';
import { findObjectInArray } from '../../hooks/useTextHelper';
import { formatInitialData } from '../../hooks/useFormHelper';

const CareplanAssignDiagnosisModal = ({ data, onDismiss }) => {
  const isInitialMount = useRef(true);
  const client = useApolloClient();

  const [formData, setFormData] = useState();

  const onSelect = (condition, careplanItemId) => {
    setFormData({
      ...formData,
      [careplanItemId]: condition,
    });
  };

  const onSave = async () => {
    const updateItem = async (_id, condition) => {
      const cleanCondition = condition['__typename']
        ? formatInitialData(condition)
        : condition;
      return await client.mutate({
        mutation: ADD_CAREPLAN_DIAGNOSIS,
        variables: {
          _id,
          condition: cleanCondition,
        },
      });
    };

    // loop through each form element and save the condition object
    const promises = Object.keys(formData).map(async key => {
      const conditionObj = findObjectInArray(
        data.diagnosis,
        'clinicalCode',
        formData[key],
      );
      const promise = await updateItem(key, conditionObj);
      return promise;
    });

    // required for map loops to work with async/await
    await Promise.all(promises);

    onDismiss();
  };

  useEffect(() => {
    const { diagnosis, items } = data;

    const initializeFormData = () => {
      // parse initial formData into key/value pairs (_id: condition)
      let obj = {};
      for (let i = 0; i < items.length; i++) {
        obj[items[i]._id] = diagnosis[0].clinicalCode;
      }
      setFormData(obj);
    };

    if (isInitialMount.current) {
      isInitialMount.current = false;
      // content cannot be blank, if so, modal should never have been shown
      if (items && items.length > 0 && diagnosis && diagnosis.length > 0)
        initializeFormData();
      else onDismiss();
    }
  }, [data, onDismiss]);

  return (
    <Container>
      <Content>
        <Header>
          <HeaderIcon>
            <MdAltRoute />
          </HeaderIcon>
          <h2>Assign Careplan Items</h2>
        </Header>
        <Body>
          <p>
            The following items were detected but are not currently assigned to
            a diagnosis. Please assign them a condition below.
          </p>
          {formData
            ? data?.items.map(item => (
                <Item key={item._id}>
                  <CareplanIcon type={item.type} />
                  <TextWrapper>
                    <Title>{item.title}</Title>
                    {item.description ? (
                      <Description>{item.description}</Description>
                    ) : null}
                  </TextWrapper>

                  <select
                    value={formData[item._id]}
                    onChange={e => onSelect(e.target.value, item._id)}>
                    {data?.diagnosis.map(option => (
                      <option key={option.display} value={option.clinicalCode}>
                        {option.display}
                      </option>
                    ))}
                  </select>
                </Item>
              ))
            : null}
        </Body>
      </Content>
      <Footer>
        <SaveBtn onClick={onSave}>Assign Items</SaveBtn>
      </Footer>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  background-color: white;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Content = styled.div`
  padding: 30px 0;
  flex: 1;
  overflow-y: scroll;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 30px;

  h2 {
    flex: 1;
    font-size: 22px;
    margin: 0;
    color: ${props => props.theme.textColors.primary};
  }
`;

const HeaderIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: white;
  border: 2px solid ${props => props.theme.colors.border};
  margin-right: 20px;

  svg {
    font-size: 26px;
    color: ${props => props.theme.textColors.primary};
  }
`;

const Body = styled.div`
  padding: 10px 30px 0;

  p {
    font-size: 14px;
    line-height: 1.4;
  }
`;

const Item = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
  padding: 10px;

  background-color: white;
  border-radius: 10px;
  border: 1px solid ${props => props.theme.colors.border};

  &:last-of-type {
    margin-bottom: 0;
  }

  span {
    display: block;
    color: ${props => props.theme.textColors.primary};
  }

  select {
    border: 1px solid ${props => props.theme.colors.border};
    border-radius: 6px;
    padding: 10px 35px 10px 10px;
    font-size: 14px;
    outline: none;
    appearance: none;
    background: white url(${arrow}) 84% / 13% no-repeat;
    background-position-x: calc(100% - 12px);
    background-size: 12px;
    width: 250px;
    margin-left: 30px;
    text-overflow: ellipsis;

    &:disabled {
      opacity: 0.5;
    }

    &:focus {
      border-color: ${props => props.theme.colors.tertiary};
    }
  }

  option {
    font-family: ${props => props.theme.fonts.secondary};
    font-size: 16px;
    color: ${props => props.theme.textColors.primary};
  }
`;

const TextWrapper = styled.div`
  flex: 1;
  padding-top: 1px;

  p {
    line-height: 1.4;
  }
`;

const Title = styled.span`
  margin: 0 0 4px;
  font-weight: bold;
  font-size: 13px;
`;

const Description = styled.span`
  margin: 0;
  font-size: 12px;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: ${props => props.theme.backgroundColors.lightAlt};
  border-top: 1px solid ${props => props.theme.colors.border};
`;

const SaveBtn = styled.button`
  height: 42px;
  border-radius: 42px;
  font-size: 13px;
  color: white;
  background-color: ${props => props.theme.colors.primary};
  padding: 0 40px;
`;

export default CareplanAssignDiagnosisModal;
