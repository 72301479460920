import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import arrow from '../../../assets/images/down-arrow.png';

const Selector = ({
  id,
  value,
  fullWidth,
  options,
  onChange,
  onFocus,
  onBlur,
  disabled = false,
}) => {
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      const defaultValue = Array.isArray(options)
        ? options[0]
        : Object.keys(options)[0];
      const initialValue = value || defaultValue;
      onChange(initialValue);
    }
  }, [value, options, onChange]);

  return (
    <Select
      onChange={e => onChange(e.target.value)}
      value={value || options[0]}
      onFocus={onFocus ? e => onFocus(e.target.value) : null}
      onBlur={onBlur ? e => onBlur(id, e.target.value) : null}
      fullWidth={fullWidth}
      disabled={options.length === 0 || disabled}>
      {Array.isArray(options)
        ? options.map(option => <Option key={option}>{option}</Option>)
        : Object.keys(options).map(key => (
            <Option key={key} value={key} disabled={options[key].disabled}>
              {options[key].label}
            </Option>
          ))}
    </Select>
  );
};

const Select = styled.select`
  border: 1px solid ${props => props.theme.colors.border};
  border-radius: 10px;
  padding: 16px 50px 16px 16px;
  outline: none;
  appearance: none;
  background: white url(${arrow}) 84% / 13% no-repeat;
  background-position-x: calc(100% - 12px);
  background-size: 12px;
  width: ${props => (props.fullWidth ? '100%' : 'initial')};

  &:disabled {
    opacity: 0.5;
  }

  &:focus {
    border-color: ${props => props.theme.colors.tertiary};
  }
`;

const Option = styled.option`
  font-family: ${props => props.theme.fonts.secondary};
  font-size: 16px;
  color: ${props => props.theme.textColors.primary};
`;

export default Selector;
