import React from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import { GET_ENCOUNTER_ROLLUP } from '../../api/encounters';
import Loader from '../common/Loader';
import EncounterBox from './EncounterBox';
import EncounterHeader from './EncounterHeader';
import EncounterVitals from './EncounterVitals';
import EncounterObservations from './EncounterObservations';
import EncounterIntakeBox from './EncounterIntakeBox';
import EncounterSymptoms from './EncounterSymptoms';
import EncounterExams from './EncounterExams';
import EncounterCareplan from './EncounterCareplan';

const EncounterSummary = ({ encounterId, activeCall }) => {
  const { loading, data } = useQuery(GET_ENCOUNTER_ROLLUP, {
    variables: { encounter: encounterId },
  });

  const { careplans, careteam, encounter, exams, symptoms, vitals } =
    data || {};

  return (
    <Container id="EncounterContainer" fullFrame={activeCall}>
      {loading ? (
        <Loader transparent activity />
      ) : data ? (
        <Content id="EncounterContent">
          <EncounterHeader
            encounterId={encounterId}
            patientId={encounter?.patient}
            encounterAddress={encounter?.address}
            encounterPhone={encounter?.phone}
            period={encounter?.period}
            careline={careteam?.careline}
            physician={careteam?.physician}
            ems={encounter?.emsAction}
            ama={encounter?.ama}
          />
          <Body id="EncounterBody">
            <VitalsColumn id="EncounterVitalsColumn">
              <h2>Vitals</h2>
              <EncounterVitals vitals={vitals} exams={exams} />
              <h2>Observations</h2>
              <EncounterObservations exams={exams} />
            </VitalsColumn>

            <SummaryColumn id="EncounterSummaryColumn">
              <Section>
                <EncounterIntakeBox encounter={encounter} />
              </Section>

              <Section>
                <h2>Symptoms ({symptoms ? symptoms.length : 0})</h2>
                <EncounterSymptoms symptoms={symptoms} />
              </Section>

              <Section>
                <h2>Exams ({exams.length > 0 ? exams.length - 1 : 0})</h2>
                <EncounterExams exams={exams} />
              </Section>

              {encounter?.notes ? (
                <Section>
                  <h2>Physician Notes</h2>
                  <EncounterBox>
                    <PhysicianNotes>{encounter.notes}</PhysicianNotes>
                  </EncounterBox>
                </Section>
              ) : null}
            </SummaryColumn>

            <DiagnosesColumn id="EncounterDiagnosesColumn">
              <h2>Diagnosis & Care Plan</h2>
              <EncounterCareplan
                careplans={careplans}
                diagnosis={encounter?.diagnosis}
                pharmacy={encounter?.pharmacy}
                activeCall={activeCall}
              />
            </DiagnosesColumn>
          </Body>
        </Content>
      ) : (
        <Empty>
          Error: No data could be retrieved for this encounter ({encounterId})
        </Empty>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  background-color: ${props => props.theme.backgroundColors.lightAlt};
  padding: 40px;
  width: 100%;
  height: ${props => (props.fullFrame ? '100vh' : '82vh')};
  overflow-y: scroll;
  flex: 1;
`;

const Content = styled.div`
  position: relative;
  z-index: 2;
  padding-bottom: 60px;
`;

const Empty = styled.div`
  font-size: 15px;
  color: ${props => props.theme.textColors.primary};
`;

const Body = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  padding-left: 300px;
  padding-right: 5px;
  margin-top: 30px;

  h1 {
    font-size: 22px;
    font-weight: 500;
    margin: 0 0 16px;
  }

  h2 {
    font-size: 18px;
    font-weight: 500;
    margin: 0 0 16px;
  }
`;

const VitalsColumn = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  width: 220px;
`;

const SummaryColumn = styled.div`
  flex: 1;
  padding-right: 50px;
`;

const DiagnosesColumn = styled.div`
  width: 260px;
  padding-top: 10px;
`;

const Section = styled.section`
  margin-bottom: 30px;
`;

const PhysicianNotes = styled.p`
  font-size: 14px !important;
  line-height: 1.5;
  margin: 0;
`;

export default EncounterSummary;
