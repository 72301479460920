import React, { useContext } from 'react';
import styled from 'styled-components';
import { lighten } from 'polished';
import EncounterSummary from '../encounter/EncounterSummary';
import { EncounterContext } from '../../contexts/EncounterContext';
import useAmplitude from '../../hooks/useAmplitude';

const EncounterManager = () => {
  const {
    state: { encounterId, activeCall, onClose },
    dispatch: dispatchEncounter,
  } = useContext(EncounterContext);
  const { trackCallDetails } = useAmplitude();

  const onDismiss = () => {
    // clear in context
    dispatchEncounter({ type: 'CLEAR' });

    // if onClose function was passed in, call it
    if (onClose) onClose();
  };

  const exitCall = () => {
    // record final analytics and get out
    trackCallDetails();
    window.location.href = '/';
  };

  return (
    <Container id="EncounterManager" show={encounterId}>
      {encounterId ? (
        <>
          <Cover id="EncounterCover" onClick={() => onDismiss()} />
          <Shadow>
            <EncounterWrapper id="EncounterWrapper">
              <EncounterSummary encounterId={encounterId} />

              {activeCall ? (
                <Footer id="EncounterFooter">
                  <FooterBtn onClick={exitCall}>Submit Encounter</FooterBtn>
                  <FooterBtn inverse onClick={onDismiss}>
                    Make Changes
                  </FooterBtn>
                </Footer>
              ) : null}

              <Abstract id="abstractArtwork">
                <Circle />
                <BottomGradient />
              </Abstract>
            </EncounterWrapper>
          </Shadow>
        </>
      ) : null}
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: ${props => (props.show ? 9999 : -1)};
  pointer-events: ${props => (props.show ? 'all' : 'none')};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 9vh;
`;

const Cover = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #aeb1bb;
  transition: opacity 0.2s ease-in;
  opacity: 0.8;
`;

const Shadow = styled.div`
  width: 100%;
  height: 100%;
  box-shadow: 0 5px 35px
    rgba(${props => props.theme.textColors.primaryRGB}, 0.8);
  border: 1px solid ${props => props.theme.colors.border};
`;

const EncounterWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const Abstract = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  overflow: hidden;
  pointer-events: none;
`;

const Circle = styled.span`
  align-self: center;
  justify-self: center;
  height: 120vh;
  width: 120vh;
  border-radius: 50%;
  background-color: white;
  box-shadow: inset 0 0 0 60px ${props => props.theme.backgroundColors.lightAlt};
  border: 20px solid white;
  opacity: 0.4;
`;

const BottomGradient = styled.span`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%) translateY(50%);
  background: radial-gradient(
    circle,
    rgba(226, 228, 240, 0.75) 0%,
    rgba(226, 228, 240, 0) 50%
  );

  width: 140vh;
  height: 90vh;
`;

const Footer = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: white;
  padding: 20px;
  border-top: 1px solid ${props => props.theme.colors.border};
`;

const FooterBtn = styled.button`
  height: 48px;
  width: 180px;
  border-radius: 48px;
  background-color: ${props =>
    props.inverse ? 'white' : props.theme.colors.blue};
  color: ${props => (props.inverse ? props.theme.textColors.primary : 'white')};
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  padding: 0 20px;
  margin: 0 10px;
  border: 1px solid;
  border-color: ${props =>
    props.inverse ? props.theme.colors.border : 'transparent'};

  &:hover {
    background-color: ${props =>
      props.inverse
        ? props.theme.backgroundColors.lightAlt
        : lighten(0.05, props.theme.colors.blue)};
  }
`;

export default EncounterManager;
