import React from 'react';
import styled from 'styled-components';
import { FaTimes } from 'react-icons/fa';
import Form from '../forms/Form';

const FormModal = ({ data, config, onDismiss }) => {
  const {
    targetId,
    form,
    formHeader,
    title,
    addTitle = '',
    editTitle = '',
    mode,
  } = config;

  return (
    <Container>
      <Header>
        <Title>
          {!mode || title ? title : mode === 'edit' ? editTitle : addTitle}
        </Title>
        <CloseBtn onClick={onDismiss}>
          <FaTimes />
        </CloseBtn>
      </Header>
      <FormWrapper>
        <Form
          targetId={targetId}
          form={form}
          headerConfig={formHeader}
          data={data}
          onClose={onDismiss}
          stickyFooter
          inModal
        />
      </FormWrapper>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  height: 100%;
  overflow: hidden;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 20px 20px 30px;
  border-bottom: 1px solid ${props => props.theme.colors.border};
`;

const Title = styled.h1`
  margin: 0;
`;

const CloseBtn = styled.button`
  outline: none;
  border: none;
  cursor: pointer;
  margin-left: auto;
  background: transparent;

  svg {
    font-size: 24px;
    color: ${props => props.theme.textColors.secondary};
  }

  &:hover {
    svg {
      color: ${props => props.theme.textColors.primary};
    }
  }
`;

const FormWrapper = styled.div`
  padding: 0;
  flex: 1;
  overflow-y: scroll;
`;

export default FormModal;
