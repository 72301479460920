import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import { FaMedkit, FaPen } from 'react-icons/fa';
import { darken } from 'polished';
import parse from 'html-react-parser';
import ActivityIndicator from '../common/ActivityIndicator';
import EditModal from '../modals/EditModal';
import { GET_PREFERRED_PROVIDERS } from '../../api/careteam';
import { DME_FORM } from '../../../forms/careteam';
import { formatHospital } from '../../hooks/useTextHelper';

const CONFIG = {
  medicalSupplier: {
    form: DME_FORM,
    formHeader: {
      title: 'Preferred Medical Supplier',
      icon: <FaMedkit />,
      label: 'Care Team',
    },
  },
};

const PreferredProvider = ({ mode, patient }) => {
  const { loading, data, error, refetch } = useQuery(GET_PREFERRED_PROVIDERS, {
    variables: { patient },
  });

  const provider = useMemo(
    () => (data && data.patient ? data.patient[mode] : null),
    [data],
  );
  const summary = useMemo(
    () => (mode === 'medicalSupplier' ? formatHospital(provider) : null),
    [mode, provider],
  );
  const fieldLabel = useMemo(
    () => (mode === 'medicalSupplier' ? 'Medical Supplier' : mode),
    [mode],
  );
  const formKey = useMemo(() => (mode === 'medicalSupplier' ? 'dme' : mode), [
    mode,
  ]);

  const [showForm, setShowForm] = useState();

  const onFormClose = () => {
    setShowForm(false);
    refetch();
  };

  return (
    <Wrapper>
      <label>{fieldLabel}</label>
      <Container>
        {loading ? (
          <ActivityIndicator size={16} />
        ) : error ? (
          <Error>{error}</Error>
        ) : provider ? (
          <Summary>
            <SummaryTitle>{parse(summary)}</SummaryTitle>
            <EditBtn onClick={() => setShowForm(true)}>
              <FaPen />
            </EditBtn>
          </Summary>
        ) : (
          <Empty>
            <p>{`No preferred ${fieldLabel} set`}</p>
            <LinkBtn onClick={() => setShowForm(true)}>
              Set Preferred Provider
            </LinkBtn>
          </Empty>
        )}
      </Container>

      {showForm ? (
        <EditModal
          data={{ [formKey]: provider }}
          config={{ ...CONFIG[mode], targetId: patient }}
          inModal
          onClose={onFormClose}
        />
      ) : null}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: 6px 14px 20px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px;

  background-color: ${props => props.theme.backgroundColors.lightAlt};
  border-radius: 10px;
  min-height: 108px;
`;

const Error = styled.div``;

const Summary = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const SummaryTitle = styled.div`
  font-family: ${props => props.theme.fonts.secondary};
  font-size: 13px;
  line-height: 1.5;
  text-align: center;
  color: ${props => props.theme.textColors.tertiary};
  margin: 0;
  flex: 1;

  strong {
    display: inline-block;
    font-weight: ${props => props.theme.fontWeights.regular};
    background-color: ${props => props.theme.backgroundColors.tertiary};
    border: 1px solid;
    border-color: ${props =>
      darken(0.02, props.theme.backgroundColors.tertiary)};
    padding: 3px 10px;
    border-radius: 15px;
    font-size: 11px;
    color: ${props => props.theme.textColors.secondary};
    letter-spacing: 1px;
    margin-top: 2px;
  }
`;

const Empty = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const LinkBtn = styled.button`
  color: ${props => props.theme.colors.blue};
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  margin-bottom: 10px;
`;

const EditBtn = styled.button`
  margin-left: auto;
  display: flex;
  border-radius: 50%;
  width: 42px;
  height: 42px;
  background-color: white;
  border: 2px solid ${props => props.theme.colors.border};

  svg {
    font-size: 16px;
    color: ${props => props.theme.textColors.secondary};
    margin: auto;
  }

  &:hover {
    border-color: ${props => props.theme.colors.blue};

    svg {
      color: ${props => props.theme.colors.blue};
    }
  }
`;

export default PreferredProvider;
