// @flow
import React, { useReducer, createContext } from 'react';

const INITIAL_STATE = {
  encounterId: null,
  activeCall: false,
  onClose: null,
};

export const EncounterContext = createContext(INITIAL_STATE);

/**
 * Encounter Context.
 *
 * Creates an object that's received and displayed by <EncounterManager>
 *
 * Encounter object structure:
 * @param {String}    encounterId           encounter id
 * @param {Boolean}   activeCall            object of props for the component
 * @param {Function}  onClose               optional: function to run on dismiss button
 *
 */

const Context = ({ children }) => {
  const generateRandomID = () =>
    Math.random()
      .toString(36)
      .substring(2, 9);

  const reducer = (state, { type, data }) => {
    switch (type) {
      case 'VIEW_ENCOUNTER': {
        data.id = generateRandomID();
        return {
          ...state,
          ...data,
        };
      }
      case 'CLEAR': {
        return INITIAL_STATE;
      }
      default: {
        return state;
      }
    }
  };

  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <EncounterContext.Provider value={{ state, dispatch }}>
      {children}
    </EncounterContext.Provider>
  );
};

export default Context;
