import React, { useState } from 'react';
import styled from 'styled-components';
import { FaBookMedical } from 'react-icons/fa';
import { capitalize, formatToPhone } from '../../../hooks/useTextHelper';

// info: a configuration for an info card, a specially designed descriptor card
// summary: a configuration for a summary card

const Contact = ({ person, count }) => (
  <Person>
    <Count>{count}</Count>
    <PersonContent>
      {person ? (
        <>
          <Name>{`${person.firstName} ${person.lastName}`}</Name>
          <Relation>{capitalize(person.relationship)}</Relation>
          <Phone>{formatToPhone(person.phoneNumber)}</Phone>
        </>
      ) : (
        <EmptyDash />
      )}
    </PersonContent>
  </Person>
);

const EmergencyContactCard = ({ data }) => (
  <Row>
    <SummaryColumn>
      <FaBookMedical />
      <SummaryTitle>Emergency Contacts</SummaryTitle>
    </SummaryColumn>
    <Column>
      <Contact person={data ? data[0] : null} count={1} />
    </Column>
    <Column>
      <Contact person={data ? data[1] : null} count={2} />
    </Column>
  </Row>
);

const Row = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 100%;
`;

const SummaryColumn = styled(Column)`
  flex: auto;
  svg {
    font-size: 36px;
    color: ${props => props.theme.colors.blue};
  }
`;

const SummaryTitle = styled.h3`
  font-size: 18px;
  font-family: ${props => props.theme.fonts.primary};
  text-align: center;
  margin: 15px 0 10px;
`;

const Person = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
`;

const Count = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 15px;
  font-weight: ${props => props.theme.fontWeights.bold};
  background-color: ${props => props.theme.colors.blue};
  width: 24px;
  height: 24px;
  border-radius: 50%;
`;

const Name = styled.p`
  font-size: 17px;
  color: ${props => props.theme.textColors.primary};
  font-weight: ${props => props.theme.fontWeights.bold};
  margin-top: 0;
  margin-bottom: 5px;
`;

const Relation = styled.p`
  font-size: 13px;
  color: ${props => props.theme.textColors.tertiary};
  font-weight: ${props => props.theme.fontWeights.normal};
  margin: 0;
`;

const Phone = styled.p`
  font-size: 14px;
  color: ${props => props.theme.textColors.secondary};
  margin-bottom: 0;
`;

const EmptyDash = styled.span`
  display: block;
  width: 20px;
  height: 4px;
  border-radius: 10px;
  background-color: ${props => props.theme.backgroundColors.secondary};
  margin: 32px 0;
`;

const PersonContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 15px;
  width: 100%;
`;

export default EmergencyContactCard;
