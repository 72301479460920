import React from 'react';
import styled from 'styled-components';
import { lighten } from 'polished';
import WidgetTabs from '../common/WidgetTabs';
import Table from './Table';
import Loader from '../common/Loader';
import ActivityIndicator from '../common/ActivityIndicator';

const TableWidget = ({
  title,
  onEdit,
  loading,
  data,
  config,
  emptyNotice,
  activeTab,
  tabs,
  onTabChange,
}) => (
  <Wrapper>
    <Header>
      {title ? <Title>{title}</Title> : null}
      {/* {onEdit ? <EditBtn onClick={onEdit}>Edit</EditBtn> : null} */}
    </Header>
    {tabs ? (
      <WidgetTabs
        config={tabs}
        activeTab={activeTab}
        center
        onTabClick={onTabChange}
      />
    ) : null}

    <TableWrapper>
      {loading ? (
        <LoadingWrapper>
          <ActivityIndicator color="secondary" />
        </LoadingWrapper>
      ) : (
        <Table
          key={activeTab}
          data={data}
          config={config}
          emptyNotice={emptyNotice}
          widget
        />
      )}
    </TableWrapper>
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 30px 25px 5px;
`;

const Title = styled.h3`
  margin: 0;
  font-weight: ${props => props.theme.fontWeights.medium};
  font-size: 18px;
`;

const EditBtn = styled.button`
  background: none;
  border: 0;
  outline: 0;
  cursor: pointer;

  font-size: 15px;
  font-weight: ${props => props.theme.fontWeights.medium};
  color: ${props => props.theme.colors.blue};
  margin-left: auto;

  &:hover {
    color: ${props => lighten(0.1, props.theme.colors.blue)};
  }
`;

const TableWrapper = styled.div`
  flex: 1;
  width: 100%;
  padding: 10px 0 0;
  overflow: hidden;
`;

const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;

  width: 100%;
  height: 100%;
`;

export default TableWidget;
