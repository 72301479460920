import React, { useReducer } from 'react';
import { ThemeProvider } from 'styled-components';
import { lighten } from 'polished';
import { DateRangeInput } from '@datepicker-react/styled';
import { subMonths } from 'date-fns';

const TODAY = new Date();
const LAST_MONTH = subMonths(new Date(), 1);

const reducer = (state, action) => {
  switch (action.type) {
    case 'focusChange':
      return { ...state, focusedInput: action.payload };
    case 'dateChange':
      return action.payload;
    default:
      throw new Error();
  }
};

const DateRange = ({ id, value, onChange, onAutosave, disabled = false }) => {
  const initialState = {
    startDate: value ? new Date(value.start) : null,
    endDate: value ? new Date(value.end) : null,
    focusedInput: null,
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const onDatesChange = data => {
    if (disabled) return;

    dispatch({ type: 'dateChange', payload: data });

    const newState = {
      start: data.startDate,
      end: data.endDate || data.startDate,
    };
    onChange(newState);

    if (onAutosave) onAutosave(id, newState, true);
  };

  return (
    <ThemeProvider
      theme={{
        breakpoints: ['32em', '48em', '64em'],
        reactDatepicker: {
          fontFamily: 'Roboto, system-ui, -apple-system',
          navButtonBorder: '1px solid #EAE8E4',
          inputColor: '#5B5F73',
          inputLabelBorder: '1px solid #EAE8E4',
          inputLabelBorderRadius: '10px',
          dateRangeZIndex: 10,
          colors: {
            selectedDay: lighten(0.1, '#57B3FA'),
            selectedDayHover: lighten(0.04, '#57B3FA'),
            primaryColor: '#57B3FA',
          },
        },
      }}>
      <DateRangeInput
        onDatesChange={onDatesChange}
        onFocusChange={focusedInput =>
          dispatch({ type: 'focusChange', payload: focusedInput })
        }
        startDate={state.startDate} // Date or null
        endDate={state.endDate} // Date or null
        focusedInput={state.focusedInput} // 'startDate', 'endDate' or null
        initialVisibleMonth={LAST_MONTH}
        maxBookingDate={TODAY}
        showResetDates={false}
        showSelectedDates={false}
        showClose={false}
        disabled={disabled}
      />
    </ThemeProvider>
  );
};

export default DateRange;
