import React from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';
import Form from '../forms/Form';
import {
  ADD_SYMPTOM,
  UPDATE_SYMPTOM,
  DELETE_SYMPTOM,
} from '../../api/symptoms';
import { SYMPTOM_FORM } from '../../../forms/symptoms';

const initialState = {
  symptom: null,
  severity: null,
  location: null,
  onset: null,
};

const SymptomModal = ({ encounter, patient, data, mode, onDismiss }) => {
  const [deleteSymptom] = useMutation(DELETE_SYMPTOM, {
    onCompleted() {
      onDismiss();
    },
  });

  const onDelete = id => {
    deleteSymptom({ variables: { id } });
  };

  return (
    <Container>
      <Header>
        <Title>{`${mode === 'new' ? 'New' : 'Edit'} Symptom`}</Title>
      </Header>
      <FormWrapper>
        <Form
          targetId={encounter}
          form={{
            mutation: mode === 'new' ? ADD_SYMPTOM : UPDATE_SYMPTOM,
            extraData: { patient },
            ...SYMPTOM_FORM,
          }}
          data={data || initialState}
          onClose={onDismiss}
          onDelete={mode !== 'new' ? () => onDelete(data._id) : null}
          stickyFooter={true}
          inModal={true}
        />
      </FormWrapper>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  background-color: ${props => props.theme.backgroundColors.tertiary};
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
`;

const Header = styled(Row)`
  padding: 2rem 3rem 0;
`;

const Title = styled.h2`
  flex: 1;
  font-size: 22px;
  margin: 5px 0;
  color: ${props => props.theme.textColors.primary};
`;

const FormWrapper = styled.div`
  fieldset {
    margin-top: 8px;
    margin-bottom: 0;
  }
`;

export default SymptomModal;
