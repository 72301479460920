import React, { useContext, useCallback, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import * as Realm from 'realm-web';
import styled from 'styled-components';
import { SystemContext } from '../contexts/SystemContext';
import { APP_ID } from '../../constants';

const LogoutPage = () => {
  const isInitialMount = useRef(true);

  const { dispatch: dispatchSystem } = useContext(SystemContext);
  const history = useHistory();

  const logout = useCallback(async () => {
    dispatchSystem({
      type: 'SET_SYSTEM',
      data: {
        userId: null,
        realmUser: null,
        twoFactor: false,
        pusher: null,
      },
    });

    try {
      const app = await new Realm.App({
        id: APP_ID[process.env.REACT_APP_ENVIRONMENT],
      });
      await app.currentUser.functions.set2faStatus(false);
      sessionStorage.clear();
      await app.currentUser.logOut();
    } catch (error) {
      console.log('issue with realm logout: ', error);
    }

    history.replace('/login');
  }, [history, dispatchSystem]);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      logout();
    }
  }, [logout]);

  return <Container />;
};

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #f8f8f8;
`;

export default LogoutPage;
