import React from 'react';
import styled from 'styled-components';

const WidgetTabs = ({ config, activeTab, onTabClick }) => (
  <Wrapper>
    <TabBar>
      {config.map(tab => (
        <Tab
          key={tab.id}
          active={activeTab === tab.id}
          onClick={() => onTabClick(tab.id)}>
          {tab.label}
        </Tab>
      ))}
    </TabBar>
  </Wrapper>
);

const Tab = styled.button`
  position: relative;
  outline: 0;
  border: 0;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.2px;
  padding: 10px 0;
  min-width: 120px;
  font-weight: ${props => props.theme.fontWeights.bold};
  color: ${props =>
    props.active
      ? props.theme.textColors.primary
      : props.theme.textColors.tertiary};
  cursor: pointer;
  background-color: ${props => (props.active ? 'white' : 'transparent')};
  border-radius: 50px;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 20px 0 10px;
`;

const TabBar = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${props => (props.center ? 'center' : 'flex-start')};
  background-color: ${props => props.theme.backgroundColors.secondary};
  border: 2px solid ${props => props.theme.backgroundColors.secondary};
  border-radius: 50px;
`;

export default WidgetTabs;
