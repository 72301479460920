import React from 'react';
import styled from 'styled-components';
import { darken, lighten } from 'polished';

const EndScreenConfirmBtn = ({
  inverse,
  btnWidth,
  disabled,
  onClick,
  children,
}) => (
  <Button
    inverse={inverse}
    btnWidth={btnWidth}
    disabled={disabled}
    onClick={onClick}>
    {children}
  </Button>
);

const Button = styled.button`
  display: block;
  border-radius: 27px;
  height: 54px;
  width: ${props => props.btnWidth || '100%'};
  outline: none;
  background: ${props =>
    props.inverse
      ? props.theme.backgroundColors.tertiary
      : props.theme.colors.blue};
  border: none;
  color: ${props => (props.inverse ? props.theme.textColors.primary : 'white')};
  text-transform: uppercase;
  font-weight: ${props => props.theme.fontWeights.medium};
  margin-bottom: 20px;
  cursor: pointer;
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  align-self: center;

  &:hover {
    background-color: ${props =>
      props.inverse
        ? darken(0.01, props.theme.backgroundColors.tertiary)
        : lighten(0.05, props.theme.colors.blue)};
  }
`;

export default EndScreenConfirmBtn;
