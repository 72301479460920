import React, { useState } from 'react';
import styled from 'styled-components';
import { FaTimes } from 'react-icons/fa';
import { formatDistance, parseISO } from 'date-fns';
import { useMutation } from '@apollo/client';
import { lighten } from 'polished';
import Field from '../forms/Field';
import { DISCONNECT_REASON } from '../../api/rooms';
import { DISCONNECT_OPTIONS } from '../../../constants';
import UserIcon from '../common/UserIcon';

const MissedCallModal = ({ call, onDismiss }) => {
  const { patient, date, room } = call;
  const [resolved, setResolved] = useState();
  const [reason, setReason] = useState();
  const [notes, setNotes] = useState();

  const [disconnectReason] = useMutation(DISCONNECT_REASON, {
    onCompleted() {
      onDismiss();
    },
  });

  const submitForm = () => {
    // record option selected
    try {
      disconnectReason({ variables: { id: room, reason, notes } });
    } catch (err) {
      console.log('Error with Disconnect Reason: ', err);
    }
  };

  return (
    <Container>
      <CloseBtn onClick={() => onDismiss()}>
        <FaTimes />
      </CloseBtn>
      <PageWrapper>
        {resolved ? (
          <ResolvedPage>
            <Title>What happened?</Title>
            <OptionsGrid>
              {DISCONNECT_OPTIONS.map(option => (
                <OptionBtn
                  key={option.id}
                  active={reason === option.id}
                  onClick={() => setReason(option.id)}>
                  {option.label}
                </OptionBtn>
              ))}
            </OptionsGrid>
            <Notes>
              <Field
                id="notes"
                type="textarea"
                label="Notes"
                value={notes}
                onChange={(id, value) => setNotes(value)}
                config={{
                  resize: 'none',
                }}
              />
            </Notes>
            <Footer>
              <SaveBtn onClick={submitForm} disabled={!reason}>
                Save &amp; Update Record
              </SaveBtn>
            </Footer>
          </ResolvedPage>
        ) : (
          <Page>
            <Header>
              <UserIcon user={patient} size="80px" patient />
              <Name>{`${patient.name.given} ${patient.name.family}`}</Name>
              <MissedCallDate>{`Missed call ${formatDistance(
                parseISO(date),
                new Date(),
                { addSuffix: true },
              )}`}</MissedCallDate>
              <PhoneLabel>Call Patient Back</PhoneLabel>
              <Phone>
                {patient.telecom
                  ? patient.telecom.home
                  : 'Phone number not collected'}
              </Phone>
            </Header>
            <Footer>
              <OptionBtn onClick={() => setResolved(true)}>
                Mark as Resolved
              </OptionBtn>
            </Footer>
          </Page>
        )}
      </PageWrapper>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: white;
`;

const PageWrapper = styled.div``;

const Page = styled.div``;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 30px 30px;
`;

const Name = styled.p`
  font-size: 16px;
  font-weight: ${props => props.theme.fontWeights.medium};
  color: ${props => props.theme.textColors.primary};
  text-align: center;
  margin: 10px 0 0;
`;

const CloseBtn = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  outline: none;
  border: none;
  margin-left: auto;
  background: transparent;
  width: 40px;
  height: 40px;
  cursor: pointer;

  svg {
    font-size: 20px;
    color: ${props => props.theme.textColors.secondary};
  }

  &:hover {
    svg {
      color: ${props => props.theme.textColors.primary};
    }
  }
`;

const Title = styled.h2`
  margin: 0;
  padding-bottom: 20px;
`;

const OptionsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  gap: 10px;
  padding-top: 20px;
`;

const OptionBtn = styled.button`
  outline: none;
  background-color: white;
  border: 1px solid ${props => props.theme.colors.border};
  border-radius: 24px;
  color: ${props => props.theme.textColors.secondary};
  font-weight: ${props => props.theme.fontWeights.bold};
  cursor: pointer;

  height: 42px;
  padding: 0 20px;
  font-size: 14px;

  &:hover {
    color: ${props => props.theme.textColors.primary};
    background-color: ${props => props.theme.backgroundColors.lightAlt};
  }

  ${({ theme, active }) =>
    active &&
    `
    background-color: ${theme.colors.blue};
    color: white;
    border-color: ${theme.colors.blue};

    &:hover {
      color: white;
      background-color: ${theme.colors.blue};
    }
  `}
`;

const SaveBtn = styled(OptionBtn)`
  background-color: ${props => props.theme.colors.blue};
  border: none;
  color: white;
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
  opacity: ${props => (props.disabled ? 0.5 : 1)};

  &:hover {
    background-color: ${props => lighten(0.05, props.theme.colors.blue)};
    color: white;
  }
`;

const Notes = styled.div`
  width: 100%;
  margin-top: 20px;
`;

const PhoneLabel = styled.label`
  text-transform: uppercase;
  color: ${props => props.theme.textColors.tertiary};
  letter-spacing: 0;
  font-size: 12px;
  font-weight: ${props => props.theme.fontWeights.bold};

  margin-top: 30px;
`;

const Phone = styled.p`
  font-size: 16px;
  margin: 0;
  padding-bottom: 10px;
`;

const MissedCallDate = styled.p`
  font-size: 12px;
  color: ${props => props.theme.textColors.secondary};
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px 20px 30px;
`;

const ResolvedPage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 30px 20px;

  ${OptionBtn} {
    width: 100%;
  }

  ${Footer} {
    padding-bottom: 20px;
  }
`;

export default MissedCallModal;
