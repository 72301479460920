// @flow
import React, { createContext, useReducer } from 'react';

const INITIAL_STATE = { alerts: [] };

export const AlertContext = createContext(INITIAL_STATE);

/**
 * Alert Context.
 *
 * Creates an array of alert objects that are received and displayed by <AlertCenter>
 *
 * Alert object structure:
 * @param {String}  id        uniquely generated id
 * @param {String}  message   the alert message to display
 * @param {String}  type      type of alert (defaults to 'alert')
 * @param {String}  icon      icon componenent from react-icons (optional)
 */

const addID = data => {
  return {
    id: `_${Math.random()
      .toString(36)
      .substring(2, 9)}`,
    ...data,
  };
};

const Context = ({ children }) => {
  const checkAlertMatch = (state, data) => {
    // break alerts into array of messages only
    const messageArr = state.alerts.map(item => item.message);
    // add new message array
    messageArr.push(data.message);
    // some runs on each item until it finds a truthy >> check position of value relative to others
    const foundDuplicate = messageArr.some(
      (item, idx) => messageArr.indexOf(item) !== idx,
    );
    return foundDuplicate;
  };

  const reducer = (state, { type, data }) => {
    // only add alert if twin is not present
    if (type === 'ADD_ALERT' && checkAlertMatch(state, data)) return state;

    switch (type) {
      case 'ADD_ALERT': {
        const alert = addID(data);
        return {
          ...state,
          alerts: [...state.alerts, alert],
        };
      }
      case 'REMOVE_OLDEST_ALERT': {
        return {
          ...state,
          alerts: [...state.alerts.slice(1)],
        };
      }
      case 'CLEAR': {
        return INITIAL_STATE;
      }
      default: {
        return state;
      }
    }
  };

  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <AlertContext.Provider value={{ state, dispatch }}>
      {children}
    </AlertContext.Provider>
  );
};

export default Context;
