import React, { useState, useRef, useCallback } from 'react';
import styled from 'styled-components';
import { FaTimes, FaArrowRight } from 'react-icons/fa';
import * as Realm from 'realm-web';
import logo from '../../../assets/images/logo-grayscale.svg';
import ActivityIndicator from '../common/ActivityIndicator';
import { darken } from 'polished';
import { APP_ID } from '../../../constants';

const EMPTY_CODE = ['', '', '', '', '', ''];

const TwoFactor = ({ onClose, onComplete }) => {
  const digitOne = useRef();
  const digitTwo = useRef();
  const digitThree = useRef();
  const digitFour = useRef();
  const digitFive = useRef();
  const digitSix = useRef();

  const digitRefs = [
    digitOne,
    digitTwo,
    digitThree,
    digitFour,
    digitFive,
    digitSix,
  ];

  const [code, setCode] = useState(EMPTY_CODE);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [sendingCode, setSendingCode] = useState(false);

  const onKeyDownCheckBackspace = (step, e) => {
    if (e.key === 'Backspace') {
      // if error is showing, hide error as soon as new digit entered
      if (error) setError();

      // if field is dirty, erase value
      if (code[step] !== '') {
        const newCode = [...code];
        newCode[step] = '';
        setCode(newCode);
      } else if (step > 0) {
        // otherwise, go to previous digit if not on first digit
        const prevRef = digitRefs[step - 1];
        prevRef.current.focus();
      }
    } else if (!/[0-9]/.test(e.key)) {
      // otherwise, allow only numbers
      e.preventDefault();
    }
  };

  const onDigitChange = (step, value) => {
    if (value === '') return;

    const newCode = [...code];
    newCode[step] = value;
    setCode(newCode);

    // if error is showing, hide error as soon as new digit entered
    if (error) setError();

    // if backspace to delete character, don't change digit placement
    if (value === '') return;

    if (step === code.length - 1) {
      // last digit, full code, submit code
      submitCode(newCode);
    } else {
      // focus next digit
      const nextRef = digitRefs[step + 1];
      nextRef.current.focus();
    }
  };

  const submitCode = async newCode => {
    setLoading(true);

    // Matt -- do whatever your code verification thing is
    const verificationCode = newCode.join('');

    const app = Realm.getApp(APP_ID[process.env.REACT_APP_ENVIRONMENT]);
    try {
      const status = await app.currentUser.functions.verifyAuthCode(
        verificationCode,
      );

      if (status === 'approved') {
        onComplete();
      } else {
        // reset code
        setCode(EMPTY_CODE);

        // focus first digit
        if (digitOne.current) digitOne.current.focus();

        // show error message
        setError(
          'The code you entered was invalid. Please try again or click the "Resend Code" button below.',
        );

        // hide loader
        setLoading(false);
      }
    } catch (e) {
      console.log('submitCode error: ', e);
    }
  };

  const onResendCode = async () => {
    // Matt -- add code here to resend new verification code
    try {
      setError();
      setSendingCode(true);

      const app = Realm.getApp(APP_ID[process.env.REACT_APP_ENVIRONMENT]);
      const status = await app.currentUser.functions.sendVerificationCode();

      if (status) {
        // code sent
        setError('Code resent.');
      } else {
        throw new Error('Code unable to be resent');
      }
    } catch (err) {
      setError(
        'The code was unable to be sent. Wait 30 seconds and try again.',
      );
      console.log('Failed to resend code', err);
    }

    setSendingCode(false);
  };

  return (
    <Container>
      <Header>
        <CloseBtn onClick={onClose}>
          <FaTimes />
        </CloseBtn>
      </Header>
      {loading ? (
        <Loader>
          <ActivityIndicator color="#9A9FAE" />
        </Loader>
      ) : (
        <Body>
          <Title>Two-factor authentication</Title>
          <Subtitle>
            A message with a verification code has been sent to your devices.
          </Subtitle>
          <CodeBox>
            <Label>Enter the code to continue</Label>
            <NumberRow>
              {digitRefs.map((ref, index) => (
                <Number
                  key={`digit-${index}`}
                  ref={ref}
                  value={code[index]}
                  onKeyDown={e => onKeyDownCheckBackspace(index, e)}
                  onChange={e => onDigitChange(index, e.target.value)}
                  maxLength={1}
                  pattern="[0-9]*"
                />
              ))}
              <SubmitBtn onClick={submitCode}>
                <FaArrowRight />
              </SubmitBtn>
            </NumberRow>

            <ErrorMessage>{error}</ErrorMessage>

            <ResendBtn onClick={onResendCode} disabled={sendingCode}>
              {sendingCode ? (
                <ActivityIndicator size={22} color="white" />
              ) : (
                'Resend Code'
              )}
            </ResendBtn>
          </CodeBox>
        </Body>
      )}
      <Logo src={logo} />
    </Container>
  );
};

const SubmitBtn = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: 2px solid ${props => props.theme.colors.border};
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: white;
  cursor: pointer;
  margin-left: 10px;

  svg {
    color: ${props => props.theme.colors.blue};
    font-size: 24px;
  }

  &:hover {
    border-color: ${props => props.theme.colors.blue};
  }
`;

const ResendBtn = styled.button`
  position: relative;
  width: 140px;
  height: 48px;
  outline: none;
  border: none;
  border-radius: 6px;
  background-color: ${props => props.theme.colors.blue};
  font-size: 14px;
  font-family: ${props => props.theme.fonts.secondary};
  font-weight: ${props => props.theme.fontWeights.bold};
  color: white;
  letter-spacing: 0.5px;
  margin-top: 36px;
  cursor: pointer;
  opacity: ${props => (props.disabled ? 0.8 : 1)};

  &:hover {
    background-color: ${props => darken(0.03, props.theme.colors.blue)};
  }
`;

const ErrorMessage = styled.p`
  font-weight: ${props => props.theme.fontWeights.medium};
  font-size: 14px;
  color: ${props => props.theme.colors.primary};
  min-height: 16px;
  margin-top: 24px;
`;

const Loader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  flex: 1;
`;

const Logo = styled.img`
  width: 160px;
  margin-top: auto;
  margin-bottom: 50px;
`;

const CodeBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 30px;
  padding-bottom: 100px;
  flex: 1;
`;

const Label = styled.p`
  font-size: 16px;
  color: ${props => props.theme.textColors.primary};
  font-weight: ${props => props.theme.fontWeights.bold};
  margin-bottom: 20px;
`;

const NumberRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-left: 54px;
`;

const Number = styled.input`
  border: 1px solid ${props => props.theme.colors.border};
  border-radius: 10px;
  padding: 10px;
  width: 60px;
  height: 80px;
  font-size: 32px;
  font-weight: ${props => props.theme.fontWeights.bold};
  color: ${props => props.theme.textColors.primary};
  line-height: 60px;
  text-align: center;
  margin: 0 10px;

  &:focus {
    border-color: ${props => props.theme.colors.blue};
  }
`;

const Title = styled.h2`
  font-weight: ${props => props.theme.fontWeights.bold};
  font-size: 32px;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 10px;
`;

const Subtitle = styled.p`
  font-size: 15px;
  color: ${props => props.theme.textColors.secondary};
`;

const Container = styled.div`
  background-color: white;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 30;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 15px 20px;
  border-top: 4px solid ${props => props.theme.colors.tertiary};
  border-bottom: 1px solid ${props => props.theme.colors.border};
  margin-bottom: auto;
  width: 100%;
`;

const CloseBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.backgroundColors.lightAlt};
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: none;
  outline: none;
  cursor: pointer;
  opacity: 0.8;

  svg {
    color: ${props => props.theme.textColors.tertiary};
    font-size: 24px;
  }

  &:hover {
    opacity: 1;
  }
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding: 30px;
  text-align: center;
`;

export default TwoFactor;
