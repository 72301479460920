import { useCallback, useContext } from 'react';
import amplitude from 'amplitude-js';
import { differenceInSeconds } from 'date-fns';
import { capitalize } from './useTextHelper';
import { CallContext } from '../contexts/CallContext';
import { AMPLITUDE } from '../../constants';

const formatCallType = type => {
  switch (type) {
    case 'request':
      return 'CareLine';
    case 'onboarding':
      return 'Onboarding';
    case 'acute':
      return 'Medical';
    default:
      return '';
  }
};

const formatCarePlanType = type => {
  switch (type) {
    case 'task':
      return 'Patient Task';
    default:
      return capitalize(type);
  }
};

const useAmplitude = () => {
  const {
    state: { room, callType, patient, requests, careplan, startTime },
  } = useContext(CallContext);

  amplitude.getInstance().init(AMPLITUDE[process.env.REACT_APP_ENVIRONMENT]);

  const track = useCallback(
    (eventName, properties, includeCallInfo) => {
      const callInfo = includeCallInfo
        ? {
            'Room ID': room,
            'Call Type': formatCallType(callType),
            'Patient MRN': patient?.mrn,
          }
        : {};
      amplitude
        .getInstance()
        .logEvent(eventName, { ...properties, ...callInfo });
    },
    [room, callType, patient],
  );

  const setId = userId => amplitude.getInstance().setUserId(userId);

  const identify = userProperties =>
    amplitude.getInstance().setUserProperties(userProperties);

  const trackLogin = async user => {
    try {
      track('User Logged In');
      setId(user?.userId);

      identify({
        'First Name': user?.firstName,
        'Last Name': user?.lastName,
        Email: user?.email,
        Role:
          user?.role === 'careline'
            ? 'CareLine Operator'
            : capitalize(user.role),
      });
    } catch (error) {
      console.log('trackLogin Error: ', error);
    }
  };

  const trackCallDetails = useCallback(() => {
    // 1. track each patient request
    requests.map(request => {
      track(
        'Patient Request Added',
        {
          'Patient Request Type': request?.type,
          'Patient Request Urgency': capitalize(request?.urgency),
        },
        true,
      );
    });

    // 2. track each careplan item
    let prescriptions = 0;
    let tasks = 0;
    careplan.map(item => {
      if (item.type === 'task') tasks++;
      else if (item.type === 'medication') prescriptions++;
      track(
        'Care Plan Item Added',
        {
          Condition: item.condition?.display,
          'Care Plan Item Type': formatCarePlanType(item.type),
        },
        true,
      );
    });

    // 3. track final call details
    const properties = {
      'Patient Request Count': requests.length,
      'Prescription Count': tasks,
      'Patient Task Count': prescriptions,
    };
    if (startTime)
      properties['Duration in Seconds'] = differenceInSeconds(
        new Date(),
        startTime,
      );
    track('Call Completed', properties, true);
  }, [requests, careplan, startTime]);

  return { track, trackLogin, trackCallDetails, identify };
};

export default useAmplitude;
