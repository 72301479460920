import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import parse from 'html-react-parser';
import { formatAddress, formatToPhone } from '../../hooks/useTextHelper';
import CareplanIcon from '../common/CareplanIcon';
import CareplanAssignDiagnosisModal from '../modals/CareplanAssignDiagnosisModal';
import { ModalContext } from '../../contexts/ModalContext';

const EncounterCareplan = ({ careplans, diagnosis, pharmacy, activeCall }) => {
  const { dispatch: dispatchModal } = useContext(ModalContext);

  const [data, setData] = useState();

  const openCareplanAssignmentModal = () => {
    dispatchModal({
      type: 'ADD_MODAL',
      data: {
        component: CareplanAssignDiagnosisModal,
        props: {
          data: {
            items: data['undefined'],
            diagnosis,
          },
          maxWidth: '640px',
          hideCover: true,
        },
      },
    });
  };

  useEffect(() => {
    const groupByCondition = array => {
      return array.reduce((acc, obj) => {
        const conditionLabel = obj.condition?.display;
        acc[conditionLabel] = acc[conditionLabel] || [];
        acc[conditionLabel].push(obj);
        return acc;
      }, []);
    };

    const arr = groupByCondition(careplans);
    setData(arr);
  }, [careplans, diagnosis]);

  return data ? (
    <Wrapper>
      {Object.keys(data).map(label => (
        <Diagnosis
          key={label}
          warn={label === 'undefined' && activeCall}
          onClick={openCareplanAssignmentModal}>
          <h3>{label}</h3>
          <Careplan>
            {data[label].map(action => (
              <CareplanAction key={action._id}>
                <CareplanIcon type={action.type} />
                <TextWrapper>
                  <Title>{action.title}</Title>
                  {action.description ? (
                    <Description>{action.description}</Description>
                  ) : null}
                </TextWrapper>
              </CareplanAction>
            ))}
          </Careplan>
        </Diagnosis>
      ))}

      {pharmacy ? (
        <Pharmacy>
          <p>
            <strong>Pharmacy</strong>
          </p>
          <p>
            {pharmacy?.name}
            <br />
            {parse(formatAddress(pharmacy?.address, true))}
          </p>
          <p>{formatToPhone(pharmacy?.phone)}</p>
        </Pharmacy>
      ) : null}
    </Wrapper>
  ) : null;
};

const Wrapper = styled.div``;

const Diagnosis = styled.div`
  background-color: rgba(236, 238, 245, 0.5);
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 15px;
  pointer-events: none;

  h3 {
    font-size: 15px;
    font-weight: normal;
    margin: 0 0 20px;
  }

  ${({ warn, theme }) =>
    warn &&
    `
    pointer-events: all;
    cursor: pointer;

    h3 {
      color: ${theme.colors.warning};
      font-weight: bold;
    }

    &:hover {
      background-color: rgba(236, 238, 245, 1);
    }
  `}
`;

const Careplan = styled.div``;

const CareplanAction = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const TextWrapper = styled.div`
  flex: 1;
  padding-top: 1px;

  p {
    line-height: 1.4;
  }
`;

const Title = styled.p`
  margin: 0 0 4px;
  font-weight: bold;
  font-size: 13px;
`;

const Description = styled.p`
  margin: 0;
  font-size: 12px;
`;

const Pharmacy = styled(Diagnosis)`
  margin-top: 30px;
  text-align: center;

  p {
    font-size: 13px;
    line-height: 1.4;
    margin: 0 0 5px;

    strong {
      font-weight: bold;
      font-size: 14px;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

export default EncounterCareplan;
