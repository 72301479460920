import React, { useContext } from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import { FaAllergies } from 'react-icons/fa';
import List from '../../forms/List';
import { useEventListener } from '../../../hooks/useEventListener';
import { ALLERGIES_FORM } from '../../../../forms/allergies';
import { CallContext } from '../../../contexts/CallContext';
import { SystemContext } from '../../../contexts/SystemContext';
import {
  GET_ALLERGIES,
  ADD_ALLERGY,
  REMOVE_ALLERGY,
  UPDATE_ALLERGY,
} from '../../../api/allergies';

const FORM_HEADER = {
  addTitle: 'Add a new allergy',
  editTitle: 'Edit an existing allergy',
  icon: <FaAllergies />,
  label: 'Allergies',
};

const ChartingCarelineAllergies = ({ patient, isVerified, onVerify, ehr }) => {
  const {
    state: { physicianStatus, room },
  } = useContext(CallContext);
  const {
    state: { realmUser },
  } = useContext(SystemContext);

  const { data, refetch } = useQuery(GET_ALLERGIES, {
    variables: { patient: patient.userId },
  });

  const refreshList = async () => {
    refetch();

    if (physicianStatus === 'waitingForPhysician') {
      await realmUser.functions.sendPusherEvent(
        `presence-${room}`,
        'update-allergies',
      );
    }
  };

  useEventListener('refreshListData', refreshList);

  return (
    <Container>
      {data ? (
        <List
          targetId={patient.userId}
          data={data.allergies}
          form={ALLERGIES_FORM}
          add={ADD_ALLERGY}
          update={UPDATE_ALLERGY}
          remove={REMOVE_ALLERGY}
          formHeader={FORM_HEADER}
          map={{
            label: 'allergy.display',
          }}
          verified={isVerified}
          onVerify={onVerify}
          ehr={ehr}
        />
      ) : null}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  /* padding: 30px 0; */
`;

export default ChartingCarelineAllergies;
