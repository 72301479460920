import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

const CropPhoto = ({ photo, filename, onCrop, unit = '%' }) => {
  const containerRef = useRef();
  const photoRef = useRef();

  const [size, setSize] = useState({ width: 0, height: 0 });

  const [crop, setCrop] = useState();

  const onImageLoaded = image => {
    photoRef.current = image;

    // set initial display size of image
    const w =
      image.naturalWidth *
      (containerRef.current.clientHeight / image.naturalHeight);

    setSize({
      height: `${containerRef.current.clientHeight}px`,
      width: `${w}px`,
    });

    // set initial size of crop bounds box
    const cropH = Math.round(
      ((image.naturalHeight *
        0.7 *
        (image.naturalWidth / image.naturalHeight)) /
        image.naturalHeight) *
        100,
    );

    setCrop({
      unit,
      width: 70,
      height: cropH,
      x: 15,
      y: Math.round((100 - cropH) / 2),
      aspect: 1 / 1,
    });

    return false;
  };

  const getCroppedImg = image => {
    const canvas = document.createElement('canvas');
    const cropWidth = (crop.width / 100) * image.naturalWidth;
    const cropHeight = (crop.height / 100) * image.naturalHeight;
    canvas.width = cropWidth;
    canvas.height = cropHeight;
    const ctx = canvas.getContext('2d');
    const cropX = (crop.x / 100) * image.naturalWidth;
    const cropY = (crop.y / 100) * image.naturalHeight;
    ctx.drawImage(
      image,
      cropX,
      cropY,
      cropWidth,
      cropHeight,
      0,
      0,
      cropWidth,
      cropHeight,
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          reject(new Error('Canvas is empty'));
          return;
        }
        resolve(blob);
      }, 'image/jpeg');
    });
  };

  const onCropComplete = async crop => {
    if (photoRef.current && crop.width && crop.height) {
      try {
        const croppedImage = await getCroppedImg(photoRef.current);
        onCrop(croppedImage);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const onCropChange = (crop, percentCrop) => {
    setCrop(percentCrop);
  };

  // handle resizing window
  useEffect(() => {
    const ro = new ResizeObserver(entries => {
      if (!photoRef.current) return;
      for (let entry of entries) {
        const w =
          photoRef.current.naturalWidth *
          (entry.contentRect.height / photoRef.current.naturalHeight);
        setSize({
          height: `${entry.contentRect.height}px`,
          width: `${w}px`,
        });
      }
    });

    ro.observe(containerRef.current);

    return () => {
      if (ro) {
        ro.disconnect();
      }
    };
  }, []);

  return (
    <Container ref={containerRef}>
      <CropWrapper style={{ width: size.width, height: size.height }}>
        <ReactCrop
          src={photo}
          crop={crop}
          onImageLoaded={onImageLoaded}
          onComplete={onCropComplete}
          onChange={onCropChange}
          ruleOfThirds
          keepSelection
          minHeight={200}
        />
      </CropWrapper>
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
`;

const CropWrapper = styled.div`
  margin: 0 auto;
  height: 100%;
`;

export default CropPhoto;
