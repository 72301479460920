import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from 'react';
import styled from 'styled-components';
import { FaMicrophoneSlash } from 'react-icons/fa';
import BatteryMeter from '../vitals/BatteryMeter';
import { VERBOSE } from '../../../constants';
import { CallContext } from '../../contexts/CallContext';
import UserIcon from '../common/UserIcon';

const RemoteVideoParticipant = ({ participant }) => {
  const {
    state: { patientBattery },
  } = useContext(CallContext);

  const [audioEnabled, setAudioEnabled] = useState(true);
  const [videoTrack, setVideoTrack] = useState();
  const [identity, setIdentity] = useState();
  const [videoEnabled, setVideoEnabled] = useState(true);

  const videoRef = useRef();
  const audioRef = useRef();

  const toggleAudio = useCallback(
    isEnabled => {
      setAudioEnabled(isEnabled);
    },
    [setAudioEnabled],
  );

  const toggleVideo = useCallback(
    isEnabled => {
      setVideoEnabled(isEnabled);
    },
    [setVideoEnabled],
  );

  const stopVideo = useCallback(() => {
    if (VERBOSE) console.log(`stopping remote video`);
    try {
      videoTrack.stop();
      videoTrack.detach().forEach(el => el.remove());
    } catch (err) {}

    setVideoTrack(null);
  }, [videoTrack, setVideoTrack]);

  /**************************************
   *  WHEN NEW TRACK IS FIRST DETECTED
   **************************************/

  const initializeTrack = useCallback(
    track => {
      if (VERBOSE) console.log('initializing track: ', track);

      // audio muting relies on enable/disable events, subscribe here
      if (track.kind === 'audio') {
        track.on('enabled', () => toggleAudio(true));
        track.on('disabled', () => toggleAudio(false));
        track.attach(audioRef.current);
        setAudioEnabled(track.isEnabled);
      } else if (track.kind === 'video') {
        videoRef.current.appendChild(track.attach());
        track.on('enabled', () => toggleVideo(true));
        track.on('disabled', () => toggleVideo(false));
        track.on('unsubscribed', stopVideo);
        setVideoTrack(track);
      }
    },
    [toggleAudio, toggleVideo, stopVideo],
  );

  /**********
   *  INIT
   **********/

  useEffect(() => {
    if (VERBOSE) console.log('participant: ', participant);
    if (!participant) return;

    setIdentity(JSON.parse(participant.identity));

    participant.tracks.forEach(publication => {
      if (publication.isSubscribed) {
        initializeTrack(publication.track);
      }

      publication.on('subscribed', initializeTrack);
    });

    return () => {
      participant.tracks.forEach(publication => {
        stopVideo();
        publication.removeAllListeners();
      });
      participant.removeAllListeners();
    };
  }, [participant]);

  return (
    <Participant id={participant.sid}>
      <VideoWrapper ref={videoRef} />
      <audio ref={audioRef} autoPlay={true} />
      {!videoEnabled ? (
        <NoVideoWrapper>
          <NoVideoInfo>
            <UserIcon
              user={identity}
              size="80px"
              video
              patient={identity?.role === 'patient'}
            />
            {identity ? (
              <>
                <Name>{identity.name}</Name>
                <Role>{identity.role}</Role>
                {audioEnabled ? null : (
                  <AudioIcon>
                    <FaMicrophoneSlash />
                  </AudioIcon>
                )}
              </>
            ) : null}
          </NoVideoInfo>
        </NoVideoWrapper>
      ) : identity ? (
        <NameTag>
          <NameTagName>{`${identity.name} (${identity.role})`}</NameTagName>
          {identity.role === 'patient' && patientBattery ? (
            <BatteryWrapper value={patientBattery.value}>
              <BatteryMeter value={patientBattery.value} color="white" ipad />
              <BatteryPct>{patientBattery.pct}</BatteryPct>
            </BatteryWrapper>
          ) : null}
          {audioEnabled ? null : (
            <AudioIcon>
              <FaMicrophoneSlash />
            </AudioIcon>
          )}
        </NameTag>
      ) : null}
    </Participant>
  );
};

const Name = styled.div`
  font-size: 22px;
  font-weight: ${props => props.theme.fontWeights.medium};
  color: white;
  margin-top: 18px;
  opacity: 60%;
`;

const Role = styled.div`
  font-size: 15px;
  text-transform: uppercase;
  color: white;
  font-weight: ${props => props.theme.fontWeights.bold};
  margin-top: 7px;
  opacity: 40%;
`;

const AudioIcon = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background-color: rgba(32, 33, 36, 0.5);
  margin-left: 12px;
  border-radius: 50%;

  svg {
    color: white;
    font-size: 22px;
  }
`;

const NoVideoWrapper = styled.div`
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #333;

  ${AudioIcon} {
    margin-left: 0;
    margin-top: 10px;
  }
`;

const NoVideoInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const NameTag = styled.div`
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  padding: 0 16px;
  width: 100%;
`;

const NameTagName = styled.span`
  font-size: 14px;
  font-family: ${props => props.theme.fonts.secondary};
  font-weight: ${props => props.theme.fontWeights.medium};
  color: white;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6), 0 0 2px rgba(0, 0, 0, 0.3);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding: 12px 0;
`;

const BatteryPct = styled.span`
  display: block;
  font-size: 9px;
  color: white;
  text-align: center;
  margin-top: -5px;
`;

const BatteryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 2px 0 0 12px;

  ${({ value }) =>
    value &&
    `
    ${BatteryPct} {
      display: none;
    }
  `}

  &:hover {
    ${BatteryPct} {
      display: block;
    }
  }
`;

const Participant = styled.div`
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  height: 100%;
  flex: 1;
`;

const VideoWrapper = styled.div`
  width: 100%;
  height: 100%;

  > video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export default RemoteVideoParticipant;
