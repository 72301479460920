import React from 'react';
import styled from 'styled-components';
import EncounterBox from './EncounterBox';

const parseFindings = findings => {
  let arr = [];
  for (let i = 0; i < findings.length; i++) {
    arr.push(findings[i].label);
  }
  return arr.join(', ');
};

const formatWithLocations = obj => {
  const { locations, label } = obj;
  const locationsArr = [];
  locations.map(item => {
    locationsArr.push(item.label);
  });
  return `${label} (${locationsArr.join(', ')})`;
};

const EncounterExams = ({ exams }) => {
  const examsData = exams.slice(1, exams.length);

  return examsData?.length > 0
    ? examsData.map(exam => {
        const { _id, system, notes, inspections } = exam;

        return (
          <Section key={_id}>
            <EncounterBox>
              <Title>{system?.label}</Title>
              <Table>
                <HeaderRow>
                  <Cell>
                    <label>Specificity</label>
                  </Cell>
                  <Cell>
                    <label>Finding</label>
                  </Cell>
                </HeaderRow>
                {inspections.map(obj => (
                  <Row key={obj.type}>
                    <Cell>
                      <p>
                        {obj.locations ? formatWithLocations(obj) : obj.label}
                      </p>
                    </Cell>
                    <Cell>{parseFindings(obj.selected)}</Cell>
                  </Row>
                ))}
                {notes ? (
                  <Row>
                    <Cell>
                      <p>Notes</p>
                    </Cell>
                    <Cell>{notes}</Cell>
                  </Row>
                ) : null}
              </Table>
            </EncounterBox>
          </Section>
        );
      })
    : null;
};

const Section = styled.div`
  margin-bottom: 30px;
`;

const Title = styled.h3`
  font-size: 16px;
  font-weight: normal;
  color: ${props => props.theme.textColors.primary};
  margin-top: 0;
`;

const Table = styled.div``;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid ${props => props.theme.colors.border};

  &:last-of-type {
    border-bottom: none;
    padding-bottom: 0;
  }
`;

const HeaderRow = styled(Row)`
	label {
		color: ${props => props.theme.textColors.secondary}
		font-size: 10px;
		letter-spacing: 0.5px;
		margin: 0;
	}
`;

const Cell = styled.div`
  font-size: 14px;
  line-height: 1.4;
  color: ${props => props.theme.textColors.primary};
  padding: 8px 0;

  p {
    text-transform: uppercase;
    font-size: 11px;
    padding-top: 3px;
    margin: 0;
  }

  &:first-of-type {
    width: 220px;
    padding-right: 20px;
  }

  &:last-of-type {
    flex: 1;
    font-weight: bold;
  }
`;

export default EncounterExams;
