import React from 'react';
import { FaPrescriptionBottle } from 'react-icons/fa';
import { formatDateRange } from '../App/hooks/useTextHelper';
import { MEDICATION_FREQUENCIES } from '../constants';

export const MEDICATIONS_FORM = {
  fields: [
    {
      id: 'medication',
      type: 'search',
      label: 'Medication',
      config: {
        fill: true,
      },
    },
    {
      id: 'medications-row',
      type: 'row',
      fields: [
        {
          id: 'timing',
          type: 'select',
          config: {
            options: MEDICATION_FREQUENCIES,
          },
          label: 'Frequency',
        },
        {
          id: 'effectiveDateTime',
          label: 'Start Date',
          type: 'monthYear',
          config: {
            convertToDate: true,
          },
        },
      ],
    },
    {
      id: 'notes',
      type: 'textarea',
      label: 'Notes',
    },
  ],
};

export const MEDICATIONS_FORM_HEADER = {
  addTitle: 'Add a new medication',
  editTitle: 'Edit an existing medication',
  icon: <FaPrescriptionBottle />,
  label: 'Medications',
};

export const MEDICATIONS_TABLE = [
  {
    id: 'effectiveDateTime',
    label: 'Start Date',
    sortable: true,
    formatFn: formatDateRange,
    width: '120px',
  },
  {
    id: 'medication.display',
    label: 'Medication',
    sortable: true,
    flex: 1,
  },
  {
    id: 'timing',
    label: 'Frequency',
    width: '120px',
    align: 'right',
    formatFn: code => MEDICATION_FREQUENCIES[code].label || '-',
  },
];
